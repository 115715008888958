import { useColorModeValue } from '@chakra-ui/react';
import { createContext, useContext, useMemo } from 'react';

interface ThemeColors {
	textColor: string;
	borderColor: string;
	buttonBg: string;
	hoverButton: { bg: string };
	activeButton: { bg: string };
	brand: string;
	textColorPrimary: string;
	textColorSecondary: string;
	brandColor: string;
	bgPrimary: string;
	borderPrimary: string;
	bg: string;
	shadow: string;
	cardBg: string;
}

const ThemeColorContext = createContext<ThemeColors | undefined>(undefined);

export function ThemeColorProvider({ children }: { children: React.ReactNode }) {
	const textColor = useColorModeValue('navy.700', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	const buttonBg = useColorModeValue('transparent', 'navy.800');
	const hoverButton = useColorModeValue({ bg: 'gray.100' }, { bg: 'whiteAlpha.100' });
	const activeButton = useColorModeValue({ bg: 'gray.200' }, { bg: 'whiteAlpha.200' });
	const brand = useColorModeValue('brand.500', 'brand.400');
	const brandColor = useColorModeValue('brand.500', 'brand.400');
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';
	const bgPrimary = useColorModeValue('transparent', 'navy.800');
	const borderPrimary = useColorModeValue(
		'secondaryGray.100',
		'whiteAlpha.100',
	);
	const bg = useColorModeValue('brand.500', 'brand.400');

	const shadow = useColorModeValue(
		' 0px 50px 40px -34px rgba(112, 144, 176, 0.16)',
		'unset',
	);
	const cardBg = useColorModeValue('white', 'navy.800');

	const themeColors = useMemo<ThemeColors>(
		() => ({
			textColor,
			textColorPrimary,
			textColorSecondary,
			borderColor,
			buttonBg,
			hoverButton,
			activeButton,
			brand,
			brandColor,
			bgPrimary,
			borderPrimary,
			bg,
			shadow,
			cardBg
		}),
		[]
	);

	return <ThemeColorContext.Provider value={themeColors}>{children}</ThemeColorContext.Provider>;
}

export function useThemeColors(): ThemeColors {
	const context = useContext(ThemeColorContext);
	if (!context) {
		throw new Error('useThemeColors must be used within a ThemeColorProvider');
	}
	return context;
}
