// Chakra imports
import {
	Avatar,
	Box,
	Flex,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue
} from '@chakra-ui/react';
import React from 'react';
import { ChatMessage } from './Messages';
import { FiLogOut, FiMinusCircle, FiMoreVertical } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

export default function MessageBlock(props: {
	item: ChatMessage;
	banUser: (memberId: string, memberType: string) => void;
	blockMessage: (messageId: string) => void;
	isLast?: boolean;
	seen?: boolean;
}) {
	const { t } = useTranslation();

	const { item, banUser, blockMessage, isLast, seen, ...rest } = props;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const blockBg = useColorModeValue('secondaryGray.300', 'navy.700');
	const brandBlockBg = useColorModeValue('brand.500', 'brand.400');
	const brandColor = useColorModeValue('brand.500', 'white');

	return (
		<Box
			borderRadius={item.messageType === 'normal' ? '0px 16px 16px 16px' : '16px 0px 16px 16px'}
			bg={item.messageType === 'normal' ? blockBg : brandBlockBg}
			justifyContent="center"
			alignItems="center"
			px="24px"
			py="16px"
			w="max-content"
			alignSelf={item.messageType === 'normal' ? 'flex-start' : 'flex-end'}
			maxW={{ base: '80%', lg: '65%', xl: '52%' }}
			mb={isLast ? { base: '10px', md: 'none' } : { base: '10px' }}
			{...rest}
		>
			{item.messageType === 'system' ? (
				<Flex direction={'column'} w={'100%'}>
					<Text color={'white'} fontSize={{ base: 'md', '2xl': 'md' }} me="6px" fontWeight="400" w={'100%'}>
						{item.message}
					</Text>
				</Flex>
			) : (
				<Flex direction={'column'} w={'100%'}>
					<Flex direction={'row'} justifyContent={'flex-start'}>
						<Avatar src={item.userProfileImgUrl} size={'sm'} />
						<Text
							color={item.messageType === 'normal' ? textColor : 'white'}
							fontWeight={'bold'}
							ml={'5px'}
							alignSelf={'center'}
							overflow={'hidden'}
						>
							{item.userName}
						</Text>
						{!item.isOwner  ? (
							<Menu size={'sm'}>
								<MenuButton size="sm" as={IconButton} aria-label="Options" icon={<FiMoreVertical />} variant="none" />
								<MenuList>
									<MenuItem
										icon={<FiLogOut />}
										onClick={() => {
											blockMessage(item.messageId);
										}}
									>
										{t('content.live.block_message')}
									</MenuItem>
									<MenuItem
										icon={<FiMinusCircle />}
										onClick={() => {
											banUser(item.userId, item.userType);
										}}
									>
										{t('content.live.ban_user')}
									</MenuItem>
								</MenuList>
							</Menu>
						) : null}
					</Flex>
					<Text
						color={item.messageType === 'normal' ? textColor : 'white'}
						fontSize={{ base: 'md', '2xl': 'md' }}
						me="6px"
						mt={'5px'}
						fontWeight="400"
						w={'100%'}
					>
						{item.message}
					</Text>
					<Flex mt="5px">
						<Text
							color={item.messageType === 'normal' ? 'secondaryGray.600' : 'white'}
							fontSize={{ base: 'xs', '2xl': 'sm' }}
							fontWeight="500"
						>
							{item.createdDateTime}
						</Text>
					</Flex>
				</Flex>
			)}
		</Box>
	);
}
