// Chakra imports
import {
	Button,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text
} from '@chakra-ui/react';
import { useLogin } from '../../../../providers/login-provider';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { MemberStatusType, MemberType } from '../../../../types/enums';

// Assets

export default function ModalBlacklistUser(props: {
	isOpen: boolean;
	requestBlacklistUser: (id: number, type: MemberType, reason: string) => void;
	requestMemberDeleteBlacklisted: (id: number, type: MemberType, reason: string) => void;
	user: any;
	onClose: () => void;
}) {
	// Chakra Color Mode
	const { isOpen, user, requestBlacklistUser, requestMemberDeleteBlacklisted, onClose } = props;

	const { loginInfo } = useLogin();
	const { t } = useTranslation();

	const [reason, setReason] = useState('');

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					{user?.memberStatus === MemberStatusType.BLACK_LISTED ? t('user.remove_blacklist') : t('user.add_blacklist')}{' '}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Text
						color="secondaryGray.600"
						fontSize={{
							base: 'sm'
						}}
						fontWeight="400"
						me="5px"
						mb="5px"
						noOfLines={0}
						w="100%"
						whiteSpace={'pre-line'}
						maxWidth="100%"
					>
						{user?.memberStatus === MemberStatusType.BLACK_LISTED
							? t('user.remove_blacklist_desc')
							: t('user.add_blacklist_desc')}
					</Text>
					<FormControl>
						<FormLabel>{t('common.admin_name')}</FormLabel>
						<text>{loginInfo.email}</text>
					</FormControl>
					<FormControl mt={4}>
						<FormLabel>
							{user?.memberStatus === MemberStatusType.BLACK_LISTED
								? t('user.reason_unblacklist')
								: t('user.reason_blacklist')}
						</FormLabel>
						<Input
							placeholder={t('user.modal_placeholder_blacklist')}
							value={reason}
							onChange={e => {
								setReason(e.target.value);
							}}
						/>
					</FormControl>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="brand"
						mr={3}
						onClick={() => {
							if (user?.memberStatus === MemberStatusType.BLACK_LISTED) {
								requestMemberDeleteBlacklisted(user?.memberId, user?.memberType, reason);
							} else {
								requestBlacklistUser(user?.memberId, user?.memberType, reason);
							}
						}}
					>
						{t('common.ok')}
					</Button>
					<Button
						onClick={() => {
							onClose();
						}}
					>
						{t('common.cancel')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
