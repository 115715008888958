// Chakra imports
import {
	Button,
	Flex,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Tooltip,
	useColorModeValue
} from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Card from 'components/card/Card';

import { useTranslation } from 'react-i18next';
import { MdOutlineAdd, MdOutlineSearch } from 'react-icons/md';
import { createDynamicColumns, VisibleColumns } from 'components/table/createDynamicColumns';
import { useApi } from '../../../providers/api-provider';
import { useThemeColors } from '../../../providers/theme-provider';
import SearchInput from '../../../components/actions/Search';
import Filter from '../../../components/actions/Filter';
import { TYPE_KEY_SORT } from '../../../helper/types';
import SearchTable from '../../../components/table/SearchTable';
import { ProductListRequest, ProductListResponse, ProductTrain } from '../../../apis/productAPI';
import { TableRowType } from '../../../types/enums';
import ProductDetail from './detail';

// 금지어 목록
export default function Products() {
	const { productApi } = useApi();

	const { t } = useTranslation();
	const { textColor, buttonBg, hoverButton, activeButton } = useThemeColors();

	// 목록
	const [list, setList] = useState<ProductTrain[]>([]);
	const [searchParams, setSearchParams] = useState<Partial<ProductListRequest>>({ isVisible: true });

	// 전체 갯수
	const [totalCount, setTotalCount] = useState<number>(0);
	// 한번에 불러올 목록
	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);

	// 상세 팝업
	const [selectDetailId, setDetailId] = useState<string>('');
	const [isShowModal, setIsShowModal] = useState<boolean>(false);

	useEffect(() => {
		requestList(offset);
	}, []);

	// 목록 요청
	const requestList = async (offset: number) => {
		setOffset(offset);

		productApi
			.getProductList({
				limit: limit,
				offset: offset * limit,
				...searchParams
			})
			.then(responseList)
			.catch(() => {});
	};

	// 목록 처리
	const responseList = (response: ProductListResponse) => {
		if (response.products) {
			setList(response.products);
		}

		if (response.count) {
			setTotalCount(response.count);
		}
	};

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<ProductTrain>[] = [
		{
			row: 'productId',
			title: 'id',
			type: TableRowType.TEXT
		},
		{ row: 'name', type: TableRowType.TEXT },
		{ row: 'type', title: 'productType', type: TableRowType.ENUM },
		{ row: 'priceUnit', title: 'priceUnit', type: TableRowType.TEXT },
		{ row: 'iconImgUrl', title: 'visualImageUrl', type: TableRowType.IMAGE }
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)
		// window.location.href = '';
		setDetailId(rowData.productId);
		setIsShowModal(true);
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	// 정렬 필터 변경
	const filterChange = (type: string) => {};

	// 검색 엔터키 처리
	const handleSearchKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleClickSearch();
		}
	};

	const handleClickSearch = () => {};

	// 검색어 저장
	const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {};

	const handleClickAdd = () => {
		setIsShowModal(true);
		setDetailId('-1');

		//window.location.href = '';
	};

	// Chakra Color Mode
	return (
		<Flex direction="column" pt={{ sm: '75px', lg: '75px' }}>
			<Card px="0px">
				<Flex w="100%" direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'} mb="36px">
					<SearchInput
						onChangeSearch={onSearchInputChange}
						onEnterKeyPress={handleSearchKeyPress}
						rightElement={
							<Button h="1.75rem" size="sm" onClick={handleClickSearch} borderRadius="20px">
								<Icon color={textColor} as={MdOutlineSearch} />
							</Button>
						}
					/>
					<Flex justifyContent={'flex-end'} mr={{ base: '0px', sm: '10px' }}>
						<Filter
							onChange={filterChange}
							initialSortType={TYPE_KEY_SORT.LATEST}
							secondaryTitle={'components.filter_alphabet'}
						/>
						<Tooltip label={t('settings.register_app_meta')}>
							<Button
								me={{ base: '10px', md: '20px' }}
								ml={{ base: '10px', sm: '10px', md: '10px' }}
								bg={buttonBg}
								border="1px solid"
								color="secondaryGray.600"
								borderColor={useColorModeValue('secondaryGray.100', 'whiteAlpha.100')}
								borderRadius="16px"
								_placeholder={{ color: 'secondaryGray.600' }}
								_hover={hoverButton}
								_active={activeButton}
								_focus={activeButton}
								onClick={handleClickAdd}
							>
								<Icon color={textColor} as={MdOutlineAdd} />
							</Button>
						</Tooltip>
					</Flex>
				</Flex>
				<SearchTable request={requestList} data={list} columns={columns} totalCount={totalCount} />
			</Card>

			<Modal
				isOpen={isShowModal}
				onClose={() => {
					setIsShowModal(false);
				}}
				isCentered
				size={'xg'}
			>
				<ModalOverlay />

				<ModalContent>
					<ModalHeader>{''}</ModalHeader>
					<ModalCloseButton />
					<ModalBody overflow="auto">
						<ProductDetail
							detailId={selectDetailId}
							requestChangeParams={() => {
								requestList(offset);
								setIsShowModal(false);
							}}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	);
}

function onClose(): void {
	throw new Error('Function not implemented.');
}
