// Chakra imports
import { Box, Flex, Grid, Icon, SimpleGrid } from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import TextField from 'components/fields/TextField';
import { useThemeColors } from '../../../../providers/theme-provider';
import { useApi } from '../../../../providers/api-provider';
import Card from 'components/card/Card';
import { showToast } from '../../../../helper/functions';
import {
	ActionTrain,
	ConditionTrain,
	MissionTrain,
	StageDetailResponse,
	StageTrain
} from '../../../../apis/projectAPI';
import { useParams } from 'react-router-dom';
import ImageField from 'components/fields/ImageField';
import { MdOutlineClose } from 'react-icons/md';
import InputField from 'components/fields/InputField';
import TextAreaField from 'components/fields/TextAreaField';
import { MissionActionGoalType } from '../../../../types/enums';
import { HSeparator } from '../../../../components/separator/Separator';
import ButtonField from 'components/fields/ButtonField';
import SelectField from 'components/fields/SelectField';

// 금지어 목록
export default function StageDetail() {
	const { t } = useTranslation();
	const { textColor } = useThemeColors();
	const { projectApi } = useApi();

	const { stageId, requestChangeParams } = useParams();

	const [item, setItem] = useState<StageTrain>(null);
	const [mission, setMission] = useState<MissionTrain>(null);

	useEffect(() => {
		if (stageId !== '-1') {
			requestDetail();
		}
	}, [stageId]);

	const requestDetail = () => {
		projectApi
			.getStageDetail(stageId)
			.then(responseDetail)
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	const responseDetail = (response: StageDetailResponse) => {
		if (response.detail) {
			setItem(response.detail);
		}
	};

	const modifyStage = () => {
		delete item.createDateTime;
		delete item.updateDateTime;

		const form = new FormData();
		form.append('request', JSON.stringify(item));

		projectApi
			.putStageDetail(form)
			.then(() => {
				showToast(t('messages.applied'));
				// requestDetail();
			})
			.catch(() => {});
	};

	const modifyMission = () => {
		delete mission.createDateTime;
		delete mission.updateDateTime;

		if (mission.rewords && mission.rewords.length > 0) {
			mission.rewords.map((r, i) => {
				delete r.createDateTime;
				delete r.updateDateTime;
			});
		}

		projectApi
			.putMissionDetail(mission)
			.then(() => {
				showToast(t('messages.applied'));
				requestDetail();
			})
			.catch(() => {});
	};

	if (!item) {
		return <Flex />;
	}

	const getMissionAction = (actionIndex: number) => {
		return actionIndex === 1 ? mission.action1 : actionIndex === 2 ? mission.action2 : mission.action3;
	};

	const changeMissionAction = (actionIndex: number, action: ActionTrain) => {
		if (actionIndex === 1) {
			setMission(prevState => {
				return {
					...prevState,
					action1: action
				};
			});
		} else if (actionIndex === 2) {
			setMission(prevState => {
				return {
					...prevState,
					action2: action
				};
			});
		} else {
			setMission(prevState => {
				return {
					...prevState,
					action3: action
				};
			});
		}
	};

	const createMissionCondition = (actionIndex: number, cd: ConditionTrain, j: number) => {
		return (
			<>
				<TextField label={'미션 ' + actionIndex + ' 타입'} text={cd.type} />
				<InputField
					label={'미션 ' + actionIndex + ' 통과 목표 갯수'}
					value={cd.goal}
					type={'number'}
					onChange={e => {
						let action = getMissionAction(actionIndex);
						action.conditions[j].goal = Number(e.target.value);
						changeMissionAction(actionIndex, action);
					}}
				/>
				{cd.type === MissionActionGoalType.EVALUATION ? (
					<>
						<InputField
							label={'미션 ' + actionIndex + ' 심사 질문'}
							value={cd.evaluation?.question}
							onChange={e => {
								let action = getMissionAction(actionIndex);
								action.conditions[j].evaluation.question = e.target.value;
								changeMissionAction(actionIndex, action);
							}}
						/>
						<InputField
							label={'미션 ' + actionIndex + ' 심사 버튼명 1'}
							value={cd.evaluation?.buttonName1}
							onChange={e => {
								let action = getMissionAction(actionIndex);
								action.conditions[j].evaluation.buttonName1 = e.target.value;
								changeMissionAction(actionIndex, action);
							}}
						/>
						<InputField
							label={'미션 ' + actionIndex + ' 심사 버튼명 2'}
							value={cd.evaluation?.buttonName2}
							onChange={e => {
								let action = getMissionAction(actionIndex);
								action.conditions[j].evaluation.buttonName2 = e.target.value;
								changeMissionAction(actionIndex, action);
							}}
						/>
						<SelectField
							label={'미션 액션 ' + actionIndex + ' 심사 통과 버튼 번호'}
							value={String(cd.evaluation?.passButtonNumber)}
							options={['1', '2']}
							onChange={e => {
								let action = getMissionAction(actionIndex);
								action.conditions[j].evaluation.passButtonNumber = Number(e.target.value);
								changeMissionAction(actionIndex, action);
							}}
						/>
					</>
				) : null}

				{/*<ButtonField*/}
				{/*	buttonName={t('common.save')}*/}
				{/*	onClick={() => {*/}
				{/*		modifyMission();*/}
				{/*	}}*/}
				{/*/>*/}
			</>
		);
	};

	return (
		<Box
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '130px', md: '80px', xl: '80px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Grid
				display={{ base: 'block', md: 'grid' }}
				pt={{ base: '0px', md: '0px', xl: '0px' }}
				gap={{ base: '20px', xl: '20px' }}
			>
				<Flex direction={'column'}>
					<Grid
						display={{ base: 'block', md: 'grid' }}
						gridTemplateColumns={{ base: mission ? '1fr 1fr 1fr' : '1.5fr 1.5fr' }}
						gap={{ base: '20px', xl: '20px' }}
					>
						<Card>
							<SimpleGrid>
								<TextField label={'Id'} text={String(item.stageId)} />
								<InputField
									label={'이름'}
									value={item?.name}
									onChange={e => {
										setItem(prevState => {
											return {
												...prevState,
												name: e.target.value
											};
										});
									}}
								/>
								<TextAreaField
									label={'설명'}
									value={item?.description}
									onChange={e => {
										setItem(prevState => {
											return {
												...prevState,
												description: e.target.value
											};
										});
									}}
								/>
								<ImageField label={'이미지'} url={item.visualImageUrl} />
								<ButtonField
									buttonName={t('common.save')}
									onClick={() => {
										modifyStage();
									}}
								/>
							</SimpleGrid>
						</Card>
						<Card>
							<SimpleGrid>
								{item.missions.map((ms, index) => {
									return (
										<Card
											mb={'20px'}
											backgroundColor={mission && mission.missionId === ms.missionId ? "blue.50": "white"}
											onClick={() => {
												setMission(ms);
											}}
										>
											<TextField
												label={'미션 Id'}
												text={String(ms.missionId)}
												onClick={() => {
													setMission(ms);
												}}
											/>
											<InputField
												label={'미션 이름'}
												value={ms.name}
												disabled={true}
											/>
											{/*<TextAreaField*/}
											{/*	label={'설명'}*/}
											{/*	value={mission ?  mission?.description: ms.description}*/}
											{/*	disabled={mission === null || mission.missionId !== ms.missionId}*/}
											{/*	onChange={e => {*/}
											{/*		setMission(prevState => {*/}
											{/*			return {*/}
											{/*				...prevState,*/}
											{/*				description: e.target.value*/}
											{/*			};*/}
											{/*		});*/}
											{/*	}}*/}
											{/*/>*/}
										</Card>
									);
								})}
							</SimpleGrid>
						</Card>
						{mission ? (
							<Card>
								<SimpleGrid>
									<Icon
										color={textColor}
										as={MdOutlineClose}
										onClick={() => {
											setMission(null);
										}}
									/>


									<InputField
										label={'미션 이름'}
										value={mission.name}
										onChange={e => {
											setMission(prevState => {
												return {
													...prevState,
													name: e.target.value
												};
											});
										}}
									/>
									<TextAreaField
										label={'설명'}
										value={mission.description ? mission.description : ''}
										onChange={e => {
											setMission(prevState => {
												return {
													...prevState,
													description: e.target.value
												};
											});
										}}
									/>

									{mission.action1 ? (
										<>
											<TextField label={'미션 액션 1 타입'} text={mission.action1.type} />

											{mission.action1.conditions &&
												mission.action1.conditions.length > 0 &&
												mission.action1.conditions.map((cd, j) => {
													return createMissionCondition(1, cd, j);
												})}
										</>
									) : null}

									{mission.action2 ? (
										<>
											<HSeparator mb={'20px'} />
											<TextField label={'미션 액션 2 타입'} text={mission.action2.type} />

											{mission.action2.conditions &&
												mission.action2.conditions.length > 0 &&
												mission.action2.conditions.map((cd, j) => {
													return createMissionCondition(2, cd, j);
												})}
										</>
									) : null}

									{mission.action3 ? (
										<>
											<HSeparator mb={'20px'} />
											<TextField label={'미션 액션 3 타입'} text={mission.action3.type} />

											{mission.action3.conditions &&
												mission.action3.conditions.length > 0 &&
												mission.action3.conditions.map((cd, j) => {
													return createMissionCondition(3, cd, j);
												})}
										</>
									) : null}

									<ButtonField
										buttonName={t('common.save')}
										onClick={() => {
											modifyMission();
										}}
									/>
								</SimpleGrid>
							</Card>
						) : null}
					</Grid>
				</Flex>
			</Grid>
		</Box>
	);
}
