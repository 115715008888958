import React from 'react';

// chakra imports
import {
	Box, Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	Flex,
	Icon, Tooltip,
	useColorModeValue,
	useDisclosure
} from "@chakra-ui/react";
import Content from 'components/sidebar/components/Content';
import { renderThumb, renderTrack, renderView, renderViewMini } from 'components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';

// Assets
import { IoMenuOutline } from 'react-icons/io5';
import { useAuth } from '../../providers/auth-provider';
import { TYPE_ROUTE_SCREEN } from 'helper/types';
import { MdOutlineLogout, MdOutlineMenu } from "react-icons/md";
import { useTranslation } from "react-i18next";

function Sidebar(props: { routes: RoutesType[]; [x: string]: any }) {
	const { routes, mini, hovered, setHovered } = props;
	const { availableAdminMenus } = useAuth();

	let variantChange = '0.2s linear';
	let sidebarMargins = '0px';

	return (
		<Box
			display={{ sm: 'none', xl: 'block' }}
			position="fixed"
			minH="100%"
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			<Box
				bg={'transparent'}
				transition={variantChange}
				w={mini === false ? '285px' : mini === true && hovered === true ? '285px' : '120px'}
				ms={{
					sm: '16px'
				}}
				my={{
					sm: '16px'
				}}
				h="calc(100vh - 32px)"
				m={sidebarMargins}
				minH="100%"
				overflowX="hidden"
			>
				<Scrollbars
					autoHide
					renderTrackVertical={renderTrack}
					renderThumbVertical={renderThumb}
					renderView={mini === false ? renderView : mini === true && hovered === true ? renderView : renderViewMini}
				>
					<Content mini={mini} hovered={hovered} routes={getAllowedRoutes(routes, availableAdminMenus)} />
				</Scrollbars>
			</Box>
		</Box>
	);
}

// FUNCTIONS
export function SidebarResponsive(props: { routes: RoutesType[]; [x: string]: any }) {
	const { routes } = props;
	const { availableAdminMenus } = useAuth();

	let menuColor = useColorModeValue('gray.400', 'white');
	let sidebarBg = useColorModeValue('white', 'secondaryGray.800');

	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef();

	const buttonBg = useColorModeValue('transparent', 'navy.800');
	const hoverButton = useColorModeValue({ bg: 'gray.100' }, { bg: 'whiteAlpha.100' });
	const activeButton = useColorModeValue({ bg: 'gray.200' }, { bg: 'whiteAlpha.200' });
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	const { t } = useTranslation();

	return (
		<Flex display={{ sm: 'flex', xl: 'none' }} alignItems="center">
			<Flex ref={btnRef} w="max-content" h="max-content" onClick={onOpen} >
				{/*<Icon*/}
				{/*	as={IoMenuOutline}*/}
				{/*	color={menuColor}*/}
				{/*	my="auto"*/}
				{/*	w="20px"*/}
				{/*	h="20px"*/}
				{/*	me="10px"*/}
				{/*	_hover={{ cursor: 'pointer' }}*/}
				{/*/>*/}
				<Tooltip label={t('content.live.list_force_stop')}>
					<Button
						bg={buttonBg}
						size={'sm'}
						border="1px solid"
						color="secondaryGray.600"
						// borderColor={useColorModeValue('secondaryGray.100', 'whiteAlpha.100')}
						// borderRadius="16px"
						_placeholder={{ color: 'secondaryGray.600' }}
						_hover={hoverButton}
						_active={activeButton}
						_focus={activeButton}
					>
						<Icon color={textColor} as={MdOutlineMenu} />
					</Button>
				</Tooltip>
			</Flex>
			<Drawer
				isOpen={isOpen}
				onClose={onClose}
				placement={document.documentElement.dir === 'rtl' ? 'right' : 'left'}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent
					w="285px"
					maxW="285px"
					ms={{
						sm: '16px'
					}}
					my={{
						sm: '16px'
					}}
					borderRadius="14px"
					bg={sidebarBg}
				>
					<DrawerCloseButton
						zIndex="3"
						onClick={onClose}
						_focus={{ boxShadow: 'none' }}
						_hover={{ boxShadow: 'none' }}
					/>
					<DrawerBody maxW="285px" px="0rem" pb="0">
						<Scrollbars
							autoHide
							renderTrackVertical={renderTrack}
							renderThumbVertical={renderThumb}
							renderView={renderView}
						>
							<Content mini={false} routes={getAllowedRoutes(routes, availableAdminMenus)} />
						</Scrollbars>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</Flex>
	);
}

/**
 * 권한에 의해 허용된 메뉴만 노출되도록 구성
 * @param routes
 * @param loginInfo
 */
export const getAllowedRoutes = (routes: RoutesType[], permissions: string[]) => {
	// 어드민 전체 메뉴
	let allMenus = routes.filter(value => value.screenType === TYPE_ROUTE_SCREEN.MAIN);
	const availableMenus: RoutesType[] = [];
	allMenus.map((value, index, array) => {
		let ex = permissions.includes(value.authType.toUpperCase()) || value.authType === 'NONE';
		if (ex) {
			availableMenus.push(value);
		}
	});

	return availableMenus;
};

export default Sidebar;
