// Chakra imports
import Card from 'components/card/Card';
import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Image, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

type PreviewFileObj = {
	file: File;
	preview: string;
};
export default function DropImageFile(
	props: {
		index: number;
		file: File;
		onDeleteFile: (index: number) => void;
		isDeletable?: boolean
	},
) {
	const { index, file, onDeleteFile, isDeletable = true } = props;

	const [previewFile, setPreviewFile] = useState<PreviewFileObj>(null);

	useEffect(() => {
		if (file.name.includes('png') || file.name.includes('jpg') || file.name.includes('jpeg')) {
			setPreviewFile({ file: file, preview: URL.createObjectURL(file) });
		} else {
			setPreviewFile({ file: file, preview: '' });
		}
	}, [file]);

	if (!previewFile) {
		return <Card />;
	}

	return (

		<Box maxW={'100%'} maxH="sm" borderWidth="1px"  overflow="hidden" position={'relative'}>
			{file.name.includes('png') || file.name.includes('jpg') || file.name.includes('jpeg') ? (
				<Zoom>
					<Image src={previewFile.preview} objectFit={'cover'} />
				</Zoom>
			) : (
				<Text fontSize="xs" maxH={'100%'}>
					{file.name}
				</Text>
			)}
			{isDeletable ? (
				<DeleteIcon
					mb={'20px'}
					position={'absolute'}
					top={'20px'}
					right={'20px'}
					onClick={() => {
						onDeleteFile(index);
					}}
				/>
			) : null}
		</Box>
	);
}
