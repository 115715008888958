import { BaseApi } from "./baseAPI";
import { URL_PRODUCT, URL_PRODUCT_DETAIL } from "./URLS";
import { CurrencyType, ProductEventType, ProductType } from "../types/enums";
import { PagingRequest } from "./commonAPI";

/**
 * Common Controller
 */
export class ProductApi extends BaseApi {
	getProductList = (request: ProductListRequest) => {
		return this.axios
			.get<ProductListResponse>(URL_PRODUCT, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	getProductDetail = (productId: string) => {
		return this.axios
			.get<ProductDetailResponse>(URL_PRODUCT_DETAIL.replace('{productId}', productId))
			.then(response => response)
			.catch(error => error.response);
	};

	putProductDetail = (productId: string, request: FormData) => {
		return this.axios
			.put(URL_PRODUCT_DETAIL.replace('{productId}', productId), request, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(response => response)
			.catch(error => error.response);
	};
}

/**
 *
 *
 * 리퀘스트들
 *
 *
 */
export type ProductListRequest = PagingRequest & {
	/**
	 * (query)
	 *  재화 종류
	 */
	currencyType?: CurrencyType;

	/**
	 * (query)
	 *  상품명
	 */
	name?: string;

	/**
	 * (query)
	 *  판매 활성 여부 (기본 true)
	 */
	isVisible?: boolean;

	/**
	 * (query)
	 *  상품 유형
	 */
	type?: ProductType;
};

/**
 *
 *
 * 리스폰스들
 *
 *
 */
export type ProductListResponse = {
	products: ProductTrain[];
	count: number;
};

export type ProductDetailResponse = {
	detail: ProductDetailTrain;
};

export type ProductTrain = {
	/**
	 * 상품 아이디
	 */
	productId: number;

	/**
	 * 상품 명
	 */
	name: string;

	/**
	 * 상품 유형
	 * Enum:
	 *    [ CONSUMABLE, NON_CONSUMABLE ]
	 */
	type: ProductType;

	/**
	 * 상품 가격
	 */
	price: number;

	/**
	 * 상품 단위
	 */
	priceUnit: string;

	/**
	 * 상품 아이콘 이미지 주소
	 */
	iconImgUrl: string;
};

export type ProductDetailTrain = ProductTrain & {
	/**
	 * 상품 아이디
	 */
	productId: number;

	/**
	 * 상품 명
	 */
	name: string;

	/**
	 * 상품 유형
	 * Enum:
	 *    [ CONSUMABLE, NON_CONSUMABLE ]
	 */
	type: ProductType;

	/**
	 * 상품 가격
	 */
	price: number;

	/**
	 * 상품 단위
	 */
	priceUnit: string;

	/**
	 * 상품 아이콘 이미지 주소
	 */
	iconImgUrl: string;

	/**
	 * 지급 재화 량
	 */
	currencyAmount: number;

	/**
	 * 지급 재화 단위
	 */
	currencyUnit: CurrencyType;

	/**
	 * 노출 여부
	 */
	isVisible: boolean;

	/**
	 * 상품 할인 여부
	 */
	isDiscounted: boolean;

	/**
	 * 상품 할인율
	 */
	discountRate: number;

	/**
	 * 할인된 상품 가격
	 */
	discountPrice: number;

	/**
	 * 이벤트 여부
	 */
	isEvent: boolean;

	/**
	 * 이벤트 유형
	 *  Enum:
	 *    [ NEW_USER, MANUAL ]
	 */
	eventType: ProductEventType;

	/**
	 * 이벤트 할인율
	 */
	eventDiscountRate: number;

	/**
	 * 할인된 이벤트 상품 가격
	 */
	eventDiscountPrice: number;

	/**
	 * 이벤트 시작 일자 시간
	 */
	eventStartDateTime: string;

	/**
	 * 이벤트 종료 일자 시간
	 */
	eventEndDateTime: string;

	/**
	 * 이벤트 이미지 주소
	 */
	eventImgUrl: string;

	/**
	 * 이벤트 명
	 */
	eventName: string;

	/**
	 * 인앱 결제 제품 ID
	 */
	iapProductId: string;

	/**
	 * 생성일
	 */
	createDateTime: string;

	/**
	 * 최근 수정일
	 */
	updateDateTime: string;
};
