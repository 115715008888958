// Chakra imports
import {
	Button,
	Flex,
	Grid,
	Popover,
	PopoverContent,
	PopoverTrigger,
	SimpleGrid,
	Stack,
	Text,
	useBoolean
} from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import TextField from 'components/fields/TextField';
import InputField from 'components/fields/InputField';
import { useThemeColors } from '../../../providers/theme-provider';
import { useApi } from '../../../providers/api-provider';
import Card from '../../../components/card/Card';
import { DATA_FORMAT_SERVER_FULL_DATE, dateFormat, showToast } from '../../../helper/functions';
import { ProductDetailResponse, ProductDetailTrain } from '../../../apis/productAPI';
import SelectField from 'components/fields/SelectField';
import { CurrencyType, ProductEventType, ProductType } from '../../../types/enums';
import SwitchField from 'components/fields/SwitchField';
import DropImageBox from '../../../components/dropzone/DropzoneImgBox';
import MiniCalendar from '../../../components/calendar/MiniCalendar';
import moment from 'moment';
import ButtonField from 'components/fields/ButtonField';

// 금지어 목록
export default function ProductDetail(props: { detailId: string; requestChangeParams: () => void }) {
	const { t } = useTranslation();
	const { textColorPrimary } = useThemeColors();
	const { productApi } = useApi();

	const { detailId, requestChangeParams } = props;

	const [item, setItem] = useState<ProductDetailTrain>();

	const [iconImgFile, setIconImgFile] = useState<File[]>([]);
	const [iconImgUrl, setIconImgUrl] = useState<string[]>([]);

	const [eventImgFile, setEventImgFile] = useState<File[]>([]);
	const [eventImgUrl, setEventImgUrl] = useState<string[]>([]);

	const [isShowStartDate, setIsShowStartDate] = useBoolean(false);
	const [isShowEndDate, setIsShowEndDate] = useBoolean(false);

	const [today, setToday] = useState(new Date());
	const [startDate, onStartDateChange] = useState<Date>(today);
	const [endDate, onEndDateChange] = useState<Date>(today);

	useEffect(() => {
		if (detailId !== '-1') {
			requestDetail();
		}
	}, [detailId]);

	const requestDetail = () => {
		productApi
			.getProductDetail(detailId)
			.then(responseDetail)
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	const responseDetail = (response: ProductDetailResponse) => {
		if (response.detail) {
			setItem(response.detail);


			if (response.detail.iconImgUrl && response.detail.iconImgUrl.length > 0) {
				setIconImgUrl([response.detail.iconImgUrl]);
			}

			if (response.detail.eventImgUrl && response.detail.eventImgUrl.length > 0) {
				setEventImgUrl([response.detail.eventImgUrl]);
			}


			if (response.detail.eventStartDateTime) {
				onStartDateChange(new Date(response.detail.eventStartDateTime));
			} else {
				onStartDateChange(today);
			}

			if (response.detail.eventEndDateTime) {
				onEndDateChange(new Date(response.detail.eventEndDateTime));
			} else {
				onEndDateChange(today);
			}
		}
	};

	const requestModifyProduct = () => {
		const form = new FormData();
		delete item.createDateTime;
		delete item.updateDateTime;

		item.currencyUnit = CurrencyType.HYPY_POINT;

		if (item.isEvent) {
			if (!item.eventName) {
				return;
			}

			if (!item.eventDiscountPrice) {
				return;
			}

			if (!item.eventDiscountRate) {
				return;
			}

			item.eventStartDateTime = dateFormat(startDate, DATA_FORMAT_SERVER_FULL_DATE);
			item.eventEndDateTime = dateFormat(endDate, DATA_FORMAT_SERVER_FULL_DATE);
		}

		console.log('=======> aaa ' + JSON.stringify(item));
		form.append('request', JSON.stringify(item));

		if (iconImgFile.length > 0) {
			form.append('iconImageFile', iconImgFile[0]);
		}

		if (eventImgFile.length > 0) {
			form.append('eventImageFile', eventImgFile[0]);
		}

		productApi
			.putProductDetail(String(item.productId), form)
			.then(() => {
				showToast(t('messages.applied'));
				requestChangeParams();
			})
			.catch(() => {});
	};

	if (!item) {
		return <Flex />;
	}

	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '0px', md: '0px', xl: '0px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Flex direction={'column'}>
				<Grid
					display={{ base: 'block', md: 'grid' }}
					gridTemplateColumns={{ base: item.isEvent ? '1fr 1fr 1fr' : '1fr 1fr' }}
					gap={{ base: '20px', xl: '20px' }}
				>
					<Card>
						<SimpleGrid>
							<TextField label={t('common.id')} text={String(item.productId)} />
							<InputField
								label={t('common.name')}
								value={item.name}
								onChange={e => {
									setItem(prevState => {
										return {
											...prevState,
											name: e.target.value
										};
									});
								}}
							/>
							<SelectField
								label={t('common.type')}
								value={item.type}
								options={Object.keys(ProductType)}
								disabled={true}
							/>
							<InputField
								label={t('product.currency_amount')}
								value={item.currencyAmount}
								type={'number'}
								onChange={e => {
									setItem(prevState => {
										return {
											...prevState,
											currencyAmount: Number(e.target.value)
										};
									});
								}}
							/>
							<SelectField
								label={t('product.currency_unit')}
								value={item.currencyUnit ? item.currencyUnit : CurrencyType.HYPY_POINT}
								options={Object.keys(CurrencyType)}
								onChange={e => {
									setItem(prevState => {
										return {
											...prevState,
											currencyUnit: e.target.value as CurrencyType
										};
									});
								}}
							/>
							<InputField
								label={t('product.price')}
								value={item.price}
								type={'number'}
								onChange={e => {
									setItem(prevState => {
										return {
											...prevState,
											price: Number(e.target.value)
										};
									});
								}}
							/>
							<InputField
								label={t('product.price_unit')}
								value={item.priceUnit}
								onChange={e => {
									setItem(prevState => {
										return {
											...prevState,
											priceUnit: e.target.value
										};
									});
								}}
							/>
							<SwitchField
								label={t('common.enable')}
								isChecked={item.isVisible}
								onChange={e => {
									setItem(prevState => {
										return {
											...prevState,
											isVisible: e.target.checked
										};
									});
								}}
							/>
							<DropImageBox
								imgFiles={iconImgFile}
								setImgFiles={setIconImgFile}
								imgUrls={iconImgUrl}
								setImgUrls={setIconImgUrl}
								maxFiles={1}
								title={t('product.icon_img')}
							/>
						</SimpleGrid>
					</Card>
					<Card>
						<SimpleGrid>
							<InputField
								label={t('product.discount_rate')}
								value={item.discountRate}
								type={'number'}
								onChange={e => {
									setItem(prevState => {
										return {
											...prevState,
											discountRate: Number(e.target.value)
										};
									});
								}}
							/>
							<InputField
								label={t('product.discount_price')}
								value={item.discountPrice}
								type={'number'}
								onChange={e => {
									setItem(prevState => {
										return {
											...prevState,
											discountPrice: Number(e.target.value)
										};
									});
								}}
							/>
							<SwitchField
								label={t('product.discounted')}
								isChecked={item.isDiscounted}
								onChange={e => {
									setItem(prevState => {
										return {
											...prevState,
											isDiscounted: e.target.checked
										};
									});
								}}
							/>
							<InputField
								label={t('product.iap_id')}
								value={item.iapProductId}
								onChange={e => {
									setItem(prevState => {
										return {
											...prevState,
											iapProductId: e.target.value
										};
									});
								}}
							/>
							<SwitchField
								label={t('product.event_status')}
								isChecked={item.isEvent}
								onChange={e => {
									setItem(prevState => {
										return {
											...prevState,
											isEvent: e.target.checked
										};
									});
								}}
							/>
							<ButtonField
								buttonName={t('common.save')}
								onClick={() => {
									requestModifyProduct();
								}}
							/>
						</SimpleGrid>
					</Card>
					{item.isEvent ? (
						<Card>
							<SimpleGrid>
								<InputField
									label={t('product.event_name')}
									value={item.eventName}
									onChange={e => {
										setItem(prevState => {
											return {
												...prevState,
												eventName: e.target.value
											};
										});
									}}
								/>
								<SelectField
									label={t('product.event_type')}
									value={item.eventType}
									options={Object.keys(ProductEventType)}
								/>
								<InputField
									label={t('product.event_discount_rate')}
									value={item.eventDiscountRate}
									type={'number'}
									onChange={e => {
										setItem(prevState => {
											return {
												...prevState,
												eventDiscountRate: Number(e.target.value)
											};
										});
									}}
								/>
								<InputField
									label={t('product.event_discount_price')}
									value={item.eventDiscountPrice}
									type={'number'}
									onChange={e => {
										setItem(prevState => {
											return {
												...prevState,
												eventDiscountPrice: Number(e.target.value)
											};
										});
									}}
								/>
								<SimpleGrid columns={{ sm: 1 }} gap={'10px'} mb={'20px'}>
									<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
										{t('product.event_duration')}
									</Text>
									<Stack spacing={5} direction="row">
										<Popover
											isOpen={isShowStartDate}
											onOpen={setIsShowStartDate.on}
											onClose={setIsShowStartDate.off}
											closeOnBlur={false}
											isLazy
											lazyBehavior="keepMounted"
										>
											<PopoverTrigger>
												<Button w="180px" colorScheme="purple">
													{dateFormat(startDate)}
												</Button>
											</PopoverTrigger>
											<PopoverContent bg={'transparent'} border={'0px'}>
												<MiniCalendar
													selectRange={false}
													mb="20px"
													onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
														if (endDate) {
															const timeStart = moment(endDate);
															const timeEnd = moment(value);
															const diff = timeEnd.diff(timeStart);
															if (diff > 0) {
																alert(t('user.list.filter_user_register_start_date_error'));
																return;
															}

															onStartDateChange(value);
															setIsShowStartDate.off();
														}
													}}
													value={startDate}
												/>
											</PopoverContent>
										</Popover>
										{' ~ '}
										<Popover
											isOpen={isShowEndDate}
											onOpen={setIsShowEndDate.on}
											onClose={setIsShowEndDate.off}
											closeOnBlur={false}
											isLazy
											lazyBehavior="keepMounted"
										>
											<PopoverTrigger>
												<Button w="180px" colorScheme="purple">
													{dateFormat(endDate)}
												</Button>
											</PopoverTrigger>
											<PopoverContent bg={'transparent'} border={'0px'}>
												<MiniCalendar
													selectRange={false}
													mb="20px"
													onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
														if (startDate) {
															const timeStart = moment(startDate);
															const timeEnd = moment(value);
															const diff = timeEnd.diff(timeStart);
															if (diff < 0) {
																alert(t('user.list.filter_user_register_end_date_error'));
																return;
															}

															onEndDateChange(value);
															setIsShowEndDate.off();
														}
													}}
													value={endDate}
												/>
											</PopoverContent>
										</Popover>
									</Stack>
								</SimpleGrid>
								<DropImageBox
									imgFiles={eventImgFile}
									setImgFiles={setEventImgFile}
									imgUrls={eventImgUrl}
									setImgUrls={setEventImgUrl}
									maxFiles={1}
									title={t('product.event_img')}
								/>
							</SimpleGrid>
						</Card>
					) : null}
				</Grid>
			</Flex>
		</Grid>
	);
}
