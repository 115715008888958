// Chakra imports
import { Flex, Image, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import { HSeparator } from 'components/separator/Separator';

import logo from '../../../assets/img/logo/logo.png';
import React from 'react';

export function SidebarBrand(props: { mini: boolean; hovered: boolean }) {
	const { mini, hovered } = props;
	//   Chakra color mode
	let logoColor = useColorModeValue('navy.700', 'white');
	let separatorBg = useColorModeValue('#E0E5F2', 'whiteAlpha.200');
	return (
		<Flex alignItems="center" flexDirection="column">
			<Image src={logo} h="22px" w="100px" my="32px" ml={'-60px'} />
			<Text
				h="16px"
				w="100px"
				top={'58px'}
				left={'170px'}
				display={mini === false ? 'block' : mini === true && hovered === true ? 'block' : 'none'}
				fontSize={'10px'}
				fontWeight="800"
				color={separatorBg}
				position={'absolute'}
			>
				Admin
			</Text>
			<HSeparator mb="20px" bg={separatorBg} w="100%" />
		</Flex>
	);
}

export default SidebarBrand;
