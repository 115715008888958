// Chakra imports
import { Flex, FormLabel, Text, useColorModeValue } from '@chakra-ui/react';
import { useThemeColors } from '../../providers/theme-provider';
// Custom components

export default function Default(props: { label?: string; extra?: string; text?: any; [x: string]: any }) {
	const { label, extra, text, type, mb, ...rest } = props;

	const { textColorSecondary, textColorPrimary } = useThemeColors();

	return (
		<Flex direction="column" mb={mb ? mb : '10px'}>
			<FormLabel
				display="flex"
				ms="10px"
				fontSize="xl"
				color={textColorPrimary}
				fontWeight="bold"
				_hover={{ cursor: 'pointer' }}
			>
				{label}
				<Text fontSize="sm" fontWeight="400" ms="2px">
					{extra}
				</Text>
			</FormLabel>
			<Text
				{...rest}
				fontWeight="500"
				variant="main"
				ml={'10px'}
				borderRadius="6px !important"
				h="44px"
				maxH="44px"
				textColor={textColorSecondary}
			>
				{text ? String(text): '-'}
			</Text>
		</Flex>
	);
}
