// Chakra imports
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import MessageBlock from 'components/chat/MessageBlock';
// Assets
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { messagesRenderThumb, messagesRenderTrack, messagesRenderView } from 'components/scrollbar/Scrollbar';
import { SocketMessageListener, useSocket } from '../../providers/socket-provider';
import { LiveStreamingDetail } from '../../apis/contentsAPI';
import { MemberType } from '../../types/enums';
import { formatDate } from '@fullcalendar/core';
import { debugLog } from '../../helper/functions';

export type ChatMessage = {
	messageId?: string;
	userId?: string;
	userType?: string;
	message: string;
	messageChanged?: boolean;
	userPid?: string;
	userName?: string;
	clientMessageId?: string;
	createdDateTime?: string;
	userProfileImgUrl?: string;
	messageType: string;
	isOwner?: boolean;
};

export default function Messages(props: { roomInfo: LiveStreamingDetail }) {
	const { roomInfo } = props;
	const { initialize, sendMessage, banUser, blockMessage } = useSocket();

	const inputText = useColorModeValue('gray.700', 'gray.100');
	const blockBg = useColorModeValue('secondaryGray.300', 'navy.700');
	const brandButton = useColorModeValue('brand.500', 'brand.400');

	const scrollRef = React.useRef<Scrollbars>(null);

	const [chatList, setChatList] = useState<ChatMessage[]>([]);

	const listener: SocketMessageListener = {
		onReceivedHideMessages(data: any): void {
			debugLog('=====> onReceivedHideMessages ' + JSON.stringify(data));
		},
		onReceivedManagerNoti(data: any): void {
			debugLog('=====> onReceivedManagerNoti ' + JSON.stringify(data));
		},
		onReceivedNoti(data: any): void {
			debugLog('=====> onReceivedNoti ' + JSON.stringify(data));
			const item = JSON.parse(data);
			setChatList(list => [
				...list,
				{
					message: item.idolManagerMsg ? item.idolManagerMsg : item.message,
					createdDateTime: formatDate(new Date().getTime()) + '',
					messageType: 'system'
				}
			]);
		},
		onReceivedSpanNoti(data: any): void {
			debugLog('=====> onReceivedSpanNoti ' + JSON.stringify(data));
		},
		onSocketConnected(isConnected: boolean): void {
			debugLog('=====> onSocketConnected ' + isConnected);
		},
		onSocketDisconnected(): void {},
		onSocketError(error: any): void {},
		onSocketReconnecting(): void {},
		onReceivedUserMessages(data: any) {
			const item = JSON.parse(data);
			setChatList(list => [
				...list,
				{
					messageId: item.messageId,
					userId: item.userId,
					userType: item.userType,
					message: item.message,
					messageChanged: item.messageChanged,
					userPid: item.userPid,
					userName: item.userName,
					clientMessageId: item.clientMessageId,
					createdDateTime: formatDate(new Date().getTime()) + '',
					userProfileImgUrl: item.userProfileImgUrl,
					messageType: 'normal',
					isOwner: roomInfo.idolId === item.userId && item.userType === MemberType.IDOL
				}
			]);
		},
		onReceivedMemberEntrance(data: any) {
			const item = JSON.parse(data);
			setChatList(list => [
				...list,
				{
					userId: item.userId,
					userType: item.userType,
					message: item.message,
					createdDateTime: formatDate(new Date().getTime()) + '',
					messageType: 'system'
				}
			]);
		},
		onReceivedBanUserNoti(data: any) {
			const item = JSON.parse(data);
			setChatList(list => [
				...list,
				{
					userId: item.userId,
					userType: item.userType,
					message: item.idolManagerMsg,
					createdDateTime: formatDate(new Date().getTime()) + '',
					messageType: 'system'
				}
			]);
		},
		onExit(data: any) {
			this.onSocketDisconnected();
		}
	};

	useEffect(() => {
		debugLog('=======> roomInfo ' + JSON.stringify(roomInfo));

		if (roomInfo) {
			initialize(roomInfo, listener);
		}
	}, [roomInfo]);

	useEffect(() => {
		scrollToBottom();
	}, [chatList]);

	const scrollToBottom = () => {
		if (scrollRef.current) {
			scrollRef.current.scrollToBottom();
		}
	};

	return (
		<Box h="100%">
			<Box h="calc(100% - 60px)" px={{ base: '10px', md: '20px' }} pt="0px" position="relative">
				<Scrollbars
					autoHide
					ref={scrollRef}
					renderTrackVertical={messagesRenderTrack}
					renderThumbVertical={messagesRenderThumb}
					renderView={messagesRenderView}
				>
					<Flex overflow="hidden">
						<Flex direction="column" w="100%">
							{chatList.map(item => {
								return (
									<MessageBlock
										item={item}
										banUser={(id, type) => {
											banUser(id, type);
										}}
										blockMessage={messageId => {
											blockMessage(messageId, roomInfo.liveStreamingId.toString());
										}}
									/>
								);
							})}
							<Box w="100%" h="20px" />
						</Flex>
					</Flex>
				</Scrollbars>
				{/*<Flex backdropFilter="blur(20px)" mt="10px" w={'100%'}>*/}
				{/*	<InputGroup me="10px" w={{ base: '100%' }}>*/}
				{/*		<Input*/}
				{/*			variant="search"*/}
				{/*			fontSize="md"*/}
				{/*			pl={{ base: '20px !important', lg: '20px !important' }}*/}
				{/*			pr={{*/}
				{/*				base: '0px',*/}
				{/*				lg: '20px !important'*/}
				{/*			}}*/}
				{/*			h={{ base: '50px', lg: '50px' }}*/}
				{/*			bg={blockBg}*/}
				{/*			color={inputText}*/}
				{/*			fontWeight="500"*/}
				{/*			_placeholder={{ color: 'gray.400', fontSize: '16px' }}*/}
				{/*			borderRadius="30px"*/}
				{/*			placeholder={'채팅 알림 메시지를 작성해 주세요..'}*/}
				{/*		/>*/}
				{/*	</InputGroup>*/}
				{/*	<Button*/}
				{/*		borderRadius="25px"*/}
				{/*		ms={{ base: '14px', lg: 'auto' }}*/}
				{/*		bg={brandButton}*/}
				{/*		w={{ base: '50px', lg: '50px' }}*/}
				{/*		h={{ base: '50px', lg: '50px' }}*/}
				{/*		minW={{ base: '50px', lg: '50px' }}*/}
				{/*		minH={{ base: '50px', lg: '50px' }}*/}
				{/*		variant="no-hover"*/}
				{/*		onClick={() => {*/}
				{/*			// setChatList(ary => [...ary, {}]);*/}

				{/*			// if (!socket) return;*/}
				{/*			// const chatMessage = document.getElementById('chatMessage');*/}
				{/*			// socket.emit('userMsg', 'asdfasfsafdsafda');*/}
				{/*			console.log('------. chat ');*/}
				{/*			sendMessage('asdfasdfasdfas');*/}
				{/*		}}*/}
				{/*	>*/}
				{/*		<Icon as={IoPaperPlane} color="white" w={{ base: '18px', lg: '25px' }} h={{ base: '18px', lg: '25px' }} />*/}
				{/*	</Button>*/}
				{/*</Flex>*/}
			</Box>
		</Box>
	);
}
