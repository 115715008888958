import { Box, Button, Flex, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useApi } from "../../../providers/api-provider";
import { EventRewardPayType, EventRewardType, MemberType, NoticePlacementType } from "../../../types/enums";
import { DATA_FORMAT_SERVER_FULL_DATE, dateFormat, debugLog, showToast } from "../../../helper/functions";
import { useParams } from "react-router-dom";
import { EventDetailResponse } from "../../../apis/noticeAPI";
import { EventImages, EventInformation, EventRewards } from "./components/EventRegisterComponents";

/**
 * 사전 계약 아이돌 등록
 * @constructor
 */
export default function EventDetail() {
	const { t } = useTranslation();
	const { noticeApi } = useApi();
	const { eventId } = useParams();

	// 변수
	const [title, setTitle] = useState<string>('');
	const [mainText, setMainText] = useState<string>('');
	const [eventRewardButtonName, setEventRewardButtonName] = useState<string>('');
	const [cautions, setCautions] = useState<string[]>([]);
	const [cautionsOrg, setCautionsOrg] = useState<string[]>([]);
	const [thumbnamilImgFile, setThumbnamilImgFile] = useState<File[]>([]);
	const [shareImgFile, setShareImgFile] = useState<File[]>([]);
	const [detailedImgFile, setDetailedImgFile] = useState<File[]>([]);


	const [thumbnamilImgUrl, setThumbnamilImgUrl] = useState<string[]>([]);
	const [shareImgUrl, setShareImgUrl] = useState<string[]>([]);
	const [detailedImgUrl, setDetailedImgUrl] = useState<string[]>([]);

	const [titleEn, setTitleEn] = useState<string>('');
	const [mainTextEn, setMainTextEn] = useState<string>('');
	const [eventRewardButtonNameEn, setEventRewardButtonNameEn] = useState<string>('');
	const [cautionsEn, setCautionsEn] = useState<string[]>([]);
	const [cautionsOrgEn, setCautionsEnOrg] = useState<string[]>([]);
	const [thumbnamilImgFileEn, setThumbnamilImgFileEn] = useState<File[]>([]);
	const [shareImgFileEn, setShareImgFileEn] = useState<File[]>([]);
	const [detailedImgFileEn, setDetailedImgFileEn] = useState<File[]>([]);

	const [thumbnamilImgUrlEn, setThumbnamilImgUrlEn] = useState<string[]>([]);
	const [shareImgUrlEn, setShareImgUrlEn] = useState<string[]>([]);
	const [detailedImgUrlEn, setDetailedImgUrlEn] = useState<string[]>([]);

	const [titleKr, setTitleKr] = useState<string>('');
	const [mainTextKr, setMainTextKr] = useState<string>('');
	const [eventRewardButtonNameKr, setEventRewardButtonNameKr] = useState<string>('');
	const [cautionsKr, setCautionsKr] = useState<string[]>([]);
	const [cautionsOrgKr, setCautionsKrOrg] = useState<string[]>([]);
	const [thumbnamilImgFileKr, setThumbnamilImgFileKr] = useState<File[]>([]);
	const [shareImgFileKr, setShareImgFileKr] = useState<File[]>([]);
	const [detailedImgFileKr, setDetailedImgFileKr] = useState<File[]>([]);

	const [thumbnamilImgUrlKr, setThumbnamilImgUrlKr] = useState<string[]>([]);
	const [shareImgUrlKr, setShareImgUrlKr] = useState<string[]>([]);
	const [detailedImgUrlKr, setDetailedImgUrlKr] = useState<string[]>([]);

	const [targetMemberTypes, setTargetMemberTypes] = useState<MemberType[]>([MemberType.IDOL, MemberType.PRODUCER]);
	const [notiePlacementType, setNoticePlacementType] = useState<NoticePlacementType[]>([NoticePlacementType.HOME]);

	const [detailMovieUrls, setDetailMovieUrls] = useState<string[]>([]);
	const [detailMovieUrlsOrg, setDetailMovieUrlsOrg] = useState<string[]>([]);

	const [isActive, setIsActive] = useState<boolean>(true);

	const [today, setToday] = useState(new Date());
	const [startDate, onStartDateChange] = useState<Date>(today);
	const [endDate, onEndDateChange] = useState<Date>(today);

	const [eventRewardType, setEventRewardType] = useState<EventRewardType>(EventRewardType.POINT);
	const [eventRewardItemId, setEventRewardItemId] = useState<number>(-1);
	const [eventRewardItemExpireDt, onEventRewardItemExpireDt] = useState<Date>(today);

	const [eventRewardPayType, setEventRewardPayType] = useState<EventRewardPayType>(EventRewardPayType.SEQUENCE);
	const [eventRewardPayMaxSequence, setEventRewardPayMaxSequence] = useState<number>(-1);

	const [eventRewardTotal, setEventRewardTotal] = useState<number>(-1);
	const [eventRewardUnit, setEventRewardUnit] = useState<number>(-1);

	const [eventRewardBalance, setEventRewardBalance] = useState<number>(-1);


	useEffect(() => {
		if (eventId) {
			requestNoticeDetail(eventId);
		}
	}, [eventId]);

	const requestNoticeDetail = (eventId: string) => {
		noticeApi
			.getEventDetail(eventId)
			.then(responseEventDetail)
			.catch(() => {});
	};

	// 임시 데이터 로딩 처리
	const responseEventDetail = (response: EventDetailResponse) => {
		if (response.eventDetail) {
			let detail = response.eventDetail;

			setTitle(detail.title.contentJa);
			setTitleEn(detail.title.contentEn);
			setTitleKr(detail.title.contentKo);

			setMainText(detail.mainText.contentJa);
			setMainTextEn(detail.mainText.contentEn);
			setMainTextKr(detail.mainText.contentKo);

			setThumbnamilImgUrl(detail.thumbnailImgUrl.contentJa ? [detail.thumbnailImgUrl.contentJa] :[]);
			setThumbnamilImgUrlEn(detail.thumbnailImgUrl.contentEn ? [detail.thumbnailImgUrl.contentEn] :[]);
			setThumbnamilImgUrlKr(detail.thumbnailImgUrl.contentKo ? [detail.thumbnailImgUrl.contentKo] :[]);

			setShareImgUrl(detail.shareImgUrl.contentJa ? [detail.shareImgUrl.contentJa] :[]);
			setShareImgUrlEn(detail.shareImgUrl.contentEn ? [detail.shareImgUrl.contentEn] :[]);
			setShareImgUrlKr(detail.shareImgUrl.contentKo ? [detail.shareImgUrl.contentKo] :[]);

			setDetailedImgUrl(detail.detailImgUrls && detail.detailImgUrls.map(item => item.contentJa).filter(item => item !== null));
			setDetailedImgUrl(detail.detailImgUrls && detail.detailImgUrls.map(item => item.contentJa).filter(item => item !== null));
			setDetailedImgUrl(detail.detailImgUrls && detail.detailImgUrls.map(item => item.contentJa).filter(item => item !== null));

			setCautions(detail.cautions && detail.cautions.length > 0 ? detail.cautions.map(item => item.contentJa).filter(item => item !== null): []);
			setCautionsEn(detail.cautions && detail.cautions.length > 0 ?detail.cautions.map(item => item.contentEn).filter(item => item !== null): []);
			setCautionsKr(detail.cautions && detail.cautions.length > 0 ? detail.cautions.map(item => item.contentKo).filter(item => item !== null): []);

			setCautionsOrg(detail.cautions && detail.cautions.length > 0 ? detail.cautions.map(item => item.contentJa).filter(item => item !== null): []);
			setCautionsEnOrg(detail.cautions && detail.cautions.length > 0 ?detail.cautions.map(item => item.contentEn).filter(item => item !== null): []);
			setCautionsKrOrg(detail.cautions && detail.cautions.length > 0 ? detail.cautions.map(item => item.contentKo).filter(item => item !== null): []);

			setNoticePlacementType(detail.bannerPlacementTypes ? detail.bannerPlacementTypes : []);
			setTargetMemberTypes(detail.targetMemberTypes ? detail.targetMemberTypes : []);

			onStartDateChange(new Date(detail.startDateTime));
			onEndDateChange(new Date(detail.endDateTime));

			setIsActive(detail.isActive);

			setEventRewardType(detail.eventRewardType ? detail.eventRewardType: EventRewardType.NONE)
			setEventRewardPayType(detail.eventRewardPayType ? detail.eventRewardPayType: EventRewardPayType.NONE)
			setEventRewardItemId(detail.eventRewardItemId)
			onEventRewardItemExpireDt(new Date(detail.eventRewardItemExpireDateTime));
			setEventRewardPayMaxSequence(detail.eventRewardPayMaxSequence);
			setEventRewardTotal(detail.eventRewardTotal);
			setEventRewardUnit(detail.eventRewardUnit);
			setEventRewardBalance(detail.eventRewardBalance);

			setEventRewardButtonName(detail.eventRewardButtonName.contentJa);
			setEventRewardButtonNameEn(detail.eventRewardButtonName.contentEn);
			setEventRewardButtonNameKr(detail.eventRewardButtonName.contentKo);

			setDetailMovieUrls(detail.detailMovieUrls);
			setDetailMovieUrlsOrg(detail.detailMovieUrls);
		}
	};

	/**
	 * 이벱트 등록
	 */
	const registerEvent = async () => {
		// // 입력값 확인
		if (title.length === 0 || !title) {
			showToast(t('error.input_title'));
			return;
		}

		const maxLength = Math.max(cautions.length, cautionsEn.length, cautionsKr.length);
		let mergedArray = null;

		if (cautions && cautions.length === 1 && cautions[0] === '') {
		} else {
			mergedArray = Array.from({ length: maxLength }, (_, index) => ({
				contentJa: cautions[index] || null, // Ja 배열에서 값이 없으면 null로 대체
				contentKo: cautionsEn[index] || null, // Ko 배열에서 값이 없으면 null로 대체
				contentEn: cautionsKr[index] || null // En 배열에서 값이 없으면 null로 대체
			}));
		}

		// 요청 데이터
		const requestData = {
			title: {
				contentJa: title,
				contentKo: titleKr,
				contentEn: titleEn
			},
			targetMemberTypes: targetMemberTypes,
			bannerPlacementTypes: notiePlacementType,
			mainText: {
				contentJa: mainText,
				contentKo: mainTextKr,
				contentEn: mainTextEn
			},
			isActive: isActive,
			startDateTime: dateFormat(startDate, DATA_FORMAT_SERVER_FULL_DATE),
			endDateTime: dateFormat(endDate, DATA_FORMAT_SERVER_FULL_DATE),
			eventRewardType: eventRewardType === EventRewardType.NONE ? null : eventRewardType,
			eventRewardItemId: eventRewardItemId,
			eventRewardItemExpireDt: dateFormat(eventRewardItemExpireDt, DATA_FORMAT_SERVER_FULL_DATE),
			eventRewardPayType: eventRewardPayType === EventRewardPayType.NONE ? null : eventRewardPayType,
			eventRewardPayMaxSequence: eventRewardPayMaxSequence,
			eventRewardTotal: eventRewardTotal,
			eventRewardUnit: eventRewardUnit,
			eventRewardButtonName: {
				contentJa: eventRewardButtonName,
				contentKo: eventRewardButtonNameKr,
				contentEn: eventRewardButtonNameEn
			},
			cautions: mergedArray,
			detailMovieUrls: detailMovieUrls
		};

		try {
			const formData = new FormData();
			formData.append('request', JSON.stringify(requestData));

			// 압축 파일 생성
			// detailImgFilesJa 배열의 각 파일을 FormData에 추가
			if (detailedImgFile.length > 0) {
				detailedImgFile.forEach((file, index) => {
					formData.append(`detailImgFilesJa`, file);
				});
			}

			// detailImgFilesKo 배열의 각 파일을 FormData에 추가
			if (detailedImgFileKr.length > 0) {
				detailedImgFileKr.forEach((file, index) => {
					formData.append(`detailImgFilesKo`, file);
				});
			}

			// detailImgFilesEn 배열의 각 파일을 FormData에 추가
			if (detailedImgFileEn.length > 0) {
				detailedImgFileEn.forEach((file, index) => {
					formData.append(`detailImgFilesEn`, file);
				});
			}


			formData.append('thumbnailImgFileJa', thumbnamilImgFile.length > 0 ? thumbnamilImgFile[0] : null);
			formData.append('thumbnailImgFileKo', thumbnamilImgFileKr.length > 0 ? thumbnamilImgFileKr[0] : null);
			formData.append('thumbnailImgFileEn', thumbnamilImgFileEn.length > 0 ? thumbnamilImgFileEn[0] : null);

			formData.append('shareImgFileJa', shareImgFile.length > 0 ? shareImgFile[0] : null);
			formData.append('shareImgFileKo', shareImgFileKr.length > 0 ? shareImgFileKr[0] : null);
			formData.append('shareImgFileEn', shareImgFileEn.length > 0 ? shareImgFileEn[0] : null);

			// 완료 후 목록 페이지로 이동
			noticeApi
				.putEventDetail(eventId, formData)
				.then(() => {
				 	window.location.href = '/admin/events/event';
					// if (notieType === NoticeType.SYSTEM) {
					// 	window.location.href = '/admin/notices/system_list';
					// } else {
					// 	window.location.href = '/admin/notices/ad_list';
					// }
				})
				.catch(() => {
					showToast(t('error.try_again'));
				});
		} catch (error) {
			debugLog('Error during compression or upload:', error);
			showToast(t('error.try_again'));
		}
	};

	return (
		<Box
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '130px', md: '80px', xl: '80px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<SimpleGrid mb="20px" columns={{ sm: 1, lg: 2 }} spacing={{ base: '20px', xl: '20px' }}>
				{/* Column Left */}

				<Flex direction="column">
					<Tabs variant="enclosed">
						<TabList>
							<Tab>{t('common.japanese')}</Tab>
							<Tab>{t('common.english')}</Tab>
							<Tab>{t('common.korean')}</Tab>
						</TabList>
						<TabPanels>
							<TabPanel>
								<EventInformation
									title={title}
									setTitle={setTitle}
									mainText={mainText}
									setMainText={setMainText}
									cautionsOrg={cautionsOrg}
									cautions={cautions}
									setCautions={setCautions}
									eventButtonName={eventRewardButtonName}
									setEventButtonName={setEventRewardButtonName}
									thumbnailImg={thumbnamilImgFile}
									setThumbnailImg={setThumbnamilImgFile}
									detailedImgs={detailedImgFile}
									setDetailedImgs={setDetailedImgFile}
									shareImg={shareImgFile}
									setShareImg={setShareImgFile}
									thumbnailImgUrl={thumbnamilImgUrl}
									setThumbnailImgUrl={setThumbnamilImgUrl}
									shareImgUrl={shareImgUrl}
									setShareImgUrl={setShareImgUrl}
									detailedImgUrls={detailedImgUrl}
									setDetailedImgUrls={setDetailedImgUrl}
								/>
							</TabPanel>
							<TabPanel>
								<EventInformation
									title={titleEn}
									setTitle={setTitleEn}
									mainText={mainTextEn}
									setMainText={setMainTextEn}
									cautionsOrg={cautionsOrgEn}
									cautions={cautionsEn}
									setCautions={setCautionsEn}
									eventButtonName={eventRewardButtonNameEn}
									setEventButtonName={setEventRewardButtonNameEn}
									thumbnailImg={thumbnamilImgFileEn}
									setThumbnailImg={setThumbnamilImgFileEn}
									shareImg={shareImgFileEn}
									setShareImg={setShareImgFileEn}
									detailedImgs={detailedImgFileEn}
									setDetailedImgs={setDetailedImgFileEn}
									thumbnailImgUrl={thumbnamilImgUrlEn}
									setThumbnailImgUrl={setThumbnamilImgUrlEn}
									shareImgUrl={shareImgUrlEn}
									setShareImgUrl={setShareImgUrlEn}
									detailedImgUrls={detailedImgUrlEn}
									setDetailedImgUrls={setDetailedImgUrlEn}
								/>
							</TabPanel>
							<TabPanel>
								<EventInformation
									title={titleKr}
									setTitle={setTitleKr}
									mainText={mainTextKr}
									setMainText={setMainTextKr}
									cautionsOrg={cautionsOrgKr}
									cautions={cautionsKr}
									setCautions={setCautionsKr}
									eventButtonName={eventRewardButtonNameKr}
									setEventButtonName={setEventRewardButtonNameKr}
									thumbnailImg={thumbnamilImgFileKr}
									setThumbnailImg={setThumbnamilImgFileKr}
									shareImg={shareImgFileKr}
									setShareImg={setShareImgFileKr}
									detailedImgs={detailedImgFileKr}
									setDetailedImgs={setDetailedImgFileKr}
									thumbnailImgUrl={thumbnamilImgUrlKr}
									setThumbnailImgUrl={setThumbnamilImgUrlKr}
									shareImgUrl={shareImgUrlKr}
									setShareImgUrl={setShareImgUrlKr}
									detailedImgUrls={detailedImgUrlKr}
									setDetailedImgUrls={setDetailedImgUrlKr}
								/>
							</TabPanel>
						</TabPanels>
					</Tabs>
					{/* <Dropzone /> */}
				</Flex>
				{/* Column Right */}
				<Flex direction="column">
					<EventImages
						noticePlacementType={notiePlacementType}
						setNoticePlacementType={setNoticePlacementType}
						detailMovieUrlsOrg={detailMovieUrlsOrg}
						detailMovieUrls={detailMovieUrls}
						setDetailMovieUrls={setDetailMovieUrls}
						targetMemberTypes={targetMemberTypes}
						setTargetMemberTypes={setTargetMemberTypes}
						startDate={startDate}
						onStartDateChange={onStartDateChange}
						endDate={endDate}
						onEndDateChange={onEndDateChange}
						isActive={isActive}
						setIsActive={setIsActive}
					/>
					<EventRewards
						eventRewardType={eventRewardType}
						setEventRewardType={setEventRewardType}
						eventRewardItemId={eventRewardItemId}
						setEventRewardItemId={setEventRewardItemId}
						eventRewardItemExpireDt={eventRewardItemExpireDt}
						onEventRewardItemExpireDt={onEventRewardItemExpireDt}
						eventRewardPayMaxSequence={eventRewardPayMaxSequence}
						setEventRewardPayMaxSequence={setEventRewardPayMaxSequence}
						eventRewardPayType={eventRewardPayType}
						setEventRewardPayType={setEventRewardPayType}
						eventRewardTotal={eventRewardTotal}
						setEventRewardTotal={setEventRewardTotal}
						eventRewardUnit={eventRewardUnit}
						setEventRewardUnit={setEventRewardUnit}
						eventRewardBalance={eventRewardBalance}
					/>
				</Flex>
			</SimpleGrid>
			<Button
				variant="brand"
				minW="183px"
				fontSize="sm"
				fontWeight="500"
				ms="auto"
				onClick={() => {
					registerEvent();
				}}
			>
				{t('common.save')}
			</Button>
		</Box>
	);
}
