// Chakra imports
import {
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Radio,
	RadioGroup,
	SimpleGrid,
	Stack,
	Stat,
	StatLabel,
	StatNumber,
	Text,
	Textarea
} from '@chakra-ui/react';

// Custom components
// Assets
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import Card from 'components/card/Card';
import MiniStatistics from 'components/card/MiniStatistics';
import { useApi } from '../../../../providers/api-provider';
import { IdolApplyStatus } from '../../../../types/enums';
import {
	IdolApplyDetailResponse,
	IdolApplyStatusApproveResponse,
	IdolApplyStatusRejectResponse
} from '../../../../apis/idolAPI';
import { DATA_FORMAT_FULL_DATE, dateFormat, getFileName, phoneFomatter, showToast } from '../../../../helper/functions';
import { saveAs } from 'file-saver';
import { BlobReader, BlobWriter, Entry, ZipReader } from '@zip.js/zip.js';
import { useThemeColors } from '../../../../providers/theme-provider';
import Zoom from 'react-medium-image-zoom';
import DropImageFileFromUrl from '../../../../components/dropzone/DropImageFileFromUrl';
import ModalBasic from '../../../../components/modal/ModalBasic';

interface ExtractedImage {
	fileName: string;
	imageUrl: string;
}

// Live 콘텐츠 목록
export default function IdolApplyDetail(props: { idolApplyId: number }) {
	const { t } = useTranslation();

	const { idolApplyId } = props;
	const { idolApi } = useApi();
	const { textColorPrimary, textColorSecondary, textColor } = useThemeColors();

	const [idolApplyDetail, setIdolApplyDetail] = useState<IdolApplyDetailResponse>(null);

	// 유저 상세 팝업 표시
	const [isShowDetail, setIsShowDetail] = useState(false);

	const [reviewResult, setReviewResult] = useState(idolApplyDetail?.reviewResult || '');
	const [applyStatus, setApplyStatus] = useState(idolApplyDetail?.applyStatus);

	const [files, setFiles] = useState<ExtractedImage[]>([]);

	const [isShowModalConfirm, setIsShowModalConfirm] = useState<boolean>(false);

	useEffect(() => {
		if (idolApplyId >= 0) {
			requestIdolDetail().then(() => {});
		}
	}, [idolApplyId]);

	useEffect(() => {
		if (idolApplyDetail) {
			setReviewResult(idolApplyDetail.reviewResult || '');
			setApplyStatus(idolApplyDetail.applyStatus);
		}
	}, [idolApplyDetail]);

	const handleReviewResult = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setReviewResult(event.target.value);
	};

	const handleApplyStatus = (value: string) => {
		setApplyStatus(value);
	};

	// 개행 문자는 <br />, 탭 문자는 공백으로 변환
	const convertNewlinesAndTabsToBreaks = (text: string) => {
		return text.split('\n').map((line, index) => (
			<React.Fragment key={index}>
				{line.split('\t').map((segment, segIndex) => (
					<React.Fragment key={segIndex}>
						{segment}
						{segIndex < line.split('\t').length - 1 && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
					</React.Fragment>
				))}
				<br />
			</React.Fragment>
		));
	};

	const requestIdolDetail = async () => {
		idolApi.idolApplyDetail(idolApplyId).then(response => {
			setIdolApplyDetail(response.idolApplyDetail);
		});
	};

	const handleSave = () => {
		if (applyStatus === 'APPROVED') {
			const params = {
				reviewResult: reviewResult
			};
			idolApi
				.idolApplyStatusApprove(idolApplyId, params)
				.then(response => {
					requestIdolDetail();
				})
				.catch(() => {
					showToast(t('error.try_again'));
				});
		} else if (applyStatus === 'REJECTED') {
			const params = {
				reviewResult: reviewResult
			};
			// console.log('params',params);
			idolApi
				.idolApplyStatusReject(idolApplyId, params)
				.then(response => {
					requestIdolDetail();
				})
				.catch(() => {
					showToast(t('error.try_again'));
				});
		} else if (applyStatus === 'TESTIDOL') {
			const params = {
				reviewResult: reviewResult
			};
			// console.log('params',params);
			idolApi
				.idolApplyStatusTestApprove(idolApplyId, params)
				.then(response => {
					requestIdolDetail();
				})
				.catch(() => {
					showToast(t('error.try_again'));
				});
		}

		// 추가적으로, 모달을 닫거나 다른 필요한 동작을 수행
		setIsShowDetail(false);
		setIsShowModalConfirm(false);
	};

	if (!idolApplyDetail) {
		return <Flex />;
	}

	// Function to handle file input change and process the zip file
	const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files !== null) {
			const file = event.target.files[0];
		}

		const zipFile = event.target.files?.[0];
		if (!zipFile) {
			alert('Could not process the ZIP file.');
			return;
		}

		try {
			await processZipFile(zipFile);
		} catch (error) {
			console.error('Error processing the zip file:', error);
			alert('Could not process the ZIP file.');
		}
	};

	/**
	 * 파일 다운로드
	 */
	const downloadZipFile = async (link?: string) => {
		if (!link || link.length === 0) {
			return;
		}

		saveAs(link, getFileName(link));
	};

	const processZipFile = async (zipBlob: Blob) => {
		try {
			// Create a ZipReader instance to read the zip file
			const reader: ZipReader<BlobReader> = new ZipReader(new BlobReader(zipBlob), {
				password: idolApplyDetail.identityDocument.password // Pass the password provided by the user
			});
			// Get all entries (files/folders) inside the zip
			const entries: Entry[] = await reader.getEntries();
			// Process each entry, extract image files and display them
			const extractedImages: ExtractedImage[] = [];
			for (const entry of entries) {
				if (!entry.directory && isImage(entry)) {
					const blob = await extractBlobFromEntry(entry);
					const imageUrl = URL.createObjectURL(blob);
					extractedImages.push({ fileName: entry.filename, imageUrl });
				}
			}

			setFiles(extractedImages); // Update state with image URLs
			await reader.close(); // Close the reader when done
		} catch (error) {
			alert('Incorrect password or invalid ZIP file.');
		}
	};

	// Function to check if an entry is an image file
	const isImage = (entry: Entry): boolean => {
		const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
		return imageExtensions.some(ext => entry.filename.toLowerCase().endsWith(`.${ext}`));
	};

	// Function to extract blob from a zip entry
	const extractBlobFromEntry = (entry: Entry): Promise<Blob> => {
		return entry.getData(new BlobWriter()); // Extract entry data as a Blob
	};

	// Chakra Color Mode
	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '0px', md: '0px', xl: '0px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Flex direction={'column'} me={{ lg: '40px' }}>
				<Grid
					display={{ base: 'block', md: 'grid' }}
					gridTemplateColumns={{ base: '1fr 3.2fr', lg: '1fr 3.2fr' }}
					gap={{ base: '20px', xl: '20px' }}
				>
					<SimpleGrid mb="10px">
						<Image src={idolApplyDetail.profileImgUrl} objectFit={'cover'} />
					</SimpleGrid>
					<Grid>
						<SimpleGrid mb="10px" columns={{ base: 1, md: 3, xl: 3 }} gap="10px">
							<MiniStatistics name={t('idol.apply.detail.title_activity_name')} value={idolApplyDetail.activityName} />
							<MiniStatistics
								name={t('idol.apply.detail.title_mobile')}
								value={'+' + idolApplyDetail.countryCode + ') ' + phoneFomatter(idolApplyDetail.mobile, false)}
							/>
							<MiniStatistics
								name={t('idol.apply.detail.title_apply_status')}
								value={
									applyStatus === 'APPROVED'
										? t('idol.apply.filter.apply_status_approved')
										: applyStatus === 'REJECTED'
										  ? t('idol.apply.filter.apply_status_rejected')
										  : t('idol.apply.filter.apply_status_under_review')
								}
							/>
						</SimpleGrid>

						{idolApplyDetail?.applyStatus === IdolApplyStatus.UNDER_REVIEW ? (
							<SimpleGrid mb="10px">
								<Box>
									<Button
										float="left"
										fontSize={14}
										color={textColorPrimary}
										onClick={() => {
											// 상태를 부모 컴포넌트에 반영하고 모달 닫기
											setIsShowDetail(true);
										}}
									>
										{t('idol.apply.detail.review')}
									</Button>
								</Box>
							</SimpleGrid>
						) : (
							<SimpleGrid mb="10px">
								<Card py="15px">
									<Flex
										my="auto"
										h="100%"
										align={{ base: 'center', xl: 'start' }}
										justify={{ base: 'center', xl: 'center' }}
									>
										<Stat>
											<StatLabel
												lineHeight="100%"
												color={textColorSecondary}
												fontSize={{
													base: 'sm'
												}}
											>
												{t('idol.apply.detail.title_review_result')}
											</StatLabel>
											<StatNumber
												color={textColor}
												minHeight="100px"
												fontSize={{
													base: 'md'
												}}
											>
												{idolApplyDetail.reviewResult
													? convertNewlinesAndTabsToBreaks(idolApplyDetail.reviewResult)
													: ''}
											</StatNumber>
										</Stat>
									</Flex>
								</Card>
								<Box margin="5px 20px 0px">
									<Text float="right" textAlign="right" fontSize={14} color="GrayText">
										{idolApplyDetail.reviewDateTime}
									</Text>
								</Box>
							</SimpleGrid>
						)}

						<SimpleGrid mb="10px" gap="10px">
							<Card gap="10px">
								<Stack direction="row" spacing={4} align="center">
									<Button
										variant="brand"
										onClick={() => {
											downloadZipFile(idolApplyDetail.identityDocument?.idCardFrontZipUrl);
										}}
									>
										{t('idol.apply.detail.identify_img_download_1')}
									</Button>
									<Button
										variant="brand"
										onClick={() => {
											downloadZipFile(idolApplyDetail.identityDocument?.idCardBackZipUrl);
										}}
									>
										{t('idol.apply.detail.identify_img_download_2')}
									</Button>
								</Stack>
								<Stack direction="row" spacing={4} align="center">
									<Text variant="brand" onClick={() => {}}>
										{t('idol.apply.detail.title_select_zip_file')}
									</Text>
									<input type="file" onChange={handleFileChange} />
								</Stack>
								{files[0]?.imageUrl ? (
									<Zoom>
										<Image src={files[0]?.imageUrl} boxSize={'sm'} objectFit={'fill'} />
									</Zoom>
								) : null}
							</Card>
						</SimpleGrid>

						<SimpleGrid columns={{ base: 3, md: 5, xl: 5 }}>
							{idolApplyDetail?.bodyProfileImgUrls && idolApplyDetail?.bodyProfileImgUrls.length > 0
								? idolApplyDetail?.bodyProfileImgUrls.map((url, index) => (
										<DropImageFileFromUrl index={index} url={url} onDeleteFile={() => {}} isDeletable={false} />
								  ))
								: null}
						</SimpleGrid>
						<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px" height={'max-content'}>
							<MiniStatistics
								name={t('idol.apply.detail.title_apply_date_time')}
								value={dateFormat(idolApplyDetail.applyDateTime, DATA_FORMAT_FULL_DATE)}
							/>
						</SimpleGrid>
					</Grid>
				</Grid>
				{/* 심사내용 작성 팝업 */}
				<Modal
					isOpen={isShowDetail}
					onClose={() => {
						setIsShowDetail(false);
					}}
					isCentered
					size={'xg'}
				>
					<ModalOverlay />

					<ModalContent w="500px" h="600px" maxWidth="100%" maxHeight="100%">
						<ModalHeader>{t('idol.apply.detail.review')}</ModalHeader>
						<ModalCloseButton />
						<ModalBody overflow="auto">
							<Grid>
								<GridItem mb="5">
									<Text mb={2}>{t('idol.apply.detail.title_review_result')}</Text>
									<Textarea
										h={300}
										onChange={handleReviewResult}
										placeholder={t('idol.apply.detail.placeholder_review_result')}
										value={reviewResult}
									/>
								</GridItem>
								<RadioGroup
									defaultValue={idolApplyDetail.applyStatus}
									value={applyStatus}
									onChange={handleApplyStatus}
									mb={6}
								>
									<Text mb={2}>{t('idol.apply.detail.title_apply_status')}</Text>
									<Stack direction="row" spacing={5}>
										{/*<Radio value="UNDER_REVIEW">{t('idol.apply.filter.apply_status_under_review')}</Radio>*/}
										<Radio value="APPROVED">{t('idol.apply.filter.apply_status_approved')}</Radio>
										<Radio value="REJECTED">{t('idol.apply.filter.apply_status_rejected')}</Radio>
										<Radio value="TESTIDOL">{t('idol.apply.filter.apply_status_test')}</Radio>
									</Stack>
								</RadioGroup>
							</Grid>
						</ModalBody>
						<ModalFooter>
							<Button
								marginRight={2}
								onClick={() => {
									// handleSave();
									setIsShowModalConfirm(true);
								}}
							>
								{t('common.save')}
							</Button>
							<Button
								onClick={() => {
									setIsShowDetail(false);
								}}
							>
								{t('common.close')}
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>

				<ModalBasic
					isOpen={isShowModalConfirm}
					onClose={() => {
						setIsShowModalConfirm(false);
					}}
					title={
						applyStatus === 'APPROVED'
							? t('idol.apply_approved_confirm')
							: applyStatus === 'REJECTED'
							  ? t('idol.apply_rejected_confirm')
							  : applyStatus === 'TESTIDOL'
							    ? t('idol.apply_approved_test_confirm')
							    : ''
					}
					desc={
						applyStatus === 'APPROVED'
							? t('idol.apply_approved_confirm_desc')
							: applyStatus === 'REJECTED'
							  ? t('idol.apply_rejected_confirm_desc')
							  : applyStatus === 'TESTIDOL'
							    ? t('idol.apply_approved_test_confirm_desc')
							    : ''
					}
					secondaryButton={{
						title: t('common.ok'),
						onClick: (value: any) => {
							handleSave();
						}
					}}
				/>
			</Flex>
		</Grid>
	);
}
