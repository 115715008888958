import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debugLog, showToast } from '../helper/functions';
import { Socket } from 'socket.io-client/build/esm/socket';
import { useLogin } from './login-provider';

const { io } = require('socket.io-client');

export type SocketContextValue = {
	// 소켓 설정
	initialize: (roomId: any, l: SocketMessageListener) => void;
	// 소켓 연결 여부
	isSocketConnected: boolean;
	// 메시지 전송
	sendMessage: (message: string) => void;

	// 유저 차단
	banUser?: (id: string, type: string) => void;
	// 유저 차단 해제
	cancelBanUser?: (id: string, type: string) => void;
	// 메시지 신고
	blockMessage?: (messageId: string, roomId: string) => void;
	// 메시지 신고
	reportMessage?: (messageId: string, roomId: string, userId: string, userType: string) => void;

	// 소켓 제거
	removeSocket: () => void;

	// 강퇴 유저 목록
	banUserList: any[];

	// 사용자 수
	userCount: number;
	//
	isEndLiveStreaming: boolean;
};

export type SocketMessageListener = {
	// 소켓 연결
	onSocketConnected: (isConnected: boolean) => void;
	// 소켓 재연결 시도 중
	onSocketReconnecting?: () => void;
	// 소켓 오류
	onSocketError?: (error: any) => void;
	// 소켓 연결 끊김
	onSocketDisconnected?: () => void;

	// 메시지 수신
	onReceivedUserMessages: (data: any) => void;
	// 공지 사항 수신
	onReceivedNoti?: (data: any) => void;
	// 매니저 공지 사항 수신
	onReceivedManagerNoti?: (data: any) => void;
	// 매니저 숨김 처리
	onReceivedHideMessages?: (data: any) => void;
	// 채팅 스팸 공지 수신
	onReceivedSpanNoti?: (data: any) => void;
	// 채팅 신규 인원 추가
	onReceivedMemberEntrance?: (data: any) => void;
	// 강퇴 인원 메시지
	onReceivedBanUserNoti?: (data: any) => void;
	// 사용자 카운트
	onReceivedUserCount?: (data: any) => void;

	// 방 종료
	onExit?: (data: any) => void;
};

export const SocketContext = createContext<SocketContextValue>(undefined!);

export const SocketProvider: React.FC<{ children?: any }> = ({ children }) => {
	const { t } = useTranslation();

	const { tokens } = useLogin();
	const [roomInfo, setRoomInfo] = useState(null);

	// TODO 소켓 URL 임시
	const SOCKET_URL = process.env.REACT_APP_CHAT_URL;

	// 연결 여부
	const [isConnected, setIsConnected] = useState(false);

	const [socketio, setSocketio] = useState(null);
	const [listener, setListener] = useState(null);
	const [banUserList, setBanUserList] = useState([]);
	const [userCount, setUserCount] = useState(0);

	const [isEndLiveStreaming, setIsEndLiveStreaming] = useState(false);

	useEffect(() => {
		if (socketio && listener) {
			setSocketSetting(socketio, listener);
		} else {
			setSocketio(null);
			setListener(null);
		}
	}, [socketio, listener]);

	function setSocketSetting(socket: Socket, listener: SocketMessageListener) {
		// client-side
		socketio.on('connect', () => {
			debugLog('======> socket connection success');
			setIsConnected(true);
			if (listener) {
				listener.onSocketConnected && listener.onSocketConnected(true);
			}
		});

		socketio.on('disconnect', () => {
			debugLog('======> socket connection disconnected');
			setIsConnected(false);
			if (listener) {
				listener.onSocketDisconnected && listener.onSocketDisconnected();
			}
		});

		socketio.on('reconnect', () => {
			debugLog('======> socket connection reconnect attempt');
			setIsConnected(true);
			if (listener) {
				listener.onSocketReconnecting && listener.onSocketReconnecting();
			}
		});

		socketio.on('reconnect_attempt', () => {
			debugLog('======> socket connection reconnect attempt');
			setIsConnected(false);
			if (listener) {
				listener.onSocketReconnecting && listener.onSocketReconnecting();
			}
		});

		socketio.on('connect_error', (err: any) => {
			debugLog('======> socket connection error ' + err.data.content);
			setIsConnected(false);
			if (listener) {
				listener.onSocketError && listener.onSocketError('error');
			}
		});

		socket.on('error', err => {
			showToast(t('error.try_again'));
		});

		socketio.on('userMsg', (data: any) => {
			debugLog('======> socket received message ' + JSON.stringify(data));
			if (listener) {
				listener.onReceivedUserMessages(data);
			}
		});

		socketio.on('entireNoti', (data: any) => {
			debugLog('======> socket received entireNoti ' + JSON.stringify(data));
			if (listener) {
				listener.onReceivedNoti && listener.onReceivedNoti(data);
			}
		});

		socketio.on('idolManagerNoti', (data: any) => {
			debugLog('======> socket received manager noti ' + JSON.stringify(data));
			if (listener) {
				listener.onReceivedManagerNoti && listener.onReceivedManagerNoti(data);
			}
		});

		socketio.on('proposalManager', (data: any) => {
			debugLog('======> socket received manager noti ' + JSON.stringify(data));
			if (listener) {
				listener.onReceivedNoti && listener.onReceivedNoti(data);
			}
		});

		socketio.on('acceptManager', (data: any) => {
			debugLog('======> socket received manager noti ' + JSON.stringify(data));
			if (listener) {
				listener.onReceivedNoti && listener.onReceivedNoti(data);
			}
		});

		socketio.on('rejectManager', (data: any) => {
			debugLog('======> socket received manager noti ' + JSON.stringify(data));
			if (listener) {
				listener.onReceivedNoti && listener.onReceivedNoti(data);
			}
		});

		socketio.on('dismissManager', (data: any) => {
			debugLog('======> socket received manager noti ' + JSON.stringify(data));
			if (listener) {
				listener.onReceivedNoti && listener.onReceivedNoti(data);
			}
		});

		socketio.on('chatProhibitNoti', (data: any) => {
			debugLog('======> socket received chat prohibit noti ' + JSON.stringify(data));
			// if (listener) {
			// 	listener.onReceivedManagerNoti && listener.onReceivedManagerNoti(data);
			// }
		});

		socketio.on('chatSpamNoti', (data: any) => {
			debugLog('======> socket received chat spam noti ' + JSON.stringify(data));
			// if (listener) {
			// 	listener.onReceivedSpanNoti && listener.onReceivedSpanNoti(data);
			// }
		});

		socketio.on('welcome', (data: any) => {
			debugLog('======> socket received chat welcome ' + JSON.stringify(data));
			if (listener) {
				listener.onReceivedNoti && listener.onReceivedNoti(data);
			}
		});

		socketio.on('exit', (data: any) => {
			debugLog('======> socket received chat exit ' + JSON.stringify(data));
			if (listener) {
				listener.onExit && listener.onExit(data);
			}
		});

		socketio.on('liveStreamingExitAlert', (data: any) => {
			debugLog('======> socket received chat liveStreamingExitAlert ' + JSON.stringify(data));
			if (listener) {
				listener.onReceivedNoti && listener.onReceivedNoti(data);
			}
		});

		socketio.on('exit', (data: any) => {
			debugLog('======> socket received chat exit ' + JSON.stringify(data));
			if (listener) {
				setIsEndLiveStreaming(true);
				listener.onExit && listener.onExit(data);
			}
		});

		socketio.on('memberEntranceReaction', (data: any) => {
			debugLog('======> socket received chat memberEntranceReaction ' + JSON.stringify(data));
			// if (listener) {
			// 	listener.onReceivedSpanNoti && listener.onReceivedSpanNoti(data);
			// }
		});

		socketio.on('sendPinMessage', (data: any) => {
			debugLog('======> socket received chat memberEntranceReaction ' + JSON.stringify(data));
			// if (listener) {
			// 	listener.onReceivedSpanNoti && listener.onReceivedSpanNoti(data);
			// }
		});

		socketio.on('memberEntrance', (data: any) => {
			debugLog('======> socket received chat memberEntrance ' + JSON.stringify(data));
			if (listener) {
				listener.onReceivedMemberEntrance && listener.onReceivedMemberEntrance(data);
			}
		});

		socketio.on('banUserNoti', (data: any) => {
			debugLog('======> socket received chat banUserNoti ' + JSON.stringify(data));
			if (listener) {
				listener.onReceivedBanUserNoti && listener.onReceivedBanUserNoti(data);
			}
		});

		socketio.on('reportNoti', (data: any) => {
			debugLog('======> socket received chat reportNoti ' + JSON.stringify(data));
			// if (listener) {
			// 	listener.onReceivedMemberEntrance && listener.onReceivedMemberEntrance(data);
			// }
			// setBanUserList(data);
		});

		socketio.on('blockMessage', (data: any) => {
			debugLog('======> socket received chat blockMessage ' + JSON.stringify(data));
			// if (listener) {
			// 	listener.onReceivedMemberEntrance && listener.onReceivedMemberEntrance(data);
			// }
			// setBanUserList(data);
		});

		socketio.on('banUserList', (data: any) => {
			debugLog('======> socket received chat banUserList ' + JSON.stringify(data));
			setBanUserList(JSON.parse(data));
		});

		socketio.on('userCount', (data: any) => {
			debugLog('======> socket received chat banUserList ' + JSON.stringify(data));
			setUserCount(JSON.parse(data).count);
		});
	}

	const initialize = (roomId: any, l: SocketMessageListener) => {
		if (tokens.accessToken && socketio === null) {
			setRoomInfo(roomId);
			const socket = io.connect(SOCKET_URL, {
				reconnectionDelayMax: 10000,
				path: '/chat/test',
				auth: {
					token: tokens.accessToken
				},
				query: {
					roomId: roomId.liveStreamingId.toString()
				},
				transports: ["websocket"]
			});

			if (socket) {
				setSocketio(socket);
				setListener(l);
			}
		}
	};

	function sendMessage(msg: string) {
		if (!socketio) {
			debugLog('====== SocketProvider [[ sendMessage ]] socketio null');
			return;
		}

		// socketio.emit('userMsg', {
		// 	message: 'ㅁㄴ아림너리ㅏㅇㅁㄴㄹ',
		// 	messageSentDatetime: new Date().getTime(),
		// 	liveStartDatetime: roomInfo.createdDateTime
		// });
		//
		// console.log('====== SocketProvider asdasfdsaf');
	}

	function banUser(id: string, type: string) {
		if (!socketio) {
			debugLog('====== SocketProvider [[ sendMessage ]] socketio null');
			return;
		}

		const data = {
			tarUserId: id,
			tarUserType: type
		};
		socketio.emit('banUser', JSON.stringify(data));
	}

	function blockMessage(messageId: string, roomId: string) {
		if (!socketio) {
			debugLog('====== SocketProvider [[ sendMessage ]] socketio null');
			return;
		}

		const data = {
			tarMessageId: messageId,
			roomId: roomId
		};
		socketio.emit('blockMessage', JSON.stringify(data));
	}

	function cancelBanUser(id: string, type: string) {
		if (!socketio) {
			debugLog('====== SocketProvider [[ sendMessage ]] socketio null');
			return;
		}

		const data = {
			tarUserId: id,
			tarUserType: type
		};
		socketio.emit('cancelBanUser', JSON.stringify(data));
	}

	function reportMessage(messageId: string, roomId: string, userId: string, userType: string) {
		if (!socketio) {
			debugLog('====== SocketProvider [[ sendMessage ]] socketio null');
			return;
		}

		const data = {
			tarMessageId: messageId,
			roomId: roomId,
			userId: userId,
			userType: userType
		};
		socketio.emit('reportMessage', data);
	}

	function removeSocket() {
		if (socketio) {
			socketio.disconnect(true);
		}

		setListener(null);
	}

	return (
		<SocketContext.Provider
			value={{
				initialize: initialize,
				isSocketConnected: isConnected,
				removeSocket: removeSocket,
				sendMessage: sendMessage,
				banUser: banUser,
				cancelBanUser: cancelBanUser,
				reportMessage: reportMessage,
				blockMessage: blockMessage,
				banUserList: banUserList,
				userCount: userCount,
				isEndLiveStreaming: isEndLiveStreaming
			}}
		>
			{children}
		</SocketContext.Provider>
	);
};

export const useSocket = () => {
	const context = useContext(SocketContext);

	if (context == null) {
		throw new Error("Cannot use 'useAuth' outside of AuthContext.");
	}

	return context;
};
