import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { showToast } from '../helper/functions';
import { getCookie, removeCookie, setCookie } from '../helper/cookies';
import { useTranslation } from 'react-i18next';
import { KEY_ACCESS_TOKEN, KEY_REFRESH_TOKEN, KEY_USER_EMAIL, KEY_USER_NAME } from '../helper/keys';

// 유저 토큰 정보
export type Tokens = {
	accessToken: string | null;
	refreshToken: string | null;
};

// 로그인 정보
export type LoginInfo = {
	email: string | null;
	// authority: string[];
	save: boolean;
	name: string;
};

export type LoginContextValue = {
	// null 일때는 로그인 되지 않은 상태를 말한다.
	tokens: Tokens;
	setTokens: (newTokens: Tokens, persist?: boolean) => void;
	logout: () => void;
	persisted: boolean;

	//로그인 정보 저장
	loginInfo: LoginInfo;
	setLoginInfo: (newLoginInfo: LoginInfo, save: boolean) => void;

	// 로그인 여부
	isLogin: boolean;
};

export const LoginContext = createContext<LoginContextValue>(undefined!);

export const LoginProvider: React.FC<{
	initialTokens: Tokens;
	initialLoginInfo: LoginInfo;
	children?: any;
}> = ({ initialTokens, initialLoginInfo, children }) => {
	const { t } = useTranslation();
	const [tokens, setTokens] = useState<Tokens>(initialTokens);
	const [loginInfo, setLoginInfo] = useState<LoginInfo>(initialLoginInfo);

	// 초기값이 있다면 존속되고 있다는 것이다. session을 도입하면 또한 그에 걸맞게 변경해 준다.
	const persisted = useMemo(
		() => initialTokens.accessToken != null || initialTokens.refreshToken != null,
		[initialTokens]
	);

	const isLogin = useMemo(
		// 엑세스 토큰이 파손되었으나 리프레시 토큰이 있다면, 그 또한 로그인 되었다고 판단한다.
		() => {
			return tokens.accessToken != null || tokens.refreshToken != null;
			// return true;
		},

		[tokens.accessToken, tokens.refreshToken]
	);

	const setTokensWithPersistence = useCallback(
		(tokens: Tokens, persist = persisted) => {
			setTokens(tokens);

			if (tokens.accessToken) {
				setCookie(KEY_ACCESS_TOKEN, tokens.accessToken);
			} else {
				removeCookie(KEY_ACCESS_TOKEN);
			}

			if (persist && tokens.refreshToken) {
				setCookie(KEY_REFRESH_TOKEN, tokens.refreshToken);
			} else {
				removeCookie(KEY_REFRESH_TOKEN);
			}
		},
		[persisted]
	);

	const logout = () => {
		setTokensWithPersistence({
			accessToken: null,
			refreshToken: null
		});
		setLoginInfoWithSaveOrNot(
			{
				email: null,
				save: false,
				name: null
			},
			false
		);

		showToast(t('common:logout'));
	};

	const setLoginInfoWithSaveOrNot = (loginInfo: LoginInfo, save: boolean) => {
		if (loginInfo.email && save === true) {
			setLoginInfo(loginInfo);
			setCookie(KEY_USER_EMAIL, loginInfo.email);
			setCookie(KEY_USER_NAME, loginInfo.name);
		} else {
			setLoginInfo({ email: null, save: save, name: null });
			removeCookie(KEY_USER_EMAIL);
			removeCookie(KEY_USER_NAME);
		}
	};

	return (
		<LoginContext.Provider
			value={{
				tokens,
				setTokens: setTokensWithPersistence,
				persisted,
				isLogin,
				logout,
				loginInfo,
				setLoginInfo: setLoginInfoWithSaveOrNot
			}}
		>
			{children}
		</LoginContext.Provider>
	);
};

export const useLogin = () => {
	const context = useContext(LoginContext);

	if (context == null) {
		throw new Error("Cannot use 'useAuth' outside of AuthContext.");
	}

	return context;
};

// TODO: 쿠키 말고 다른 걸로 대체할 것
//  브라우져 토큰 정보 불러오기
export function getBrowserTokens(): Tokens | null {
	if (typeof window !== 'undefined') {
		return {
			accessToken: getCookie(KEY_ACCESS_TOKEN) ?? null,
			refreshToken: getCookie(KEY_REFRESH_TOKEN) ?? null
		};
	} else {
		return {
			accessToken: null,
			refreshToken: null
		};
	}
}

// 로그인 정보 토큰 정보 불러오기
export function getLoginInfo(): LoginInfo | null {
	if (typeof window !== 'undefined') {
		return {
			email: getCookie(KEY_USER_EMAIL) ?? null,
			save: !!getCookie(KEY_USER_EMAIL) ? true : false,
			name: getCookie(KEY_USER_NAME) ?? null
		};
	} else {
		return {
			email: null,
			save: false,
			name: null
		};
	}
}
