import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Chakra imports
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	Text,
	useColorModeValue
} from '@chakra-ui/react';
// Assets
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';

// Custom components
import CenteredAuth from 'layouts/auth/variants/Centered';
import { useLogin } from '../../../providers/login-provider';
import { debugLog, showToast } from "../../../helper/functions";
import { useApi } from '../../../providers/api-provider';
import { useAuth } from '../../../providers/auth-provider';
import { useTranslation } from 'react-i18next';
import { REGEXP_EMAIL, REGEXP_PASSWORD } from '../../../helper/reg';
import { SigninResponse } from '../../../apis/commonAPI';
import { useThemeColors } from '../../../providers/theme-provider';

function SignIn() {
	const { t } = useTranslation();
	const { commonApi } = useApi();
	const { setTokens, setLoginInfo } = useLogin();
	const { savePermissions, currentUserPermissions } = useAuth();

	const { textColor } = useThemeColors();

	// const textColor = useColorModeValue("navy.700", "white");
	const textColorSecondary = 'gray.400';
	const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
	const textColorBrand = useColorModeValue('brand.500', 'white');
	const brandStars = useColorModeValue('brand.500', 'brand.400');
	const [show, setShow] = React.useState(false);
	const handleClick = () => setShow(!show);
	const navigate = useNavigate();

	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	const tempCheck = true;

	const requestLogin = () => {
		if (email === '') {
			showToast(t('error.input_email'), 'error', true);
			return;
		}

		if (!email.match(REGEXP_EMAIL)) {
			showToast(t('error.input_wrong_email'), 'error', true);
			return;
		}

		if (password === '') {
			showToast(t('error.input_password'), 'error', true);
			return;
		}

		// if (!password.match(REGEXP_PASSWORD)) {
		// 	showToast(t('error.input_password'), 'error', true);
		// 	return;
		// }

		commonApi
			.signin({
				email: email,
				password: password
			})
			.then(
				response => {
					let signResponse = response as SigninResponse;
					if (signResponse) {
						setTokens(
							{
								accessToken: signResponse.token.token,
								refreshToken: signResponse.token.refreshToken
							},
							tempCheck
						);

						setLoginInfo(
							{
								email: signResponse.accountDetail?.email,
								save: tempCheck,
								name: signResponse.accountDetail?.name
							},
							tempCheck
						);

						let authTrain = response.accountDetail?.authTrain;
						savePermissions(authTrain);

						showToast(t('common.complete_login'));

						// TODO 권한에 따라 첫 페이지 이동 분기 처리 필요
						setTimeout(() => {
							navigate('/admin/dashboard');
						}, 100);
					} else {
						showToast(t('error.try_again'));
					}
				},
				error => {
					showToast(t('error.try_again'));
				}
			);
	};

	return (
		<CenteredAuth cardTop={{ base: '140px', md: '14vh' }} cardBottom={{ base: '50px', lg: 'auto' }} mx="0px">
			<Flex
				maxW={{ base: '100%', md: 'max-content' }}
				w="100%"
				mx={{ base: 'auto', lg: '0px' }}
				me="auto"
				justifyContent="center"
				px={{ base: '20px', md: '0px' }}
				flexDirection="column"
			>
				<Box me="auto">
					<Heading color={textColor} fontSize="36px" mb="10px">
						{t('common.signin')}
					</Heading>
					<Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
						{t('signin.enter_email_password')}
					</Text>
				</Box>
				<Flex
					zIndex="2"
					direction="column"
					w={{ base: '100%', md: '420px' }}
					maxW="100%"
					background="transparent"
					borderRadius="15px"
					mx={{ base: 'auto', lg: 'unset' }}
					me="auto"
					mb={{ base: '20px', md: 'auto' }}
				>
					<FormControl>
						<FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
							Email<Text color={brandStars}>*</Text>
						</FormLabel>
						<Input
							isRequired={true}
							fontSize="sm"
							ms={{ base: '0px', md: '0px' }}
							placeholder="email@hypy.jp"
							mb="24px"
							fontWeight="500"
							size="lg"
							value={email}
							onChange={e => {
								setEmail(e.target.value);
							}}
						/>
						<FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
							Password<Text color={brandStars}>*</Text>
						</FormLabel>
						<InputGroup size="md">
							<Input
								isRequired={true}
								fontSize="sm"
								ms={{ base: '0px', md: '4px' }}
								placeholder="Min. 8 characters"
								mb="24px"
								size="lg"
								type={show ? 'text' : 'password'}
								variant="auth"
								value={password}
								onChange={e => {
									setPassword(e.target.value);
								}}
								onKeyDown={e => {
									if (e.key === 'Enter') {
										requestLogin();
									}
								}}
							/>
							<InputRightElement display="flex" alignItems="center" mt="4px">
								<Icon
									color={textColorSecondary}
									_hover={{ cursor: 'pointer' }}
									as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
									onClick={handleClick}
								/>
							</InputRightElement>
						</InputGroup>
						<Button fontSize="sm" variant="brand" fontWeight="500" w="100%" h="50" mb="24px" onClick={requestLogin}>
							{t('common.signin')}
						</Button>
					</FormControl>
					<Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%" mt="0px">
						<Text color={textColorDetails} fontWeight="400" fontSize="14px">
							{t('signin.not_yet_registered')}

							<Text
								color={textColorBrand}
								as="span"
								ms="5px"
								fontWeight="500"
								onClick={() => {
									window.location.href = 'mailto:support@hypy.jp';
								}}
							>
								{t('signin.inquire')}
							</Text>
						</Text>
					</Flex>
				</Flex>
			</Flex>
		</CenteredAuth>
	);
}

export default SignIn;
