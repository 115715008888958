// Chakra imports
import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Image, Text } from '@chakra-ui/react';
import { getFileName } from '../../helper/functions';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

export default function DropImageFileFromUrl(props: {
	index: number;
	url: string;
	onDeleteFile: (index: number) => void;
	isDeletable?: boolean;
}) {
	const { index, url, onDeleteFile, isDeletable = true } = props;

	if (!url) {
		return <Box />;
	}

	return (
		<Box maxW={'100%'} maxH="sm" borderWidth="1px"  overflow="hidden" position={'relative'}>
			{url.includes('png') || url.includes('jpg') || url.includes('jpeg') ? (
				<Zoom>
					<Image src={url} objectFit={'cover'} />
				</Zoom>
			) : (
				<Text fontSize="xs" h={'80px'}>
					{getFileName(url)}
				</Text>
			)}
			{isDeletable ? (
				<DeleteIcon
					mb={'20px'}
					position={'absolute'}
					top={'20px'}
					right={'20px'}
					onClick={() => {
						onDeleteFile(index);
					}}
				/>
			) : null}
		</Box>
	);
}
