// Chakra imports
import {
	Avatar,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSocket } from '../../../../../providers/socket-provider';

// Assets

export default function ModalBanUserList(props: {
	isOpen: boolean;
	cancelBanUser: (userId: string, userType: string) => void;
	onClose: any;
}) {
	// Chakra Color Mode

	const { t } = useTranslation();
	const { banUserList } = useSocket();
	const { isOpen, cancelBanUser, onClose } = props;

	const textColor = useColorModeValue('navy.700', 'white');

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={'2xl'} isCentered scrollBehavior={'inside'}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{t('content.live.ban_user_list')} </ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<TableContainer overflowY={'auto'} maxHeight={'500px'}>
						<Table variant="simple">
							<Thead>
								<Tr>
									<Th>{t('common.name')}</Th>
									<Th>{t('user.member_type')}</Th>
									<Th>{t('common.cancel')}</Th>
								</Tr>
							</Thead>
							<Tbody>
								{banUserList &&
									banUserList.map((item, index) => {
										return (
											<Tr>
												<Td>
													<Flex direction="row" justifyContent={'flex-start'} alignItems={'center'}>
														<Avatar src={item.userProfileImgUrl} size={'sm'} />
														<Flex direction="column" ml={2}>
															<Text
																color={textColor}
																fontSize={{
																	base: 'sm'
																}}
																fontWeight="bold"
																me="5px"
																noOfLines={1}
															>
																{item.userName}
															</Text>
															<Text
																color="secondaryGray.600"
																fontSize={{
																	base: 'sm'
																}}
																fontWeight="400"
																me="5px"
																noOfLines={1}
																w="100%"
																maxWidth="100%"
															>
																{item.userId}
															</Text>
														</Flex>
													</Flex>
												</Td>
												<Td>
													<Flex direction="row">
														<Flex direction="column" ml={2}>
															<Text
																color={textColor}
																fontSize={{
																	base: 'sm'
																}}
																fontWeight="bold"
																me="5px"
																noOfLines={1}
															>
																{item.userType}
															</Text>
														</Flex>
													</Flex>
												</Td>
												<Th>
													<Flex direction="row" justifyContent={'center'} alignItems={'center'}>
														<Button
															colorScheme="brand"
															mr={3}
															onClick={() => {
																cancelBanUser(item.userId, item.userType);
															}}
														>
															{t('common.cancel')}
														</Button>
													</Flex>
												</Th>
											</Tr>
										);
									})}
							</Tbody>
						</Table>
					</TableContainer>
				</ModalBody>
				<ModalFooter></ModalFooter>
			</ModalContent>
		</Modal>
	);
}
