// Chakra imports
import {
	Button,
	Flex,
	Icon, Modal, ModalBody, ModalCloseButton,
	ModalContent, ModalFooter, ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Tooltip,
	useColorModeValue
} from "@chakra-ui/react";

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Card from 'components/card/Card';
import { useApi } from '../../../../providers/api-provider';
import { RewardPointType, RewardType, SortCondition, TableRowType } from '../../../../types/enums';
import { useTranslation } from 'react-i18next';
import { useThemeColors } from '../../../../providers/theme-provider';
import SearchTable from '../../../../components/table/SearchTable';
import { MdOutlineAdd } from 'react-icons/md';
import Filter from '../../../../components/actions/Filter';
import { CharacterTypeString, RewardTypeString, SkillTypeString, TYPE_KEY_SORT } from '../../../../helper/types';
import { createDynamicColumns, VisibleColumns } from 'components/table/createDynamicColumns';
import { RewardsRequest, RewardsResponse, RewardsTrain } from '../../../../apis/projectAPI';
import { TableBodyText } from '../../../../components/table/TableComponents';
import SelectField from 'components/fields/SelectField';
import ReportDetail from "../../report/member/detail";
import RewardsDetail from "./detail";

// 금지어 목록
export default function Rewards() {
	const { projectApi } = useApi();

	const { t } = useTranslation();
	const { textColor, buttonBg, hoverButton, activeButton } = useThemeColors();

	// 목록
	const [list, setList] = useState<RewardsTrain[]>([]);

	// 전체 갯수
	const [totalCount, setTotalCount] = useState<number>(0);
	// 한번에 불러올 목록
	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);

	// 검색 조건
	const [searchParams, setSearchParams] = useState<Partial<RewardsRequest>>({});

	const [searchRewardType, setSearchRewardType] = useState<RewardType>(null);

	// 모달 창
	const [isShowDetailModal, setIsShowDetailModal] = useState(false);
	const [selectId, setSelectId] = useState<string>("-1");

	useEffect(() => {
		requestRewardsList(offset);
	}, [searchParams]);

	// 목록 요청
	const requestRewardsList = async (offset: number) => {
		setOffset(offset);
		projectApi
			.getRewardsList({
				limit: limit,
				offset: offset * limit,
				...searchParams
			})
			.then(responseReportList)
			.catch(() => {});
	};

	// 목록 처리
	const responseReportList = (response: RewardsResponse) => {
		if (response.rewards) {
			setList(response.rewards);
		}

		if (response.totalElements) {
			setTotalCount(response.totalElements);
		}
	};

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<RewardsTrain>[] = [
		{
			row: 'rewardId',
			title: 'id',
			type: TableRowType.TEXT
		},
		{ row: 'name', type: TableRowType.ENUM },
		{ row: 'iconImageUrl', type: TableRowType.IMAGE },
		{ row: 'type', title: 'rewardType', type: TableRowType.ENUM, enumToText: RewardTypeString },
		{
			row: 'rewardId',
			title: 'rewards',
			type: TableRowType.ACTION,
			extraComponent: (value: any) => {
				let item = list.filter(x => x.rewardId === value);
				if (item[0].type === RewardType.CHARACTER) {
					return (
						<TableBodyText
							title={
								CharacterTypeString(t, item[0].character.type) +
								':  ' +
								t('common.score', { score: item[0].character.point })
							}
						/>
					);
				} else {
					return (
						<TableBodyText
							title={SkillTypeString(t, item[0].skill.type) + ':  ' + t('common.score', { score: item[0].skill.point })}
						/>
					);
				}
			}
		}
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)
		setSelectId(String(rowData.rewardId))
		setIsShowDetailModal(true);
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	// 정렬 필터 변경
	const filterChange = (type: string) => {
		setSearchParams(prevState => {
			return {
				...prevState,
				direction: type === TYPE_KEY_SORT.LATEST ? SortCondition.DESC : SortCondition.ASC
			};
		});
	};

	const handleClickAdd = () => {
		setSelectId("-1")
		setIsShowDetailModal(true);
	};

	// Chakra Color Mode
	return (
		<Flex direction="column" pt={{ sm: '75px', lg: '75px' }}>
			<Card px="0px">
				<Flex w="100%" direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'} mb="36px">
					<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px" height={'max-content'} px={'20px'}>
						<SelectField
							label={t('project.filter_search_reward')}
							value={searchRewardType}
							options={Object.keys(RewardType)}
							onChange={e => {
								setOffset(0);
								setSearchParams(prevState => {
									return {
										...prevState,
										type:
											e.target.value === RewardType.SKILL
												? RewardType.SKILL
												: e.target.value === RewardType.CHARACTER
												  ? RewardType.CHARACTER
												  : null
									};
								});
							}}
							displayOptionsString={RewardTypeString}
						/>

						<SelectField
							label={t('project.filter_search_reward_point')}
							value={searchRewardType}
							options={Object.keys(RewardPointType)}
							onChange={e => {
								setOffset(0);
								setSearchParams(prevState => {
									return {
										...prevState,
										index: e.target.value.replace("P", "")
									};
								});
							}}
						/>
					</SimpleGrid>
					<Flex justifyContent={'flex-end'} mr={{ base: '0px', sm: '10px' }}>
						<Filter
							onChange={filterChange}
							initialSortType={TYPE_KEY_SORT.LATEST}
							secondaryTitle={'components.filter_alphabet'}
						/>
						<Tooltip label={t('project.add_rewards')}>
							<Button
								me={{ base: '10px', md: '20px' }}
								ml={{ base: '10px', sm: '10px', md: '10px' }}
								bg={buttonBg}
								border="1px solid"
								color="secondaryGray.600"
								borderColor={useColorModeValue('secondaryGray.100', 'whiteAlpha.100')}
								borderRadius="16px"
								_placeholder={{ color: 'secondaryGray.600' }}
								_hover={hoverButton}
								_active={activeButton}
								_focus={activeButton}
								onClick={handleClickAdd}
							>
								<Icon color={textColor} as={MdOutlineAdd} />
							</Button>
						</Tooltip>
					</Flex>
				</Flex>
				<SearchTable request={requestRewardsList} data={list} columns={columns} totalCount={totalCount} index={offset}/>
			</Card>

			<Modal
				isOpen={isShowDetailModal}
				onClose={() => {
					setIsShowDetailModal(false);
				}}
				isCentered
				size={'xl'}
			>
				<ModalOverlay />

				<ModalContent>
					<ModalHeader>{t('project.reward_detail')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody overflow="auto">
						<RewardsDetail
							detailId={selectId}
							requestChangeParams={() => {
								setIsShowDetailModal(false);
							}}
						/>
					</ModalBody>
					<ModalFooter>
						<Button
							onClick={() => {
								setIsShowDetailModal(false);
							}}
						>
							{t('common.close')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	);
}
