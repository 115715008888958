import { Box, Checkbox, Flex, FormControl, Icon, SimpleGrid, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import Dropzone from 'sample/admin/main/ecommerce/settingsProduct/components/Dropzone';
import DropImageFile from './DropImageFile';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { useThemeColors } from '../../../../providers/theme-provider';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';
import TextAreaField from 'components/fields/TextAreaField';
import TextField from 'components/fields/TextField';

export function PreContractIdolInformation(props: {
	name: string;
	setName: React.Dispatch<React.SetStateAction<string>>;
	mobileNo: string;
	setMobileNo: React.Dispatch<React.SetStateAction<string>>;
	countryCode: string;
	setCountryCode: React.Dispatch<React.SetStateAction<string>>;
	memo: string;
	setMemo: React.Dispatch<React.SetStateAction<string>>;
}) {
	const { t } = useTranslation();
	const { textColorPrimary, textColorSecondary } = useThemeColors();
	const { name, setName, mobileNo, setMobileNo, countryCode, setCountryCode, memo, setMemo } = props;

	return (
		<FormControl>
			<Card>
				<SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
					<TextField label={t('idol.precontract.detail.regist')} text={t('idol.precontract.detail.regist_desc')} />
					<InputField
						id="name"
						label={t('common.name')}
						placeholder={t('common.name')}
						value={name}
						onChange={e => {
							setName(e.target.value);
						}}
					/>
					<InputField
						id="mobile"
						label={t('common.mobile')}
						placeholder={t('common.mobile')}
						value={mobileNo}
						type={'number'}
						onChange={e => {
							setMobileNo(e.target.value);
						}}
					/>
					<InputField
						id="mobile"
						label={t('common.country_code')}
						placeholder={t('common.country_code')}
						value={countryCode}
						type={'number'}
						onChange={e => {
							setCountryCode(e.target.value);
						}}
					/>
					<TextAreaField
						id="memo"
						label={t('common.memo')}
						h="100px"
						placeholder={t('common.memo')}
						value={memo}
						onChange={e => {
							setMemo(e.target.value);
						}}
					/>
				</SimpleGrid>
			</Card>
		</FormControl>
	);
}

export function PreContractIdolImages(props: {
	imgFiles: File[];
	setImgFiles: React.Dispatch<React.SetStateAction<File[]>>;
	password: string;
	setPassword: React.Dispatch<React.SetStateAction<string>>;
}) {
	const { t } = useTranslation();
	const { textColor, brand, textColorPrimary, textColorSecondary } = useThemeColors();
	const { imgFiles, setImgFiles, password, setPassword } = props;

	// 비밀번호 확인 여부
	const [visible, setVisible] = useState(false);

	const onDropImages = useCallback(
		(acceptedFiles: File[]) => {
			const mappedFiles = acceptedFiles.map(file =>
				Object.assign(file, {
					preview: URL.createObjectURL(file)
				})
			);

			setImgFiles([...imgFiles, ...mappedFiles]);
		},
		[imgFiles]
	);

	const onDeleteImages = useCallback(
		(index: number) => {
			const updatedImagesPreview = imgFiles.filter((_, i) => i !== index);
			setImgFiles(updatedImagesPreview);
		},
		[imgFiles, setImgFiles]
	);

	return (
		<FormControl>
			<Card>
				<SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
					<TextField label={t('idol.precontract.detail.id_imgs')} text={t('idol.precontract.detail.id_imgs_desc')} />
					<InputField
						mb="25px"
						me="30px"
						id="idCardPassword"
						label={t('idol.precontract.detail.compress_password')}
						placeholder={t('idol.precontract.detail.compress_password_desc')}
						value={password}
						type={visible ? 'text' : 'password'}
						onChange={e => {
							setPassword(e.target.value);
						}}
					/>
					<Checkbox
						size="md"
						colorScheme="brand"
						isChecked={visible}
						onChange={e => {
							setVisible(e.target.checked);
						}}
					>
						{t('idol.new.visible_password')}
					</Checkbox>
					<Dropzone
						content={
							<Box maxW="100%">
								<Icon as={MdOutlineCloudUpload} w="80px" h="80px" color={textColor} />
								<Text
									mb="12px"
									fontSize="lg"
									w="100%"
									maxW="100%"
									fontWeight="700"
									color={textColor}
									whiteSpace="pre-wrap"
								>
									{t('idol.new.images_drop') + ' '}
									<Text as="span" fontSize="lg" fontWeight="700" color={brand}>
										{t('idol.new.images_click')}
									</Text>
								</Text>
								<Text fontSize="sm" fontWeight="500" color="secondaryGray.500" white-space="pre-wrap !important">
									{t('idol.new.images')}
								</Text>
							</Box>
						}
						onDropImage={onDropImages}
					/>
					<SimpleGrid columns={{ sm: 5 }} mt={'20px'} gap={'10px'}>
						{imgFiles.map((item, index) => {
							return <DropImageFile index={index} file={item} onDeleteFile={onDeleteImages} />;
						})}
					</SimpleGrid>
				</SimpleGrid>
			</Card>
		</FormControl>
	);
}
