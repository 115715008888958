// Chakra imports
import { Button, Flex, Link } from "@chakra-ui/react";

// Custom components
import { SidebarContext } from "contexts/SidebarContext";

// Assets

export default function AuthNavbar(props: {
	logo?: JSX.Element | string;
	logoText?: string;
	secondary?: boolean;
	sidebarWidth?: any;
}) {
	const { logoText, sidebarWidth } = props;
	let mainText = '#fff';
	let navbarBg = 'none';
	let navbarShadow = 'initial';
	let navbarPosition = 'absolute' as 'absolute';

	let brand = (
		<Link
			href={process.env.REACT_APP_DEFAULT_URI}
			display="flex"
			lineHeight="100%"
			fontWeight="bold"
			justifyContent="center"
			alignItems="center"
			color={mainText}
		>
			<Button bg={navbarBg} size="lg" variant="unstyled">
				{logoText}
			</Button>
		</Link>
	);

	return (
		<SidebarContext.Provider value={{ sidebarWidth }}>
			<Flex
				position={navbarPosition}
				top="16px"
				left="50%"
				transform="translate(-50%, 0px)"
				background={navbarBg}
				boxShadow={navbarShadow}
				borderRadius="15px"
				px="16px"
				py="22px"
				mx="auto"
				width="1044px"
				maxW="90%"
				alignItems="center"
				zIndex="3"
			>
				<Flex w="100%" justifyContent={{ sm: 'start', lg: 'space-between' }}>
					{brand}
				</Flex>
			</Flex>
		</SidebarContext.Provider>
	);
}
