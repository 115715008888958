// chakra imports

// Assets

import { Avatar, Flex, Icon, Image, Switch, Text } from "@chakra-ui/react";
import * as React from "react";
import { useThemeColors } from "../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import { DATA_FORMAT_FULL_DATE, dateFormat } from "../../helper/functions";
import { MdDeleteOutline } from "react-icons/md";
import { IconType } from "react-icons";

export function TableHeader(props: { title: string }) {
  const { t } = useTranslation();
  const { textColor } = useThemeColors();
  const { title } = props;

  return (
    <Text justifyContent="center" align="center" fontSize={{ sm: "10px", lg: "12px" }} color={textColor}>
      {title}
    </Text>
  );
}

export function TableBodyText(props: { title: string, onClick?: any }) {
  const { textColor } = useThemeColors();
  const { title, onClick } = props;

  return (
    <Flex align="center">
      <Text color={textColor} fontSize="md" fontWeight="500" onClick={onClick} maxWidth={"300px"}>
        {title}
      </Text>
    </Flex>
  );
}

export function TableBodyImage(props: { url: string, onClick?: any }) {
  const { url, onClick } = props;

  return (
    <Flex align="center">
      <Avatar src={url} h="60px" w="60px" me="10px" onClick={onClick} borderRadius={'5px'} />
    </Flex>
  );
}


export function TableBodyDateTime(props: { title: string, onClick?: any }) {
  const { textColor } = useThemeColors();
  const { title, onClick } = props;

  return (
    <Flex align="center">
      <Text color={textColor} fontSize="md" fontWeight="500" onClick={onClick}>
        {dateFormat(title, DATA_FORMAT_FULL_DATE)}
      </Text>
    </Flex>
  );
}

export function TableBodySwitch(props: { isChecked: boolean, onChange: () => void }) {
  const { isChecked, onChange } = props;

  return (
    <Flex direction="row" pl={"20px"}>
      <Switch
        isChecked={isChecked}
        colorScheme="brand"
        size="md"
        onChange={onChange}
      />
    </Flex>
  );
}


export function TableBodyButton(props: { icon: IconType, title?: string, onClick: () => void }) {
  const { icon, title, onClick } = props;

  return (
    <Flex direction="row" pl={"20px"}>
      {icon ? (<Icon
        color="secondaryGray.500"
        as={icon}
        w="20px"
        h="20px"
        ml={"-15px"}
        onClick={onClick}
      />) : title}
    </Flex>
  );
}

// export function TableBodyText(props: { title: string }) {
// 	const { t } = useTranslation();
// 	const { textColor } = useThemeColors();
// 	const { title } = props;
//
// 	return (
// 		<Flex align="center">
// 			<Text color={textColor} fontSize="md" fontWeight="500">
// 				{title}
// 			</Text>
// 		</Flex>
// 	);
// }
