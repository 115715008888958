// Chakra imports
import { Flex, FormLabel, Select } from '@chakra-ui/react';
import { useThemeColors } from '../../providers/theme-provider';
import { EventRewardType } from '../../types/enums';
import React from 'react';
import { useTranslation } from 'react-i18next';
// Custom components
export default function Default<T extends string>(props: {
	label?: string;
	placeholder?: string;
	value: T;
	options: T[];
	displayOptionsString?: (t: any, i: T) => string;
	onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	disabled?: boolean;
	[x: string]: any;
}) {
	const {
		label,
		placeholder,
		value,
		options,
		displayOptionsString,
		onChange,
		textWidth,
		reversed,
		fontSize,
		disabled,
		mb,
		mt,
		...rest
	} = props;

	const { textColorPrimary } = useThemeColors();
	const { t } = useTranslation();

	return (
		<Flex direction="column" mb={mb ? mb : '10px'} mt={mt ? mt : '10px'}>
			<FormLabel
				display="flex"
				ms="10px"
				fontSize="sm"
				color={textColorPrimary}
				fontWeight="bold"
				_hover={{ cursor: 'pointer' }}
			>
				{label}
			</FormLabel>

			<Select
				{...rest}
				fontWeight="500"
				variant="main"
				disabled={disabled}
				_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
				borderRadius="6px !important"
				h="44px"
				maxH="44px"
				placeholder={placeholder}
				value={value}
				onChange={onChange}
			>
				{options?.map((option) => (
					<option key={option} value={option}>
						{displayOptionsString ? displayOptionsString(t, option): option}
					</option>
				))}
			</Select>
		</Flex>
	);
}
