// Chakra imports
import { Input, InputGroup, InputLeftElement, InputRightElement, Stack, Text } from '@chakra-ui/react';
import { IoAddCircleOutline } from 'react-icons/io5';
import { TiDeleteOutline } from 'react-icons/ti';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useThemeColors } from '../../providers/theme-provider';
// Custom components

export default function Default(props: {
	label?: string;
	placeholder?: string;
	values: string[];
	originalValues: string[];
	setValues: (values: string[]) => void;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
	const { t } = useTranslation();
	const { originalValues, label, placeholder, values, setValues, onChange } = props;
	const { textColorPrimary } = useThemeColors();

	const [inputFields, setInputFields] = useState([{ id: Date.now(), value: '' }]);

	useEffect(() => {
		if (originalValues && originalValues.length > 0) {
			const orgArray: any[] = [];
			originalValues.map((t, index) => {
				const newField = { id: Date.now() + index, value: t };
				orgArray.push(newField);
			})
			setInputFields(orgArray);
		}

	}, [originalValues]);

	const addInputField = () => {
		const newField = { id: Date.now(), value: '' };
		let newFields = [...inputFields, newField];
		setInputFields(newFields);
		setValues(convertStringArray(newFields));
	};

	const convertStringArray = (fields: any) => {
		let newArray = fields.map((i: any) => {
			return i.value;
		});
		return newArray;
	};

	const removeInputField = (id: number) => {
		const updatedFields = inputFields.filter(field => field.id !== id);
		if (updatedFields.length === 0) {
			setInputFields( [{ id: Date.now(), value: '' }]);
		} else {
			setInputFields( updatedFields);
		}

		setValues(convertStringArray(updatedFields));
	};

	const handleInputChange = (id: number, newValue: string) => {
		const updatedFields = inputFields.map(field => (field.id === id ? { ...field, value: newValue } : field));
		setInputFields(updatedFields);
		setValues(convertStringArray(updatedFields));
	};

	return (
		<Stack direction="column" gap="10px" mb={'20px'}>
			<Text ms="10px" fontSize="sm" color={textColorPrimary} fontWeight="bold" _hover={{ cursor: 'pointer' }}>
				{label ? t(label) : ''}
			</Text>
			{inputFields.map(field => (
				<InputGroup key={field.id}>
					<InputLeftElement>
						<IoAddCircleOutline onClick={addInputField} />
					</InputLeftElement>
					<Input
						placeholder={placeholder ? t(placeholder) : ''}
						value={field.value}
						onChange={e => handleInputChange(field.id, e.target.value)}
					/>
					<InputRightElement>
						<TiDeleteOutline
							onClick={() => {
								removeInputField(field.id);
							}}
						/>
					</InputRightElement>
				</InputGroup>
			))}
		</Stack>
	);
}
