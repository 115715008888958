// Chakra imports
import {
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay
} from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Card from 'components/card/Card';

import { useTranslation } from 'react-i18next';
import { createDynamicColumns, VisibleColumns } from 'components/table/createDynamicColumns';

import Detail from './detail';
import { useApi } from '../../../providers/api-provider';
import { useThemeColors } from '../../../providers/theme-provider';
import { PayoutStatusTypeString } from '../../../helper/types';
import SearchTable from '../../../components/table/SearchTable';
import { IdolRedeemHistoryListResponse, RedeemHistoryTrain } from '../../../apis/memberAPI';
import { PayoutStatusType, TableRowType } from '../../../types/enums';
import { TableBodyText } from '../../../components/table/TableComponents';
import { numberFormat } from '../../../helper/functions';
import SelectField from '../../../components/fields/SelectField';
import IdolRequestPayoutDetail from "./detail";

// 금지어 목록
export default function IdolRequestPayoutList() {
	const { memberApi } = useApi();

	const { t } = useTranslation();
	const { textColor, buttonBg, hoverButton, activeButton } = useThemeColors();

	// 목록
	const [list, setList] = useState<RedeemHistoryTrain[]>([]);
	const [searchType, setSearchType] = useState<PayoutStatusType>(PayoutStatusType.ALL)

	// 전체 갯수
	const [totalCount, setTotalCount] = useState<number>(0);
	// 한번에 불러올 목록
	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);

	// 상세 팝업
	const [selectDetail, setDetail] = useState<RedeemHistoryTrain>(null);
	const [isShowModal, setIsShowModal] = useState<boolean>(false);

	useEffect(() => {
		requestList(offset);
	}, []);

	useEffect(() => {
		requestList(0);
	}, [searchType]);

	// 목록 요청
	const requestList = async (offset: number) => {
		setOffset(offset);
		memberApi
			.getIdolRedeemHistory({
				limit: limit,
				offset: offset * limit,
				status: searchType === PayoutStatusType.ALL ? '' : searchType
			})
			.then(responseList)
			.catch(() => {});
	};

	// 목록 처리
	const responseList = (response: IdolRedeemHistoryListResponse) => {
		if (response.redeemHistoryList) {
			setList(response.redeemHistoryList);
		}

		if (response.count) {
			setTotalCount(response.count);
		}
	};

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<RedeemHistoryTrain>[] = [
		{
			row: 'redeemHistoryId',
			title: 'id',
			type: TableRowType.TEXT
		},
		{
			row: 'memberActiveName',
			title: 'activityName',
			type: TableRowType.TEXT
		},
		{
			row: 'memberId',
			title: 'memberId',
			type: TableRowType.TEXT
		},
		{
			row: 'status',
			title: 'status',
			type: TableRowType.ENUM,
			enumToText: PayoutStatusTypeString
		},
		{
			row: 'redeemValue',
			title: 'redeemValue',
			type: TableRowType.ACTION,
			extraComponent: (value: any, rowData: any) => {
				return (
					<TableBodyText
						title={String(numberFormat(value))}
						onClick={() => {
							handleRowClick(rowData);
						}}
					/>
				);
			}
		},
		{
			row: 'createDateTime',
			title: 'createDateTime',
			type: TableRowType.DATETIME
		},
		{
			row: 'confirmAdminName',
			title: 'confirmAdminName',
			type: TableRowType.ACTION,
			extraComponent: (value: any, rowData: any) => {
				return (
					<TableBodyText
						title={value ? value : '-'}
						onClick={() => {
							handleRowClick(rowData);
						}}
					/>
				);
			}
		}
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)
		// window.location.href = '';
		setDetail(rowData);
		setIsShowModal(true);
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	// 정렬 필터 변경
	const filterChange = (type: string) => {};

	// 검색 엔터키 처리
	const handleSearchKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleClickSearch();
		}
	};

	const handleClickSearch = () => {};

	// 검색어 저장
	const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {};

	const handleClickAdd = () => {
		setIsShowModal(true);
		setDetail(null);

		//window.location.href = '';
	};

	// Chakra Color Mode
	return (
		<Flex direction="column" pt={{ sm: '75px', lg: '75px' }}>
			<Card px="0px">
				<Flex w="100%" direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'} mb="36px">
					<Flex justifyContent={'flex-end'} ml={{ base: '20px', sm: '20px' }}>
					<SelectField
						label={t('common.status')}
						value={searchType}
						options={Object.values(PayoutStatusType)}
						onChange={e => {
							setSearchType(e.target.value as PayoutStatusType);
						}}
						displayOptionsString={PayoutStatusTypeString}
					/>
					</Flex>
				</Flex>
				<SearchTable request={requestList} data={list} columns={columns} totalCount={totalCount} />
			</Card>

			<Modal
				isOpen={isShowModal}
				onClose={() => {
					setIsShowModal(false);
				}}
				isCentered
				size={'xg'}
			>
				<ModalOverlay />

				<ModalContent>
					<ModalHeader>{''}</ModalHeader>
					<ModalCloseButton />
					<ModalBody overflow="auto">
						<IdolRequestPayoutDetail
							detail={selectDetail}
							requestChangeParams={() => {
								requestList(offset);
								setIsShowModal(false);
							}}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	);
}
