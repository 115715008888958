// Chakra imports
import { Box, Button, Flex, Grid, SimpleGrid } from "@chakra-ui/react";

// Custom components
// Assets
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useApi } from '../../../../providers/api-provider';
import InputField from 'components/fields/InputField';
import { showToast } from '../../../../helper/functions';
import { iOSAppVersionResponse } from '../../../../apis/metaDataAPI';

interface ExtractedImage {
	fileName: string;
	imageUrl: string;
}

// Live 콘텐츠 목록
export default function AppVersion() {
	const { t } = useTranslation();

	const { metaDataApi } = useApi();

	const [newAppVersion, setNewAppVersion] = useState<string>('');
	const [reviewAppVersion, setReviewAppVersion] = useState<string>('');
	const [minAppVersion, setMinAppVersion] = useState<string>('');

	const versionRegex = /^\d+\.\d+\.\d+$/;

	useEffect(() => {
		getAppVersion();
	}, []);

	const getAppVersion = () => {
		metaDataApi
			.getiOSAppVersion()
			.then(responseAppVersion)
			.catch(() => {});
	};

	const responseAppVersion = (response: iOSAppVersionResponse) => {
		if (response) {
			setNewAppVersion(response.iosVersionConfig.newVersion);
			setReviewAppVersion(response.iosVersionConfig.reviewVersion);
			setMinAppVersion(response.iosVersionConfig.minVersion);
		}
	};

	const requestAppVersion = () => {
		if (!versionRegex.test(minAppVersion) || !versionRegex.test(reviewAppVersion) || !versionRegex.test(newAppVersion)) {
			showToast(t('error.invalid_number_format'));
			return;
		}


		metaDataApi
			.putiOSAppVersion(newAppVersion, reviewAppVersion, minAppVersion)
			.then(responseAppVersion)
			.catch(() => {});
	}



	// Chakra Color Mode
	return (
		<Box
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '130px', md: '80px', xl: '80px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<SimpleGrid mb="20px" columns={{ sm: 1, lg: 2 }} spacing={{ base: '20px', xl: '20px' }}>
				{/* Column Left */}

				<Flex direction="column">
					<SimpleGrid>
						<InputField
							id="mobile"
							label={t('settings.new_app_version')}
							placeholder={t('settings.new_app_version')}
							value={newAppVersion}
							onChange={e => {
								setNewAppVersion(e.target.value);
							}}
						/>
						<InputField
							id="mobile"
							label={t('settings.review_app_version')}
							placeholder={t('settings.review_app_version')}
							value={reviewAppVersion}
							onChange={e => {
								setReviewAppVersion(e.target.value);
							}}
						/>
						<InputField
							id="mobile"
							label={t('settings.min_app_version')}
							placeholder={t('settings.min_app_version')}
							value={minAppVersion}
							onChange={e => {
								setMinAppVersion(e.target.value);
							}}
						/>
					</SimpleGrid>

					<Button
						variant="brand"
						minW="183px"
						fontSize="sm"
						fontWeight="500"
						ms="auto"
						onClick={() => {
							// registerNotice();
							requestAppVersion();
						}}
					>
						{t('common.save')}
					</Button>
				</Flex>
			</SimpleGrid>
		</Box>
	);
}
