// Chakra imports
import {
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SimpleGrid
} from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Card from 'components/card/Card';
import { useApi } from '../../../../providers/api-provider';
import { ReportContentsType, ReportStatusType, TableRowType } from '../../../../types/enums';
import { useTranslation } from 'react-i18next';
import { useThemeColors } from '../../../../providers/theme-provider';
import SearchTable from '../../../../components/table/SearchTable';
import { ReportContentTypeString, ReportStatusString } from '../../../../helper/types';
import { createDynamicColumns, VisibleColumns } from 'components/table/createDynamicColumns';
import { ReportListRequest, ReportListResponse, ReportTrain } from '../../../../apis/reportAPI';
import { useLocation } from 'react-router-dom';
import ReportDetail from './detail';
import SelectField from '../../../../components/fields/SelectField';

// 금지어 목록
export default function MemberReport() {
	const { reportApi } = useApi();

	const { t } = useTranslation();
	const { textColor, buttonBg, hoverButton, activeButton } = useThemeColors();

	// 목록
	const [list, setList] = useState<ReportTrain[]>([]);

	// 전체 갯수
	const [totalCount, setTotalCount] = useState<number>(0);
	// 한번에 불러올 목록
	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);

	// 검색 조건
	const [searchParams, setSearchParams] = useState<Partial<ReportListRequest>>({});
	const [searchStatus, setSearchStatus] = useState<ReportStatusType>(null);

	const location = useLocation();

	// 경로가 바뀔 때마다 호출되도록 useEffect 설정
	useEffect(() => {
		switch (location.pathname) {
			case '/admin/report/idol_profile':
				setSearchParams({
					searchType: ReportContentsType.PROFILE_IDOL
				});
				break;
			case '/admin/report/producer_profile':
				setSearchParams({
					searchType: ReportContentsType.PROFILE_PRODUCER
				});
				break;
			case '/admin/report/live':
				setSearchParams({
					searchType: ReportContentsType.LIVE_STREAMING
				});
				break;
			case '/admin/report/lchat':
				setSearchParams({
					searchType: ReportContentsType.LIVE_CHATTING
				});
				break;
			case '/admin/report/vod':
				setSearchParams({
					searchType: ReportContentsType.VOD
				});
				break;
			case '/admin/report/vcomment':
				setSearchParams({
					searchType: ReportContentsType.VOD_COMMENT
				});
				break;
			case '/admin/report/review':
				setSearchParams({
					searchType: ReportContentsType.ALL,
					reviewRequested: true
				});
				break;
			default:
				setSearchParams({
					searchType: ReportContentsType.PROFILE_IDOL
				});
		}
		setOffset(0);
	}, [location.pathname]); // 경로가 바뀔 때마다 실행됨

	// 모달 창
	const [isShowModal, setIsShowModal] = useState(false);
	const [selectDetail, setSelectDetail] = useState<ReportTrain>(null);

	useEffect(() => {
		if (searchParams && searchParams.searchType) {
			requestReportList(offset);
		}
	}, [searchParams]);

	// 목록 요청
	const requestReportList = async (offset: number) => {
		setOffset(offset);
		reportApi
			.getReportNeedConfirm({
				limit: limit,
				offset: offset * limit,
				...searchParams
			})
			.then(responseReportList)
			.catch(() => {});
	};

	// 목록 처리
	const responseReportList = (response: ReportListResponse) => {
		if (response.masterReportDetail) {
			setList(response.masterReportDetail);
		}

		if (response.count) {
			setTotalCount(response.count);
		}
	};

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<ReportTrain>[] = [
		{
			row: 'reportId',
			title: 'id',
			type: TableRowType.TEXT
		},
		{ row: 'contentsType', title: 'reportContentType', type: TableRowType.ENUM, enumToText: ReportContentTypeString },
		{ row: 'contentsId', type: TableRowType.TEXT },
		{ row: 'contentsMemberType', type: TableRowType.TEXT },
		{ row: 'contentsMemberId', type: TableRowType.TEXT },
		{ row: 'memberId', title: 'reportMemberId', type: TableRowType.TEXT },
		{ row: 'status', title: 'reportStatus', type: TableRowType.ENUM, enumToText: ReportStatusString },
		{ row: 'masterReportId', type: TableRowType.TEXT },
		{ row: 'confirmAdminName', type: TableRowType.TEXT },
		{ row: 'confirmDateTime', type: TableRowType.DATETIME },
		{ row: 'warningDays', type: TableRowType.DATETIME },
		{ row: 'penaltyStartDateTime', type: TableRowType.DATETIME },
		{ row: 'penaltyEndDateTime', type: TableRowType.DATETIME }
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)
		setIsShowModal(true);
		setSelectDetail(rowData);
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	// 정렬 필터 변경
	const filterChange = (type: string) => {
		// setSearchParams(prevState => {
		// 	return {
		// 		...prevState,
		// 		sortCondition: type === TYPE_KEY_SORT.LATEST ? SortCondition.ASC : SortCondition.DESC
		// 	};
		// });
	};

	// 검색 엔터키 처리
	const handleSearchKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleClickSearch();
		}
	};

	const handleClickSearch = () => {
		// if (searchString.trim().length < 2 && searchString.trim() !== '') {
		// 	showToast(t('common.alert_search_word_length'), 'error');
		// 	return;
		// }
		//
		// setSearchParams(prevState => {
		// 	return {
		// 		...prevState,
		// 		keyword: searchString
		// 	};
		// });
	};

	// 검색어 저장
	const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// setSearchString(e.target.value);
	};

	const handleClickAdd = () => {};

	// Chakra Color Mode
	return (
		<Flex direction="column" pt={{ sm: '75px', lg: '75px' }}>
			<Card px="0px">
				<Flex w="100%" direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'} mb="36px">
					<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px" height={'max-content'} px={'20px'}>
						<SelectField
							label={t('reports.report_status')}
							value={searchStatus}
							options={Object.keys(ReportStatusType)}
							onChange={e => {
								setOffset(0);
								setSearchParams(prevState => {
									return {
										...prevState,
										status: e.target.value === ReportStatusType.NONE ? null : e.target.value
									};
								});
							}}
							displayOptionsString={ReportStatusString}
						/>
					</SimpleGrid>
					<Flex justifyContent={'flex-end'} mr={{ base: '0px', sm: '10px' }}></Flex>
				</Flex>
				<SearchTable request={requestReportList} data={list} columns={columns} totalCount={totalCount} />
			</Card>

			<Modal
				isOpen={isShowModal}
				onClose={() => {
					setIsShowModal(false);
				}}
				isCentered
				size={'xl'}
			>
				<ModalOverlay />

				<ModalContent>
					<ModalHeader>{t('reports.detail')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody overflow="auto">
						<ReportDetail
							reportDetail={selectDetail}
							requestChangeParams={() => {
								requestReportList(offset);
								setIsShowModal(false);
							}}
						/>
					</ModalBody>
					<ModalFooter>
						<Button
							onClick={() => {
								setIsShowModal(false);
							}}
						>
							{t('common.close')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	);
}
