import { BaseApi } from "./baseAPI";
import {
	URL_GET_EVENT_DETAIL,
	URL_GET_EVENT_LIST,
	URL_GET_NOTICE_LIST,
	URL_POST_EVENT,
	URL_POST_NOTICE,
	URL_PUT_NOTICE_DETAIL
} from "./URLS";
import { EventRewardPayType, EventRewardType, MemberType, NoticePlacementType, NoticeType } from "../types/enums";
import { PagingRequest } from "./commonAPI";

/**
 * Common Controller
 */
export class NoticeApi extends BaseApi {
	getNotcieList = (request: NoticeListRequest) => {
		return this.axios
			.get<NoticeListResponse>(URL_GET_NOTICE_LIST, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	getNoticeDetail = (noticeId: string) => {
		return this.axios
			.get<NoticeDetailResponse>(URL_PUT_NOTICE_DETAIL.replace('{noticeId}', noticeId))
			.then(response => response)
			.catch(error => error.response);
	};

	putNoticeDetail = (noticeId: string, request: FormData) => {
		return this.axios
			.put(URL_PUT_NOTICE_DETAIL.replace('{noticeId}', noticeId), request, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(response => response)
			.catch(error => error.response);
	};

	postNoticeDetail = (request: FormData) => {
		return this.axios
			.post(URL_POST_NOTICE, request, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(response => response)
			.catch(error => error.response);
	};

	getEventList = (request: EventListRequest) => {
		return this.axios
			.get<NoticeListResponse>(URL_GET_EVENT_LIST, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	getEventDetail = (eventId: string) => {
		return this.axios
			.get<EventDetailResponse>(URL_GET_EVENT_DETAIL.replace('{eventId}', eventId))
			.then(response => response)
			.catch(error => error.response);
	};

	postEventDetail = (request: FormData) => {
		return this.axios
			.post(URL_POST_EVENT, request, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(response => response)
			.catch(error => error.response);
	};

	putEventDetail = (eventId: string, request: FormData) => {
		return this.axios
			.put(URL_GET_EVENT_DETAIL.replace('{eventId}', eventId), request, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(response => response)
			.catch(error => error.response);
	};
}

/**
 *
 *
 * 리퀘스트들
 *
 *
 */
export type NoticeListRequest = PagingRequest & {
	/**
	 * 이벤트 종류
	 *  Available values : SYSTEM, ADVERTISEMENT
	 */
	noticeType?: NoticeType;

	/**
	 * 시작 날짜
	 *  searchStartDt
	 */
	searchStartDt?: string;

	/**
	 * 종료 날짜
	 *  searchEndDt
	 */
	searchEndDt?: string;

	/**
	 * 공개 여부
	 */
	isActive?: boolean;

	/**
	 * 검색어
	 *  word
	 */
	word?: string;
};

export type EventListRequest = PagingRequest & {

	/**
	 * 시작 날짜
	 *  searchStartDt
	 */
	searchStartDateTime?: string;

	/**
	 * 종료 날짜
	 *  searchEndDt
	 */
	searchEndDateTime?: string;

	/**
	 * 공개 여부
	 */
	isActive?: boolean;

	/**
	 * 검색어
	 *  word
	 */
	word?: string;
};

/**
 *
 *
 * 리스폰스들
 *
 *
 */
export type NoticeListResponse = {
	noticeTrain: NoticeTrain[];
	count: number;
};

export type NoticeDetailResponse = {
	noticeDetail: NoticeDetailTrain;
}

export type EventListResponse = {
	eventTrain: EventListTrain[];
	count: number;
}


export type EventDetailResponse = {
	eventDetail: EventDetailTrain;
}

export type NoticeTrain = {
	/**
	 * 공지 ID
	 */
	noticeId: number;

	/**
	 * 사용자 유형
	 */
	targetMemberTypes: MemberType[];

	/**
	 * 공개 여부
	 */
	isActive: boolean;

	/**
	 * 시작 일시
	 */
	startDateTime: string;

	/**
	 * 종료 일시
	 */
	endDateTime: string;

	/**
	 * 생성 일시
	 */
	createDateTime: string;

	/**
	 * 제목
	 */
	title: BilingualContentDto;

	/**
	 *
	 */
	noticeType?: NoticeType;
};

export type NoticeDetailTrain = {
	/**
	 * 공지 ID
	 */
	noticeId: number;

	/**
	 * 사용자 유형
	 */
	targetMemberTypes: MemberType[];

	/**
	 * 공개 여부
	 */
	isActive: boolean;

	/**
	 * 시작 일시
	 */
	startDateTime: string;

	/**
	 * 종료 일시
	 */
	endDateTime: string;

	/**
	 * 생성 일시
	 */
	createDateTime: string;

	/**
	 * 제목
	 */
	title: BilingualContentDto;

	/**
	 * 배너 구좌
	 * Enum:
	 *  [ HOME, LOOK_AROUND ]
	 */
	bannerPlacementTypes: NoticePlacementType[];

	/**
	 * 공지 종류
	 */
	noticeType: string;

	/**
	 * 앱 내 화면 url
	 */
	internalUrl: string;

	/**
	 *    메인 텍스트
	 */
	mainText: BilingualContentDto;

	/**
	 * 썸네일 이미지 URL
	 */
	thumbnailImgUrl: BilingualContentDto;

	/**
	 * 설명
	 */
	description: BilingualContentDto;
};

export type EventListTrain = {
	/**
	 * 이벤트 ID
	 */
	eventId: number;

	/**
	 * description:
	 */
	title: BilingualContentDto;

	/**
	 *  사용자 유형
	 */
	targetMemberTypes: MemberType[];

	/**
	 * 시작 일시
	 */
	startDateTime: string;

	/**
	 * 종료 일시
	 */
	endDateTime: string;

	/**
	 * 노출 여부
	 */
	isActive: boolean;

	/**
	 * 생성 일시
	 */
	createDateTime: string;
};

export type EventDetailTrain = {
	/**
	 * 이벤트 아이디
	 */
	eventId: number;
	title: BilingualContentDto;
	targetMemberTypes: MemberType[];
	startDateTime: string;
	endDateTime: string;
	isActive: boolean;
	createDateTime: string;
	bannerPlacementTypes: NoticePlacementType[];
	eventRewardType: EventRewardType;
	eventRewardItemId: number;
	eventRewardItemExpireDateTime: string;
	eventRewardPayType: EventRewardPayType;
	eventRewardPayMaxSequence: number;
	eventRewardTotal: number;
	eventRewardBalance: number;
	eventRewardUnit: number;
	eventRewardButtonName: BilingualContentDto;
	cautions: BilingualContentDto[];
	detailImgUrls: BilingualContentDto[];
	detailMovieUrls: string[];
	thumbnailImgUrl: BilingualContentDto;
	mainText: BilingualContentDto;
	shareImgUrl: BilingualContentDto;
};

export type BilingualContentDto = {
	/**
	 * 일문 컨텐츠
	 */
	contentJa: string;

	/**
	 * 국문 컨텐츠
	 */
	contentKo: string;

	/**
	 * 영문 컨텐츠
	 */
	contentEn: string;
};
