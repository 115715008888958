import { Button, Flex, Text, useColorMode, useColorModeValue } from "@chakra-ui/react";
import { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { TYPE_KEY_SORT } from "../../helper/types";

// Custom components
const reducer = (state: any, action: any) => {
	if (action.type === 'SWITCH_ACTIVE') {
		if (action.payload === TYPE_KEY_SORT.LATEST) {
			const newState = {
				latest: true,
				popular: false
			};
			return newState;
		} else {
			const newState = {
				latest: false,
				popular: true
			};
			return newState;
		}
	}
	return state;
};

export default function Filter(props: { initialSortType?: string; onChange?: any, secondaryTitle?: string }) {
	const { initialSortType, secondaryTitle } = props;
	const [state, dispatch] = useReducer(reducer, {
		latest: initialSortType === TYPE_KEY_SORT.LATEST,
		popular: initialSortType === TYPE_KEY_SORT.POPULAR,
		projects: false
	});

	const { onChange } = props;
	const { t } = useTranslation();

	const { colorMode } = useColorMode();

	// Chakra color mode
	const textColor = useColorModeValue('gray.700', 'white');
	return (
		<Flex direction={{ sm: 'row', lg: 'row' }}>
			<Button p="0px" bg="transparent" variant="no-effects">
				<Flex
					align="center"
					w={{ base: '80px', lg: '105px' }}
					borderRadius="30px"
					justifyContent="center"
					py="10px"
					border="1px solid"
					borderColor={state.latest ? (colorMode === 'dark' ? 'transparent' : 'secondaryGray.400') : 'transparent'}
					bg={state.latest ? (colorMode === 'dark' ? 'navy.800' : '#fff') : null}
					cursor="pointer"
					transition="all .22s ease"
					onClick={() => {
						dispatch({ type: 'SWITCH_ACTIVE', payload: TYPE_KEY_SORT.LATEST });
						onChange && onChange(TYPE_KEY_SORT.LATEST);
					}}
				>
					<Text fontSize="sm" color={textColor} fontWeight="bold">
						{t('components.filter_latest')}
					</Text>
				</Flex>
			</Button>
			<Button p="0px" bg="transparent" variant="no-effects">
				<Flex
					align="center"
					w={{ base: '80px', lg: '135px' }}
					borderRadius="30px"
					justifyContent="center"
					py="10px"
					mx={{ lg: '1rem' }}
					cursor="pointer"
					border="1px solid"
					borderColor={state.popular ? (colorMode === 'dark' ? 'transparent' : 'secondaryGray.400') : 'transparent'}
					bg={state.popular ? (colorMode === 'dark' ? 'navy.800' : '#fff') : null}
					transition="all .22s ease"
					onClick={() => {
						dispatch({ type: 'SWITCH_ACTIVE', payload: TYPE_KEY_SORT.POPULAR });
						onChange && onChange(TYPE_KEY_SORT.POPULAR);
					}}
				>
					<Text fontSize="sm" color={textColor} fontWeight="bold">
						{secondaryTitle ? t(secondaryTitle): t('components.filter_popular')}
					</Text>
				</Flex>
			</Button>
		</Flex>
	);
}
