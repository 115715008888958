// Chakra imports
import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import TagsField, { ROW_TAGS_OBJ } from './TagFields';

// Assets

export default function ModalAddBanWord(props: { isOpen: boolean; requestSaveBanWords: (tags: ROW_TAGS_OBJ[]) => void; onClose: () => void }) {
	// Chakra Color Mode

	const { t } = useTranslation();
	const { isOpen, requestSaveBanWords, onClose } = props;

	const [tags, setTags] = useState<ROW_TAGS_OBJ[]>([]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{t('content.ban_words.alert_add_title')}</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<TagsField
						id="description"
						label={t('content.ban_words.alert_add_desc')}
						mb="0px"
						h="140px"
						tags={tags}
						setTags={setTags}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="brand"
						mr={3}
						onClick={() => {
							requestSaveBanWords(tags);
							setTags([]);
						}}
					>
						{t('common.save')}
					</Button>
					<Button onClick={onClose}>{t('common.cancel')}</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
