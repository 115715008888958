import { createStandaloneToast } from '@chakra-ui/react';
import { AlertStatus } from '@chakra-ui/alert';
import moment from 'moment';
// uuid v4 가져오기
import { v4 as uuidv4 } from 'uuid';

const { toast } = createStandaloneToast();

export function pickFile() {
	return new Promise<File>((resolve, reject) => {
		const input = document.createElement('input');
		input.type = 'file';
		input.accept = 'image/*';
		input.onchange = function (event) {
			const file = (event.target as HTMLInputElement)?.files?.[0];
			if (file != null) {
				document.body.removeChild(input);
				resolve(file);
			}
		};
		input.click();
		input.style.position = 'absolute';
		input.style.top = '-9999px';
		document.body.appendChild(input);
	});
}

export function fileToDataUrl(file: File) {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.addEventListener(
			'load',
			function () {
				if (typeof reader.result === 'string') {
					resolve(reader.result);
				}
			},
			false
		);
		reader.readAsDataURL(file);
	});
}

export function getVideoCdnDirectory(fileName: string) {
	const parts = fileName.split(/_|\./);

	return `${parts[0]}/${parts[1]}/${parts[2]}/${parts[3].substring(0, 6)}/${parts[4]}`;
}

// export function getVideoCdnSrc(fileName: string) {
//     const extname = path.extname(fileName);
//
//     const subDirs: Record<string, string> = {
//         ".mp4": "original",
//         ".mp3": "audio/original",
//     };
//
//     const subDir = subDirs[extname] ?? "encoding";
//
//     const dir = getVideoCdnDirectory(fileName);
//
//     return `${config.CDN_URL}/${dir}/${subDir}/${fileName}`;
// }
//
// export function getVideoOriginalThumbnailSrc(fileName: string) {
//     const dir = getVideoCdnDirectory(fileName);
//
//     return `${config.CDN_URL}/${dir}/coverImg/${
//         fileName.split(".")[0]
//     }_original.png`;
// }

// export function getMediaMimeType(fileName: string) {
//     const extname = path.extname(fileName);
//
//     const types: Record<string, string> = {
//         ".mp4": "video/mp4",
//         ".mp3": "audio/mp3",
//     };
//
//     return types[extname] ?? "application/x-mpegURL";
// }
//
// export function getVideoSource(fileName: string) {
//     const fullSrc = getVideoCdnSrc(fileName);
//
//     const type = getMediaMimeType(fileName);
//
//     return {
//         src: fullSrc,
//         type: type,
//     };
// }

export const DATA_FORMAT_FULL_DATE = 'YYYY-MM-DD HH:mm:ss';
export const DATA_FORMAT_SERVER_FULL_DATE = 'YYYY-MM-DDTHH:mm:ss';
export const DATA_FORMAT_SHORT_DATE = 'YYYY-MM-DD';

export function dateFormat(date: any, format?: string, isUtc?: boolean) {
	if (date === null || date === '') {
		return '-';
	}

	if (format && format.length > 0) {
		if (isUtc) {
			return moment(date).utc().format(format);
		} else {
			return moment(date).format(format);
		}
	} else {
		if (isUtc) {
			return moment(date).utc().format(DATA_FORMAT_SHORT_DATE);
		} else {
			return moment(date).format(DATA_FORMAT_SHORT_DATE);
		}
	}
}

export function showToast(title: string, status?: AlertStatus, isClosable?: boolean) {
	toast({
		title: title,
		duration: 3000,
		isClosable: isClosable ? isClosable : false,
		status: status || 'success'
	});
}

export function copyLink(url: string) {
	const el = document.createElement('input');
	el.value = url;
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
	showToast('카피 링크');
}

export function copyTag(data: any) {
	let tags = [] as any;
	data.map((tag: any) => tags.push(tag.tag));

	const copyText = tags.join(' ');
	debugLog(copyText);
	const el = document.createElement('input');
	el.value = copyText;
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
	showToast('링크가 복사되었습니다.');
}

export function stringMaxLength(string: string, maxLegnth: number) {
	const maxString = string.substring(0, maxLegnth - 1);
	if (string.length > maxLegnth) {
		return maxString + '...';
	} else {
		return maxString;
	}
}

export function intParser(num: number) {
	if (num < 1000) {
		return num;
	}
	let si = [
		{ v: 1e3, s: 'K' },
		{ v: 1e6, s: 'M' },
		{ v: 1e9, s: 'B' },
		{ v: 1e12, s: 'T' },
		{ v: 1e15, s: 'P' },
		{ v: 1e18, s: 'E' }
	];
	let index;
	for (index = si.length - 1; index > 0; index--) {
		if (num >= si[index].v) {
			break;
		}
	}
	return (num / si[index].v).toFixed(1).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
}

export function checkFileExtension(url: string) {
	if (!url || url.length === 0) {
		return '';
	}
	return url.split('.').pop();
}

export function getFileName(url: string) {
	if (!url || url.length === 0) {
		return '';
	}
	return url.split('/').pop();
}

export function phoneFomatter(number: string, isHideZero?: boolean) {
	var formatNum = '';

	if (number === null) {
		return '';
	}

	if (!number.startsWith('0')) {
		number = '0' + number;
	}

	if (number.length === 11) {
		if (isHideZero) {
			formatNum = number.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
		} else {
			formatNum = number.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
		}
	} else if (number.length === 8) {
		formatNum = number.replace(/(\d{4})(\d{4})/, '$1-$2');
	} else {
		if (number.indexOf('02') === 0) {
			if (isHideZero) {
				formatNum = number.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
			} else {
				formatNum = number.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
			}
		} else {
			if (isHideZero) {
				formatNum = number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
			} else {
				formatNum = number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
			}
		}
	}

	return formatNum;
}

export function formatMyNumber(myNumber: string) {
	// 숫자가 아니거나 길이가 12가 아닌 경우 에러 반환
	if (typeof myNumber !== 'string' || !/^\d{12}$/.test(myNumber)) {
		return myNumber;
	}

	// 4자리씩 나누어 하이픈(-)으로 연결
	return `${myNumber.slice(0, 4)}-${myNumber.slice(4, 8)}-${myNumber.slice(8, 12)}`;
}

export function formatJapaneseBankAccount(accountNumber: string) {
	// 숫자가 아니거나 길이가 14가 아닌 경우 에러 반환
	if (typeof accountNumber !== 'string' || !/^\d{14}$/.test(accountNumber)) {
		return accountNumber;
	}

	// 은행 코드(4자리), 지점 코드(3자리), 계좌번호(7자리)로 나누어 포맷팅
	const bankCode = accountNumber.slice(0, 4);
	const branchCode = accountNumber.slice(4, 7);
	const account = accountNumber.slice(7);

	return `${bankCode}-${branchCode}-${account}`;
}

export function randomId(length: number) {
	var result = '';
	var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export const UNIT_YEN = '¥';
export const UNIT_HG = 'HG';

export const UNIT_PERCENT = '%';

export function numberFormat(num: string | number, unit = '') {
	try {
		const n = Number(num);
		if (isNaN(n)) {
			return addNumberUnit('0', unit);
		}

		return addNumberUnit(n.toLocaleString(), unit);
	} catch {
		return addNumberUnit('0', unit);
	}
}

export function addNumberUnit(num: string, unit = '') {
	if (unit === UNIT_YEN) {
		return UNIT_YEN + ' ' + num;
	} else if (unit === UNIT_HG) {
		return num + ' ' + UNIT_HG;
	} else if (unit === UNIT_PERCENT) {
		return num + UNIT_PERCENT;
	} else {
		return num;
	}
}

export const chunkArray = <T extends any = any>(array: T[], size = 2) => {
	const newArray = [...array];
	var results = [];

	while (newArray.length) {
		results.push(newArray.splice(0, size));
	}

	return results;
};

export const emojiCounter = (value: string) => {
	const emojiDetector =
		/\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]/g;
	const emojiCount = value.match(emojiDetector)?.length ?? 0;
	return emojiCount;
};

export function debugLog(...args: any) {
	if (process.env.REACT_APP_IS_DEBUG_MODE === 'true') {
		console.log(...args);
	}
}

export function getDeviceType(userAgent: string) {
	return userAgent.match(/iPhone/i)
		? 'iPhone'
		: userAgent.match(/Windows/i)
		  ? 'Desktop Windows'
		  : userAgent.match(/Android/i)
		    ? 'Android'
		    : userAgent.match(/Macintosh/i) && window.navigator.maxTouchPoints === 0
		      ? 'Desktop Macintosh'
		      : userAgent.match(/Macintosh/i) && window.navigator.maxTouchPoints === 5
		        ? 'iPad'
		        : undefined;
}

// Generic function to convert any object into FormData
export function objectToFormData<T>(inputObject: T, formData: FormData = new FormData(), parentKey?: string): FormData {
	Object.keys(inputObject).forEach(key => {
		const value = inputObject[key as keyof T];
		const formKey = parentKey ? `${parentKey}[${key}]` : key;

		if (value instanceof Date) {
			formData.append(formKey, value.toISOString());
		} else if (typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean') {
			formData.append(formKey, value.toString());
		} else if (Array.isArray(value)) {
			value.forEach((arrayItem, index) => {
				if (typeof arrayItem === 'object' && arrayItem !== null) {
					// Recursively handle arrays of objects
					objectToFormData(arrayItem, formData, `${formKey}[${index}]`);
				} else {
					formData.append(`${formKey}[${index}]`, arrayItem.toString());
				}
			});
		} else if (typeof value === 'object' && value !== null) {
			// Recursively handle nested objects
			objectToFormData(value, formData, formKey);
		}
	});

	return formData;
}

export function generateUUID() {
	return uuidv4();
}
