import { Icon } from "@chakra-ui/react";
import {
	MdAnnouncement, MdHowToVote,
	MdLock,
	MdOutlineArticle, MdOutlineDashboard,
	MdOutlineMusicVideo, MdOutlinePaid, MdOutlinePayments,
	MdOutlineReport,
	MdOutlineSettings,
	MdOutlineStar,
	MdPerson2
} from "react-icons/md";

import ForgotPasswordCentered from "views/auth/forgotPassword/ForgotPasswordCentered";
import ForgotPasswordDefault from "views/auth/forgotPassword/ForgotPasswordDefault";
import LockCentered from "views/auth/lock/LockCentered";
import LockDefault from "views/auth/lock/LockDefault";
import SignInCentered from "views/auth/signIn/SignInCentered";
import SignUpCentered from "views/auth/signUp/SignUpCentered";
import VerificationCentered from "views/auth/verification/VerificationCentered";
import VerificationDefault from "views/auth/verification/VerificationDefault";
import Users from "./views/admin/users";
import Vods from "./views/admin/contents/vod";
import LiveContents from "./views/admin/contents/live";
import Alerts from "./views/common/404";
import LiveDetailContents from "./views/admin/contents/live/detail";
import ForbiddenWord from "./views/admin/contents/banword";
import { TYPE_ROUTE_AUTH, TYPE_ROUTE_SCREEN } from "./helper/types";
import DeleteVODs from "./views/admin/contents/vod/delete";
import Blacklisted from "./views/admin/users/blacklisted";
import Projects from "./views/admin/project/project";
import Applylisted from "./views/admin/idol/apply/list";
import PreContractIdolList from "./views/admin/idol/precontract/list";
import PreContractIdolRegister from "./views/admin/idol/precontract/register";
import MemberReport from "./views/admin/report/member";
import WithdrawalWating from "./views/admin/users/withdrawal";
import Stage from "./views/admin/project/stage";
import Rewards from "./views/admin/project/rewards";
import Missions from "./views/admin/project/mission";
import Notices from "./views/admin/notice/system";
import NoticeRegistration from "./views/admin/notice/register";
import Events from "./views/admin/event";
import NoticeDetail from "./views/admin/notice/detail";
import NoticesAd from "./views/admin/notice/ad";
import EventDetail from "./views/admin/event/detail";
import EventRegistration from "./views/admin/event/register";
import AppVersion from "./views/admin/settings/appVersion";
import AppMetaDatas from "./views/admin/settings/appMeta";
import Accounts from "./views/admin/account";
import ProjectAppliedList from "./views/admin/project/applied";
import MemberCurrencyDeposit from "./views/admin/users/deposit/deposit";
import Dashboard from "./views/admin/dashboard";
import Vote from 'views/admin/vote';
import IdolTestAdd from "./views/admin/idol/test/add";
import Products from "./views/admin/product";
import StageDetail from "./views/admin/project/stage/detail";
import IdolPayoutRatioList from "./views/admin/payout/ratio";
import IdolRequestPayoutList from "./views/admin/payout";

/**
 *  name: string;
 *     path: string;
 *     icon?: JSX.Element;
 *     secondary?: boolean;
 *     layout?: string;
 *     component?: JSX.Element;
 *     collapse?: boolean;
 *     authType?: string;
 *     screenType: string;
 */

const routes = [
	// --- Users ---
	{
		name: '404',
		path: '/404',
		layout: '/auth',
		screenType: TYPE_ROUTE_SCREEN.ETC,
		authType: TYPE_ROUTE_AUTH.NONE,
		icon: <Icon as={MdPerson2} width="20px" height="20px" color="inherit" />,
		component: <Alerts />
	},

	{
		name: 'menu.dashboard',
		path: '/dashboard',
		screenType: TYPE_ROUTE_SCREEN.MAIN,
		authType: TYPE_ROUTE_AUTH.USER,
		layout: '/admin',
		icon: <Icon as={MdOutlineDashboard} width="20px" height="20px" color="inherit" />,
		component: <Dashboard />
	},

	// --- Contract ---
	{
		name: 'menus.idol',
		path: '/idol',
		screenType: TYPE_ROUTE_SCREEN.MAIN,
		authType: TYPE_ROUTE_AUTH.IDOL,
		layout: '/admin',
		icon: <Icon as={MdOutlineStar} width="20px" height="20px" color="inherit" />,
		collapse: true,
		items: [
			{
				name: 'menu.idol.pre_contract.register',
				layout: '/admin',
				path: '/idol/pre_contract/register',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.IDOL,
				component: <PreContractIdolRegister />
			},
			{
				name: 'menu.idol.pre_contract.list',
				layout: '/admin',
				path: '/idol/pre_contract/list',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.IDOL,
				component: <PreContractIdolList />
			},
			{
				name: 'menu.idol.apply.list',
				layout: '/admin',
				path: '/idol/apply/list',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.IDOL,
				component: <Applylisted />
			},
			{
				name: 'menu.idol.test',
				layout: '/admin',
				path: '/idol/test',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.IDOL,
				component: <IdolTestAdd />
			}
		]
	},
	// --- Users ---
	{
		name: 'menu.user',
		path: '/users',
		screenType: TYPE_ROUTE_SCREEN.MAIN,
		authType: TYPE_ROUTE_AUTH.USER,
		layout: '/admin',
		icon: <Icon as={MdPerson2} width="20px" height="20px" color="inherit" />,
		collapse: true,
		items: [
			{
				name: 'menu.users.normal',
				layout: '/admin',
				path: '/users/list',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.USER,
				component: <Users />
			},
			{
				name: 'menu.users.blacklist',
				layout: '/admin',
				path: '/users/blacklist',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.USER,
				component: <Blacklisted />
			},
			{
				name: 'menu.users.waitingwithdrawal',
				layout: '/admin',
				path: '/users/withdrawal',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.USER,
				component: <WithdrawalWating />
			},
			{
				name: 'menu.users.currency',
				layout: '/admin',
				path: '/users/deposit',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.USER,
				component: <MemberCurrencyDeposit />
			}
		]
	},
	// {
	//   name: "menu.users.detail",
	//   path: "/users/list/detail/:userId",
	//   screenType: TYPE_ROUTE_SCREEN.SUB,
	//   authType: TYPE_ROUTE_AUTH.USER,
	//   layout: "/admin",
	//   icon: <Icon as={MdPerson2} width="20px" height="20px" color="inherit" />,
	//   component: <UserDetail />
	// },

	// --- Contents ---
	{
		name: 'menu.content',
		path: '/contents',
		screenType: TYPE_ROUTE_SCREEN.MAIN,
		authType: TYPE_ROUTE_AUTH.CONTENT,
		icon: <Icon as={MdOutlineMusicVideo} width="20px" height="20px" color="inherit" />,
		collapse: true,
		items: [
			{
				name: 'menu.contents.live',
				layout: '/admin',
				path: '/contents/live',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.CONTENT,
				component: <LiveContents />
			},
			{
				name: 'menu.contents.vod',
				layout: '/admin',
				path: '/contents/vod',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.CONTENT,
				component: <Vods />
			},
			{
				name: 'menu.delete_vod',
				path: '/contents/delete-vod',
				layout: '/admin',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.CONTENT,
				component: <DeleteVODs />
			},

			{
				name: 'menu.contents.ban_words',
				layout: '/admin',
				path: '/contents/banwords',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.CONTENT,
				component: <ForbiddenWord />
			}
		]
	},
	{
		name: 'menu.report',
		path: '/report',
		screenType: TYPE_ROUTE_SCREEN.MAIN,
		authType: TYPE_ROUTE_AUTH.CONTENT,
		icon: <Icon as={MdOutlineReport} width="20px" height="20px" color="inherit" />,
		collapse: true,
		items: [
			{
				name: 'menu.reports.idol_profile',
				layout: '/admin',
				path: '/report/idol_profile',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.CONTENT,
				component: <MemberReport />
			},
			{
				name: 'menu.reports.producer_profile ',
				layout: '/admin',
				path: '/report/producer_profile',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.CONTENT,
				component: <MemberReport />
			},
			{
				name: 'menu.reports.live',
				layout: '/admin',
				path: '/report/live',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.CONTENT,
				component: <MemberReport />
			},
			{
				name: 'menu.reports.live_chat',
				layout: '/admin',
				path: '/report/lchat',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.CONTENT,
				component: <MemberReport />
			},
			{
				name: 'menu.reports.vod',
				layout: '/admin',
				path: '/report/vod',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.CONTENT,
				component: <MemberReport />
			},
			{
				name: 'menu.reports.vod_comment',
				layout: '/admin',
				path: '/report/vcomment',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.CONTENT,
				component: <MemberReport />
			},
			{
				name: 'menu.reports.review',
				layout: '/admin',
				path: '/report/review',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.CONTENT,
				component: <MemberReport />
			}
		]
	},

	// {
	//   name: "Vod Detail",
	//   path: "/contents/vod/:vodId",
	//   screenType: TYPE_ROUTE_SCREEN.SUB,
	//   authType: TYPE_ROUTE_AUTH.CONTENT,
	//   layout: "/admin",
	//   icon: <Icon as={MdPerson2} width="20px" height="20px" color="inherit" />,
	//   component: <VODDetail />
	// },
	{
		name: 'Live Detail',
		path: '/contents/live/:roomId',
		screenType: TYPE_ROUTE_SCREEN.SUB,
		authType: TYPE_ROUTE_AUTH.CONTENT,
		layout: '/admin',
		icon: <Icon as={MdPerson2} width="20px" height="20px" color="inherit" />,
		component: <LiveDetailContents />
	},

	// {
	// 	name: 'menu.paid_items.vote',
	// 	path: '/vote',
	// 	screenType: TYPE_ROUTE_SCREEN.MAIN,
	// 	authType: TYPE_ROUTE_AUTH.PAID_ITEM,
	// 	layout: '/admin',
	// 	icon: <Icon as={MdHowToVote} width="20px" height="20px" color="inherit" />,
	// 	component: <Vote />
	// },

	// --- Missions ---
	// {
	// 	name: 'menu.add_project',
	// 	path: '/project/create',
	// 	screenType: TYPE_ROUTE_SCREEN.SUB,
	// 	authType: TYPE_ROUTE_AUTH.CONTENT,
	// 	layout: '/admin',
	// 	icon: <Icon as={MdPerson2} width="20px" height="20px" color="inherit" />,
	// 	component: <CreatedProject />
	// },
	{
		name: 'menu.project',
		path: '/project',
		screenType: TYPE_ROUTE_SCREEN.MAIN,
		authType: TYPE_ROUTE_AUTH.MISSION,
		icon: <Icon as={MdOutlineArticle} width="20px" height="20px" color="inherit" />,
		collapse: true,
		items: [
			{
				name: 'menu.project',
				layout: '/admin',
				path: '/project/list',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.MISSION,
				component: <Projects />
			},
			{
				name: 'menu.project_applied',
				layout: '/admin',
				path: '/project/applied',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.MISSION,
				component: <ProjectAppliedList />
			},
			{
				name: 'menu.projects.stage',
				layout: '/admin',
				path: '/project/stage',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.MISSION,
				component: <Stage />
			},
			{
				name: 'menu.projects.missions',
				layout: '/admin',
				path: '/project/missions',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.MISSION,
				component: <Missions />
			},
			{
				name: 'menu.projects.rewards',
				layout: '/admin',
				path: '/project/rewards',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.MISSION,
				component: <Rewards />
			}
		]
	},

	{
		name: 'Stage Detail',
		path: '/project/stage/:stageId',
		screenType: TYPE_ROUTE_SCREEN.SUB,
		authType: TYPE_ROUTE_AUTH.MISSION,
		layout: '/admin',
		icon: <Icon as={MdPerson2} width="20px" height="20px" color="inherit" />,
		component: <StageDetail />
	},

	// {
	//   name: "Vod Detail",
	//   path: "/contents/vod/:vodId",
	//   screenType: TYPE_ROUTE_SCREEN.SUB,
	//   authType: TYPE_ROUTE_AUTH.CONTENT,
	//   layout: "/admin",
	//   icon: <Icon as={MdPerson2} width="20px" height="20px" color="inherit" />,
	//   component: <VODDetail />
	// },

	// --- 기타 ---
	{
		name: 'menu.products',
		path: '/product',
		screenType: TYPE_ROUTE_SCREEN.MAIN,
		authType: TYPE_ROUTE_AUTH.SETTING,
		icon: <Icon as={MdOutlinePaid} width="20px" height="20px" color="inherit" />,
		collapse: true,
		items: [
			{
				name: 'menu.product.list',
				layout: '/admin',
				path: '/products/list',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.PAID_ITEM,
				component: <Products />
			}
		]
	},

	// --- 기타 ---
	{
		name: 'menu.notices',
		path: '/notice',
		screenType: TYPE_ROUTE_SCREEN.MAIN,
		authType: TYPE_ROUTE_AUTH.SETTING,
		icon: <Icon as={MdAnnouncement} width="20px" height="20px" color="inherit" />,
		collapse: true,
		items: [
			{
				name: 'menu.notice.register',
				layout: '/admin',
				path: '/notices/register',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.SETTING,
				component: <NoticeRegistration />
			},
			{
				name: 'menu.notice.system_list',
				layout: '/admin',
				path: '/notices/system_list',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.SETTING,
				component: <Notices />
			},
			{
				name: 'menu.notice.ad_list',
				layout: '/admin',
				path: '/notices/ad_list',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.SETTING,
				component: <NoticesAd />
			},
			{
				name: 'menu.event.register',
				layout: '/admin',
				path: '/events/register',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.SETTING,
				component: <EventRegistration />
			},
			{
				name: 'menu.notice.event',
				layout: '/admin',
				path: '/events/event',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.SETTING,
				component: <Events />
			}
		]
	},

	{
		name: 'Notice Detail',
		path: '/notice/:noticeId',
		screenType: TYPE_ROUTE_SCREEN.SUB,
		authType: TYPE_ROUTE_AUTH.SETTING,
		layout: '/admin',
		icon: <Icon as={MdPerson2} width="20px" height="20px" color="inherit" />,
		component: <NoticeDetail />
	},

	{
		name: 'Event Detail',
		path: '/event/:eventId',
		screenType: TYPE_ROUTE_SCREEN.SUB,
		authType: TYPE_ROUTE_AUTH.SETTING,
		layout: '/admin',
		icon: <Icon as={MdPerson2} width="20px" height="20px" color="inherit" />,
		component: <EventDetail />
	},

	// --- Payout ---
	{
		name: 'menu.payout',
		path: '/payout',
		screenType: TYPE_ROUTE_SCREEN.MAIN,
		authType: TYPE_ROUTE_AUTH.SETTING,
		icon: <Icon as={MdOutlinePayments} width="20px" height="20px" color="inherit" />,
		collapse: true,
		items: [
			{
				name: 'menu.payouts.ratio',
				layout: '/admin',
				path: '/payout/ratio',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.REFUND,
				component: <IdolPayoutRatioList />
			},
			{
				name: 'menu.payouts.request',
				layout: '/admin',
				path: '/payout/request',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.REFUND,
				component: <IdolRequestPayoutList />
			},
		]
	},

	// --- Settings ---
	{
		name: 'menu.setting',
		path: '/settings',
		screenType: TYPE_ROUTE_SCREEN.MAIN,
		authType: TYPE_ROUTE_AUTH.SETTING,
		icon: <Icon as={MdOutlineSettings} width="20px" height="20px" color="inherit" />,
		collapse: true,
		items: [
			{
				name: 'menu.settings.app_version',
				layout: '/admin',
				path: '/settings/app_version',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.SETTING,
				component: <AppVersion />
			},
			{
				name: 'menu.settings.app_metadata',
				layout: '/admin',
				path: '/settings/app_metadata',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.SETTING,
				component: <AppMetaDatas />
			},
			// {
			// 	name: 'menu.settings.app_settings',
			// 	layout: '/admin',
			// 	path: '/settings/banwords',
			// 	screenType: TYPE_ROUTE_SCREEN.MAIN,
			// 	authType: TYPE_ROUTE_AUTH.SETTING,
			// 	component: <Alerts />
			// },
			// {
			// 	name: 'menu.settings.maintenance',
			// 	layout: '/admin',
			// 	path: '/settings/push',
			// 	screenType: TYPE_ROUTE_SCREEN.MAIN,
			// 	authType: TYPE_ROUTE_AUTH.SETTING,
			// 	component: <Alerts />
			// }
		]
	},

	{
		name: 'menu.account',
		path: '/account',
		screenType: TYPE_ROUTE_SCREEN.MAIN,
		authType: TYPE_ROUTE_AUTH.ACCOUNT,
		icon: <Icon as={MdOutlineSettings} width="20px" height="20px" color="inherit" />,
		collapse: true,
		items: [
			{
				name: 'menu.accounts.admin',
				layout: '/admin',
				path: '/account',
				screenType: TYPE_ROUTE_SCREEN.MAIN,
				authType: TYPE_ROUTE_AUTH.ACCOUNT,
				component: <Accounts />
			}
		]
	},

	// --- Authentication ---
	{
		name: 'Authentication',
		path: '/auth',
		icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
		collapse: true,
		screenType: TYPE_ROUTE_SCREEN.AUTH,
		authType: TYPE_ROUTE_AUTH.NONE,
		items: [
			// --- Sign In ---
			{
				name: 'Sign In',
				path: '/sign-in',
				collapse: true,
				screenType: TYPE_ROUTE_SCREEN.AUTH,
				authType: TYPE_ROUTE_AUTH.NONE,
				items: [
					{
						name: 'Centered',
						layout: '/auth',
						path: '/sign-in/centered',
						screenType: TYPE_ROUTE_SCREEN.AUTH,
						authType: TYPE_ROUTE_AUTH.NONE,
						component: <SignInCentered />
					}
				]
			},
			// --- Sign Up ---
			{
				name: 'Sign Up',
				path: '/sign-up',
				collapse: true,
				screenType: TYPE_ROUTE_SCREEN.AUTH,
				authType: TYPE_ROUTE_AUTH.NONE,
				items: [
					{
						name: 'Centered',
						layout: '/auth',
						path: '/sign-up/centered',
						screenType: TYPE_ROUTE_SCREEN.AUTH,
						authType: TYPE_ROUTE_AUTH.NONE,
						component: <SignUpCentered />
					}
				]
			},
			// --- Verification ---
			{
				name: 'Verification',
				path: '/verification',
				collapse: true,
				screenType: TYPE_ROUTE_SCREEN.AUTH,
				authType: TYPE_ROUTE_AUTH.NONE,
				items: [
					{
						name: 'Default',
						layout: '/auth',
						path: '/verification/default',
						screenType: TYPE_ROUTE_SCREEN.AUTH,
						authType: TYPE_ROUTE_AUTH.NONE,
						component: <VerificationDefault />
					},
					{
						name: 'Centered',
						layout: '/auth',
						path: '/verification/centered',
						screenType: TYPE_ROUTE_SCREEN.AUTH,
						authType: TYPE_ROUTE_AUTH.NONE,
						component: <VerificationCentered />
					}
				]
			},
			// --- Lock ---
			{
				name: 'Lock',
				path: '/lock',
				screenType: TYPE_ROUTE_SCREEN.AUTH,
				authType: TYPE_ROUTE_AUTH.NONE,
				collapse: true,
				items: [
					{
						name: 'Default',
						layout: '/auth',
						path: '/lock/default',
						screenType: TYPE_ROUTE_SCREEN.AUTH,
						authType: TYPE_ROUTE_AUTH.NONE,
						component: <LockDefault />
					},
					{
						name: 'Centered',
						layout: '/auth',
						path: '/lock/centered',
						screenType: TYPE_ROUTE_SCREEN.AUTH,
						authType: TYPE_ROUTE_AUTH.NONE,
						component: <LockCentered />
					}
				]
			},
			// --- Forgot Password ---
			{
				name: 'Forgot Password',
				path: '/forgot-password',
				collapse: true,
				screenType: TYPE_ROUTE_SCREEN.AUTH,
				authType: TYPE_ROUTE_AUTH.NONE,
				items: [
					{
						name: 'Default',
						layout: '/auth',
						path: '/forgot-password/default',
						screenType: TYPE_ROUTE_SCREEN.AUTH,
						authType: TYPE_ROUTE_AUTH.NONE,
						component: <ForgotPasswordDefault />
					},
					{
						name: 'Centered',
						layout: '/auth',
						path: '/forgot-password/centered',
						screenType: TYPE_ROUTE_SCREEN.AUTH,
						authType: TYPE_ROUTE_AUTH.NONE,
						component: <ForgotPasswordCentered />
					}
				]
			}
		]
	}
];

export default routes;
