import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	Icon,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Select,
	SimpleGrid,
	Stack,
	Text,
	useBoolean
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import Dropzone from 'sample/admin/main/ecommerce/settingsProduct/components/Dropzone';

import { MdOutlineCloudUpload } from 'react-icons/md';
import { useThemeColors } from '../../../../providers/theme-provider';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useCallback } from 'react';
import Quill from 'quill';
import ReactQuill from 'react-quill';
import { dateFormat, showToast } from "../../../../helper/functions";
import MiniCalendar from '../../../../components/calendar/MiniCalendar';
import moment from 'moment/moment';
import { MemberType, NoticePlacementType, NoticeType } from '../../../../types/enums';
import TextField from 'components/fields/TextAreaField';
import DropImageFile from "../../../../components/dropzone/DropImageFile";
import DropImageFileFromUrl from "../../../../components/dropzone/DropImageFileFromUrl";

const BlockEmbed = Quill.import('blots/block/embed');

class VideoBlot extends BlockEmbed {
	static create(value: string) {
		const node = super.create(value);
		node.setAttribute('src', value);
		node.setAttribute('frameborder', '0');
		node.setAttribute('allowfullscreen', 'true');
		node.setAttribute('width', '100%'); // Full width
		node.setAttribute('height', '400px'); // Adjust height as needed
		return node;
	}

	static value(node: HTMLIFrameElement) {
		return node.getAttribute('src');
	}
}

VideoBlot.blotName = 'video';
VideoBlot.tagName = 'iframe';
VideoBlot.className = 'ql-video';
Quill.register(VideoBlot);

// Custom size format using px
const Size = Quill.import('formats/size');
Size.whitelist = ['12px', '14px', '16px', '18px', '24px', '32px', '48px']; // Define your custom sizes in px
Quill.register(Size, true);

const Parchment = Quill.import('parchment');
const SizeStyle = new Parchment.Attributor.Style('size', 'font-size', {
	scope: Parchment.Scope.INLINE,
	whitelist: ['12px', '14px', '16px', '18px', '20px', '24px', '32px', '48px'] // Define px sizes here
});
Quill.register(SizeStyle, true);

// Custom Toolbar component
const CustomToolbar = () => (
	<div id="toolbar">
		{/* Font size dropdown with px values */}
		<select className="ql-size" defaultValue="16px">
			<option value="12px">12px</option>
			<option value="14px">14px</option>
			<option value="16px">16px</option>
			<option value="18px">18px</option>
			<option value="20px">20px</option>
			<option value="24px">24px</option>
			<option value="32px">32px</option>
			<option value="48px">48px</option>
		</select>

		{/* Bold, Italic, Underline, Strikethrough */}
		<button className="ql-bold" />
		<button className="ql-italic" />
		<button className="ql-underline" />
		<button className="ql-strike" />

		{/* Blockquote */}
		<button className="ql-blockquote" />

		{/* List buttons */}
		<button className="ql-list" value="ordered" />
		<button className="ql-list" value="bullet" />
		<button className="ql-list" value="check" />

		{/* Indent buttons */}
		<button className="ql-indent" value="-1" />
		<button className="ql-indent" value="+1" />

		{/* Subscript/Superscript */}
		<button className="ql-script" value="sub" />
		<button className="ql-script" value="super" />

		{/* Color dropdown */}
		<select className="ql-color">
			<option value="black" />
			<option value="blue" />
			<option value="red" />
			<option value="green" />
			<option value="purple" />
			<option value="white" />
			<option value="yellow" />
			<option value="gray" />
		</select>

		{/* Right-to-left text direction */}
		<button className="ql-direction" value="rtl" />

		{/* Link, Image, Video */}
		<button className="ql-link" />
		<button className="ql-image" />
		<button className="ql-video" />

		{/* Clean formatting */}
		<button className="ql-clean" />
	</div>
);

const modules = {
	toolbar: '#toolbar'
};

const formats = [
	'header',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'check',
	'indent',
	'script',
	'color',
	'direction',
	'link',
	'image',
	'video'
];

export function NoticeInformation(props: {
	title: string;
	setTitle: React.Dispatch<React.SetStateAction<string>>;
	mainText: string;
	setMainText: React.Dispatch<React.SetStateAction<string>>;
	description: string;
	setDescription: React.Dispatch<React.SetStateAction<string>>;
	thumbnailImg: File;
	setThumbnailImg: React.Dispatch<React.SetStateAction<File>>;
	thumbnailImgUrl?: string;
	setThumbnailImgUrl?: React.Dispatch<React.SetStateAction<string>>;
}) {
	const { t } = useTranslation();
	const { textColor, textColorSecondary } = useThemeColors();
	const {
		title,
		setTitle,
		mainText,
		setMainText,
		description,
		setDescription,
		thumbnailImg,
		setThumbnailImg,
		thumbnailImgUrl,
		setThumbnailImgUrl
	} = props;

	const onDropImages = useCallback(
		(acceptedFiles: File[]) => {
			if (thumbnailImgUrl && thumbnailImgUrl.length > 0) {
				showToast(t('error.exist_thumbnail_img'));
				return;
			}


			const mappedFiles = acceptedFiles.map(file =>
				Object.assign(file, {
					preview: URL.createObjectURL(file)
				})
			);

			setThumbnailImg(mappedFiles[0]);
		},
		[thumbnailImg]
	);

	const onDeleteImages = useCallback(
		(index: number) => {
			setThumbnailImg(null);
		},
		[thumbnailImg, setThumbnailImg]
	);

	const onDeleteImagesUrl = useCallback(
		(index: number) => {
			setThumbnailImgUrl(null);
		},
		[thumbnailImgUrl, setThumbnailImgUrl]
	);

	return (
		<FormControl>
			<Card>
				<SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
					<InputField
						id="name"
						label={t('common.title')}
						placeholder={t('common.title')}
						value={title}
						onChange={e => {
							setTitle(e.target.value);
						}}
					/>
					<InputField
						id="memo"
						label={t('common.banner_title')}
						h="100px"
						placeholder={t('common.banner_title')}
						value={mainText}
						onChange={e => {
							setMainText(e.target.value);
						}}
					/>
					<Flex direction="column">
						<TextField
							id="memo"
							label={t('common.description')}
							h="100px"
							placeholder={t('common.description')}
							value={description}
							onChange={e => {
								setDescription(e.target.value);
							}}
						/>
						{/*<CustomToolbar />*/}
						{/*<ReactQuill*/}
						{/*	modules={modules}*/}
						{/*	formats={formats}*/}
						{/*	value={description}*/}
						{/*	placeholder={t('common.description')}*/}
						{/*	onChange={(content, delta, source, editor) => setDescription(editor.getHTML())}*/}
						{/*/>*/}
					</Flex>
					<Dropzone
						content={
							<Box maxW="100%">
								<Icon as={MdOutlineCloudUpload} w="80px" h="80px" color={textColor} />
								<Text
									mb="12px"
									fontSize="lg"
									w="100%"
									maxW="100%"
									fontWeight="700"
									color={textColor}
									whiteSpace="pre-wrap"
								>
									{t('notice.thumbnail_img')}
								</Text>
								<Text fontSize="sm" fontWeight="500" color="secondaryGray.500" white-space="pre-wrap !important">
									{t('idol.new.images')}
								</Text>
							</Box>
						}
						maxFiles={1}
						onDropImage={onDropImages}
					/>
					<SimpleGrid columns={{ sm: 1 }} mt={'20px'} gap={'10px'}>
						{thumbnailImg ? <DropImageFile index={0} file={thumbnailImg} onDeleteFile={onDeleteImages} /> : null}
						{thumbnailImgUrl ? <DropImageFileFromUrl index={0} url={thumbnailImgUrl} onDeleteFile={onDeleteImagesUrl} /> : null}
					</SimpleGrid>
				</SimpleGrid>
			</Card>
		</FormControl>
	);
}

export function NoticeImages(props: {
	noticeType: string;
	setNoticeType: React.Dispatch<React.SetStateAction<string>>;
	noticePlacementType: NoticePlacementType[];
	setNoticePlacementType: React.Dispatch<React.SetStateAction<NoticePlacementType[]>>;
	targetMemberTypes: MemberType[];
	setTargetMemberTypes: React.Dispatch<React.SetStateAction<MemberType[]>>;
	interalUrl: string;
	setInternalUrl: React.Dispatch<React.SetStateAction<string>>;
	startDate: Date;
	onStartDateChange: React.Dispatch<React.SetStateAction<Date>>;
	endDate: Date;
	onEndDateChange: React.Dispatch<React.SetStateAction<Date>>;
	isActive: boolean;
	setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { t } = useTranslation();
	const { textColor, brand, textColorPrimary, textColorSecondary } = useThemeColors();
	const {
		noticeType,
		setNoticeType,
		noticePlacementType,
		setNoticePlacementType,
		targetMemberTypes,
		setTargetMemberTypes,
		interalUrl,
		setInternalUrl,
		startDate,
		onStartDateChange,
		endDate,
		onEndDateChange,
		isActive,
		setIsActive
	} = props;

	// 검색 필터 날짜
	const [isShowStartDate, setIsShowStartDate] = useBoolean(false);
	const [isShowEndDate, setIsShowEndDate] = useBoolean(false);

	return (
		<FormControl>
			<Card>
				<Flex direction="column" mb="40px" ms="10px">
					<SimpleGrid columns={{ sm: 1 }} gap={'10px'}>
						<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
							{t('notice.target_member')}
						</Text>
						<Stack spacing={5} direction="row">
							<Checkbox
								isChecked={targetMemberTypes?.includes(MemberType.IDOL) || false}
								onChange={() => {
									if (targetMemberTypes?.includes(MemberType.IDOL)) {
										setTargetMemberTypes(targetMemberTypes?.filter(value => value !== MemberType.IDOL));
									} else {
										setTargetMemberTypes([...targetMemberTypes, MemberType.IDOL]);
									}
								}}
							>
								{t('common.idol')}
							</Checkbox>
							<Checkbox
								isChecked={targetMemberTypes?.includes(MemberType.PRODUCER)}
								onChange={() => {
									if (targetMemberTypes?.includes(MemberType.PRODUCER)) {
										setTargetMemberTypes(targetMemberTypes?.filter(value => value !== MemberType.PRODUCER));
									} else {
										setTargetMemberTypes([...targetMemberTypes, MemberType.PRODUCER]);
									}
								}}
							>
								{t('common.producer')}
							</Checkbox>
						</Stack>
					</SimpleGrid>
					<SimpleGrid columns={{ sm: 1 }} gap={'10px'} mt={'20px'}>
						<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
							{t('notice.target_placement_type')}
						</Text>
						<Stack spacing={5} direction="row">
							<Checkbox
								isChecked={noticePlacementType?.includes(NoticePlacementType.HOME)}
								onChange={() => {
									if (noticePlacementType?.includes(NoticePlacementType.HOME)) {
										setNoticePlacementType(noticePlacementType?.filter(value => value !== NoticePlacementType.HOME));
									} else {
										setNoticePlacementType([...noticePlacementType, NoticePlacementType.HOME]);
									}
								}}
							>
								HOME
							</Checkbox>
							<Checkbox
								isChecked={noticePlacementType?.includes(NoticePlacementType.LOOK_AROUND)}
								onChange={() => {
									if (noticePlacementType?.includes(NoticePlacementType.LOOK_AROUND)) {
										setNoticePlacementType(
											noticePlacementType?.filter(value => value !== NoticePlacementType.LOOK_AROUND)
										);
									} else {
										setNoticePlacementType([...noticePlacementType, NoticePlacementType.LOOK_AROUND]);
									}
								}}
							>
								LOOK_AROUND
							</Checkbox>
						</Stack>
					</SimpleGrid>
					<SimpleGrid columns={{ sm: 1 }} gap={'10px'} mt={'20px'}>
						<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
							{t('notice.notice_type')}
						</Text>
						<Select
							placeholder={t('notice.notice_type')}
							value={noticeType}
							onChange={e => {
								setNoticeType(e.target.value);
							}}
						>
							<option value={NoticeType.SYSTEM}>{t('notice.type_system')}</option>
							<option value={NoticeType.ADVERTISEMENT}>{t('notice.type_ad')}</option>
						</Select>
					</SimpleGrid>
					<SimpleGrid columns={{ sm: 1 }} gap={'10px'} mt={'20px'}>
						<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
							{t('common.enable')}
						</Text>
						<Checkbox
							isChecked={isActive}
							onChange={e => {
								setIsActive(e.target.checked);
							}}
						>
							{t('common.enable')}
						</Checkbox>
					</SimpleGrid>
				</Flex>
				<SimpleGrid columns={{ sm: 1 }} gap={'10px'} mt={'20px'}>
					<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
						{t('notice.notice_date')}
					</Text>
					<Stack spacing={5} direction="row">
						<Popover
							isOpen={isShowStartDate}
							onOpen={setIsShowStartDate.on}
							onClose={setIsShowStartDate.off}
							closeOnBlur={false}
							isLazy
							lazyBehavior="keepMounted"
						>
							<PopoverTrigger>
								<Button w="180px" colorScheme="purple">
									{dateFormat(startDate)}
								</Button>
							</PopoverTrigger>
							<PopoverContent bg={'transparent'} border={'0px'}>
								<MiniCalendar
									selectRange={false}
									mb="20px"
									onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
										if (endDate) {
											const timeStart = moment(endDate);
											const timeEnd = moment(value);
											const diff = timeEnd.diff(timeStart);
											if (diff > 0) {
												alert(t('user.list.filter_user_register_start_date_error'));
												return;
											}

											onStartDateChange(value);
											setIsShowStartDate.off();
										}
									}}
									value={startDate}
								/>
							</PopoverContent>
						</Popover>
						{' ~ '}
						<Popover
							isOpen={isShowEndDate}
							onOpen={setIsShowEndDate.on}
							onClose={setIsShowEndDate.off}
							closeOnBlur={false}
							isLazy
							lazyBehavior="keepMounted"
						>
							<PopoverTrigger>
								<Button w="180px" colorScheme="purple">
									{dateFormat(endDate)}
								</Button>
							</PopoverTrigger>
							<PopoverContent bg={'transparent'} border={'0px'}>
								<MiniCalendar
									selectRange={false}
									mb="20px"
									onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
										if (startDate) {
											const timeStart = moment(startDate);
											const timeEnd = moment(value);
											const diff = timeEnd.diff(timeStart);
											if (diff < 0) {
												alert(t('user.list.filter_user_register_end_date_error'));
												return;
											}

											onEndDateChange(value);
											setIsShowEndDate.off();
										}
									}}
									value={endDate}
								/>
							</PopoverContent>
						</Popover>
					</Stack>
				</SimpleGrid>
			</Card>
		</FormControl>
	);
}
