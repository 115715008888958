// Chakra imports
import { Flex, Grid, SimpleGrid } from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import TextField from '../../../../components/fields/TextField';
import { useThemeColors } from '../../../../providers/theme-provider';
import { useApi } from '../../../../providers/api-provider';
import Card from '../../../../components/card/Card';
import { IdolMemberTrain } from '../../../../apis/memberAPI';
import ButtonField from 'components/fields/ButtonField';
import NumberField from 'components/fields/NumberField';
import { showToast } from "../../../../helper/functions";

// 금지어 목록
export default function IdolPayoutRatioDetail(props: { detail: IdolMemberTrain; requestChangeParams: () => void }) {
	const { t } = useTranslation();
	const { memberApi } = useApi();

	const { detail, requestChangeParams } = props;

	const [item, setItem] = useState<IdolMemberTrain>();

	useEffect(() => {
		if (detail !== null) {
			setItem(detail);
		}
	}, [detail]);

	const requestEditPayoutRatio = () => {
		memberApi.putIdolCurrencyRedeemRate(String(detail?.memberId), String(item.redeemRate ? item.redeemRate : 40)).then(() => {
			requestChangeParams();
		}).catch(() => {
			showToast(t('error.try_again'))
		})
	};

	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '0px', md: '0px', xl: '0px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Flex direction={'column'}>
				<Grid
					display={{ base: 'block', md: 'grid' }}
					gridTemplateColumns={{ base: '1.5fr 1.5fr', lg: '1.5fr 1.5fr' }}
					gap={{ base: '20px', xl: '20px' }}
				>
					<Card>
						<SimpleGrid>
							<TextField label={t('user.member_id')} text={String(item?.memberId)} />
							<TextField label={t('user.activity_name')} text={String(item?.activityName)} />
							<TextField label={t('user.member_type')} text={String(item?.memberType)} />
						</SimpleGrid>
					</Card>
					<Card>
						<SimpleGrid>
							<NumberField
								label={t('member.redeem_rate')}
								defaultValue={item?.redeemRate ? item?.redeemRate : 40}
								step={1}
								min={5}
								max={50}
								keepWithinRange={true}
								clampValueOnBlur={true}
								onChange={valueAsString => {
									setItem(prevState => {
										return {
											...prevState,
											redeemRate: Number(valueAsString)
										};
									});
								}}
							/>
							<ButtonField buttonName={t('common.save')} onClick={() => {
								requestEditPayoutRatio();
							}} />
						</SimpleGrid>
					</Card>
				</Grid>
			</Flex>
		</Grid>
	);
}
