import { BaseApi } from "./baseAPI";
import { PagingRequest } from "./commonAPI";
import { MemberType, ReportContentsType, ReportStatusType } from "../types/enums";
import {
	URL_GET_REPORT_ALL,
	URL_GET_REPORT_CONFIRM,
	URL_GET_REPORT_NEED_CONFIRM,
	URL_GET_REPORT_REVERT,
	URL_POST_NOTICE
} from "./URLS";
import { MemberDetailResponse, MemberTrain } from "./memberAPI";

/**
 * Common Controller
 */
export class ReportApi extends BaseApi {
	getReportNeedConfirm = (request: ReportListRequest) => {
		return this.axios
			.get<ReportListResponse>(URL_GET_REPORT_ALL, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	postReportConfirm = (reportId: string, status: ReportStatusType) => {
		return this.axios
			.post(URL_GET_REPORT_CONFIRM, {
				reportId: reportId,
				status: status
			})
			.then(response => response)
			.catch(error => error.response);
	};

	postReportRevert = (reportId: string) => {
		return this.axios
			.post(URL_GET_REPORT_REVERT, {
				reportId: reportId
			})
			.then(response => response)
			.catch(error => error.response);
	};
}

/**
 * ====================================================
 * ====================================================
 * Request
 */
export type ReportListRequest = PagingRequest & {
	/**
	 *  신고 검색 타입
	 *  Available values : ALL,
	 * PROFILE_IDOL,
	 * PROFILE_PRODUCER,
	 * LIVE_STREAMING,
	 * VOD,
	 * VOD_COMMENT,
	 * LIVE_STREAMING_CHAT
	 */
	searchType?: ReportContentsType;
	reviewRequested?: boolean;
};

export type ReportListResponse = {
	masterReportDetail: ReportTrain[];
	count: number;
};

export type ReportTrain = {
	reportId: number;
	contentsType: ReportContentsType;
	contentsId: number;
	memberId: number;
	memberType: MemberType;
	masterReportId: number;
	masterReportDetailId: number;
	status: ReportStatusType;
	contentsMemberType: MemberType;
	contentsMemberId: number;
	confirmAdminAccountId: number;
	confirmAdminName: string;
	confirmDateTime: string;
	warningDays: string;
	sanctionsDays: string;
	penaltyStartDateTime: MemberDetailResponse;
	penaltyEndDateTime: string;
};
