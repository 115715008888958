// Chakra imports
import { Button, Flex, Grid, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react';

// Custom components
// Assets
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import MiniStatistics from 'components/card/MiniStatistics';
// import SwitchField from 'components/fields/SwitchField';
// import ModalDeleteVod from 'views/admin/contents/vod/components/ModalDeleteVod';
import { useApi } from '../../../../providers/api-provider';
import { IdolPreContractDetailResponse } from '../../../../apis/idolAPI';
import { DATA_FORMAT_FULL_DATE, dateFormat, getFileName, phoneFomatter } from "../../../../helper/functions";
import { BlobReader, BlobWriter, Entry, ZipReader } from '@zip.js/zip.js';
import { saveAs } from 'file-saver';
import Card from '../../../../components/card/Card';

interface ExtractedImage {
	fileName: string;
	imageUrl: string;
}

export default function IdolPreContractDetail(props: { idolPreContractId: number }) {
	const { t } = useTranslation();

	const { idolPreContractId } = props;
	const { idolApi } = useApi();

	const [idolPreContractDetail, setIdolPreContractDetail] = useState<IdolPreContractDetailResponse>(null);
	const [files, setFiles] = useState<ExtractedImage[]>([]);

	useEffect(() => {
		if (idolPreContractId >= 0) {
			requestIdolDetail().then(() => {});
		}
	}, [idolPreContractId]);

	const requestIdolDetail = async () => {
		idolApi.idolPreContractDetail(idolPreContractId).then(response => {
			setIdolPreContractDetail(response.idolPreContractDetail);
		});
	};

	if (!idolPreContractDetail) {
		return <Flex />;
	}

	// Function to handle file input change and process the zip file
	const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files !== null) {
			const file = event.target.files[0];
		}

		const zipFile = event.target.files?.[0];
		if (!zipFile) {
			alert('Could not process the ZIP file.');
			return;
		}

		try {
			await processZipFile(zipFile);
		} catch (error) {
			console.error('Error processing the zip file:', error);
			alert('Could not process the ZIP file.');
		}
	};

	/**
	 * 파일 다운로드
	 */
	const downloadZipFile = async (link?: string) => {
		if (!link || link.length === 0) {
			return;
		}

		saveAs(link, getFileName(link));
	};

	const processZipFile = async (zipBlob: Blob) => {
		try {
			// Create a ZipReader instance to read the zip file
			const reader: ZipReader<BlobReader> = new ZipReader(new BlobReader(zipBlob), {
				password: idolPreContractDetail.identityDocument.password // Pass the password provided by the user
			});
			// Get all entries (files/folders) inside the zip
			const entries: Entry[] = await reader.getEntries();
			// Process each entry, extract image files and display them
			const extractedImages: ExtractedImage[] = [];
			for (const entry of entries) {
				if (!entry.directory && isImage(entry)) {
					const blob = await extractBlobFromEntry(entry);
					const imageUrl = URL.createObjectURL(blob);
					extractedImages.push({ fileName: entry.filename, imageUrl });
				}
			}

			setFiles(extractedImages); // Update state with image URLs
			await reader.close(); // Close the reader when done
		} catch (error) {
			alert('Incorrect password or invalid ZIP file.');
		}
	};

	// Function to check if an entry is an image file
	const isImage = (entry: Entry): boolean => {
		const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
		return imageExtensions.some(ext => entry.filename.toLowerCase().endsWith(`.${ext}`));
	};

	// Function to extract blob from a zip entry
	const extractBlobFromEntry = (entry: Entry): Promise<Blob> => {
		return entry.getData(new BlobWriter()); // Extract entry data as a Blob
	};

	// Chakra Color Mode
	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '0px', md: '80px', xl: '0px' }}
			// gridTemplateColumns={{ base: '1fr 1.2fr', lg: '1fr 1.2fr' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Flex direction={'column'}>
				<Grid
					display={{ base: 'block', md: 'grid' }}
					gridTemplateColumns={{ base: '5.4fr', lg: '5.4fr' }}
					gap={{ base: '20px', xl: '20px' }}
				>
					<Grid>
						<SimpleGrid mb="10px">
							<MiniStatistics name={t('idol.precontract.detail.title_name')} value={idolPreContractDetail.name} />
						</SimpleGrid>
						<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="10px" mb="10px" height={'max-content'}>
							<MiniStatistics
								name={t('idol.precontract.detail.title_mobile')}
								value={'+(' + idolPreContractDetail.countryCode + ') ' + phoneFomatter(idolPreContractDetail.mobile, false)}
							/>
							<MiniStatistics
								name={t('idol.precontract.detail.title_apply_code')}
								value={idolPreContractDetail.applyCode}
							/>
						</SimpleGrid>
						<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="10px" mb="10px">
							<MiniStatistics
								name={t('idol.precontract.detail.title_apply_code_generate_date_time')}
								value={dateFormat(idolPreContractDetail.applyCodeGenerateDateTime, DATA_FORMAT_FULL_DATE)}
							/>
							<MiniStatistics
								name={t('idol.precontract.detail.title_apply_code_expire_date_time')}
								value={dateFormat(idolPreContractDetail.applyCodeExpireDateTime, DATA_FORMAT_FULL_DATE)}
							/>
						</SimpleGrid>
					</Grid>
					<Grid mb="10px">
						<SimpleGrid>
							<MiniStatistics name={t('idol.precontract.detail.title_memo')} value={idolPreContractDetail.memo} />
						</SimpleGrid>
					</Grid>
				</Grid>
				<SimpleGrid mb="10px" gap="10px">
					<Card gap="10px">
						<Stack direction="row" spacing={4} align="center">
							{idolPreContractDetail.identityDocument?.idCardZipUrls?.map((item, index) => {
								return (
									<Button
										variant="brand"
										onClick={() => {
											downloadZipFile(item);
										}}
									>
										{t('idol.apply.detail.identify_img_download')}
									</Button>
								);
							})}
						</Stack>
						<Stack direction="row" spacing={4} align="center">
							<Text variant="brand" onClick={() => {}}>
								{t('idol.apply.detail.title_select_zip_file')}
							</Text>
							<input type="file" onChange={handleFileChange} />
						</Stack>
						{files[0]?.imageUrl ? <Image src={files[0]?.imageUrl} boxSize={'sm'} objectFit={'fill'} /> : null}
					</Card>
				</SimpleGrid>
			</Flex>
		</Grid>
	);
}
