// Chakra imports
import { Button, Flex, useColorModeValue } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

// Assets

function Dropzone(props: { content: JSX.Element | string; onDropAccepted: (files: File[]) => void; [x: string]: any }) {
	const { content, onDropAccepted, ...rest } = props;
	const { getRootProps, getInputProps } = useDropzone({
		onDropAccepted: onDropAccepted,
		onDropRejected: files => console.log('Rejected files:', files)
	});
	const bg = useColorModeValue('gray.100', 'navy.700');
	const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100');

	return (
		<Flex
			align='center'
			justify='center'
			bg={bg}
			borderTop='1px dashed'
			borderColor={borderColor}
			maxW='100%'
			maxH={'100%'}
			h={{ base: '120px', 'xl': '120px' }}
			cursor='pointer'
			{...getRootProps({ className: 'dropzone' })}
			{...rest}>
			<input {...getInputProps()} />
			<Button variant='no-effects'>{content}</Button>
		</Flex>
	);
}

export default Dropzone;
