// Chakra imports
import { Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import Card from '../../../components/card/Card';
import SearchTableUsers from './components/SearchTableUsers';
import { useApi } from '../../../providers/api-provider';
import {
	MemberBlackListedRequest,
	MemberListSearchRequest,
	MemberListSearchResponse,
	MemberTrain
} from '../../../apis/memberAPI';
import { DATA_FORMAT_SERVER_FULL_DATE, dateFormat, showToast } from '../../../helper/functions';
import { MemberStatusType, MemberType } from '../../../types/enums';
import { useTranslation } from 'react-i18next';
// Custom components
// Assets

export default function Users() {

	const { memberApi } = useApi();
	const { t } = useTranslation();

	const [list, setList] = useState<MemberTrain[]>([]);

	const [offset, setOffset] = useState(0);
	const [searchParams, setSearchParams] = useState<MemberListSearchRequest>(null);

	const [totalCount, setTotalCount] = useState(0);
	const [limit, setLimit] = useState(10);

	const requestUserList = async (offset: number, search?: MemberListSearchRequest) => {
		setOffset(offset);
		setSearchParams(search);
		memberApi
			.getMemberListSearch({
				limit: limit,
				offset: offset,
				...search
			})
			.then(responseMemberSearch)
			.catch(() => {});
	};

	const responseMemberSearch = (response: MemberListSearchResponse) => {
		if (response.memberTrain) {
			setList(response.memberTrain);
		}

		if (response.count) {
			setTotalCount(response.count);
		}
	};

	const requestMemberBlacklisted = (id: number, type: MemberType, reason: string) => {
		let current = new Date();
		let endDate = new Date(current.setFullYear(current.getFullYear() + 90));

		let memberBlacklistRequest: MemberBlackListedRequest = {
			memberType: type,
			blackListStartDate: dateFormat(new Date(), DATA_FORMAT_SERVER_FULL_DATE),
			blackListEndDate: dateFormat(endDate, DATA_FORMAT_SERVER_FULL_DATE),
			registerReason: reason
		};

		memberApi.addBlacklisted(id, memberBlacklistRequest).then(
			response => {
				// list.map(user => {
				// 	if (user.memberId === id) {
				// 		user.blackListed = true;
				// 		user.memberStatus = MemberStatusType.BLACK_LISTED;
				// 	}
				//
				// 	return user;
				// });
				// setList(list);
				requestUserList(offset, searchParams);
			},
			error => {
				showToast(t('error.try_again'))
			}
		);
	};

	const requestMemberDeleteBlacklisted = (id: number, type: MemberType, reason: string) => {
		let memberBlacklistRequest: MemberBlackListedRequest = {
			memberType: type,
			deRegisterReason: reason
		};

		memberApi.deleteBlacklisted(id, memberBlacklistRequest).then(
			response => {
				// list.map(user => {
				// 	if (user.memberId === id) {
				// 		user.blackListed = false;
				// 		user.memberStatus = MemberStatusType.NORMAL;
				// 	}
				//
				// 	return user;
				// });
				// setList(list);
				requestUserList(offset, searchParams);
			},
			error => {
				showToast(t('error.try_again'))
			}
		);
	};

	return (
		<Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
			<Card px="0px">
				<SearchTableUsers
					tableData={list}
					totalCount={totalCount}
					requestMemberSearch={requestUserList}
					requestMemberBlacklisted={requestMemberBlacklisted}
					requestMemberDeleteBlacklisted={requestMemberDeleteBlacklisted}
				/>
			</Card>
		</Flex>
	);
}
