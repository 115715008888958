// chakra imports
import { Box, Flex, Stack, Text, useColorModeValue } from '@chakra-ui/react';
//   Custom components
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import { useLogin } from '../../../providers/login-provider';

// FUNCTIONS

function SidebarContent(props: { routes: RoutesType[]; hovered?: boolean; mini?: boolean }) {
	const { routes, mini, hovered } = props;
	const textColor = useColorModeValue('navy.700', 'white');

	const { loginInfo } = useLogin();

	// SIDEBAR
	return (
		<Flex direction="column" height="100%" pt="20px" borderRadius="30px" w="100%">
			<Brand mini={mini} hovered={hovered} />
			<Stack direction="column" mb="auto" mt="8px">
				<Box
					ps={mini === false ? '20px' : mini === true && hovered === true ? '20px' : '16px'}
					pe={{ md: '16px', '2xl': '1px' }}
					ms={mini && hovered === false ? '-16px' : 'unset'}
				>
					<Links mini={mini} hovered={hovered} routes={routes} />
				</Box>
			</Stack>

			<Flex mt="75px" mb="56px" justifyContent="left" alignItems="center" pl="30px">
				<Box display={mini === false ? 'block' : mini === true && hovered === true ? 'block' : 'none'}>
					<Text color={textColor} fontSize="md" fontWeight="700">
						{loginInfo.name}
					</Text>
					<Text color="secondaryGray.600" fontSize="sm" fontWeight="400">
						IDID Inc.
					</Text>
				</Box>
			</Flex>
		</Flex>
	);
}

export default SidebarContent;
