type RowObj = {
	id: string;
	name: string;
	type: string;
	mobile: string;
	email: string;
	loginType: string;
	createdDt: string;
	status: string;
	blacklistYn: string;
	purchasedYn: string;
};

const tableDataUsersOverview: RowObj[] = [
	{
		id: 'P1234',
		name: 'Vlad Mihalache',
		type: 'I',
		mobile: '010-0000-0000',
		email: 'a@a.com',
		loginType: 'X',
		createdDt: '2022-10-22',
		status: 'N',
		blacklistYn: 'N',
		purchasedYn: 'N'
	},
	{
		id: 'P1235',
		name: 'Fredy Andrei',
		type: 'I',
		mobile: '010-0000-0000',
		email: 'b@b.com',
		loginType: 'G',
		createdDt: '2022-10-28',
		status: 'D',
		blacklistYn: 'N',
		purchasedYn: 'N'
	},
	{
		id: 'P1236',
		name: '최빛가람',
		type: 'I',
		mobile: '010-0000-0000',
		email: 'c@c.com',
		loginType: 'G',
		createdDt: '2022-10-31',
		status: 'D',
		blacklistYn: 'N',
		purchasedYn: 'N'
	},
	{
		id: 'P1237',
		name: '류슬기',
		type: 'I',
		mobile: '010-0000-0000',
		email: 'd@d.com',
		loginType: 'A',
		createdDt: '2023-11-28',
		status: 'N',
		blacklistYn: 'Y',
		purchasedYn: 'N'
	},
	{
		id: 'P1238',
		name: 'さくら',
		type: 'P',
		mobile: '010-0000-0000',
		email: 'e@e.com',
		loginType: 'A',
		createdDt: '2024-03-28',
		status: 'B',
		blacklistYn: 'N',
		purchasedYn: 'N'
	},
	{
		id: 'P1239',
		name: 'あおい',
		type: 'I',
		mobile: '010-0000-0000',
		email: 'f@f.com',
		loginType: 'G',
		createdDt: '2024-02-28',
		status: 'N',
		blacklistYn: 'N',
		purchasedYn: 'N'
	},
	{
		id: 'P1240',
		name: 'りこ',
		type: 'P',
		mobile: '010-0000-0000',
		email: 'g@g.com',
		loginType: 'A',
		createdDt: '2022-10-28',
		status: 'B',
		blacklistYn: 'Y',
		purchasedYn: 'N'
	},
	{
		id: 'P1241',
		name: 'みなみ',
		type: 'P',
		mobile: '010-0000-0000',
		email: 'h@h.com',
		loginType: 'G',
		createdDt: '2023-10-28',
		status: 'N',
		blacklistYn: 'N',
		purchasedYn: 'Y'
	},
	{
		id: 'P1242',
		name: 'れいこ',
		type: 'P',
		mobile: '010-0000-0000',
		email: 'i@i.com',
		loginType: 'G',
		createdDt: '2022-10-28',
		status: 'N',
		blacklistYn: 'Y',
		purchasedYn: 'N'
	},
	{
		id: 'P1243',
		name: 'えりか',
		type: 'I',
		mobile: '010-0000-0000',
		email: 'j@j.com',
		loginType: 'G',
		createdDt: '2022-10-28',
		status: 'B',
		blacklistYn: 'Y',
		purchasedYn: 'N'
	},
	{
		id: 'P1244',
		name: 'あやか',
		type: 'I',
		mobile: '010-0000-0000',
		email: 'k@k.com',
		loginType: 'G',
		createdDt: '2022-10-28',
		status: 'D',
		blacklistYn: 'Y',
		purchasedYn: 'Y'
	},
	{
		id: 'P1245',
		name: 'かりな',
		type: 'I',
		mobile: '010-0000-0000',
		email: 'l@l.com',
		loginType: 'L',
		createdDt: '2022-10-28',
		status: 'N',
		blacklistYn: 'N',
		purchasedYn: 'Y'
	},
	{
		id: 'P1246',
		name: 'そら)',
		type: 'I',
		mobile: '010-0000-0000',
		email: 'm@m.com',
		loginType: 'G',
		createdDt: '2023-02-23',
		status: 'N',
		blacklistYn: 'N',
		purchasedYn: 'Y'
	},
	{
		id: 'P1247',
		name: 'さくみ',
		type: 'P',
		mobile: '010-0000-0000',
		email: 'n@m.com',
		loginType: 'G',
		createdDt: '2023-11-09',
		status: 'N',
		blacklistYn: 'N',
		purchasedYn: 'N'
	}
];

export default tableDataUsersOverview;
