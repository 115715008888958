// Chakra imports
import { Box, Flex, FormLabel, Switch, Text } from '@chakra-ui/react';
import { useThemeColors } from '../../providers/theme-provider';
// Custom components
export default function Default(props: {
	label?: string;
	isChecked?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	desc?: string;
	textWidth?: string | number;
	reversed?: boolean;
	disabled?: boolean;
	[x: string]: any;
}) {
	const { label, isChecked, onChange, desc, textWidth, reversed, fontSize, disabled, mb, mt, ...rest } = props;
	//const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

	const { textColorPrimary } = useThemeColors();

	return (
		<Box w="100%" fontWeight="500" {...rest} pl={'10px'} mt={mt ? mt : '10px'} mb={mb ? mb : '10px'}>
			{reversed ? (
				<Flex align="center">
					<Switch isChecked={isChecked} colorScheme="brand" size="md" isDisabled={disabled} onChange={onChange} />
					<FormLabel
						ms="15px"
						_hover={{ cursor: 'pointer' }}
						flexDirection="column"
						mb="0px"
						maxW={textWidth ? textWidth : '75%'}
						justifyContent={'center'}
					>
						<Text color={textColorPrimary} fontSize="sm" fontWeight="bold">
							{label}
						</Text>
						<Text color="secondaryGray.600" fontSize={fontSize ? fontSize : 'md'}>
							{desc}
						</Text>
					</FormLabel>
				</Flex>
			) : (
				<Flex justify="space-between" align="center" borderRadius="6px">
					<FormLabel
						_hover={{ cursor: 'pointer' }}
						flexDirection="column"
						maxW={textWidth ? textWidth : '75%'}
						justifyContent={'center'}
					>
						<Text color={textColorPrimary} fontSize="sm" fontWeight="bold">
							{label}
						</Text>
						<Text color="secondaryGray.600" fontSize={fontSize ? fontSize : 'md'}>
							{desc}
						</Text>
					</FormLabel>
					<Switch isChecked={isChecked} colorScheme="brand" size="md" isDisabled={disabled} onChange={onChange} />
				</Flex>
			)}
		</Box>
	);
}
