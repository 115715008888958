/* eslint-disable */

import {
	Avatar,
	Button,
	Flex,
	HStack,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Radio,
	RadioGroup,
	SimpleGrid,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useBoolean
} from '@chakra-ui/react';
// Custom components
import * as React from 'react';
import { useEffect, useState } from 'react';
// Assets
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TYPE_KEY_SORT } from '../../../../helper/types';
import { useTranslation } from 'react-i18next';
import {
	DATA_FORMAT_FULL_DATE,
	DATA_FORMAT_SHORT_DATE,
	dateFormat,
	phoneFomatter,
	showToast
} from '../../../../helper/functions';
import tempData from '../variables/tableDataUsersOverview';
import Pagination from '../../../../components/paging/Pagination';
import Card from 'components/card/Card';
import IdolApplyDetail from '../apply/detail';
import { IdolApplySearchRequest, IdolApplyTrain } from '../../../../apis/idolAPI';
import { IdolApplySearchType, IdolApplyStatus } from '../../../../types/enums';
import { useThemeColors } from '../../../../providers/theme-provider';

type RowObj = {
	id: string;
	name: string;
	type: string;
	mobile: string;
	email: string;
	loginType: string;
	createdDt: string;
	status: string;
	blacklistYn: string;
	purchasedYn: string;
};

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

export default function SearchTableApplyList(props: {
	tableData: IdolApplyTrain[];
	totalCount: number;
	requestIdolApplySearch: (offset: number, searchParams?: IdolApplySearchRequest) => {};
	// requestIdolApplyList: (offset: number, searchParams?: IdolApplyListRequest) => {};
	// requestIdolApplyDetail: (idolApplyId: number, status: IdolApplyStatus, reason: string) => void;
}) {
	const { tableData, totalCount, requestIdolApplySearch } = props;

	const { t } = useTranslation();
	const { textColor, borderColor } = useThemeColors();

	// 현재 페이지에 노출되고 있는 데이터
	const [data, setData] = useState([]);

	// 검색 필터 설정 값
	const [searchParams, setSearchParams] = useState<IdolApplySearchRequest>(null);

	// 체크 박스 선택 값
	const [filterIdolApplyStatus, setFilterIdolApplyStatus] = useState<(string | number)[]>([]);
	// const [filterIdolApplyStatus, setFilterIdolApplyStatus] = useState<(string | number)>('');
	// const [filterUserType, setFilterUserType] = useState<(string | number)[]>([]);
	// const [filterUserLoginType, setFilterUserLoginType] = useState<(string | number)[]>([]);
	// const [filterUserIapYn, setFilterUserIapYn] = useState<(string | number)[]>([]);

	// 검색어
	const [searchString, setSearchString] = useState('');
	// 검색어 타입
	const [currentSearchType, setCurrentSearchType] = useState<IdolApplySearchType>(IdolApplySearchType.ALL);

	// 현재 정렬 필터
	const [currentSortFilter, setCurrentSortFilter] = useState(TYPE_KEY_SORT.LATEST);

	// 검색 필터 날짜
	const [today, setToday] = useState(new Date());

	const [isShowStartDate, setIsShowStartDate] = useBoolean(false);
	const [startDate, onStartDateChange] = useState<Date>(today);

	const [isShowEndDate, setIsShowEndDate] = useBoolean(false);
	const [endDate, onEndDateChange] = useState<Date>(today);

	const [selectIdolApplyId, setSelectIdolApplyId] = useState(-1);
	// const [selectMemberType, setSelectMemberType] = useState(MemberType.IDOL);

	// 현재 페이지 인덱스
	const [currentPageIndex, setCurrentPageIndex] = useState(0);

	// 유저 상세 팝업 표시
	const [isShowDetail, setIsShowDetail] = useState(false);

	const ROW_COUNT = 10;
	// 1행에 보여줄 카드 갯수
	const CardPageSize = 1;

	// 모바일여부
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const userAgent = navigator.userAgent.toLowerCase();
		const isMobileDevice = /android|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(userAgent);
		setIsMobile(isMobileDevice);
	}, []);

	const moveToDetail = (idolApplyId: number) => {
		setSelectIdolApplyId(idolApplyId);
		setIsShowDetail(true);
	};

	const columnHelper = createColumnHelper<IdolApplyTrain>();
	const columns = [
		columnHelper.accessor('idolApplyId', {
			id: 'idolApplyId',
			header: () => (
				<Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
					{t('idol.apply.list.table_title_idol_apply_id')}
				</Text>
			),
			cell: (info: any) => (
				<Flex
					align="center"
					onClick={() => {
						moveToDetail(info.cell.row.original.idolApplyId);
					}}
				>
					<Text color={textColor} fontSize="md" fontWeight="500">
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
		columnHelper.accessor('activityName', {
			id: 'activityName',
			header: () => (
				<Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
					{t('idol.apply.list.table_title_activity_name')}
				</Text>
			),
			cell: (info: any) => (
				<Text
					color={textColor}
					fontSize="md"
					fontWeight="500"
					onClick={() => {
						moveToDetail(info.cell.row.original.idolApplyId);
					}}
				>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('email', {
			id: 'email',
			header: () => (
				<Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
					{t('idol.apply.list.table_title_email')}
				</Text>
			),
			cell: (info: any) => (
				<Flex
					align="center"
					onClick={() => {
						moveToDetail(info.cell.row.original.idolApplyId);
					}}
				>
					<Text color={textColor} fontSize="md" fontWeight="500">
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
		columnHelper.accessor('countryCode', {
			id: 'countryCode',
			header: () => (
				<Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
					{t('idol.apply.list.table_title_country_code')}
				</Text>
			),
			cell: (info: any) => (
				<Text
					color={textColor}
					fontSize="md"
					fontWeight="500"
					onClick={() => {
						moveToDetail(info.cell.row.original.idolApplyId);
					}}
				>
					{'+' + info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('mobile', {
			id: 'mobile',
			header: () => (
				<Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
					{t('idol.apply.list.table_title_mobile')}
				</Text>
			),
			cell: (info: any) => (
				<Text
					color={textColor}
					fontSize="md"
					fontWeight="500"
					onClick={() => {
						moveToDetail(info.cell.row.original.idolApplyId);
					}}
				>
					{phoneFomatter(info.getValue(), true)}
				</Text>
			)
		}),
		columnHelper.accessor('pid', {
			id: 'pid',
			header: () => (
				<Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
					{t('idol.apply.list.table_title_pid')}
				</Text>
			),
			cell: (info: any) => (
				<Text
					color={textColor}
					fontSize="md"
					fontWeight="500"
					onClick={() => {
						moveToDetail(info.cell.row.original.idolApplyId);
					}}
				>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('profileImgUrl', {
			id: 'profileImgUrl',
			header: () => (
				<Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
					{t('idol.apply.list.table_title_profile_img_url')}
				</Text>
			),
			cell: (info: any) => (
				<Flex align="center">
					<Avatar src={info.getValue()} h="60px" w="60px" me="10px" borderRadius={'5px'} />
				</Flex>
			)
		}),
		// columnHelper.accessor('agreements', {
		// 	id: 'agreements',
		// 	header: () => (
		// 		<Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
		// 			{t('user.list.table_title_blacklist_yn')}
		// 		</Text>
		// 	),
		// 	cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
		// 		const initialValue = getValue();
		//
		// 		const [value, setValue] = React.useState(initialValue);
		// 		const [isShowModal, setIsShowModal] = useState(false);
		//
		// 		React.useEffect(() => {
		// 			setValue(initialValue);
		// 		}, [initialValue]);
		//
		// 		const onSwitchChange = () => {
		// 			if (value) {
		// 				// setValue(false);
		// 			} else {
		// 				// setValue(true);
		// 			}
		//
		// 			setIsShowModal(false);
		// 		};
		//
		// 		return (
		// 			<Flex direction="row" pl={'20px'}>
		// 				<Switch
		// 					isChecked={value.termsOfService.isAgree}
		// 					id={id + ''}
		// 					colorScheme="brand"
		// 					size="md"
		// 					onChange={() => {
		// 						if (isShowModal) {
		// 							return;
		// 						}
		//
		// 						setIsShowModal(true);
		// 					}}
		// 				/>
		// 				<ModalApplyListIdol
		// 					isOpen={isShowModal}
		// 					onClose={() => {
		// 						setIsShowModal(false);
		// 					}}
		// 					requestApplyListIdol={(id, code, reason) => {
		// 						requestIdolApplyList(id, code, reason);
		// 						setIsShowModal(false);
		// 					}}
		// 					// user={original}
		// 				/>
		// 			</Flex>
		// 		);
		// 	}
		// }),
		columnHelper.accessor('applyStatus', {
			id: 'applyStatus',
			header: () => (
				<Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
					{t('idol.apply.list.table_title_apply_status')}
				</Text>
			),
			cell: info => {
				const value = info.getValue();
				let color, displayText;

				switch (value) {
					case 'UNDER_REVIEW':
						color = 'blue.500';
						displayText = t('idol.apply.list.table_value_apply_status.under_review');
						break;
					case 'REJECTED':
						color = 'red.500';
						displayText = t('idol.apply.list.table_value_apply_status.rejected');
						break;
					case 'APPROVED':
						color = 'green.500';
						displayText = t('idol.apply.list.table_value_apply_status.approved');
						break;
					default:
						color = textColor;
						displayText = value;
						break;
				}

				return (
					<Text cursor="pointer" color={color} fontSize="md" fontWeight="500">
						{displayText}
					</Text>
				);
			}
		}),
		columnHelper.accessor('applyDateTime', {
			id: 'applyDateTime',
			header: () => (
				<Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
					{t('idol.apply.list.table_title_apply_date_time')}
				</Text>
			),
			cell: info => (
				<Text cursor="pointer" color={textColor} fontSize="md" fontWeight="500">
					{dateFormat(info.getValue(), DATA_FORMAT_SHORT_DATE)}
				</Text>
			)
		})
		// columnHelper.accessor('reviewResult', {
		// 	id: 'reviewResult',
		// 	header: () => (
		// 		<Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
		// 			{t('idol.list.table_title_review_result')}
		// 		</Text>
		// 	),
		// 	cell: info => {
		// 		const value = info.getValue();
		// 		let color, displayText;

		// 		if (value) {
		// 			color = 'blue.500';
		// 			// displayText = t('idol.list.table_value_apply_status.under_review');
		// 			displayText = "내용있음";
		// 		} else {
		// 			color = textColor;
		// 			displayText = "내용없음";
		// 		}

		// 		return (
		// 			<Text cursor="pointer" color={color} fontSize="md" fontWeight="500">
		// 				{displayText}
		// 			</Text>
		// 		);
		// 	}
		// }),
		// columnHelper.accessor('reviewDateTime', {
		// 	id: 'reviewDateTime',
		// 	header: () => (
		// 		<Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
		// 			{t('idol.list.table_title_review_date_time')}
		// 		</Text>
		// 	),
		// 	cell: info => (
		// 		<Text cursor="pointer" color={textColor} fontSize="md" fontWeight="500">
		// 			{dateFormat(info.getValue(), DATA_FORMAT_FULL_DATE)}
		// 		</Text>
		// 	)
		// })
	];
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		debugTable: true,
		debugHeaders: true,
		debugColumns: false
	});

	//  최초 데이터 불러오기 : 6개월치 회원 데이터
	useEffect(() => {
		let startDate = new Date();
		onStartDateChange(new Date(startDate.setMonth(startDate.getMonth() - 10)));
		onEndDateChange(new Date());

		setSearchParams(prevState => {
			return {
				...prevState,
				searchWord: '',
				registerDateStart: dateFormat(startDate, DATA_FORMAT_FULL_DATE),
				registerDateEnd: dateFormat(endDate, DATA_FORMAT_FULL_DATE)
			};
		});
		setCurrentPageIndex(0);
	}, []);

	// 데이터 로딩, 인덱스, 검색 파라미터가 변경될 때마다 데이터 갱신
	useEffect(() => {
		if (currentPageIndex !== -1 && searchParams !== null) {
			loadData(currentPageIndex);
		}
	}, [currentPageIndex, searchParams]);

	// 검색 타입이 변경된 경우
	// useEffect(() => {
	// 	if (currentSearchType) {
	// 		setCurrentPageIndex(0);
	// 		setSearchParams(prevState => {
	// 			return {
	// 				...prevState,
	// 				searchType: currentSearchType === MemberSearchType.ALL ? null : currentSearchType
	// 			};
	// 		});
	// 	}
	// }, [currentSearchType]);

	// 필터 유저 상태
	useEffect(() => {
		if (filterIdolApplyStatus) {
			setCurrentPageIndex(0);
			setSearchParams(prevState => {
				return {
					...prevState,
					applyStatus: filterIdolApplyStatus[0] as IdolApplyStatus
				};
			});
		}
	}, [filterIdolApplyStatus]);

	// // 필터 로그인 타입
	// useEffect(() => {
	// 	if (filterUserLoginType) {
	// 		setCurrentPageIndex(0);
	// 		setSearchParams(prevState => {
	// 			return {
	// 				...prevState,
	// 				loginType: filterUserLoginType[0] as MemberSocialLoginType
	// 			};
	// 		});
	// 	}
	// }, [filterUserLoginType]);
	//
	// 필터 유저 타입
	// useEffect(() => {
	// 	if (filterUserType) {
	// 		setCurrentPageIndex(0);
	// 		setSearchParams(prevState => {
	// 			return {
	// 				...prevState,
	// 				memberType: filterUserType[0] as MemberType
	// 			};
	// 		});
	// 	}
	// }, [filterUserType]);
	//
	// 가입 날짜 - 시작
	useEffect(() => {
		if (startDate) {
			setCurrentPageIndex(0);
			setSearchParams(prevState => {
				return {
					...prevState,
					registerDateStart: dateFormat(startDate, DATA_FORMAT_FULL_DATE)
				};
			});
		}
	}, [startDate]);

	// 가입 날짜 - 종료
	useEffect(() => {
		if (endDate) {
			setCurrentPageIndex(0);
			setSearchParams(prevState => {
				return {
					...prevState,
					registerDateEnd: dateFormat(endDate, DATA_FORMAT_FULL_DATE)
				};
			});
		}
	}, [endDate]);

	// 데이타 셋팅
	useEffect(() => {
		if (tableData) {
			setData(tableData);
		}
	}, [tableData]);

	const loadData = (index: number) => {
		requestIdolApplySearch(index * ROW_COUNT, searchParams);
	};

	// 이전 페이지 데이터 로딩
	const loadPreviousData = () => {
		if (currentPageIndex > 0) {
			setCurrentPageIndex(currentPageIndex - 1);
		}
	};

	// 다음 페이지 데이터 로딩
	const loadNextData = () => {
		if (currentPageIndex * CardPageSize * ROW_COUNT < tempData.length) {
			setCurrentPageIndex(currentPageIndex + 1);
		}
	};

	// 정렬 필터 변경
	const filterChange = (type: string) => {
		setCurrentSortFilter(type);
	};

	// 검색 엔터키 처리
	const handleSearchKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleClickSearch();
		}
	};

	// 검색 버튼 클릭
	const handleClickSearch = () => {
		if (searchString.trim().length < 2 && searchString.trim() !== '') {
			showToast(t('common.alert_search_word_length'), 'error');
			return;
		}

		setSearchParams(prevState => {
			return {
				...prevState,
				searchWord: searchString
			};
		});
	};

	// 검색어 저장
	const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchString(e.target.value);
	};

	return (
		<Flex direction="column" w="100%" overflowX={{ sm: 'scroll', lg: 'scroll' }}>
			<Flex w="100%" direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'} mb="10px">
				{/* <SearchInput
					onChangeSearch={onSearchInputChange}
					onEnterKeyPress={handleSearchKeyPress}
					leftElement={
						<Select
							fontSize="sm"
							id="edit_product"
							variant="unstyled"
							h={'44px'}
							w={{ base: '100px', md: '80px', xl: '120px' }}
							onChange={e => setCurrentSearchType(e.target.value as IdolApplySearchType)}
						>
							<option value={IdolApplySearchType.ALL}>{t('components.all')}</option>
							<option value={IdolApplySearchType.ACTIVITY_NAME}>{t('components.list_search_type_idol_real_name')}</option>
							<option value={IdolApplySearchType.PID}>{t('components.list_search_type_idol_pid')}</option>
							<option value={IdolApplySearchType.REGISTER_DATE}>{t('components.list_search_type_reg_date')}</option>
							<option value={IdolApplySearchType.MOBILE}>{t('components.list_search_type_mobile')}</option>
							<option value={IdolApplySearchType.EMAIL}>{t('components.list_search_type_email')}</option>
						</Select>
					}
					rightElement={
						<Button h="1.75rem" size="sm" onClick={handleClickSearch} borderRadius="20px">
							<Icon color={textColor} as={MdOutlineSearch} />
						</Button>
					}
				/> */}
				{/* <Flex justifyContent={'flex-end'} mr={{ base: '0px', sm: '10px' }}>
					<Popover
						isOpen={isShowStartDate}
						onOpen={setIsShowStartDate.on}
						onClose={setIsShowStartDate.off}
						closeOnBlur={false}
						isLazy
						lazyBehavior="keepMounted"
					>
						<PopoverTrigger>
							<Button w="180px" colorScheme="purple">
								{dateFormat(startDate)}
							</Button>
						</PopoverTrigger>
						<PopoverContent bg={'transparent'} border={'0px'}>
							<MiniCalendar
								selectRange={false}
								mb="20px"
								onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
									if (endDate) {
										const timeStart = moment(value);
										const timeEnd = moment(endDate);
										const diff = timeEnd.diff(timeStart);
										if (diff < 0) {
											alert(t('user.list.filter_user_register_start_date_error'));
											return;
										}

										onStartDateChange(value);
										setIsShowStartDate.off();
									}
								}}
								value={startDate}
							/>
						</PopoverContent>
					</Popover>
					<Text px={'20px'} textAlign={'center'} mt={'10px'}>
						{'  ~  '}
					</Text>
					<Popover
						isOpen={isShowEndDate}
						onOpen={setIsShowEndDate.on}
						onClose={setIsShowEndDate.off}
						closeOnBlur={false}
						isLazy
						lazyBehavior="keepMounted"
					>
						<PopoverTrigger>
							<Button w="180px" colorScheme="purple">
								{dateFormat(endDate)}
							</Button>
						</PopoverTrigger>
						<PopoverContent bg={'transparent'} border={'0px'}>
							<MiniCalendar
								selectRange={false}
								mb="20px"
								onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
									if (startDate) {
										const timeStart = moment(startDate);
										const timeEnd = moment(value);
										const diff = timeEnd.diff(timeStart);
										if (diff < 0) {
											alert(t('user.list.filter_user_register_end_date_error'));
											return;
										}

										onEndDateChange(value);
										setIsShowEndDate.off();
									}
								}}
								value={endDate}
							/>
						</PopoverContent>
					</Popover>
					<Tooltip label={t('components.filter_clear')}>
						<Button
							mt={'3px'}
							me={{ base: '10px', md: '20px' }}
							ml={{ base: '10px', sm: '10px', md: '10px' }}
							bg={buttonBg}
							border="1px solid"
							color="secondaryGray.600"
							borderColor={useColorModeValue('secondaryGray.100', 'whiteAlpha.100')}
							borderRadius="16px"
							_placeholder={{ color: 'secondaryGray.600' }}
							_hover={hoverButton}
							_active={activeButton}
							_focus={activeButton}
							onClick={() => {
								setFilterIdolApplyStatus([]);
								// setFilterUserLoginType([]);
								// setFilterUserType([]);
								// setFilterUserIapYn([]);
							}}
						>
							<Icon color={textColor} as={MdRestore} />
						</Button>
					</Tooltip>
				</Flex> */}
			</Flex>
			<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px" height={'max-content'} px={'20px'}>
				<Card flexDir={'row'} alignItems={'center'}>
					<Text fontSize="sm" textColor={textColor} mr={'20px'}>
						{t('idol.apply.list.search_apply_status')}
					</Text>
					<RadioGroup
						colorScheme="purple"
						// value={filterIdolApplyStatus}
						onChange={e => {
							setFilterIdolApplyStatus([e]);
						}}
						defaultValue=""
					>
						<HStack spacing="24px">
							<Radio value="">{t('idol.apply.list.filter_apply_status.all')}</Radio>
							<Radio value={IdolApplyStatus.UNDER_REVIEW}>
								{t('idol.apply.list.filter_apply_status.under_review')}
							</Radio>
							<Radio value={IdolApplyStatus.APPROVED}>{t('idol.apply.list.filter_apply_status.approved')}</Radio>
							<Radio value={IdolApplyStatus.REJECTED}>{t('idol.apply.list.filter_apply_status.rejected')}</Radio>
						</HStack>
					</RadioGroup>
					{/* <CheckboxGroup
						colorScheme="purple"
						value={filterIdolApplyStatus}
						onChange={e => {
							setFilterIdolApplyStatus(e);
						}}
					>
						<Stack spacing={[1, 5]} direction={['column', 'row']}>
							<Checkbox value={IdolApplyStatus.UNDER_REVIEW} >{t('idol.apply.list.filter_apply_status.under_review')}</Checkbox>
							<Checkbox value={IdolApplyStatus.APPROVED}>{t('idol.apply.list.filter_apply_status.approved')}</Checkbox>
							<Checkbox value={IdolApplyStatus.REJECTED}>{t('idol.apply.list.filter_apply_status.rejected')}</Checkbox>
						</Stack>
					</CheckboxGroup> */}
				</Card>
				{/*<Card flexDir={'row'} alignItems={'center'}>*/}
				{/*	<Text fontSize="sm" textColor={textColor} mr={'20px'}>*/}
				{/*		{t('user.list.filter_user_type')}*/}
				{/*	</Text>*/}
				{/*	<CheckboxGroup*/}
				{/*		colorScheme="purple"*/}
				{/*		value={filterUserType}*/}
				{/*		onChange={e => {*/}
				{/*			setFilterUserType(e);*/}
				{/*		}}*/}
				{/*	>*/}
				{/*		<Stack spacing={[1, 5]} direction={['column', 'row']}>*/}
				{/*			<Checkbox value={MemberType.IDOL}>{t('user.list.filter_user_type_idol')}</Checkbox>*/}
				{/*			<Checkbox value={MemberType.PRODUCER}>{t('user.list.filter_user_type_producer')}</Checkbox>*/}
				{/*		</Stack>*/}
				{/*	</CheckboxGroup>*/}
				{/*</Card>*/}
				{/*<Card flexDir={'row'} alignItems={'center'}>*/}
				{/*	<Text fontSize="sm" textColor={textColor} mr={'20px'}>*/}
				{/*		{t('user.list.filter_user_login_type')}*/}
				{/*	</Text>*/}
				{/*	<CheckboxGroup*/}
				{/*		colorScheme="purple"*/}
				{/*		value={filterUserLoginType}*/}
				{/*		onChange={e => {*/}
				{/*			setFilterUserLoginType(e);*/}
				{/*		}}*/}
				{/*	>*/}
				{/*		<Stack spacing={[1, 5]} direction={['column', 'row']}>*/}
				{/*			<Checkbox value={MemberSocialLoginType.APPLE}>{t('user.list.filter_user_login_type_A')}</Checkbox>*/}
				{/*			<Checkbox value={MemberSocialLoginType.GOOGLE}>{t('user.list.filter_user_login_type_G')}</Checkbox>*/}
				{/*			<Checkbox value={MemberSocialLoginType.LINE}>{t('user.list.filter_user_login_type_L')}</Checkbox>*/}
				{/*			<Checkbox value={MemberSocialLoginType.TWITTER}>{t('user.list.filter_user_login_type_X')}</Checkbox>*/}
				{/*		</Stack>*/}
				{/*	</CheckboxGroup>*/}
				{/*</Card>*/}
				{/*<Card flexDir={'row'} alignItems={'center'}>*/}
				{/*	<Text fontSize="sm" textColor={textColor} mr={'20px'}>*/}
				{/*		{t('user.list.filter_user_iap_yn')}*/}
				{/*	</Text>*/}
				{/*	<CheckboxGroup*/}
				{/*		colorScheme="purple"*/}
				{/*		value={filterUserIapYn}*/}
				{/*		onChange={e => {*/}
				{/*			setFilterUserIapYn(e);*/}
				{/*		}}*/}
				{/*	>*/}
				{/*		<Stack spacing={[1, 5]} direction={['column', 'row']}>*/}
				{/*			<Checkbox value="Y">{t('user.list.filter_user_iap_y')}</Checkbox>*/}
				{/*			<Checkbox value="N">{t('user.list.filter_user_iap_n')}</Checkbox>*/}
				{/*		</Stack>*/}
				{/*	</CheckboxGroup>*/}
				{/*</Card>*/}
			</SimpleGrid>
			<Table variant="simple" color="gray.500" mb="24px">
				<Thead>
					{table.getHeaderGroups().map(headerGroup => (
						<Tr key={headerGroup.id}>
							{headerGroup.headers.map(header => {
								return (
									<Th pe="10px" borderColor={borderColor} key={header.id} colSpan={header.colSpan}>
										{header.isPlaceholder ? null : (
											<Flex
												{...{
													className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
													onClick: header.column.getToggleSortingHandler()
												}}
												justify="space-between"
												align="center"
												fontSize={{ sm: '10px', lg: '12px' }}
												color="gray.400"
											>
												{flexRender(header.column.columnDef.header, header.getContext())}
												{{
													asc: '',
													desc: ''
												}[header.column.getIsSorted() as string] ?? null}
											</Flex>
										)}
									</Th>
								);
							})}
						</Tr>
					))}
				</Thead>
				<Tbody>
					{table.getRowModel().rows.map(row => {
						return (
							<Tr px="20px" key={row.id}>
								{row.getVisibleCells().map(cell => {
									return (
										<Td
											key={cell.id}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor={borderColor}
										>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</Td>
									);
								})}
							</Tr>
						);
					})}
				</Tbody>
			</Table>
			<Pagination
				currentIndex={currentPageIndex}
				setCurrentIndex={setCurrentPageIndex}
				pageSize={CardPageSize * ROW_COUNT}
				totalLength={totalCount}
				loadPrevious={loadPreviousData}
				loadNext={loadNextData}
				loadPage={(index: number) => {
					setCurrentPageIndex(index);
					loadData(index);
				}}
			/>
			<Modal
				isOpen={isShowDetail}
				onClose={() => {
					loadData(currentPageIndex);
					setIsShowDetail(false);
				}}
				isCentered
				size={'xg'}
			>
				<ModalOverlay />

				<ModalContent>
					<ModalHeader>{t('idol.apply.detail.title')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody overflow="auto">
						<IdolApplyDetail idolApplyId={selectIdolApplyId} />
					</ModalBody>
					<ModalFooter>
						<Button
							onClick={() => {
								loadData(currentPageIndex);
								setIsShowDetail(false);
							}}
						>
							{t('common.close')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	);
}
