// Chakra imports
import { Box, Flex, FormLabel, Input, Tag, TagCloseButton, TagLabel, useColorModeValue } from "@chakra-ui/react";
// Custom components

export type ROW_TAGS_OBJ = {
	id: number;
	name: string;
};

function TagsField(props: { label?: string; id?: string; tags: ROW_TAGS_OBJ[]; setTags: any; [x: string]: any }) {
	const { label, id, tags, setTags, ...rest } = props;

	const keyPress = (e: any) => {
		if (e.keyCode === 13) {
			setTags([
				...tags,
				{
					name: e.target.value,
					id: tags.length === 0 ? 1 : tags[tags.length - 1].id + 1
				}
			]);
			e.target.value = '';
		}
	};

	let borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
	let bg = useColorModeValue('brand.500', 'brand.400');
	return (
		<Box>
			<FormLabel htmlFor={id} fontWeight="400" fontSize="sm" mb="8px">
				{label}
			</FormLabel>
			<Flex
				direction="row"
				p="12px"
				wrap="wrap"
				bg="transparent"
				border="1px solid"
				borderColor={borderColor}
				borderRadius="6px"
				_focus={{ borderColor: 'teal.300' }}
				minH="40px"
				h="stretch"
				cursor="text"
				overflow={'scroll'}
				{...rest}
			>
				{tags &&
					tags.map((tag, index) => {
						return (
							<Tag fontSize="xs" h="25px" mb="6px" me="6px" borderRadius="12px" variant="solid" bg={bg} key={index}>
								<TagLabel w="100%">{tag.name}</TagLabel>
								<TagCloseButton
									justifySelf="flex-end"
									color="white"
									onClick={() => setTags([...tags.filter(element => element.id !== tag.id)])}
								/>
							</Tag>
						);
					})}
				<Input variant="main" bg="transparent" border="none" p="0px" onKeyDown={e => keyPress(e)} fontSize="sm" />
			</Flex>
		</Box>
	);
}

export default TagsField;
