// Chakra imports
import {
	Button,
	Flex,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Select,
	Tooltip,
	useColorModeValue
} from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Card from 'components/card/Card';
import SearchInput from '../../../../../components/actions/Search';
import { MdDeleteOutline, MdOutlineSearch, MdRestore, MdRestoreFromTrash } from "react-icons/md";
import Filter from '../../../../../components/actions/Filter';
import { TYPE_KEY_SORT } from '../../../../../helper/types';
import { PeroidSearchCondition, TableRowType } from '../../../../../types/enums';
import SearchTable from '../../../../../components/table/SearchTable';
import VODDetail from '../detail';
import { useTranslation } from 'react-i18next';
import { useThemeColors } from '../../../../../providers/theme-provider';
import { useApi } from '../../../../../providers/api-provider';
import { VodListRequest, VodListResponse, VodTrain } from '../../../../../apis/contentsAPI';
import { createDynamicColumns, VisibleColumns } from '../../../../../components/table/createDynamicColumns';

// Live 콘텐츠 목록
export default function DeleteVODs() {
	const { t } = useTranslation();
	const { textColor, borderColor, buttonBg, hoverButton, activeButton } = useThemeColors();
	const { contentsApi } = useApi();

	/**
	 *  목록
	 */
	const [list, setList] = useState([]);
	const [totalCount, setTotalCount] = useState(0);

	// 목록 함수
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState<number>(0);

	// 검색 조건
	const [searchParams, setSearchParams] = useState<Partial<VodListRequest>>({
		periodSearchCondition: PeroidSearchCondition.ALL,
		isDeleted: true
	});

	// 검색어
	const [searchString, setSearchString] = useState('');
	// 검색 기간 타입
	const [peroidSearchCondition, setPeroidSearchCondition] = useState(PeroidSearchCondition.ALL);

	// 현재 정렬 필터
	const [currentSortFilter, setCurrentSortFilter] = useState(TYPE_KEY_SORT.LATEST);

	/**
	 * 상세 팝업
	 */
	const [isShowDetail, setIsShowDetail] = useState(false);
	const [selectVodId, setSelectVodId] = useState(null);

	useEffect(() => {
		setSearchParams(prevState => {
			return {
				...prevState,
				periodSearchCondition: peroidSearchCondition,
				isDeleted: true
			};
		});
	}, [peroidSearchCondition]);

	// 검색 조건 변경시 목록 불러오기
	useEffect(() => {
		requestVodList(0);
	}, [searchParams]);

	const requestVodList = async (offset: number) => {
		setOffset(offset);
		contentsApi
			.getVodList({
				limit: limit,
				offset: offset * limit,
				...searchParams
			})
			.then(responseVodList)
			.catch(() => {});
	};

	const responseVodList = (response: VodListResponse) => {
		if (response.vodTrain) {
			setList(response.vodTrain);
		}

		if (response.count) {
			setTotalCount(response.count);
		}
	};

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<VodTrain>[] = [
		{
			row: 'vodId',
			type: TableRowType.TEXT
		},
		{ row: 'idolActivityName', type: TableRowType.TEXT },
		{ row: 'idolProfileImgUrl', type: TableRowType.IMAGE },
		{ row: 'title', type: TableRowType.TEXT },
		{ row: 'thumbnailImgUrl', type: TableRowType.IMAGE },
		{ row: 'createDateTime', type: TableRowType.DATETIME },
		{
			row: 'isDeleted',
			type: TableRowType.BUTTON,
			icon: MdRestore,
			action: rowData => {}
		}
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)
		setSelectVodId(rowData.vodId);
		setIsShowDetail(true);
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	// 정렬 필터 변경
	const filterChange = (type: string) => {
		setCurrentSortFilter(type);
	};

	// 검색 엔터키 처리
	const handleSearchKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleClickSearch();
		}
	};

	const handleClickSearch = () => {
		// if (searchString.trim().length < 2 && searchString.trim() !== '') {
		// 	showToast(t('common.alert_search_word_length'), 'error');
		// 	return;
		// }
		//
		// loadData(currentPageIndex, searchString);
	};

	// 검색어 저장
	const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchString(e.target.value);
	};

	return (
		<Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
			<Card px="0px">
				<Flex w="100%" direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'} mb="36px">
					<SearchInput
						onChangeSearch={onSearchInputChange}
						onEnterKeyPress={handleSearchKeyPress}
						rightElement={
							<Button h="1.75rem" size="sm" onClick={handleClickSearch} borderRadius="20px">
								<Icon color={textColor} as={MdOutlineSearch} />
							</Button>
						}
					/>
					<Flex justifyContent={'flex-end'} mr={{ base: '0px', sm: '10px' }}>
						<Filter
							onChange={filterChange}
							initialSortType={TYPE_KEY_SORT.LATEST}
							secondaryTitle={'components.filter_alphabet'}
						/>
						<Select
							fontSize="sm"
							id="edit_product"
							variant="outline"
							h={'40px'}
							borderRadius={'16px'}
							w={{ base: '100px', md: '80px', xl: '120px' }}
							mr={{ base: '30px', sm: '30px', md: '30px' }}
							onChange={e => setPeroidSearchCondition(e.target.value as PeroidSearchCondition)}
						>
							<option value={PeroidSearchCondition.ALL}>{t('components.list_filter_duration_all')}</option>
							<option value={PeroidSearchCondition.WITHIN_7_DAY}>{t('components.list_filter_duration_last_7d')}</option>
							<option value={PeroidSearchCondition.WITHIN_14_DAY}>
								{t('components.list_filter_duration_last_14d')}
							</option>
							<option value={PeroidSearchCondition.WITHIN_30_DAY}>
								{t('components.list_filter_duration_last_30d')}
							</option>
							<option value={PeroidSearchCondition.WITHIN_90_DAY}>
								{t('components.list_filter_duration_last_90d')}
							</option>
						</Select>
						{/*<Tooltip label={t('content.vod.delete_list')}>*/}
						{/*	<Button*/}
						{/*		me={{ base: '10px', md: '20px' }}*/}
						{/*		ml={{ base: '10px', sm: '10px', md: '10px' }}*/}
						{/*		bg={buttonBg}*/}
						{/*		border="1px solid"*/}
						{/*		color="secondaryGray.600"*/}
						{/*		borderColor={useColorModeValue('secondaryGray.100', 'whiteAlpha.100')}*/}
						{/*		borderRadius="16px"*/}
						{/*		_placeholder={{ color: 'secondaryGray.600' }}*/}
						{/*		_hover={hoverButton}*/}
						{/*		_active={activeButton}*/}
						{/*		_focus={activeButton}*/}
						{/*		onClick={() => {*/}
						{/*			window.location.href = '/admin/contents/vod-delete';*/}
						{/*		}}*/}
						{/*	>*/}
						{/*		<Icon color={textColor} as={MdDeleteOutline} />*/}
						{/*	</Button>*/}
						{/*</Tooltip>*/}
					</Flex>
				</Flex>
				<SearchTable request={requestVodList} data={list} columns={columns} totalCount={totalCount} />
			</Card>

			<Modal
				isOpen={isShowDetail}
				onClose={() => {
					setIsShowDetail(false);
				}}
				isCentered
				size={'full'}
			>
				<ModalOverlay />

				<ModalContent>
					<ModalHeader></ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<VODDetail vodId={selectVodId} />
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	);
}
