import { Box, Button, Flex, SimpleGrid } from "@chakra-ui/react";
import React, { useState } from "react";

import { PreContractIdolImages, PreContractIdolInformation } from "../components/ModalPreContractRegister";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../../providers/api-provider";
import { debugLog, showToast } from "../../../../helper/functions";
import { BlobWriter, ZipWriter } from "@zip.js/zip.js";

/**
 * 사전 계약 아이돌 등록
 * @constructor
 */
export default function PreContractIdolRegister() {
	const { t } = useTranslation();
	const { idolApi } = useApi();

	// 변수
	const [name, setName] = useState<string>('');
	const [mobileNo, setMobileNo] = useState<string>('');
	const [countryCode, setCountryCode] = useState<string>('81');
	const [memo, setMemo] = useState<string>('');

	const [password, setPassword] = useState<string>('');

	// 이미지 파일
	const [imagesPreview, setImagesPreview] = useState<File[]>([]);

	/**
	 * 아이돌 등록
	 */
	const registerPreContractIdol = async () => {
		// 입력값 확인
		if (name.length === 0 || !name) {
			showToast(t('error.input_name'));
			return;
		}

		if (mobileNo.length === 0 || !mobileNo) {
			showToast(t('error.input_mobile'));
			return;
		}

		if (countryCode.length === 0 || !countryCode) {
			showToast(t('error.country_code'));
			return;
		}

		if (imagesPreview.length === 0 || !password) {
			showToast('error.input_file_password');
			return;
		}

		// 요청 데이터
		const requestData = {
			name: name,
			memo: memo,
			countryCode: countryCode,
			mobile: mobileNo,
			idCardPassword: password
		};

		try {
			const formData = new FormData();
			formData.append('request', JSON.stringify(requestData));

			// 압축 파일 생성
			let index = 1;
			for (const file of imagesPreview) {
				// Create a BlobWriter to write the zip file
				const blobWriter = new BlobWriter('application/zip');
				const zipWriter = new ZipWriter(blobWriter, {
					password
				});

				const stream = file.stream();
				await zipWriter.add(file.name, stream, {
					password
				});

				// Finalize the ZIP archive
				await zipWriter.close();

				// Get the resulting ZIP as a Blob
				const zipBlob = await blobWriter.getData();
				formData.append('idCardZipUrl' + index, zipBlob, 'compressed_files.zip');
				index = index + 1;
			}

			// 완료 후 목록 페이지로 이동
			idolApi
				.postIdolPreContract(formData)
				.then(() => {
					window.location.href = '/admin/idol/pre_contract/list';
				})
				.catch(() => {
					showToast(t('error.try_again'));
				});
		} catch (error) {
			debugLog('Error during compression or upload:', error);
			showToast(t('error.try_again'));
		}
	};

	return (
		<Box
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '130px', md: '80px', xl: '80px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<SimpleGrid mb="20px" columns={{ sm: 1, lg: 2 }} spacing={{ base: '20px', xl: '20px' }}>
				{/* Column Left */}
				<Flex direction="column">
					<PreContractIdolInformation
						name={name}
						setName={setName}
						mobileNo={mobileNo}
						setMobileNo={setMobileNo}
						countryCode={countryCode}
						setCountryCode={setCountryCode}
						memo={memo}
						setMemo={setMemo}
					/>
					{/* <Dropzone /> */}
				</Flex>
				{/* Column Right */}
				<Flex direction="column">
					<PreContractIdolImages
						imgFiles={imagesPreview}
						setImgFiles={setImagesPreview}
						password={password}
						setPassword={setPassword}
					/>
				</Flex>
			</SimpleGrid>
			<Button
				variant="brand"
				minW="183px"
				fontSize="sm"
				fontWeight="500"
				ms="auto"
				onClick={() => {
					registerPreContractIdol();
				}}
			>
				{t('common.save')}
			</Button>
		</Box>
	);
}
