// Chakra imports
import { Flex, Grid, SimpleGrid } from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import TextField from 'components/fields/TextField';
import InputField from 'components/fields/InputField';
import { useThemeColors } from '../../../../providers/theme-provider';
import { useApi } from '../../../../providers/api-provider';
import Card from 'components/card/Card';
import { showToast } from '../../../../helper/functions';
import { RewardsTrain } from '../../../../apis/projectAPI';
import SelectField from 'components/fields/SelectField';
import { CharacterType, CurrencyType, ProductType, RewardType, SkillType } from '../../../../types/enums';
import { CharacterTypeString, CurrencyTypeString, RewardTypeString, SkillTypeString } from '../../../../helper/types';

// 금지어 목록
export default function RewardsDetail(props: { detailId: string; requestChangeParams: () => void }) {
	const { t } = useTranslation();
	const {} = useThemeColors();
	const { projectApi } = useApi();

	const { detailId, requestChangeParams } = props;

	const [detail, setDetail] = useState<RewardsTrain>();

	useEffect(() => {
		if (detailId !== '-1') {
			requestDetail();
		}
	}, [detailId]);

	/**
	 * {
	 *   "rewardId": 0,
	 *   "name": "string",
	 *   "iconImageUrl": "string",
	 *   "type": "SKILL",
	 *   "character": {
	 *     "type": "CUTE",
	 *     "point": 0
	 *   },
	 *   "skill": {
	 *     "type": "MUSIC",
	 *     "point": 0
	 *   },
	 *   "currency": {
	 *     "currencyType": "HYPY_POINT",
	 *     "price": 0
	 *   },
	 *   "product": {
	 *     "productType": "CONSUMABLE",
	 *     "productId": 0
	 *   }
	 * }
	 * @param id
	 */

	const requestDetail = () => {
		projectApi
			.getRewardsDetail(detailId)
			.then(responseDetail)
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	const responseDetail = (response: any) => {
		if (response.detail) {
			setDetail(response.detail);
		}
	};

	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '0px', md: '0px', xl: '0px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Flex direction={'column'}>
				<Grid
					display={{ base: 'block', md: 'grid' }}
					gridTemplateColumns={{ base: '1.5fr 1.5fr', lg: '1.5fr 1.5fr' }}
					gap={{ base: '20px', xl: '20px' }}
				>
					<Card>
						<SimpleGrid>
							<TextField label={t('common.id')} value={detail?.rewardId ? detail?.rewardId : '-'} />
							<InputField
								label={t('common.name')}
								value={detail?.name}
								onChange={e => {
									setDetail(prevState => ({
										...prevState,
										name: e.target.value
									}));
								}}
							/>
							<SelectField
								label={t('project.reward_skill')}
								options={Object.values(RewardType)}
								value={detail?.type}
								displayOptionsString={RewardTypeString}
								onChange={e => {
									setDetail(prevState => ({
										...prevState,
										type: e.target.value as RewardType
									}));
								}}
							/>
							{detail?.type === RewardType.SKILL ? (
								<>
									<SelectField
										label={t('project.reward_skill')}
										options={Object.values(SkillType)}
										value={detail?.skill?.type}
										displayOptionsString={SkillTypeString}
										onChange={e => {
											setDetail(prevState => ({
												...prevState,
												skill: {
													type: e.target.value as SkillType,
													point: prevState?.skill?.point
												}
											}));
										}}
									/>
									<InputField
										label={'스킬 타입'}
										value={detail?.skill?.point}
										type={'number'}
										onChange={e => {
											setDetail(prevState => ({
												...prevState,
												skill: {
													type: prevState?.skill?.type,
													point: Number(e.target.value)
												}
											}));
										}}
									/>
									{/*<InputField label={t('획득 스킬 포인트')} value={detail?.skill.point} type={'number'} />*/}
								</>
							) : null}

							{detail?.type === RewardType.CHARACTER ? (
								<>
									<SelectField
										label={'캐릭터 타입'}
										options={Object.values(CharacterType)}
										value={detail?.character?.type}
										displayOptionsString={CharacterTypeString}
										onChange={e => {
											setDetail(prevState => ({
												...prevState,
												character: {
													type: e.target.value as CharacterType,
													point: prevState?.character?.point
												}
											}));
										}}
									/>
									<InputField
										label={'캐릭터 타입 포인트'}
										value={detail?.character?.point}
										type={'number'}
										onChange={e => {
											setDetail(prevState => ({
												...prevState,
												character: {
													type: prevState?.character?.type,
													point: Number(e.target.value)
												}
											}));
										}}
									/>
								</>
							) : null}
						</SimpleGrid>
					</Card>
					<Card>
						<SimpleGrid>

							<>

								<SelectField
									label={'유료 포인트'}
									options={Object.values(CurrencyType)}
									value={detail?.currency?.currencyType ? detail?.currency?.currencyType : CurrencyType.NONE}
									displayOptionsString={CurrencyTypeString}
									onChange={e => {
										setDetail(prevState => ({
											...prevState,
											currency: {
												currencyType: e.target.value as CurrencyType,
												price: prevState?.currency?.price
											}
										}));
									}}
								/>
								<InputField
									label={'캐릭터 타입 포인트'}
									value={detail?.currency?.price}
									type={'number'}
									onChange={e => {
										setDetail(prevState => ({
											...prevState,
											currency: {
												currencyType: prevState.currency.currencyType,
												price: Number(e.target.value)
											}
										}));
									}}
								/>
								{/*<InputField label={t('common.name')} value={detail?.currency?.price} />*/}
							</>


							<>
								<SelectField
									label={'유료 아이템'}
									options={Object.values(ProductType)}
									value={detail?.product?.productType ? detail?.product?.productType : ProductType.NONE}
									onChange={e => {
										setDetail(prevState => ({
											...prevState,
											product: {
												productType: e.target.value as ProductType,
												productId: prevState?.product?.productId
											}
										}));
									}}
								/>
								<InputField label={t('common.name')} value={detail?.product?.productId} />
							</>
						</SimpleGrid>
					</Card>
				</Grid>
			</Flex>
		</Grid>
	);
}
