// Chakra imports
import {
	Button,
	Flex,
	Icon,
	Modal, ModalBody, ModalCloseButton,
	ModalContent, ModalFooter,
	ModalHeader,
	ModalOverlay,
	Tooltip,
	useColorModeValue
} from "@chakra-ui/react";

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Card from 'components/card/Card';

import { useTranslation } from 'react-i18next';
import { MdOutlineAdd, MdOutlineSearch } from 'react-icons/md';
import { createDynamicColumns, VisibleColumns } from 'components/table/createDynamicColumns';
import i18n from 'i18next';
import { useApi } from '../../../../providers/api-provider';
import { useThemeColors } from '../../../../providers/theme-provider';
import SearchInput from '../../../../components/actions/Search';
import Filter from '../../../../components/actions/Filter';
import { TYPE_KEY_SORT } from '../../../../helper/types';
import SearchTable from '../../../../components/table/SearchTable';
import { AppMetaListResponse, AppMetaTrain } from '../../../../apis/metaDataAPI';
import { TableRowType } from '../../../../types/enums';
import IdolApplyDetail from "../../idol/apply/detail";
import AppMetaDataDetail from "./detail";

// 금지어 목록
export default function AppMetaDatas() {
	const { metaDataApi } = useApi();

	const { t } = useTranslation();
	const { textColor, buttonBg, hoverButton, activeButton } = useThemeColors();

	// 목록
	const [list, setList] = useState<AppMetaTrain[]>([]);

	// 전체 갯수
	const [totalCount, setTotalCount] = useState<number>(0);
	// 한번에 불러올 목록
	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);

	const [selectAppMetaId, setSelectMetaId] = useState<string>("");
	const [isShowModal, setIsShowModal] = useState<boolean>(false);

	useEffect(() => {
		requestAppMetaList(offset);
	}, []);

	// 목록 요청
	const requestAppMetaList = async (offset: number) => {
		setOffset(offset);
		metaDataApi
			.getAppMetaList({
				limit: limit,
				offset: offset * limit
			})
			.then(responseAppMetaList)
			.catch(() => {});
	};

	// 목록 처리
	const responseAppMetaList = (response: AppMetaListResponse) => {
		if (response.appMetaTrain) {
			setList(response.appMetaTrain);
		}

		if (response.count) {
			setTotalCount(response.count);
		}
	};

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<AppMetaTrain>[] = [
		{
			row: 'appMetaId',
			title: 'id',
			type: TableRowType.TEXT
		},
		{
			row: 'osType',
			type: TableRowType.TEXT
		},
		{
			row: 'appVersion',
			type: TableRowType.TEXT
		},
		{
			row: 'isUnderReview',
			type: TableRowType.BOOLEAN,
			action: rowData => {
				// setSelectNotice(rowData);
				setSelectMetaId(String(rowData.appMetaId));
				setIsShowModal(true);
			}
		}
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)
		// window.location.href = '/admin/event/' + rowData.eventId;
		setSelectMetaId(String(rowData.appMetaId));
		setIsShowModal(true);
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	// 정렬 필터 변경
	const filterChange = (type: string) => {

	};

	// 검색 엔터키 처리
	const handleSearchKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleClickSearch();
		}
	};

	const handleClickSearch = () => {};

	// 검색어 저장
	const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// setSearchString(e.target.value);
	};

	const handleClickAdd = () => {
		setIsShowModal(true);
		setSelectMetaId("-1");
		//window.location.href = '/admin/notice/register';
	};

	const handleSwitchHide = () => {
		// setIsShowModal(false);
	};

	// Chakra Color Mode
	return (
		<Flex direction="column" pt={{ sm: '75px', lg: '75px' }}>
			<Card px="0px">
				<Flex w="100%" direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'} mb="36px">
					<SearchInput
						onChangeSearch={onSearchInputChange}
						onEnterKeyPress={handleSearchKeyPress}
						rightElement={
							<Button h="1.75rem" size="sm" onClick={handleClickSearch} borderRadius="20px">
								<Icon color={textColor} as={MdOutlineSearch} />
							</Button>
						}
					/>
					<Flex justifyContent={'flex-end'} mr={{ base: '0px', sm: '10px' }}>
						<Filter
							onChange={filterChange}
							initialSortType={TYPE_KEY_SORT.LATEST}
							secondaryTitle={'components.filter_alphabet'}
						/>
						<Tooltip label={t('settings.register_app_meta')}>
							<Button
								me={{ base: '10px', md: '20px' }}
								ml={{ base: '10px', sm: '10px', md: '10px' }}
								bg={buttonBg}
								border="1px solid"
								color="secondaryGray.600"
								borderColor={useColorModeValue('secondaryGray.100', 'whiteAlpha.100')}
								borderRadius="16px"
								_placeholder={{ color: 'secondaryGray.600' }}
								_hover={hoverButton}
								_active={activeButton}
								_focus={activeButton}
								onClick={handleClickAdd}
							>
								<Icon color={textColor} as={MdOutlineAdd} />
							</Button>
						</Tooltip>
					</Flex>
				</Flex>
				<SearchTable request={requestAppMetaList} data={list} columns={columns} totalCount={totalCount} />
			</Card>

			<Modal
				isOpen={isShowModal}
				onClose={() => {
					setIsShowModal(false);
				}}
				isCentered
				size={'md'}
			>
				<ModalOverlay />

				<ModalContent>
					<ModalHeader>{t('setting.app_meta_detail')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody overflow="auto">
						<AppMetaDataDetail appMetaId={selectAppMetaId} onRefresh={() => {
							requestAppMetaList(offset);
							setIsShowModal(false);
						}} />
					</ModalBody>
					<ModalFooter />
				</ModalContent>
			</Modal>
		</Flex>
	);
}
