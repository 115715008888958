// 관리자 메뉴 권한 타입
import {
	CharacterType,
	CurrencyType,
	MemberStatusType,
	MemberType, PayoutStatusConfirmType,
	PayoutStatusType,
	ProjectApplicantResultStatus,
	ReportContentsType,
	ReportStatusType,
	RewardType,
	SkillType
} from "../types/enums";

export const TYPE_ROUTE_AUTH = {
	IDOL: 'IDOL',
	USER: 'USER',
	CONTENT: 'CONTENT',
	MISSION: 'MISSION',
	REFUND: 'REFUND',
	PAID_ITEM: 'PAID_ITEM',
	EXCHANGE: 'EXCHANGE',
	SETTING: 'SETTING',
	ACCOUNT: 'ACCOUNT',
	NONE: 'NONE'
};

// 어드민 페이지 메뉴
export const TYPE_ROUTE_PERMISSION = {
	MANAGER: 'manager',
	ALL: 'all',
	READ: 'read',
	WRITE: 'write',
	DELETE: 'delete'
};

export const TYPE_ROUTE_SCREEN = {
	AUTH: 'AUTH',
	MAIN: 'MAIN',
	SUB: 'SUB',
	ETC: 'ETC'
};

// 콘텐츠 키

export const TYPE_KEY_SORT = {
	LATEST: 'latest',
	POPULAR: 'popular',
	ALPHABET: 'alphabet'
};

export const TYPE_KEY_SEARCH_LIVE = {
	ALL: 'all',
	USER_NAME: 'userName',
	USER_REAL_NAME: 'userRealName',
	TITLE: 'title',
	TAG: 'tag',
	CONTENT_ID: 'contentId'
};

export const TYPE_KEY_FILTER_DURATION = {
	ALL: 'all',
	LAST_7DAYS: '7',
	LAST_14DAYS: '14',
	LAST_30DAYS: '30',
	LAST_60DAYS: '60',
	LAST_90DAYS: '90'
};

/**
 * 회원 유형
 * @param t
 * @param key
 * @constructor
 */

export function MemberTypeString(t: any, key: string) {
	if (key === MemberType.IDOL) {
		return t('user.type_idol');
	} else {
		return t('user.type_pd');
	}
}

export function CurrencyTypeString(t: any, key: string) {
	if (key === CurrencyType.HYPY_POINT) {
		return 'HYPY POINT';
	} else if (key === CurrencyType.HYPY_GOLD) {
		return 'HYPY GOLD';
	} else if (key === CurrencyType.NONE) {
		return t('common.none');
	} else {
		return '';
	}
}

/**
 * 회원 상태
 * @param t
 * @param key
 * @constructor
 */
export function MemberStatusString(t: any, key: string) {
	if (key === MemberStatusType.NORMAL) return t('user.status_normal');
	else if (key === MemberStatusType.BLACK_LISTED) return t('user.status_blacklist');
	else if (key === MemberStatusType.WITHDRAW_SUBMITTED) return t('user.status_delete');
	else return '';
}

/**
 * 회원 로그인 타입
 * @param t
 * @param key
 * @constructor
 */
export function MemberLoginType(t: any, key: string) {
	if (key === 'APPLE') return t('user.login_type_apple');
	else if (key === 'GOOGLE') return t('user.login_type_google');
	else if (key === 'LINE') return t('user.login_type_line');
	else if (key === 'TWITTER') return t('user.login_type_X');
	else return '';
}

/**
 *  신고 콘텐츠 유형
 */
export function ReportContentTypeString(t: any, key: string) {
	if (key === ReportContentsType.ALL) {
		return t('report.contents_type_all');
	} else if (key === ReportContentsType.PROFILE_IDOL) {
		return t('report.contents_type_profile_idol');
	} else if (key === ReportContentsType.PROFILE_PRODUCER) {
		return t('report.contents_type_profile_producer');
	} else if (key === ReportContentsType.LIVE_STREAMING) {
		return t('report.contents_type_livestreaming');
	} else if (key === ReportContentsType.VOD) {
		return t('report.contents_type_vod');
	} else if (key === ReportContentsType.VOD_COMMENT) {
		return t('report.contents_type_vod_comment');
	} else if (key === ReportContentsType.LIVE_STREAMING_CHAT || key === ReportContentsType.LIVE_CHATTING) {
		return t('report.contents_type_livestreaming_chat');
	} else {
		return '';
	}
}

/**
 *  신고 상태
 */
export function ReportStatusString(t: any, key: string) {
	if (key === ReportStatusType.PENDING) {
		return t('report.status_pending');
	} else if (key === ReportStatusType.INVALID) {
		return t('report.status_invalid');
	} else if (key === ReportStatusType.REVERTED) {
		return t('report.status_reverted');
	} else if (key === ReportStatusType.VALID) {
		return t('report.status_valid');
	} else if (key === ReportStatusType.NONE) {
		return t('common.none');
	} else {
		return '';
	}
}

/**
 * 프로젝트 지원 결과 상태
 * @param t
 * @param key
 * @constructor
 */

export function ProjectApplicantResultStatusString(t: any, key: string) {
	if (key === ProjectApplicantResultStatus.UNQUALIFIED) {
		return t('project.applied_unqualified');
	} else if (key === ProjectApplicantResultStatus.QUALIFIED) {
		return t('project.applied_qualified');
	} else {
		return '';
	}
}

export function RewardTypeString(t: any, key: string) {
	if (key === RewardType.SKILL) {
		return t('project.reward_skill');
	} else if (key === RewardType.CHARACTER) {
		return t('project.reward_character');
	} else if (key === RewardType.NONE) {
		return t('common.none');
	} else {
		return '';
	}
}

/**
 * 매력 타입
 */
export function CharacterTypeString(t: any, key: string) {
	if (key === CharacterType.CUTE) {
		return t('common.cute');
	} else if (key === CharacterType.COOL) {
		return t('common.cool');
	} else if (key === CharacterType.FRESH) {
		return t('common.fresh');
	} else if (key === CharacterType.SEXY) {
		return t('common.sexy');
	} else if (key === CharacterType.NICE) {
		return t('common.nice');
	} else {
		return '';
	}
}

/**
 * 스킬 타입
 */
export function SkillTypeString(t: any, key: string) {
	if (key === SkillType.MUSIC) {
		return t('common.music');
	} else if (key === SkillType.DANCE) {
		return t('common.dance');
	} else if (key === SkillType.PERFORMANCE) {
		return t('common.performance');
	} else if (key === SkillType.PHYSICAL) {
		return t('common.physical');
	} else if (key === SkillType.COMMUNICATION) {
		return t('common.communication');
	} else {
		return '';
	}
}

/**
 *
 */
export function PayoutStatusTypeString(t: any, key: string) {
	if (key === PayoutStatusType.PENDING) {
		return t('payout.status_pending');
	} else if (key === PayoutStatusType.APPROVED || key === PayoutStatusConfirmType.VALID) {
		return t('payout.status_approved');
	} else if (key === PayoutStatusType.REJECTED || key === PayoutStatusConfirmType.INVALID) {
		return t('payout.status_rejected');
	} else {
		return t('common.all');
	}
}
