// Chakra imports
import { Flex, Grid } from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { TableRowType } from '../../../../types/enums';
import { useApi } from '../../../../providers/api-provider';
import { useThemeColors } from '../../../../providers/theme-provider';
import { createDynamicColumns, VisibleColumns } from '../../../../components/table/createDynamicColumns';
import { ProjectsResponse, ProjectTrain } from '../../../../apis/projectAPI';
import SearchTable from '../../../../components/table/SearchTable';

// 금지어 목록

export default function ProjectAddIdolSearch(props: { requestChangeParams: (selectId: string) => void }) {
	const { projectApi } = useApi();

	const { requestChangeParams } = props;
	const { t } = useTranslation();
	const {} = useThemeColors();

	// 목록
	const [list, setList] = useState<ProjectTrain[]>([]);

	// 전체 갯수
	const [totalCount, setTotalCount] = useState<number>(0);
	// 한번에 불러올 목록
	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);

	const requestProjectList = async (offset: number) => {
		projectApi
			.getProjectList({
				limit: limit,
				offset: offset
			})
			.then(responseProjectList)
			.catch(() => {});
	};

	const responseProjectList = (response: ProjectsResponse) => {
		if (response.projects) {
			setList(response.projects);
		}

		if (response.totalElements) {
			setTotalCount(response.totalElements);
		}
	};

	const visibleColumns: VisibleColumns<ProjectTrain>[] = [
		{
			row: 'projectId',
			type: TableRowType.TEXT
		},
		{ row: 'name', type: TableRowType.TEXT },
		{ row: 'recruitStartDateTime', type: TableRowType.DATETIME },
		{ row: 'recruitEndDateTime', type: TableRowType.DATETIME },
		{ row: 'operationStartDateTime', type: TableRowType.DATETIME },
		{ row: 'operationEndDateTime', type: TableRowType.DATETIME }
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		requestChangeParams(String(rowData.projectId));
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '0px', md: '0px', xl: '0px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Flex direction={'column'}>
				<SearchTable request={requestProjectList} data={list} columns={columns} totalCount={totalCount} />
			</Flex>
		</Grid>
	);
}
