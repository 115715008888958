// Chakra imports
import { Button, Flex, Grid, Image, LightMode, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
// Assets
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Card from 'components/card/Card';
import MiniStatistics from 'components/card/MiniStatistics';
import SwitchField from 'components/fields/SwitchField';
import ModalDeleteVod from 'views/admin/contents/vod/components/ModalDeleteVod';
import { useApi } from '../../../../providers/api-provider';
import { MemberStatusType, MemberType } from '../../../../types/enums';
import { MemberDetailResponse } from '../../../../apis/memberAPI';
import ModalBlacklistUser from '../components/ModalBlacklistUser';
import { DATA_FORMAT_FULL_DATE, dateFormat, phoneFomatter } from "../../../../helper/functions";
// Live 콘텐츠 목록
export default function UserDetail(props: { memberId: number; type: MemberType }) {
	const { t } = useTranslation();

	const { memberId, type } = props;
	const { memberApi } = useApi();

	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

	const [memberDetail, setMemberDetail] = useState<MemberDetailResponse>(null);

	const [isShowBlacklisted, setIsShowBlacklisted] = useState(memberDetail?.memberStatus === MemberStatusType.BLACK_LISTED);
	const [isShowDelete     , setIsShowDelete]      = useState(memberDetail?.memberStatus === MemberStatusType.WITHDRAW_SUBMITTED);

	useEffect(() => {
		if (memberId >= 0) {
			requestMemberDetail().then(() => {});
		}
	}, [memberId]);

	const requestMemberDetail = async () => {
		memberApi
			.memberDetail(memberId, {
				memberType: type
			})
			.then(response => {
				setMemberDetail(response.memberDetail);
			});
	};

	if (!memberDetail) {
		return <Flex />;
	}

	// Chakra Color Mode
	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '30px', md: '80px', xl: '0px' }}
			gridTemplateColumns={{ base: '1fr 1.2fr', lg: '1fr 1.2fr' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Flex direction={'column'} me={{ lg: '40px' }}>
				<Text fontSize={'md'} fontWeight={'bold'} mb={'20px'}>
					개인 정보
				</Text>
				<Grid
					display={{ base: 'block', md: 'grid' }}
					gridTemplateColumns={{ base: '1fr 2.8fr', lg: '1fr 2.8fr' }}
					gap={{ base: '20px', xl: '20px' }}
				>
					<Card>
						<Image src={memberDetail.profileImgUrl} objectFit={'cover'} />
					</Card>
					<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px" height={'max-content'}>
						<MiniStatistics name={t('user.member_type')} value={memberDetail.memberType} />
						<MiniStatistics name={t('user.is_verified')} value={memberDetail.isVerified ? 'Y' : 'N'} />
						<MiniStatistics name={t('user.country_code')} value={memberDetail.countryCode} />
						<MiniStatistics name={t('user.activity_name')} value={memberDetail.activityName} />
						<MiniStatistics
							name={t('user.register_date')}
							value={dateFormat(memberDetail.createDateTime, DATA_FORMAT_FULL_DATE)}
						/>
						<MiniStatistics
							name={t('user.last_sign_in_date')}
							value={dateFormat(memberDetail.lastSignInDateTime, DATA_FORMAT_FULL_DATE)}
						/>
					</SimpleGrid>
				</Grid>
				<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mt="20px" height={'max-content'}>
					<MiniStatistics name={t('user.mobile')} value={phoneFomatter(memberDetail.mobile, true)} />
					<MiniStatistics name={t('user.email')} value={memberDetail.email} />
				</SimpleGrid>
				<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mt="20px" height={'max-content'}>
					<MiniStatistics
						name={t('user.member_status')}
						value={
							memberDetail.memberStatus === MemberStatusType.NORMAL
								? t('user.status_normal')
								: memberDetail.memberStatus === MemberStatusType.BLACK_LISTED
								  ? t('user.status_blacklist')
								  : t('user.status_withdrawal_submitted')
						}
					/>
					{/*<Card>*/}
					{/*	<SwitchField*/}
					{/*		me="30px"*/}
					{/*		id="1"*/}
					{/*		label={t('user.blacklisted')}*/}
					{/*		disabled={memberDetail.memberStatus === MemberStatusType.WITHDRAW_SUBMITTED}*/}
					{/*		desc={*/}
					{/*			memberDetail.memberStatus === MemberStatusType.BLACK_LISTED*/}
					{/*				? t('user.is_blacklisted')*/}
					{/*				: t('user.is_normal')*/}
					{/*		}*/}
					{/*		isChecked={memberDetail.memberStatus === MemberStatusType.BLACK_LISTED}*/}
					{/*		onChange={() => {*/}
					{/*			setIsShowBlacklisted(true);*/}
					{/*		}}*/}
					{/*	/>*/}
					{/*</Card>*/}
				</SimpleGrid>
				{memberDetail.memberStatus === MemberStatusType.BLACK_LISTED ? (
					<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mt="20px" height={'max-content'}>
						<MiniStatistics
							name={t('user.blacklist_admin_name')}
							value={memberDetail.memberStatus === MemberStatusType.BLACK_LISTED ? '' : ''}
						/>
						<MiniStatistics
							name={t('user.blacklist_reason')}
							value={memberDetail.memberStatus === MemberStatusType.BLACK_LISTED ? '' : ''}
						/>
						<MiniStatistics
							name={t('user.blacklist_start_date')}
							value={dateFormat(memberDetail.blackListStartDateTime, DATA_FORMAT_FULL_DATE)}
						/>
						<MiniStatistics
							name={t('user.blacklist_end_date')}
							value={dateFormat(memberDetail.blackListEndDateTime, DATA_FORMAT_FULL_DATE)}
						/>
					</SimpleGrid>
				) : null}
				{/*{memberDetail.memberStatus === MemberStatusType.NORMAL ? (*/}
				{/*	<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mt="20px" height={'max-content'}>*/}
				{/*		<Card flexDir={'row'}>*/}
				{/*			<Flex direction="column" justifyContent={'center'}>*/}
				{/*				<Text fontSize="xl" color={textColorPrimary} fontWeight="bold">*/}
				{/*					{t('content.vod.detail_reported_vod_title')}*/}
				{/*				</Text>*/}
				{/*			</Flex>*/}
				{/*			<LightMode>*/}
				{/*				<Button*/}
				{/*					colorScheme="red"*/}
				{/*					variant="outline"*/}
				{/*					mt={{ base: '20px', md: '5px' }}*/}
				{/*					_hover={{ bg: 'whiteAlpha.100' }}*/}
				{/*					_focus={{ bg: 'transparent' }}*/}
				{/*					_active={{ bg: 'transparent' }}*/}
				{/*					p="15px 40px"*/}
				{/*					fontSize="sm"*/}
				{/*					h="44px"*/}
				{/*					fontWeight="500"*/}
				{/*					ms="auto"*/}
				{/*					onClick={() => {*/}
				{/*						// setIsShowDelete(true);*/}
				{/*						window.location.href = '/';*/}
				{/*					}}*/}
				{/*				>*/}
				{/*					{t('common.report_detail')}*/}
				{/*				</Button>*/}
				{/*			</LightMode>*/}
				{/*		</Card>*/}
				{/*	</SimpleGrid>*/}
				{/*) : null}*/}
			</Flex>
			<Flex direction={'column'} me={{ lg: '40px' }}>
				<Text fontSize={'md'} fontWeight={'bold'} mb={'20px'}>
					{t('user.personal_activity_information')}
				</Text>
				<SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} gap="20px" mb="20px" height={'max-content'}>
					<MiniStatistics name={t('user.number_livestreaming_count')} value={memberDetail?.liveStreamingCount} />
					<MiniStatistics name={t('user.vod_count')} value={memberDetail?.vodCount} />
					<MiniStatistics name={t('user.idol_card_count')} value={memberDetail?.idolCardCount} />
					<MiniStatistics name={t('user.donation_count')} value={memberDetail?.donationCount} />
					{memberDetail.strikeStatus ? (
						<Card flexDir={'row'}>
							<Flex direction="column" justifyContent={'center'}>
								<Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
									{t("user.report_detail")}
								</Text>
							</Flex>
							<LightMode>
								<Button
									colorScheme="red"
									variant="outline"
									mt={{ base: '20px', md: '5px' }}
									_hover={{ bg: 'whiteAlpha.100' }}
									_focus={{ bg: 'transparent' }}
									_active={{ bg: 'transparent' }}
									p="15px 40px"
									fontSize="sm"
									h="44px"
									fontWeight="500"
									ms="auto"
									onClick={() => {
										setIsShowDelete(true);
									}}
								>
									{t("common.move")}
								</Button>
							</LightMode>
						</Card>
					) : null}
				</SimpleGrid>
				<Text fontSize={'md'} fontWeight={'bold'} mb={'20px'}>
					{t("user.agree_terms_conditions")}
				</Text>
				<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px" height={'max-content'}>
					<MiniStatistics name={t("user.agree_terms_12y_olds")} value={dateFormat(memberDetail?.termsOfServiceDate, DATA_FORMAT_FULL_DATE)} />
					<MiniStatistics name={t("user.agree_terms")} value={dateFormat(memberDetail?.termsOfServiceDate, DATA_FORMAT_FULL_DATE)} />
					<MiniStatistics name={t("user.agree_privacy_policy")} value={dateFormat(memberDetail?.termsOfServiceDate, DATA_FORMAT_FULL_DATE)} />
					{/*<MiniStatistics name={'이벤트 알림 및 광고성 정보 수신 동의 (SMS)'} value={dateFormat(memberDetail?.termsOfServiceDate, DATA_FORMAT_FULL_DATE)} />*/}
					{/*<MiniStatistics name={'이벤트 알림 및 광고성 정보 수신 동의 (이메일)'} value={dateFormat(memberDetail?.termsOfServiceDate, DATA_FORMAT_FULL_DATE)} />*/}
				</SimpleGrid>
				{/*<Text fontSize={'md'} fontWeight={'bold'} mb={'20px'}>*/}
				{/*	{t("user.agree_push")}*/}
				{/*</Text>*/}
				{/*<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px" height={'max-content'}>*/}
				{/*	<MiniStatistics name={'이벤트 알림 및 광고성 정보 수신 동의 (Push)'} value="2024.12.20 11:22:09" />*/}
				{/*	<MiniStatistics name={'심야 알림 수신 동의 (이메일)'} value="2024.12.20 11:22:09" />*/}
				{/*</SimpleGrid>*/}
			</Flex>
			<ModalBlacklistUser
				isOpen={isShowBlacklisted}
				onClose={() => {
					setIsShowBlacklisted(false);
				}}
				requestBlacklistUser={() => {
					// onSwitchChange();
				}}
				requestMemberDeleteBlacklisted={() => {

				}}
				user={memberDetail}
			/>
			<ModalDeleteVod
				isOpen={isShowDelete}
				onClose={() => {
					setIsShowDelete(false);
				}}
				requestDeleteVod={() => {
					setIsShowDelete(false);
				}}
			/>
		</Grid>
	);
}
