// Chakra imports
import { Button, Flex, Icon, Tooltip, useColorModeValue } from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useMemo, useState } from 'react';
import Card from 'components/card/Card';
import { useApi } from '../../../../providers/api-provider';
import { TableRowType } from '../../../../types/enums';
import { useTranslation } from 'react-i18next';
import { useThemeColors } from '../../../../providers/theme-provider';
import SearchTable from '../../../../components/table/SearchTable';
import SearchInput from '../../../../components/actions/Search';
import { MdOutlineAdd, MdOutlineSearch } from 'react-icons/md';
import Filter from '../../../../components/actions/Filter';
import { TYPE_KEY_SORT } from '../../../../helper/types';
import { createDynamicColumns, VisibleColumns } from 'components/table/createDynamicColumns';
import { showToast } from '../../../../helper/functions';
import ModalBasic from '../../../../components/modal/ModalBasic';
import { ProjectTrain, StageResponse, StageSimpleTrain } from '../../../../apis/projectAPI';

// 금지어 목록
export default function Stage() {
	const { projectApi } = useApi();

	const { t } = useTranslation();
	const { textColor, buttonBg, hoverButton, activeButton } = useThemeColors();

	// 목록
	const [list, setList] = useState<StageSimpleTrain[]>([]);

	// 전체 갯수
	const [totalCount, setTotalCount] = useState<number>(0);
	// 한번에 불러올 목록
	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);

	// 검색 조건
	const [searchString, setSearchString] = useState<string>('');

	// 모달 창
	const [isShowDetailModal, setIsShowDetailModal] = useState(false);
	const [selectId, setSelectId] = useState(null);

	// 목록 요청
	const requestStageList = async (offset: number) => {
		setOffset(offset);
		projectApi
			.getStageList({
				limit: limit,
				offset: offset * limit,
				keyword: ''
			})
			.then(responseStageList)
			.catch(() => {});
	};

	// 목록 처리
	const responseStageList = (response: StageResponse) => {
		if (response.stages) {
			setList(response.stages);
		}

		if (response.totalElements) {
			setTotalCount(response.totalElements);
		}
	};

	/**
	 * 금지어 수정
	 * @param id
	 * @param word
	 */
	const requstAddForbiddenWord = (word: string) => {
		// metaDataApi
		// 	.postForbiddenWord(word)
		// 	.then(() => {
		// 		requestForbiddenWord(offset);
		// 		setIsShowAddModal(false);
		// 	})
		// 	.catch(() => {
		// 		showToast(t('error.try_again'));
		// 	});
	};

	/**
	 * 금지어 삭제
	 * @param id
	 */
	const deleteProject = (id: string) => {
		// metaDataApi
		// 	.deleteForbiddenWord(id)
		// 	.then(() => {
		// 		requestForbiddenWord(offset);
		// 		setIsShowDeleteModal(false);
		// 	})
		// 	.catch(() => {});
	};

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<StageSimpleTrain>[] = [
		{
			row: 'stageId',
			type: TableRowType.TEXT
		},
		{ row: 'name', type: TableRowType.TEXT },
		{ row: 'description', type: TableRowType.TEXT },
		{ row: 'visualImageUrl', type: TableRowType.IMAGE },
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)
		window.location.href = '/admin/project/stage/' + rowData.stageId;
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	// 검색 엔터키 처리
	const handleSearchKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleClickSearch();
		}
	};

	const handleClickSearch = () => {
		if (searchString.trim().length < 2 && searchString.trim() !== '') {
			showToast(t('common.alert_search_word_length'), 'error');
			return;
		}

		// setSearchParams(prevState => {
		// 	return {
		// 		...prevState,
		// 		keyword: searchString
		// 	};
		// });
	};

	// 검색어 저장
	const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchString(e.target.value);
	};

	const handleClickAdd = () => {
		setIsShowDetailModal(true);
	};

	// Chakra Color Mode
	return (
		<Flex direction="column" pt={{ sm: '75px', lg: '75px' }}>
			<Card px="0px">
				<Flex w="100%" direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'} mb="36px">
					<SearchInput
						onChangeSearch={onSearchInputChange}
						onEnterKeyPress={handleSearchKeyPress}
						rightElement={
							<Button h="1.75rem" size="sm" onClick={handleClickSearch} borderRadius="20px">
								<Icon color={textColor} as={MdOutlineSearch} />
							</Button>
						}
					/>
					<Flex justifyContent={'flex-end'} mr={{ base: '0px', sm: '10px' }}>
						{/*<Filter*/}
						{/*	onChange={filterChange}*/}
						{/*	initialSortType={TYPE_KEY_SORT.LATEST}*/}
						{/*	secondaryTitle={'components.filter_alphabet'}*/}
						{/*/>*/}
						<Tooltip label={t('content.ban_words.add')}>
							<Button
								me={{ base: '10px', md: '20px' }}
								ml={{ base: '10px', sm: '10px', md: '10px' }}
								bg={buttonBg}
								border="1px solid"
								color="secondaryGray.600"
								borderColor={useColorModeValue('secondaryGray.100', 'whiteAlpha.100')}
								borderRadius="16px"
								_placeholder={{ color: 'secondaryGray.600' }}
								_hover={hoverButton}
								_active={activeButton}
								_focus={activeButton}
								onClick={handleClickAdd}
							>
								<Icon color={textColor} as={MdOutlineAdd} />
							</Button>
						</Tooltip>
					</Flex>
				</Flex>
				<SearchTable request={requestStageList} data={list} columns={columns} totalCount={totalCount} />
			</Card>

			<ModalBasic
				isOpen={isShowDetailModal}
				onClose={() => {
					setIsShowDetailModal(false);
				}}
				title={t('content.ban_words.modal_delete_title')}
				desc={t('content.ban_words.modal_delete_desc')}
				secondaryButton={{
					title: t('common.delete'),
					onClick: (value: any) => {
						deleteProject(selectId);
					}
				}}
			/>
		</Flex>
	);
}
