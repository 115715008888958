// Chakra imports
import {
	Flex, Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay
} from "@chakra-ui/react";

// Custom components
// Assets
import * as React from 'react';
import { useMemo, useState } from 'react';
import Card from 'components/card/Card';
import { useApi } from 'providers/api-provider';
import { ProjectsResponse, ProjectTrain } from '../../../../apis/projectAPI';
import SearchTable from '../../../../components/table/SearchTable';
import { createDynamicColumns, VisibleColumns } from '../../../../components/table/createDynamicColumns';
import { useTranslation } from 'react-i18next';
import { TableRowType } from '../../../../types/enums';
import ModalBasic from '../../../../components/modal/ModalBasic';
import { showToast } from '../../../../helper/functions';
import AdmininstratorDetail from "../../account/detail";
import ProjectDetail from "./detail";

// Live 콘텐츠 목록
export default function Projects() {
	const { t } = useTranslation();
	const { projectApi } = useApi();

	const [list, setList] = useState([]);

	const [totalCount, setTotalCount] = useState(0);

	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState<number>(0);

	// 모달 창
	const [isShowDetail, setIsShowDetail] = useState(false);
	const [selectData, setSelectData] = useState<ProjectTrain>(null);

	const [isShowSwitchModal, setIsShowSwitchModal] = useState(false);

	const requestProjectList = async (offset: number) => {
		projectApi
			.getProjectList({
				limit: limit,
				offset: offset * limit,
			})
			.then(responseProjectList)
			.catch(() => {});
	};

	const responseProjectList = (response: ProjectsResponse) => {
		if (response.projects) {
			setList(response.projects);
		}

		if (response.totalElements) {
			setTotalCount(response.totalElements);
		}
	};

	const modifyProject = (request: FormData) => {
		projectApi
			.putProjectDetail(request)
			.then(() => {})
			.catch(() => {});
	};

	const deleteProject = (projectId: string) => {
		projectApi
			.deleteProjectDetail(projectId)
			.then(() => {})
			.catch(() => {});
	};

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<ProjectTrain>[] = [
		{
			row: 'projectId',
			type: TableRowType.TEXT
		},
		{ row: 'name', type: TableRowType.TEXT },
		{ row: 'description', type: TableRowType.TEXT },
		{ row: 'visualImageUrl', type: TableRowType.IMAGE },
		{ row: 'recruitStartDateTime', type: TableRowType.DATETIME },
		{ row: 'recruitEndDateTime', type: TableRowType.DATETIME },
		{ row: 'applyResultNoticeDateTime', type: TableRowType.DATETIME },
		{ row: 'operationStartDateTime', type: TableRowType.DATETIME },
		{ row: 'operationEndDateTime', type: TableRowType.DATETIME },
		{
			row: 'isActive',
			type: TableRowType.BOOLEAN,
			action: rowData => {
				setSelectData(rowData);
				setIsShowSwitchModal(true);
			}
		}
	];

	const onSwitchChange = () => {
		let requestData = {
			projectId: selectData?.projectId,
			isActive: !selectData?.isActive
		};

		const formData = new FormData();
		formData.append('request', JSON.stringify(requestData));
		projectApi
			.putProjectDetail(formData)
			.then(() => {
				requestProjectList(offset);
				setIsShowSwitchModal(false);
			})
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)
		setSelectData(rowData);
		setIsShowDetail(true);
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	return (
		<Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
			<Card px="0px">
				<SearchTable request={requestProjectList} data={list} columns={columns} totalCount={totalCount} />
			</Card>

			<ModalBasic
				isOpen={isShowSwitchModal}
				onClose={() => {
					setIsShowSwitchModal(false);
				}}
				title={selectData?.isActive === false ? t('project.public') : t('project.private')}
				desc={selectData?.isActive === false ? t('project.public_desc') : t('project.private_desc')}
				secondaryButton={{
					title: selectData?.isActive === false ? t('common.public') : t('common.private'),
					onClick: onSwitchChange
				}}
			/>

			<Modal
				isOpen={isShowDetail}
				onClose={() => {
					setIsShowDetail(false);
				}}
				isCentered
				size={'xg'}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('project.detail')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody overflow="auto">
						<ProjectDetail
							projectId={String(selectData?.projectId)}
							requestChangeParams={() => {
								requestProjectList(offset);
								setIsShowDetail(false);
							}}
						/>
					</ModalBody>
					<ModalFooter />
				</ModalContent>
			</Modal>
		</Flex>
	);
}
