// Chakra imports
import {
	Button,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay
} from '@chakra-ui/react';
import { useLogin } from '../../../../../providers/login-provider';
import { LiveStreamingTrain } from '../../../../../apis/livestreamingAPI';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Assets

export default function ModalForceClose(props: {
	isOpen: boolean;
	requestStreamingForceClose: any;
	item: LiveStreamingTrain;
	onClose: any;
}) {
	// Chakra Color Mode
	const { isOpen, item, requestStreamingForceClose, onClose } = props;

	const { t } = useTranslation();
	const { loginInfo } = useLogin();

	const [reason, setReason] = useState('');

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{t('content.live.force_quit_title', { name: item && item.idolActivityName ? item.idolActivityName: '' })}</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<FormControl>
						<FormLabel>{t('common.admin_name')}</FormLabel>
						{/*<Input placeholder="First name" />*/}
						<text>{loginInfo.email}</text>
					</FormControl>
					<FormControl mt={4}>
						<FormLabel>{t('content.live.force_quit_reason')}</FormLabel>
						<Input
							placeholder={t('content.live.force_quit_reason_desc')}
							onChange={e => {
								setReason(e.target.value);
							}}
						/>
					</FormControl>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="brand"
						mr={3}
						onClick={() => {
							requestStreamingForceClose(reason);
						}}
					>
						{t('content.live.force_quit')}
					</Button>
					<Button onClick={onClose}>{t('common.cancel')}</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
