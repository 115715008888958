// Chakra imports
import { AspectRatio, Avatar, Box, Button, Flex, Grid, Link, Tag, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
// Assets
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../../../../../components/card/Card';
import Messages from 'components/chat/Messages';
import ModalForceClose from '../components/ModalForceClose';
import ModalBanUserList from '../components/ModalBanUserList';
import { useApi } from '../../../../../providers/api-provider';
import { LiveStreamingDetail, LiveStreamingDetailResponse } from '../../../../../apis/contentsAPI';
import ReactHlsPlayer from 'react-hls-player';
import { useSocket } from '../../../../../providers/socket-provider';
import { showToast } from '../../../../../helper/functions';
import ModalBasic from '../../../../../components/modal/ModalBasic';
import ReactPlayer from "react-player";

// Live 콘텐츠 목록
export default function LiveDetailContents() {
	const { t } = useTranslation();

	const { roomId } = useParams();
	const { contentsApi } = useApi();
	const { cancelBanUser, userCount, isEndLiveStreaming } = useSocket();

	const playerRef = React.useRef();

	const textColor = useColorModeValue('navy.700', 'white');
	const textBrand = useColorModeValue('brand.500', 'white');
	const bgBadge = useColorModeValue('secondaryGray.300', 'whiteAlpha.50');
	const textColorBid = useColorModeValue('brand.500', 'white');

	const [roomInfo, setRoomInfo] = useState<LiveStreamingDetail>(null);
	const [showModalBanUserList, setShowModalBanUserList] = useState(false);
	const [viewCount, setViewCount] = useState(0);

	const [isShowModal, setIsShowModal] = useState<boolean>(false);

	useEffect(() => {
		if (roomId) {
			requestLiveStreamingDetail(roomId);
		}
	}, [roomId]);

	useEffect(() => {
		setViewCount(userCount);
	}, [userCount]);

	const requestLiveStreamingDetail = (roomId: string) => {
		contentsApi
			.getLiveStreamingDetail(roomId)
			.then(responseLiveStreaming)
			.catch(() => {});
	};

	// 임시 데이터 로딩 처리
	const responseLiveStreaming = (response: LiveStreamingDetailResponse) => {
		if (response) {
			setRoomInfo(response.liveStreaming);
			setViewCount(response.liveStreaming.currentViewerCount);
		}
	};

	const handleClickBanUserList = (item: any) => {
		setShowModalBanUserList(true);
	};

	useEffect(() => {
		if (isEndLiveStreaming) {
			setIsShowModal(true);
		}
	}, [isEndLiveStreaming]);

	const [showModalForceClose, setShowModalForceClose] = useState(false);
	const [selectItem, setSelectItem] = useState(null);
	const requestStreamaingForceClose = (reason: string) => {
		contentsApi
			.postLivestreamingForceQuit(roomId + '', reason)
			.then(response => {
				window.location.href = '/admin/contents/live';
			})
			.catch(error => {
				showToast(t('error.try_again'));
			});
	};

	const handleClickForceClose = (item: any) => {
		setSelectItem(item);
		setShowModalForceClose(true);
	};

	// Chakra Color Mode
	return !roomInfo ? (
		<></>
	) : (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '130px', md: '80px', xl: '80px' }}
			gridTemplateColumns={{ base: '1fr 1.83fr', lg: '1fr 1.83fr' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Card gridArea="1 / 1 / 1 / 1" p="0px" px="20px" pt="20px" pb="40px" h="100%">
				<Box gridArea="1 / 1 / 2 / 2" me={{ lg: '20px' }} mb={{ base: '20px', lg: '0px' }}>
					<Flex alignItems={'center'} justifyContent={'center'}>
						<AspectRatio w="80%" maxW="100%" ratio={720 / 1280}>
							<ReactPlayer
								playerRef={playerRef}
								playing
								controls
								width={'100%'}
								height={'100%'}
								style={{ borderRadius: '30px', alignSelf: 'center' }}
								url={roomInfo.playbackUrl}
							/>
						</AspectRatio>
					</Flex>
				</Box>
				<Box gridArea="1 / 2 / 1 / 1" mt={10}>
					<Flex
						direction={{ base: 'column', md: 'row' }}
						justifyContent={{ base: 'space-between', sm: 'space-between', lg: 'space-between' }}
					>
						<Flex direction="column">
							<Flex direction="column">
								<Flex direction="row">
									<Avatar src={roomInfo.idolProfileImgUrl} />
									<Flex direction="column" ml={5}>
										<Text
											color={textColor}
											fontSize={{
												base: 'xl',
												md: 'lg',
												lg: 'lg',
												xl: 'lg',
												'2xl': 'md',
												'3xl': 'lg'
											}}
											fontWeight="bold"
											me="14px"
											noOfLines={1}
										>
											{roomInfo.idolActivityName}
										</Text>
										<Text
											color="secondaryGray.600"
											fontSize={{
												base: 'sm'
											}}
											fontWeight="400"
											me="14px"
											noOfLines={1}
											w="100%"
											maxWidth="100%"
										>
											{roomInfo.idolRealName}
										</Text>
									</Flex>
								</Flex>
								<Text
									color={textColor}
									fontSize={{
										base: 'xl',
										md: 'lg',
										lg: 'lg',
										xl: 'lg',
										'2xl': 'md',
										'3xl': 'lg'
									}}
									mb="5px"
									fontWeight="bold"
									me="14px"
									mt="5px"
								>
									{roomInfo.title}
								</Text>
							</Flex>
							<Flex direction="row" mt="5px">
								{roomInfo.hashTagTrain &&
									roomInfo.hashTagTrain.map(hashTag => (
										<Tag
											key={hashTag.hashTagId}
											bg={bgBadge}
											textAlign="center"
											mb={{ base: '20px', md: '0px' }}
											color={textBrand}
											me="10px"
											h="max-content"
										>
											{hashTag.hashTag}
										</Tag>
									))}
							</Flex>
						</Flex>
						<Flex
							direction="column"
							justifyContent="center"
							alignItems={{ base: 'flex-end', sm: 'flex-end', lg: 'flex-end' }}
						>
							<Text fontWeight="700" fontSize="sm" color={textColorBid}>
								{t('components.view_count', { counts: viewCount })}
							</Text>
							<Link
								mt={'10px'}
								onClick={handleClickBanUserList}
								color="teal.500"
								style={{ textDecoration: 'underline' }}
							>
								{t('content.live.list_ban_user')}
							</Link>
							<Link mt={'10px'}>
								<Button
									variant="brand"
									color="white"
									fontSize="sm"
									fontWeight="500"
									borderRadius="6px"
									px="24px"
									py="5px"
									onClick={handleClickForceClose}
								>
									{t('content.live.force_stop')}
								</Button>
							</Link>
						</Flex>
					</Flex>
				</Box>
			</Card>
			<Card h="80vh" gridArea="1 / 2 / 2 / 2" px="0px" minHeight={500} mt={{ base: 5, md: 0 }}>
				<Messages roomInfo={roomInfo} />
			</Card>
			<ModalForceClose
				isOpen={showModalForceClose}
				requestStreamingForceClose={requestStreamaingForceClose}
				item={selectItem}
				onClose={() => {
					setShowModalForceClose(false);
				}}
			/>
			<ModalBanUserList
				isOpen={showModalBanUserList}
				cancelBanUser={(userId: string, userType: string) => {
					cancelBanUser(userId, userType);
				}}
				onClose={() => {
					setShowModalBanUserList(false);
				}}
			/>
			<ModalBasic
				isOpen={isShowModal}
				onClose={() => {
					setIsShowModal(false);
					window.location.href = '/admin/contents/live';
				}}
				title={t('content.live.quit_livestreamin')}
			/>
		</Grid>
	);
}
