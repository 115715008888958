// Chakra imports
import Card from 'components/card/Card';
import { DeleteIcon } from '@chakra-ui/icons';
import { Text, Image } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

type PreviewFileObj = {
	file: File;
	preview: string;
};
export default function DropImageFile(props: { index: number; file: File; onDeleteFile: (index: number) => void }) {
	const { index, file, onDeleteFile } = props;

	const [previewFile, setPreviewFile] = useState<PreviewFileObj>(null);

	useEffect(() => {
		if (file.name.includes('png') || file.name.includes('jpg') ) {
			setPreviewFile({ file: file, preview: URL.createObjectURL(file) });
		} else {
			setPreviewFile({ file: file, preview: '' });
		}
	}, [file]);

	if (!previewFile) {
		return <Card />;
	}

	return (
		<Card mb="20px" pb="50px">
			<DeleteIcon
				mb={'20px'}
				onClick={() => {
					onDeleteFile(index);
				}}
			/>
			{file.name.includes('png') || file.name.includes('jpg') ? (
				<Image src={previewFile.preview} h={'80px'} objectFit={'cover'} />
			) : (
				<Text fontSize="xs" h={'80px'}>
					{file.name}
				</Text>
			)}
		</Card>
	);
}
