// Chakra imports
import { Flex, Grid, SimpleGrid, Text } from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useApi } from '../../../providers/api-provider';
import { useThemeColors } from '../../../providers/theme-provider';
import { showToast } from '../../../helper/functions';
import { DashboardResponse, DashboardStoreTrain, DashboardValueTrain } from '../../../apis/commonAPI';
import Card from 'components/card/Card';
import DashboardMemberCard from './components/DashboardMemberCard';
import { HSeparator } from '../../../components/separator/Separator';
import DashboardPaymentCard from './components/DashboardPaymentCard';

// 금지어 목록
export default function Dashboard() {
	const { t } = useTranslation();
	const { textColor } = useThemeColors();
	const { commonApi } = useApi();

	const [data, setData] = useState<DashboardResponse>();

	useEffect(() => {
		// 오늘
		requestDashboard();
	}, []);

	const requestDashboard = () => {
		commonApi
			.getDashboard()
			.then(responseDashboard)
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	const responseDashboard = (response: DashboardResponse) => {
		if (response) {
			const data = response;
			data.paymentAmount = formatStorePaymentData(data.paymentAmount);
			setData(data);
		}
	};

	const formatStorePaymentData = (storeData: DashboardStoreTrain) => {
		const formattedData: DashboardStoreTrain = {
			totalRecord: [],
			dailyRecord: [],
			weeklyRecord: [],
			monthlyRecord: []
		};

		if (storeData) {
			const dailyPayments: DashboardValueTrain[] = [];
			const weeklyPayments: DashboardValueTrain[] = [];
			const monthlyPayments: DashboardValueTrain[] = [];

			storeData.totalRecord.sort((a, b) => a.store.localeCompare(b.store));

			storeData.totalRecord.forEach(item => {
				addOrUpdatePaymentRecord(item, storeData.dailyRecord, dailyPayments);
				addOrUpdatePaymentRecord(item, storeData.weeklyRecord, weeklyPayments);
				addOrUpdatePaymentRecord(item, storeData.monthlyRecord, monthlyPayments);
			});

			formattedData.totalRecord = storeData.totalRecord;
			formattedData.dailyRecord = dailyPayments;
			formattedData.weeklyRecord = weeklyPayments;
			formattedData.monthlyRecord = monthlyPayments;
		}

		return formattedData;
	};

	const addOrUpdatePaymentRecord = (
		sourceItem: DashboardValueTrain,
		sourceArray: DashboardValueTrain[],
		targetArray: DashboardValueTrain[]
	) => {
		const existingIndex = sourceArray.findIndex(
			record => record.store === sourceItem.store && record.payType === sourceItem.payType
		);

		if (existingIndex < 0) {
			targetArray.push({
				value: '',
				store: sourceItem.store,
				payType: sourceItem.payType,
				searchStartDateTime: '',
				searchEndDateTime: ''
			});
		} else {
			targetArray.push(sourceArray[existingIndex]);
		}
	};

	/**
	 *  | "none"
	 *     | "sm"
	 *     | "base"
	 *     | "md"
	 *     | "lg"
	 *     | "xl"
	 *     | "2xl"
	 *     | "3xl"
	 */

	if (!data) {
		return <Flex />;
	}

	return (
		<Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
			<Grid
				display={{ base: 'block', md: 'grid' }}
				pt={{ base: '0px', md: '0px', xl: '0px' }}
				gap={{ base: '20px', xl: '20px' }}
			>
				<Flex direction={'column'} me={{ lg: '40px' }}>
					<Grid
						display={{ base: 'block', md: 'grid' }}
						gridTemplateColumns={{ base: '1fr 1fr', lg: '1fr 1fr' }}
						gap={{ base: '20px', xl: '20px' }}
					>
						<Card px="0px">
							<Text color={textColor} fontSize="xl" fontWeight="700" pl={'20px'} mb={'20px'}>
								<h2>{t('dashboard.member')}</h2>
							</Text>
							<SimpleGrid columns={{ base: 1, sm: 1, md: 1, lg: 1, xl: 1 }} gap={'20px'}>
								<DashboardMemberCard title={t('dashboard.register_idol_count')} orgData={data?.registerIdolCount} />
								<DashboardMemberCard
									title={t('dashboard.register_producer_count')}
									orgData={data?.registerProducerCount}
								/>
								<HSeparator />
								<DashboardMemberCard
									title={t('dashboard.withdrawal_idol_count')}
									orgData={data?.withdrawProducerCount}
								/>
								<DashboardMemberCard
									title={t('dashboard.withdrawal_producer_count')}
									orgData={data?.withdrawProducerCount}
								/>
							</SimpleGrid>
						</Card>
						<Card px="0px">
							<Text color={textColor} fontSize="xl" fontWeight="700" pl={'20px'} mb={'20px'}>
								<h2>{t('dashboard.payment')}</h2>
							</Text>
							<SimpleGrid columns={{ base: 1, sm: 1, md: 1, lg: 1, xl: 1 }} gap={'20px'}>
								{data?.paymentAmount.totalRecord.map((v, i) => (
									<DashboardPaymentCard
										title={v.store === 'KOR' ? t('common.korea') : v.store === 'JPN' ? t('common.japan') : v.store}
										orgData={data?.paymentAmount}
										index={i}
									/>
								))}
							</SimpleGrid>
						</Card>
					</Grid>
				</Flex>
			</Grid>
		</Flex>
	);
}
