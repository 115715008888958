// Chakra imports
import { Flex, Grid, SimpleGrid } from "@chakra-ui/react";

// Custom components
// Assets
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import TextField from "components/fields/TextField";
import InputField from "components/fields/InputField";
import { useThemeColors } from "providers/theme-provider";
import { useApi } from "providers/api-provider";
import Card from "components/card/Card";
import { RedeemHistoryTrain } from "../../../apis/memberAPI";
import { getLoginInfo } from "../../../providers/login-provider";
import ButtonField from "components/fields/ButtonField";
import { PayoutStatusConfirmType, PayoutStatusType } from "../../../types/enums";
import { PayoutStatusTypeString } from "../../../helper/types";
import SelectField from "../../../components/fields/SelectField";
import {
	DATA_FORMAT_FULL_DATE,
	dateFormat,
	formatJapaneseBankAccount,
	formatMyNumber,
	numberFormat,
	phoneFomatter,
	showToast,
	UNIT_HG,
	UNIT_PERCENT,
	UNIT_YEN
} from "../../../helper/functions";
import { HSeparator } from "../../../components/separator/Separator";

// 금지어 목록
export default function IdolRequestPayoutDetail(props: {
	detail: RedeemHistoryTrain;
	requestChangeParams: () => void;
}) {
	const { t } = useTranslation();
	const {} = useThemeColors();
	const { memberApi } = useApi();

	const { detail, requestChangeParams } = props;

	const [item, setItem] = useState<RedeemHistoryTrain>();
	const [finalPayoutAmount, setFinalPayoutAmount] = useState<number>(0);
	const [payoutStatus, setPayoutStatus] = useState<PayoutStatusConfirmType>(PayoutStatusConfirmType.VALID);
	const [reason, setReason] = useState('');

	useEffect(() => {
		if (detail !== null) {
			setItem(detail);
			setReason(detail?.confirmReason);

			if (detail?.redeemValue && detail?.redeemPee) {
				setFinalPayoutAmount(detail?.redeemValue - detail?.redeemPee);
			} else {
				setFinalPayoutAmount(0);
			}

			setPayoutStatus(detail?.status === PayoutStatusType.ALL || detail?.status === PayoutStatusType.PENDING || detail?.status === PayoutStatusType.APPROVED ? PayoutStatusConfirmType.VALID : PayoutStatusConfirmType.INVALID);
		}
	}, [detail]);

	const requestConfirmRedeem = () => {
		if (!reason || reason === '' && payoutStatus === PayoutStatusConfirmType.INVALID) {
			showToast(t('error.confirm_reason'))
			return;
		}

		memberApi
			.postCurrentRedeem(String(detail?.redeemHistoryId), payoutStatus, reason)
			.then(() => {
				requestChangeParams();
			})
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	const reponseDetail = (response: any) => {};

	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '0px', md: '0px', xl: '0px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Flex direction={'column'}>
				<Grid
					display={{ base: 'block', md: 'grid' }}
					gridTemplateColumns={{ base: '1fr 1fr', lg: '1fr 1fr' }}
					gap={{ base: '20px', xl: '20px' }}
				>
					<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} spacing={'20px'}>
						<Card>
							<TextField label={t('payout.redeem_history_id')} text={detail?.redeemHistoryId} />
							<TextField label={t('user.activity_name')} text={detail?.memberActiveName} />
							<TextField
								label={t('payout.created_date')}
								text={dateFormat(detail?.createDateTime, DATA_FORMAT_FULL_DATE)}
							/>
							<TextField label={t('user.mobile')} text={phoneFomatter(detail?.memberMobile)} />
							<TextField label={t('payout.status')} text={PayoutStatusTypeString(t, detail?.status)} />
						</Card>
						<Card>
							<TextField label={t('payout.value')} text={numberFormat(detail?.redeemValue, UNIT_HG)} />
							<HSeparator mb={'20px'} />
							<TextField
								label={t('payout.payout_percent')}
								text={numberFormat(detail?.redeemFeeRate ? detail?.redeemFeeRate : 40, UNIT_PERCENT)}
							/>
							<TextField label={t('payout.payout_hg')} text={numberFormat(detail?.redeemFeePersonal, UNIT_HG)} />
							{/*<TextField label={t('payout.payout_yen')} text={numberFormat(detail?.redeemFeePersonal, UNIT_YEN)} />*/}
							<HSeparator mb={'20px'} />
							<TextField
								label={t('payout.apple_commission_percent')}
								text={numberFormat(detail?.appleFeeRate, UNIT_PERCENT)}
							/>
							<TextField label={t('payout.apple_commission_hg')} text={numberFormat(detail?.appleFeeAmount, UNIT_HG)} />
							{/*<TextField*/}
							{/*	label={t('payout.apple_commission_yen')}*/}
							{/*	text={numberFormat(detail?.appleFeeAmount, UNIT_YEN)}*/}
							{/*/>*/}
							<HSeparator mb={'20px'} />
							<TextField label={t('payout.total_commission_hg')} text={numberFormat(detail?.redeemPee, UNIT_HG)} />
							<HSeparator mb={'20px'} />
							<TextField label={t('payout.total_payout_hg')} text={numberFormat(finalPayoutAmount, UNIT_HG)} />
							<TextField label={t('payout.total_payout_yen')} text={numberFormat(finalPayoutAmount, UNIT_YEN)} />
						</Card>
					</SimpleGrid>

					<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} spacing={'20px'}>
						<Card>
							<TextField label={t('payout.kanji_given_name')} text={detail?.kanjiGivenName} />
							<TextField label={t('payout.kanji_sur_name')} text={detail?.kanjiSurName} />
							<TextField label={t('payout.furigana_given_name')} text={detail?.furiganaGivenName} />
							<TextField label={t('payout.furigana_sur_name')} text={detail?.furiganaSurName} />
							<HSeparator mb={'20px'} />
							<TextField label={t('payout.address_nationality')} text={detail?.addressNationality} />
							<TextField label={t('payout.zip_code')} text={detail?.addressZipcode} />
							<TextField label={t('payout.address')} text={detail?.addressDetail} />
							<HSeparator mb={'20px'} />
							<TextField label={t('payout.my_number')} text={formatMyNumber(detail?.myNumber)} />
						</Card>
						<Card>
							<TextField label={t('payout.bank_holder_given_name')} text={detail?.bankAccountHolderGivenName} />
							<TextField label={t('payout.bank_holder_sur_name')} text={detail?.bankAccountHolderSurName} />
							<TextField label={t('payout.bank_name')} text={detail?.bankAccountBankName} />
							<TextField label={t('payout.bank_code')} text={detail?.bankAccountBankCode} />
							<TextField label={t('payout.bank_type')} text={detail?.bankAccountType} />
							<TextField
								label={t('payout.account_number')}
								text={formatJapaneseBankAccount(detail?.bankAccountNumber)}
							/>
						</Card>
					</SimpleGrid>

					<Card>
						<SimpleGrid>
							<TextField label={t('payout.admin_name')} text={getLoginInfo().name} />
							<InputField
								label={t('payout.admin_reason')}
								value={reason}
								disabled={detail?.status !== PayoutStatusType.PENDING}
								onChange={e => {
									setReason(e.target.value);
								}}
							/>
							<SelectField
								label={t('common.status')}
								value={payoutStatus}
								disabled={detail?.status !== PayoutStatusType.PENDING}
								options={Object.values(PayoutStatusConfirmType)}
								onChange={e => {
									setPayoutStatus(e.target.value as PayoutStatusConfirmType);
								}}
								displayOptionsString={PayoutStatusTypeString}
							/>
							{detail?.status === PayoutStatusType.PENDING ? (
								<ButtonField
									buttonName={t('common.save')}
									onClick={() => {
										requestConfirmRedeem();
									}}
								/>
							) : null}
						</SimpleGrid>
					</Card>
				</Grid>
			</Flex>
		</Grid>
	);
}
