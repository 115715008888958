import { BaseApi } from "./baseAPI";
import { PagingRequest } from "./commonAPI";
import {
	URL_MEMBER_BLACKLISTED,
	URL_MEMBER_BLACKLISTED_DETAIL,
	URL_MEMBER_DETAIL,
	URL_MEMBER_SEARCH, URL_GET_ACCOUNT_DETAIL,
	URL_GET_ACCOUNT_LIST, URL_POST_ACCOUNT, URL_PUT_ACTIVATION
} from "./URLS";
import { AdminAuth } from "../providers/auth-provider";

/**
 * Common Controller
 */
export class AccountApi extends BaseApi {
	getAccountList = (request: AccountListRequest) => {
		return this.axios
			.get<AccountListResponse>(URL_GET_ACCOUNT_LIST, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	getAccountDetail = (accoundId: string) => {
		return this.axios
			.get<AccountDetailResponse>(URL_GET_ACCOUNT_DETAIL.replace('{accountId}', accoundId))
			.then(response => response)
			.catch(error => error.response);
	};

	putAccountAuth = (adminId: string, request: AccountAuthRequest) => {
		return this.axios
			.put(URL_GET_ACCOUNT_DETAIL.replace('{accountId}', adminId), { request })
			.then(response => response)
			.catch(error => error.response);
	};

	putAccountPassword = (accountId: string, password: string) => {
		return this.axios
			.put(URL_GET_ACCOUNT_DETAIL.replace('{accountId}', accountId.toString()), { password: password })
			.then(response => response)
			.catch(error => error.response);
	};

	putAccountActivation = (accountId: string, isActive: boolean) => {
		return this.axios
			.put(URL_PUT_ACTIVATION.replace('{accountId}', accountId.toString()), { isActive: isActive } )
			.then(response => response)
			.catch(error => error.response);
	};

	putAccountInfomation = (accountId: string, request: FormData) => {
		return this.axios
			.put(URL_GET_ACCOUNT_DETAIL.replace('{accountId}', accountId.toString()), request, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(response => response)
			.catch(error => error.response);
	};

	postAccount = (request: FormData) => {
		return this.axios
			.post(URL_POST_ACCOUNT, request, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(response => response)
			.catch(error => error.response);
	};
}

/**
 * ====================================================
 * ====================================================
 * Request
 */
export type AccountListRequest = PagingRequest & {};

export type AccountRequest = {
	email: string;
	password: string;
	countryCode: string;
	mobile: string;
	name: string;
	authTrain: AdminAuth[];
	isActive: boolean;
};

export type AccountInformationRequest = {
	countryCode: string;
	mobile: string;
	name: string;
	authTrain: AdminAuth[];
};

export type AccountAuthRequest = {
	authTrain: AdminAuth[];
};

/**
 * ====================================================
 * ====================================================
 * Response
 */
export type AccountListResponse = {
	accountTrain: AdminAccountTrain[];
	count: number;
};

export type AccountDetailResponse = {
	accountDetail: AdminAccountTrain;
};

export type AdminAccountTrain = {
	adminAccountId: number;
	name: string;
	email: string;
	profileImgUrl: string;
	countryCode: string;
	mobile: string;
	authTrain: AdminAuth[];
	isActive: boolean;
};
