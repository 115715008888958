// Chakra imports
import { Button, Flex, FormLabel, Text } from '@chakra-ui/react';
import { useThemeColors } from '../../providers/theme-provider';
import React from 'react';
// Custom components
export default function Default(props: {
	label?: string;
	extra?: string;
	buttonName?: string;
	selectData?: string[];
	onClick?: () => void;
	disabled?: boolean;
	[x: string]: any;
}) {
	const {
		label,
		extra,
		buttonName,
		selectData,
		onClick,
		desc,
		textWidth,
		reversed,
		fontSize,
		disabled,
		mb,
		mt,
		...rest
	} = props;
	//const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

	const { textColorPrimary } = useThemeColors();

	const data = ['a', 'b', 'c'];

	return (
		<Flex direction="column" mb={mb ? mb : '10px'}>
			<FormLabel
				display="flex"
				ms="10px"
				fontSize="sm"
				color={textColorPrimary}
				fontWeight="bold"
				_hover={{ cursor: 'pointer' }}
			>
				{label}
				<Text fontSize="sm" fontWeight="400" ms="2px">
					{extra}
				</Text>
			</FormLabel>
			<Button
				variant="brand"
				minW="183px"
				w={'100%'}
				fontSize="sm"
				fontWeight="500"
				ms="auto"
				disabled={disabled}
				onClick={!disabled ? onClick : null}
			>
				{buttonName}
			</Button>
		</Flex>
	);
}
