// Chakra imports
import {
	Flex,
	FormLabel,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Text,
	useColorModeValue
} from '@chakra-ui/react';
import * as React from 'react';
// Custom components

export default function Default(props: {
	id?: string;
	label?: string;
	extra?: JSX.Element;
	placeholder?: string;
	disabled?: boolean;
	[x: string]: any;
	onChange?: (valueAsString: string, valueAsNumber: number) => void;
}) {
	const { id, label, extra, placeholder, type, mb, disabled = false, ...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

	return (
		<Flex direction="column" mb={mb ? mb : '10px'}>
			<FormLabel
				display="flex"
				ms="10px"
				htmlFor={id}
				fontSize="sm"
				color={textColorPrimary}
				fontWeight="bold"
				_hover={{ cursor: 'pointer' }}
			>
				{label}
				<Text fontSize="sm" fontWeight="400" ms="2px">
					{extra}
				</Text>
			</FormLabel>
			<NumberInput {...rest} onChange={props?.onChange}>
				<NumberInputField />
				<NumberInputStepper>
					<NumberIncrementStepper />
					<NumberDecrementStepper />
				</NumberInputStepper>
			</NumberInput>
		</Flex>
	);
}
