// Chakra imports
import { AspectRatio, Box, Button, Flex, Grid, LightMode, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
// Assets
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useEffect, useState } from 'react';
import MiniStatistics from '../../../../../components/card/MiniStatistics';
import SwitchField from '../../../../../components/fields/SwitchField';
import ModalBasic from '../../../../../components/modal/ModalBasic';
import Card from 'components/card/Card';
import { VodDetail, VodDetailResponse } from "../../../../../apis/contentsAPI";
import { useApi } from '../../../../../providers/api-provider';
import { checkFileExtension, DATA_FORMAT_FULL_DATE, dateFormat } from "../../../../../helper/functions";
import ReactHlsPlayer from 'react-hls-player';
import ModalDeleteVod from '../components/ModalDeleteVod';

// Live 콘텐츠 목록
export default function VODDetail(params: { vodId: string }) {
	const { t } = useTranslation();
	const { contentsApi } = useApi();

	const { vodId } = params;
	const playerRef = React.useRef();

	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

	const [data, setData] = useState<VodDetail>(null);

	const [isShowPrivate, setIsShowPrivate] = useState(false);
	const [isShowDelete, setIsShowDelete] = useState(false);

	useEffect(() => {
		if (vodId) {
			requestVodDetail(vodId);
		}
	}, [vodId]);

	const requestVodDetail = async (vodId: string) => {
		contentsApi
			.getVodDetail(vodId)
			.then(responseVodDetail)
			.catch(() => {});
	};

	const responseVodDetail = (response: VodDetailResponse) => {
		setData(response.vod);
	};

	if (!data) {
		return <Flex />;
	}

	// Chakra Color Mode
	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '30px', md: '80px', xl: '0px' }}
			gridTemplateColumns={{ base: '1fr 1.83fr', lg: '1fr 1.83fr' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Box gridArea="1 / 1 / 1 / 1" me={{ lg: '20px' }} mb={{ base: '20px', lg: '0px' }}>
				<Flex alignItems={'center'} justifyContent={'center'}>
					<AspectRatio w="80%" maxW="100%" ratio={720 / 1280}>
						{checkFileExtension(data?.vodUrl).includes('m3u8') ? (
							<ReactHlsPlayer
								playerRef={playerRef}
								style={{ borderRadius: '30px', alignSelf: 'center' }}
								src={data?.vodUrl.replace('playlist.m3u8', 'playlist_sfm4s.m3u8')}
								controls={true}
							/>
						) : (
							<video
								style={{ borderRadius: '30px', alignSelf: 'center' }}
								src={data?.vodUrl}
								title={''}
								controls={true}
							/>
						)}
					</AspectRatio>
				</Flex>
			</Box>
			<Flex direction={'column'} me={{ lg: '40px' }}>
				<SimpleGrid columns={{ base: 1, md: 1, xl: 3 }} gap="20px" mb="20px" height={'max-content'}>
					<MiniStatistics name={t('content.vod.detail_content_id')} value={data?.vodId} />
					<MiniStatistics
						name={t('content.vod.detail_vod_created_date')}
						value={dateFormat(data?.createDateTime, DATA_FORMAT_FULL_DATE)}
					/>
					<MiniStatistics name={t('content.vod.detail_user_name')} value={data?.idolActivityName} />
				</SimpleGrid>
				<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px" height={'max-content'}>
					<MiniStatistics name={t('content.vod.vod_title')} value={data?.title} />
				</SimpleGrid>
				{data?.isDeleted ? (
					<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px" height={'max-content'}>
						{/*<MiniStatistics name={t('content.vod.table_title_user_delete_administrator')} value="김나니" />*/}
						<MiniStatistics name={t('content.vod.table_title_user_delete_reason')} value={data?.deleteReason} />
					</SimpleGrid>
				) : null}
				<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px" height={'max-content'}>
					<Card>
						<SwitchField
							me="30px"
							id="1"
							label={t('content.vod.detail_vod_private_yn_title')}
							desc={
								data?.isPublic
									? t('content.vod.detail_vod_private_yn_public_desc')
									: t('content.vod.detail_vod_private_yn_private_desc')
							}
							isChecked={data?.isPublic}
							onChange={() => {
								setIsShowPrivate(true);
							}}
						/>
					</Card>
					<Card flexDir={'row'}>
						<Flex direction="column" justifyContent={'center'}>
							<Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
								{data?.isDeleted ? t('content.vod.detail_restore_vod_title') : t('content.vod.detail_delete_vod_title')}
							</Text>
						</Flex>
						<LightMode>
							<Button
								colorScheme="red"
								variant="outline"
								mt={{ base: '20px', md: '5px' }}
								_hover={{ bg: 'whiteAlpha.100' }}
								_focus={{ bg: 'transparent' }}
								_active={{ bg: 'transparent' }}
								p="15px 40px"
								fontSize="sm"
								h="44px"
								fontWeight="500"
								ms="auto"
								onClick={() => {
									setIsShowDelete(true);
								}}
							>
								{data?.isDeleted ? t('common.restore') : t('common.delete')}
							</Button>
						</LightMode>
					</Card>
				</SimpleGrid>
				{/*{data?.isDeleted ? (*/}
				{/*	<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px" height={'max-content'}>*/}
				{/*		<Card flexDir={'row'}>*/}
				{/*			<Flex direction="column" justifyContent={'center'}>*/}
				{/*				<Text fontSize="xl" color={textColorPrimary} fontWeight="bold">*/}
				{/*					{t('content.vod.detail_reported_vod_title')}*/}
				{/*				</Text>*/}
				{/*			</Flex>*/}
				{/*			<LightMode>*/}
				{/*				<Button*/}
				{/*					colorScheme="red"*/}
				{/*					variant="outline"*/}
				{/*					mt={{ base: '20px', md: '5px' }}*/}
				{/*					_hover={{ bg: 'whiteAlpha.100' }}*/}
				{/*					_focus={{ bg: 'transparent' }}*/}
				{/*					_active={{ bg: 'transparent' }}*/}
				{/*					p="15px 40px"*/}
				{/*					fontSize="sm"*/}
				{/*					h="44px"*/}
				{/*					fontWeight="500"*/}
				{/*					ms="auto"*/}
				{/*					onClick={() => {*/}
				{/*						// setIsShowDelete(true);*/}
				{/*						window.location.href = '/';*/}
				{/*					}}*/}
				{/*				>*/}
				{/*					{t('common.report_detail')}*/}
				{/*				</Button>*/}
				{/*			</LightMode>*/}
				{/*		</Card>*/}
				{/*	</SimpleGrid>*/}
				{/*) : null}*/}
			</Flex>
			<ModalBasic
				isOpen={isShowPrivate}
				onClose={() => {
					setIsShowPrivate(false);
				}}
				title={
					data?.isPublic ? t('content.vod.modal_change_public_title') : t('content.vod.modal_change_private_title')
				}
				desc={data?.isPublic ? t('content.vod.modal_change_public_desc') : t('content.vod.modal_change_private_desc')}
				secondaryButton={{
					title: data?.isPublic ? t('common.public') : t('common.private'),
					onClick: () => {
						if (data?.isPublic) {
							setData(prevState => {
								return {
									...prevState,
									isPublic: false
								};
							});
						} else {
							setData(prevState => {
								return {
									...prevState,
									isPublic: true
								};
							});
						}
						setIsShowPrivate(false);
					}
				}}
			/>
			<ModalDeleteVod
				isOpen={isShowDelete}
				onClose={() => {
					setIsShowDelete(false);
				}}
				requestDeleteVod={() => {
					setIsShowDelete(false);
				}}
			/>
		</Grid>
	);
}
