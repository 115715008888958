// Chakra imports
import {
	Flex,
	Grid,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SimpleGrid
} from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import InputField from 'components/fields/InputField';
import { CurrencyType, MemberType } from '../../../../types/enums';
import { useApi } from '../../../../providers/api-provider';
import { useThemeColors } from '../../../../providers/theme-provider';
import Card from 'components/card/Card';
import ButtonField from 'components/fields/ButtonField';
import { HSeparator } from '../../../../components/separator/Separator';
import SearchMember from './searchMember';
import TextField from 'components/fields/TextField';
import { CurrencyTypeString, MemberTypeString } from '../../../../helper/types';
import SelectField from 'components/fields/SelectField';
import { MemberCurrencyRequest, MemberTrain } from "../../../../apis/memberAPI";
import { generateUUID, showToast } from '../../../../helper/functions';
import ModalBasic from '../../../../components/modal/ModalBasic';

// 금지어 목록
export default function IdolTestAdd() {
	const { idolApi } = useApi();

	const { t } = useTranslation();
	const {} = useThemeColors();


	const [idolId, setIdolId] = useState<string>(null);
	const [memberId, setMemberId] = useState<string>(null);
	const [memberType, setMemberType] = useState<MemberType>(MemberType.PRODUCER);
	const [memberEmail, setMemberEmail] = useState<string>('');
	const [memberName, setMemberName] = useState<string>('');

	const [isShowConfirm, setIsShowConfirm] = useState<boolean>();
	const [isShowModalSearchMember, setIsShowModalSearchMember] = useState<boolean>(false);

	const requestAddIdol = () => {
		if (memberId === null || memberId === '') {
			showToast(t('error.input_member_id'));
			return;
		}

		idolApi
			.postTileStorageForceStores(idolId, [memberId])
			.then(responseAddIdol)
			.catch(() => {});
	};

	const responseAddIdol = () => {
		setIsShowConfirm(false);

		showToast(t('idol.add_producer'))

		setIdolId('');
		setMemberId('');
		setMemberEmail('');
		setMemberName('');
		setMemberType(MemberType.PRODUCER);
	};

	return (
		<Flex direction="column" pt={{ sm: '75px', lg: '75px' }}>
			<Grid
				display={{ base: 'block', md: 'grid' }}
				pt={{ base: '0px', md: '0px', xl: '0px' }}
				gap={{ base: '20px', xl: '20px' }}
			>
				<Flex direction={'column'}>
					<Grid display={{ base: 'block', md: 'grid' }} gap={{ base: '20px', xl: '20px' }}>
						<Card>
							<SimpleGrid>
								<InputField
									label={t('idol.idol_id')}
									disabled={false}
									value={idolId}
									type={'number'}
									onChange={e => {
										setIdolId(String(e.target.value));
									}}
								/>
								<ButtonField
									label={t('user.list.filter_user_type_producer')}
									buttonName={t('idol.search_producer')}
									onClick={() => {
										setIsShowModalSearchMember(true);
									}}
								/>
								<InputField
									label={t('user.member_id')}
									disabled={false}
									value={memberId}
									type={'number'}
									onChange={e => {
										setMemberId(String(e.target.value));
									}}
								/>
								<InputField
									label={t('common.name')}
									disabled={true}
									value={memberName}
								/>
								<InputField
									label={t('common.email')}
									disabled={true}
									value={memberEmail}
								/>
								<SelectField
									label={t('user.member_type')}
									value={memberType}
									options={Object.keys(MemberType)}
									disabled={true}
									onChange={e => {
										setMemberType(e.target.value as MemberType);
									}}
									displayOptionsString={MemberTypeString}
								/>
								<HSeparator mb={'10px'} />
								<ButtonField
									buttonName={t('common.add')}
									onClick={() => {
										if (memberId === null || memberId === '') {
											showToast(t('error.input_member_id'));
											return;
										}

										setIsShowConfirm(true);
									}}
								/>
							</SimpleGrid>
						</Card>
					</Grid>
				</Flex>

				<Modal
					isOpen={isShowModalSearchMember}
					onClose={() => {
						setIsShowModalSearchMember(false);
					}}
					isCentered
					size={'4xl'}
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>{t('common.select_id_click')}</ModalHeader>
						<ModalCloseButton />
						<ModalBody overflow="auto">
							<SearchMember
								requestChangeParams={(selectMember: MemberTrain) => {
									setIsShowModalSearchMember(false);
									setMemberId(String(selectMember.memberId));
									setMemberType(selectMember.memberType);
									setMemberName(selectMember.activityName);
									setMemberEmail(selectMember.email);
								}}
							/>
						</ModalBody>
						<ModalFooter />
					</ModalContent>
				</Modal>

				<ModalBasic
					isOpen={isShowConfirm}
					onClose={() => {
						setIsShowConfirm(false);
					}}
					title={t('idol.add_producer_test_idol')}
					desc={t('idol.add_producer_test_idol_desc')}
					secondaryButton={{
						title: t('common.ok'),
						onClick: (value: any) => {
							requestAddIdol();
						}
					}}
				/>
			</Grid>
		</Flex>
	);
}
