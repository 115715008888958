// Chakra imports
import { Box, FormLabel, Image, Text, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import * as CSS from 'csstype';
import Zoom from 'react-medium-image-zoom';
// Custom components
export default function Default(props: {
	label?: string;
	extra?: string;
	url?: string;
	objectFit?: CSS.Property.ObjectFit;
	size?: string;
	[x: string]: any;
}) {
	const { label, extra, url, objectFit, mb, size, ...rest } = props;
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

	return (
		<Box w="100%" fontWeight="500" {...rest} mb={mb ? mb : '10px'}>
			<FormLabel
				display="flex"
				ms="10px"
				fontSize="sm"
				color={textColorPrimary}
				fontWeight="bold"
				_hover={{ cursor: 'pointer' }}
			>
				{label}
				<Text fontSize="sm" fontWeight="400" ms="2px">
					{extra}
				</Text>
			</FormLabel>
			<Zoom>
				<Image src={url} objectFit={objectFit ? objectFit : 'cover'} loading={"lazy"} />
			</Zoom>
		</Box>
	);
}
