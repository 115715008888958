// Chakra imports
import {
	Flex,
	Grid,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SimpleGrid
} from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import InputField from 'components/fields/InputField';
import { ProjectLeagueType } from '../../../../types/enums';
import { useApi } from '../../../../providers/api-provider';
import { useThemeColors } from '../../../../providers/theme-provider';
import Card from 'components/card/Card';
import ButtonField from 'components/fields/ButtonField';
import ProjectAddIdolSearch from './searchProject';
import ProjectAddIdolSearchMember from './searchMember';
import { HSeparator } from '../../../../components/separator/Separator';

// 금지어 목록
export default function ProjectAddIdol(props: { requestChangeParams: () => void }) {
	const { projectApi } = useApi();

	const { requestChangeParams } = props;
	const { t } = useTranslation();
	const {} = useThemeColors();

	const [projectId, setProjectId] = useState<string>('');
	const [idolId, setIdolId] = useState<string>('');

	const [isShowModalSearchProject, setIsShowModalSearchProject] = useState<boolean>(false);
	const [isShowModalSearchMember, setIsShowModalSearchMember] = useState<boolean>(false);

	const requestProjectAddIdol = () => {
		projectApi
			.postProjectAddApplicant(ProjectLeagueType.OFFICIAL, String(projectId), String(idolId))
			.then(responseAddIdol)
			.catch(() => {});
	};

	const responseAddIdol = () => {
		requestChangeParams();
	};

	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '0px', md: '0px', xl: '0px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Flex direction={'column'}>
				<Grid display={{ base: 'block', md: 'grid' }} gap={{ base: '20px', xl: '20px' }}>
					<Card>
						<SimpleGrid>
							<ButtonField
								label={t('project.project_id')}
								buttonName={t('project.search_project')}
								onClick={() => {
									setIsShowModalSearchProject(true);
								}}
							/>
							<InputField
								disabled={false}
								value={projectId}
								type={'number'}
								onChange={e => {
									setProjectId(e.target.value);
								}}
							/>

							<HSeparator mb={'10px'} />
							<ButtonField
								label={t('idol.idol_id')}
								buttonName={t('project.search_idol')}
								onClick={() => {
									setIsShowModalSearchMember(true);
								}}
							/>
							<InputField
								disabled={false}
								value={idolId}
								type={'number'}
								onChange={e => {
									setIdolId(e.target.value);
								}}
							/>
							<HSeparator />
							<ButtonField
								buttonName={t('common.add')}
								onClick={() => {
									requestProjectAddIdol();
								}}
							/>
						</SimpleGrid>
					</Card>
				</Grid>
			</Flex>

			<Modal
				isOpen={isShowModalSearchProject}
				onClose={() => {
					setIsShowModalSearchProject(false);
				}}
				isCentered
				size={'4xl'}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('common.select_id_click')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody overflow="auto">
						<ProjectAddIdolSearch
							requestChangeParams={(selectId: string) => {
								setIsShowModalSearchProject(false);
								setProjectId(selectId);
							}}
						/>
					</ModalBody>
					<ModalFooter />
				</ModalContent>
			</Modal>

			<Modal
				isOpen={isShowModalSearchMember}
				onClose={() => {
					setIsShowModalSearchMember(false);
				}}
				isCentered
				size={'4xl'}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('common.select_id_click')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody overflow="auto">
						<ProjectAddIdolSearchMember
							requestChangeParams={(selectId: string) => {
								setIsShowModalSearchMember(false);
								setIdolId(selectId);
							}}
						/>
					</ModalBody>
					<ModalFooter />
				</ModalContent>
			</Modal>
		</Grid>
	);
}
