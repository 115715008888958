// Chakra imports
import { Button, Flex, Grid, SimpleGrid } from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../providers/api-provider';
import { useThemeColors } from '../../../../providers/theme-provider';
import { AppMetaDetailResponse, AppMetaTrain } from '../../../../apis/metaDataAPI';
import SwitchField from 'components/fields/SwitchField';
import { showToast } from '../../../../helper/functions';
import InputField from '../../../../components/fields/InputField';
import { OSType } from '../../../../types/enums';
import Card from 'components/card/Card';
import TextField from 'components/fields/TextField';
import { HSeparator } from '../../../../components/separator/Separator';

// 금지어 목록
export default function AppMetaDataDetail(props: { appMetaId: string; onRefresh: () => void }) {
	const { metaDataApi } = useApi();

	const { t } = useTranslation();
	const { textColorPrimary } = useThemeColors();
	const { appMetaId, onRefresh } = props;

	const [appMetaDetail, setAppMetaDetail] = useState<AppMetaTrain>(null);

	const [appVersion, setAppVersion] = useState<string>('');
	const [isUnderReview, setIsUnderReview] = useState<boolean>(false);

	useEffect(() => {
		if (appMetaId !== '-1') {
			requestAppMetaDetail(appMetaId);
		}
	}, [appMetaId]);

	// 목록 요청
	const requestAppMetaDetail = async (appMetaId: string) => {
		metaDataApi
			.getAppMetaDetail(String(appMetaId))
			.then(responseAppMetaDetail)
			.catch(() => {});
	};

	// 목록 처리
	const responseAppMetaDetail = (response: AppMetaDetailResponse) => {
		if (response.appMetaDetail) {
			setAppMetaDetail(response.appMetaDetail);

			setAppVersion(response.appMetaDetail.appVersion);
			setIsUnderReview(response.appMetaDetail.isUnderReview);
		}
	};

	const requestAppMeta = () => {
		if (appMetaId === '-1') {
			metaDataApi
				.postAppMeta(OSType.iOS, appVersion, isUnderReview)
				.then(() => {
					onRefresh();
				})
				.catch(() => {
					showToast(t('error.try_again'));
				});
		} else {
			metaDataApi
				.putAppMeta(appMetaId, appMetaDetail.osType, appVersion, isUnderReview)
				.then(() => {
					onRefresh();
				})
				.catch(() => {
					showToast(t('error.try_again'));
				});
		}
	};

	// Chakra Color Mode
	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '0px', md: '0px', xl: '0px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Flex direction={'column'}>
				<Grid
					display={{ base: 'block', md: 'grid' }}
					gridTemplateColumns={{ base: '1fr', lg: '1fr' }}
					gap={{ base: '20px', xl: '20px' }}
				>
					<Card>
						<SimpleGrid>
							<TextField label={t('settings.os_type')} text={appMetaDetail?.osType ? appMetaDetail?.osType : 'iOS'} />

							<InputField
								id="button"
								label={t('settings.review_app_version')}
								h="100px"
								mb="20px"
								disabled={appMetaId !== '-1'}
								placeholder={t('settings.review_app_version')}
								value={appVersion}
								onChange={e => {
									setAppVersion(e.target.value);
								}}
							/>

							<SwitchField
								id="mobile"
								mb="20px"
								label={t('settings.is_under_review')}
								isChecked={isUnderReview}
								onChange={e => {
									setIsUnderReview(e.target.checked);
								}}
							/>
						</SimpleGrid>

						<HSeparator />

						<Button
							variant="brand"
							minW="183px"
							w={'100%'}
							fontSize="sm"
							fontWeight="500"
							ms="auto"
							mt={'20px'}
							onClick={() => {
								requestAppMeta();
							}}
						>
							{t('common.save')}
						</Button>
					</Card>
				</Grid>
			</Flex>
		</Grid>
	);
}
