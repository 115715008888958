import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import translationEN from ".//lang/translation.en.json";
import translationKO from ".//lang/translation.ko.json";
import translationJP from ".//lang/translation.jp.json";


i18n
    .use(LanguageDetector)
    .use(initReactI18next) // bind react-i18next to the instance
    .init({
        fallbackLng: 'en',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        resources: {
            en: {
                translation: translationEN
            },
            ko: {
                translation: translationKO
            },
            jp: {
                translation: translationJP
            }
        },
        react: {
            bindI18n: 'languageChanged',
            bindI18nStore: '',
            transEmptyNodeValue: '',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
            useSuspense: true,
        }

    });


export default i18n;
