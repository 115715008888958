import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const setCookie = (name: string, value: string, options?: any) => {
	return cookies.set(name, value, { ...options, path: '/' });
};

export const getCookie = (name: string, options?: any) => {
	return cookies.get(name, { ...options, path: '/' });
};

export const removeCookie = (name: string, options?: any) => {
	return cookies.remove(name, { ...options, path: '/' });
}
