// Chakra imports
import { Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import Card from '../../../../components/card/Card';
import SearchTablePreContractList from '../components/SearchTablePreContractList';
import { useApi } from '../../../../providers/api-provider';
import { IdolPreContractListRequest, IdolPreContractSearchResponse } from '../../../../apis/idolAPI'; // 변경된 부분
// Custom components
// Assets

export default function PreContractIdolList() {
	const { idolApi } = useApi(); // 변경된 부분

	const [list, setList] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [limit, setLimit] = useState(10);

	const requestIdolPreContractSearch = async (offset: number, searchParams?: IdolPreContractListRequest) => {
		// 변경된 부분
		idolApi // 변경된 부분
			.idolPreContractList({
				limit: limit,
				offset: offset
			})
			.then(responseIdolPreContractSearch)
			.catch(() => {});
	};
	const responseIdolPreContractSearch = (response: IdolPreContractSearchResponse) => {
		// 변경된 부분
		if (response.idolPreContractTrain) {
			setList(response.idolPreContractTrain);
		}

		if (response.count) {
			setTotalCount(response.count);
		}
	};

	return (
		<Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
			<Card px="0px">
				<SearchTablePreContractList
					tableData={list}
					totalCount={totalCount}
					requestIdolPreContractSearch={requestIdolPreContractSearch}
				/>
			</Card>
		</Flex>
	);
}
