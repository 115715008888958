/* eslint-disable */

import { Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
// Custom components
import * as React from "react";
import { useEffect, useState } from "react";
// Assets
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import Pagination from "../paging/Pagination";
import { useThemeColors } from "../../providers/theme-provider";

interface Props<P, D> {
	request: (index: number, ...args: any[]) => void; // Function type remains the same
	data: D[]; // Array of generic type T for data
	columns: ColumnDef<D, any>[]; // Array of ColumnDefinition, which uses T
	totalCount: number;
	limit?: number;
	index?: number;
}

const SearchTable = <P, D>({ request, data, columns, totalCount, limit, index }: Props<P, D>) => {
	const { borderColor } = useThemeColors();

	// 현재 페이지 인덱스
	const [currentPageIndex, setCurrentPageIndex] = useState(0);
	const ROW_COUNT = limit ? limit : 10;
	// 1행에 보여줄 카드 갯수
	const CardPageSize = 1;

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		debugTable: true,
		debugHeaders: true,
		debugColumns: false
	});

	useEffect(() => {
		loadData(0);
	}, []);

	// 임시 데이터 로딩 처리
	const loadData = (index: number) => {
		request(index);
	};

	// 이전 페이지 데이터 로딩
	const loadPreviousDataPerPageSize = () => {
		if (currentPageIndex > 5) { // 5페이지 이상일 때만
			setCurrentPageIndex(currentPageIndex - 5);
			loadData(currentPageIndex - 5);
		}
	};

	const loadPreviousData = () => {
		if (currentPageIndex > 0) {
			setCurrentPageIndex(currentPageIndex - 1);
			loadData(currentPageIndex - 1);
		}
	};

	// 다음 페이지 데이터 로딩
	const loadNextData = () => {
		if (currentPageIndex * CardPageSize * ROW_COUNT < totalCount) {
			setCurrentPageIndex(currentPageIndex + 1);
			loadData(currentPageIndex + 1);
		}
	};

	const loadNextDataPerPageSize = () => {
		if ((currentPageIndex + 5) * CardPageSize * ROW_COUNT < totalCount) {
			// 5페이지 이상이고 더 불러올 데이터가 있을 때
			setCurrentPageIndex(currentPageIndex + 5);
			loadData(currentPageIndex + 5);
		}
	};

	return (
		<Flex direction="column" w="100%" overflowX={{ sm: 'scroll', md: 'scroll', lg: 'scroll' }}>
			<Table variant="simple" color="gray.500" mb="24px">
				<Thead>
					{table.getHeaderGroups().map(headerGroup => (
						<Tr key={headerGroup.id}>
							{headerGroup.headers.map(header => {
								return (
									<Th pe="10px" borderColor={borderColor} key={header.id} colSpan={header.colSpan}>
										{header.isPlaceholder ? null : (
											<Flex
												{...{
													className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
													onClick: header.column.getToggleSortingHandler()
												}}
												justify="space-between"
												align="center"
												fontSize={{ sm: '10px', lg: '12px' }}
												color="gray.400"
											>
												{flexRender(header.column.columnDef.header, header.getContext())}
												{{
													asc: '',
													desc: ''
												}[header.column.getIsSorted() as string] ?? null}
											</Flex>
										)}
									</Th>
								);
							})}
						</Tr>
					))}
				</Thead>
				<Tbody>
					{table.getRowModel().rows.map(row => {
						return (
							<Tr px="20px" key={row.id}>
								{row.getVisibleCells().map(cell => {
									return (
										<Td
											key={cell.id}
											fontSize={{ sm: '14px' }}
											minW={{ sm: '150px', md: '200px', lg: 'auto' }}
											borderColor={borderColor}
										>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</Td>
									);
								})}
							</Tr>
						);
					})}
				</Tbody>
			</Table>
			<Pagination
				currentIndex={currentPageIndex}
				setCurrentIndex={setCurrentPageIndex}
				pageSize={CardPageSize * ROW_COUNT}
				totalLength={totalCount}
				loadPreviousForPageSize={loadPreviousDataPerPageSize}
				loadNextForPageSize={loadNextDataPerPageSize}
				loadPrevious={loadPreviousData}
				loadNext={loadNextData}
				loadPage={(index: number) => {
					setCurrentPageIndex(index);
					loadData(index);
				}}
			/>
		</Flex>
	);
};

export default SearchTable;
