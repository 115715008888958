// Chakra imports
import {
	Box,
	Button,
	Flex,
	Icon,
	Select,
	SimpleGrid,
	Text,
	Tooltip,
	useBreakpointValue,
	useColorModeValue, useInterval
} from "@chakra-ui/react";

// Custom components
// Assets
import { MdOutlineDangerous, MdOutlineSearch } from "react-icons/md";
import LiveStreaming from "../../../../components/card/LiveStreaming";
import { useTranslation } from "react-i18next";
import * as React from "react";
import { useEffect, useState } from "react";
import Pagination from "../../../../components/paging/Pagination";
import { TYPE_KEY_SORT } from "../../../../helper/types";
import Filter from "../../../../components/actions/Filter";
import { showToast } from "../../../../helper/functions";
import ModalForceClose from "./components/ModalForceClose";
import SearchInput from "../../../../components/actions/Search";
import Card from "components/card/Card";
import { LiveStreamingResponse, LiveStreamingTrain } from "../../../../apis/livestreamingAPI";
import { useApi } from "../../../../providers/api-provider";
import { LiveStreamingBroadcastStatusType, LiveStreamingSearchType } from "../../../../types/enums";

// Live 콘텐츠 목록
export default function LiveContents() {
	const { t } = useTranslation();
	const { contentsApi } = useApi();

	// UI Color
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const buttonBg = useColorModeValue('transparent', 'navy.800');
	const hoverButton = useColorModeValue({ bg: 'gray.100' }, { bg: 'whiteAlpha.100' });
	const activeButton = useColorModeValue({ bg: 'gray.200' }, { bg: 'whiteAlpha.200' });

	// 현재 페이지에 노출되고 있는 데이터
	const [data, setData] = useState<LiveStreamingTrain[]>([]);

	// 검색어
	const [searchString, setSearchString] = useState('');
	// 검색어 타입
	const [currentSearchType, setCurrentSearchType] = useState<LiveStreamingSearchType>(LiveStreamingSearchType.ALL);

	// 현재 정렬 필터
	const [currentSortFilter, setCurrentSortFilter] = useState(TYPE_KEY_SORT.LATEST);

	const [totalCount, setTotalCount] = useState(0);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);

	const [isRefreshing, setIsRefreshing] = useState(false);

	// 현재 페이지 인덱스
	const [currentPageIndex, setCurrentPageIndex] = useState(0);
	const ROW_COUNT = 2;
	// 1행에 보여줄 카드 갯수
	const CardPageSize = useBreakpointValue(
		{
			base: 1,
			md: 2,
			lg: 3,
			xl: 4,
			'2xl': 5
		},
		{}
	);

	useInterval(() => {
		if (isRefreshing) {
			requestLiveStreaming(currentPageIndex);
		}

	}, isRefreshing ? 30 * 1000 : 0);

	const [showModalForceClose, setShowModalForceClose] = useState(false);
	const [selectLiveStreamingId, setSelectLiveStreamingId] = useState<number>(-1);
	const requestStreamaingForceClose = (reason: string) => {
		if (selectLiveStreamingId !== -1) {
			contentsApi
				.postLivestreamingForceQuit(String(selectLiveStreamingId), reason)
				.then((response) => {
					requestLiveStreaming(0);
				})
				.catch((error) => {
					showToast(t('error.try_again'));
				});
		}
	};

	useEffect(() => {
		setCurrentPageIndex(0);
		requestLiveStreaming(0);

		setIsRefreshing(true);

		return () => {
			setIsRefreshing(false);
		}
	}, []);


	const requestLiveStreaming = (offset: number) => {
		contentsApi
			.getLivestreamingList({
				searchWord: searchString,
				searchCondition: currentSearchType === LiveStreamingSearchType.ALL ? null : currentSearchType,
				broadcastStatus: LiveStreamingBroadcastStatusType.LIVE,
				limit: limit,
				offset: offset * limit
			})
			.then(responseLiveStreaming)
			.catch(() => {});
	};

	// 임시 데이터 로딩 처리
	const responseLiveStreaming = (response: LiveStreamingResponse) => {
		if (response) {
			setData(response.liveStreamingTrain);
			setTotalCount(response.count);
		}
	};

	// 화면 사이즈에 따른 1행에 표시될 갯수가 정해진 뒤 데이타 로딩
	useEffect(() => {
		if (CardPageSize !== undefined) {
			// loadData(currentPageIndex);
			requestLiveStreaming(currentPageIndex);
		}
	}, [CardPageSize]);

	useEffect(() => {
		// loadData(currentPageIndex);
		requestLiveStreaming(currentPageIndex);
	}, [currentSortFilter]);

	// 이전 페이지 데이터 로딩
	const loadPreviousData = () => {
		if (currentPageIndex > 0) {
			setCurrentPageIndex(currentPageIndex - 1);
			// loadData(currentPageIndex - 1);
			setCurrentPageIndex(currentPageIndex - 1);
			requestLiveStreaming(currentPageIndex - 1);
		}
	};

	// 다음 페이지 데이터 로딩
	const loadNextData = () => {
		if (currentPageIndex * CardPageSize * ROW_COUNT < totalCount) {
			setCurrentPageIndex(currentPageIndex + 1);
			requestLiveStreaming(currentPageIndex + 1);
		}
	};

	// 정렬 필터 변경
	const filterChange = (type: string) => {
		setCurrentSortFilter(type);
	};

	// 검색 엔터키 처리
	const handleSearchKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleClickSearch();
		}
	};

	const handleClickSearch = () => {
		if (searchString.trim().length < 2 && searchString.trim() !== '') {
			showToast(t('common.alert_search_word_length'), 'error');
			return;
		}

		requestLiveStreaming(0);
	};

	// 검색어 저장
	const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchString(e.target.value);
	};

	const handleClickForceClose = (id: number) => {
		setSelectLiveStreamingId(id);
		setShowModalForceClose(true);
	};

	// Chakra Color Mode
	return (
		<Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
			<Card>
				<Box>
					{/* Main Fields */}
					<Flex w="100%" direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'}>
						<SearchInput
							leftElement={
								<Select
									fontSize="sm"
									id="edit_product"
									variant="unstyled"
									h={'44px'}
									w={{ base: '100px', md: '80px', xl: '120px' }}
									onChange={e => setCurrentSearchType(e.target.value as LiveStreamingSearchType)}
								>
									<option value={LiveStreamingSearchType.ALL}>{t('components.all')}</option>
									<option value={LiveStreamingSearchType.IDOL_STAGE_NAME}>{t('components.list_search_type_idol_name')}</option>
									<option value={LiveStreamingSearchType.IDOL_NAME}>
										{t('components.list_search_type_idol_real_name')}
									</option>
									<option value={LiveStreamingSearchType.LIVE_STREAMING_TITLE}>{t('components.list_search_type_title')}</option>
									<option value={LiveStreamingSearchType.LIVE_STREAMING_TAG}>{t('components.list_search_type_tag')}</option>
								</Select>
							}
							rightElement={
								<Button h="1.75rem" size="sm" onClick={handleClickSearch} borderRadius="20px">
									<Icon color={textColor} as={MdOutlineSearch} />
								</Button>
							}
							onChangeSearch={onSearchInputChange}
							onEnterKeyPress={handleSearchKeyPress}
						/>

						<Flex justifyContent={'flex-end'} mr={{ base: '0px', sm: '10px' }}>
							<Filter onChange={filterChange} initialSortType={TYPE_KEY_SORT.LATEST} />
							<Tooltip label={t('content.live.list_force_stop')}>
								<Button
									me={{ base: '10px', md: '20px' }}
									ml={{ base: '10px', sm: '10px', md: '10px' }}
									bg={buttonBg}
									border="1px solid"
									color="secondaryGray.600"
									borderColor={useColorModeValue('secondaryGray.100', 'whiteAlpha.100')}
									borderRadius="16px"
									_placeholder={{ color: 'secondaryGray.600' }}
									_hover={hoverButton}
									_active={activeButton}
									_focus={activeButton}
								>
									<Icon color={textColor} as={MdOutlineDangerous} />
								</Button>
							</Tooltip>
						</Flex>
					</Flex>
					<Text mt="25px" mb="36px" color={textColor} fontSize="2xl" ms="24px" fontWeight="700">
						{t('content.live.list_title')}
					</Text>
					<SimpleGrid columns={CardPageSize} gap="20px">
						{data.map(item => {
							return (
								<LiveStreaming
									id={item.liveStreamingId}
									userName={item.idolActivityName}
									userRealName={item.idolRealName}
									title={item.title}
									tags={item.hashTagTrain}
									thumbnail={item.thumbnailImgUrl}
									userProfileThumbnail={item.idolProfileImgUrl}
									url={item.thumbnailImgUrl}
									viewCount={item.currentViewerCount}
									handleClickForceClose={handleClickForceClose}
								/>
							);
						})}
					</SimpleGrid>
					<Pagination
						currentIndex={currentPageIndex}
						setCurrentIndex={setCurrentPageIndex}
						pageSize={CardPageSize * ROW_COUNT}
						totalLength={totalCount}
						loadPrevious={loadPreviousData}
						loadNext={loadNextData}
						loadNextForPageSize={() => {
							if (currentPageIndex + limit > totalCount) {
								setCurrentPageIndex(totalCount / limit);
								requestLiveStreaming(totalCount / limit);
							} else {
								setCurrentPageIndex(currentPageIndex + limit);
								requestLiveStreaming(currentPageIndex + limit);
							}
						}}
						loadPage={(index: number) => {
							setCurrentPageIndex(index);
							requestLiveStreaming(index);
						}}
					/>
					<ModalForceClose
						isOpen={showModalForceClose}
						requestStreamingForceClose={requestStreamaingForceClose}
						item={data.find(item => item.liveStreamingId === selectLiveStreamingId)}
						onClose={() => {
							setShowModalForceClose(false);
						}}
					/>
				</Box>
			</Card>
		</Flex>
	);
}
