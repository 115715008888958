import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'assets/css/MiniCalendar.css';
import { Button, Flex, Icon, Select, Text } from '@chakra-ui/react';
// Chakra imports
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
// Custom components
import Card from 'components/card/Card';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function MiniCalendar(props: {
	selectRange: boolean;
	value?: any;
	isShowTimeSelect?: boolean;
	onChange?: any;
	onClose?: () => void;
	[x: string]: any;
}) {
	const { selectRange, value, onChange, isShowTimeSelect, onClose, ...rest } = props;

	const { t } = useTranslation();
	// value가 Date 객체라면 시간과 분을 추출하여 초기화
	const initialHour = value ? value.getHours() : 0;
	const initialMinute = value ? value.getMinutes() : 0;

	// 시간을 선택할 상태
	const [selectedHour, setSelectedHour] = useState<number>(initialHour);
	const [selectedMinute, setSelectedMinute] = useState<number>(initialMinute);

	// 시간 선택 핸들러
	const handleTimeChange = (type: 'hour' | 'minute', event: React.ChangeEvent<HTMLSelectElement>) => {
		const newValue = parseInt(event.target.value, 10);
		const updatedDate = value // value 객체를 복사하여 새로운 Date 생성

		if (type === 'hour') {
			updatedDate.setHours(newValue);
			setSelectedHour(newValue);
		} else {
			updatedDate.setMinutes(newValue);
			setSelectedMinute(newValue);
		}

		// 날짜 + 시간을 합쳐서 onChange로 전달
		if (onChange) {
			onChange(updatedDate);
		}
	};

	useEffect(() => {
		// value가 변경되면 시간과 분을 업데이트
		if (value) {
			setSelectedHour(value.getHours());
			setSelectedMinute(value.getMinutes());
		}
	}, [value]);

	return (
		<Card
			alignItems="center"
			flexDirection="column"
			w="100%"
			maxW="max-content"
			p="20px 15px"
			h="max-content"
			{...rest}
		>
			<Calendar
				onChange={onChange}
				value={value}
				selectRange={selectRange}
				view={'month'}
				tileContent={<Text color="brand.500" />}
				prevLabel={<Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />}
				nextLabel={<Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />}
			/>

			{isShowTimeSelect ? (
				<Flex mt={4} alignItems="center">
					<Select value={selectedHour} onChange={event => handleTimeChange('hour', event)} w="80px" mr={2}>
						{Array.from({ length: 24 }, (_, i) => (
							<option key={i} value={i}>
								{i < 10 ? `0${i}` : i}
							</option>
						))}
					</Select>
					<Select value={selectedMinute} onChange={event => handleTimeChange('minute', event)} w="80px">
						{Array.from({ length: 60 }, (_, i) => (
							<option key={i} value={i}>
								{i < 10 ? `0${i}` : i}
							</option>
						))}
					</Select>
					<Button onClick={onClose} ml={'10px'}> {t('common.select')} </Button>
				</Flex>
			) : null}
		</Card>
	);
}
