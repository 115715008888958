// Chakra imports
import { Box, Icon, SimpleGrid, Text } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useThemeColors } from '../../providers/theme-provider';
import { debugLog, showToast } from "../../helper/functions";
import { MdOutlineCloudUpload } from 'react-icons/md';
import DropImageFile from './DropImageFile';
import DropImageFileFromUrl from './DropImageFileFromUrl';
import Dropzone from 'views/admin/project/project/components/Dropzone';

export default function DropImageBox(props: {
	imgUrls?: string[];
	setImgUrls?: React.Dispatch<React.SetStateAction<string[]>>;
	imgFiles?: File[];
	setImgFiles?: React.Dispatch<React.SetStateAction<File[]>>;
	maxFiles?: number;
	title?: string;
}) {
	const { t } = useTranslation();
	const { imgUrls, setImgUrls, imgFiles, setImgFiles, maxFiles, title } = props;
	const { textColor } = useThemeColors();

	const onDropImages = useCallback(
		(acceptedFiles: File[]) => {
			if (imgUrls && imgUrls.length > 0) {
				showToast(t('error.exist_thumbnail_img'));
				return;
			}

			const mappedFiles = acceptedFiles.map(file =>
				Object.assign(file, {
					preview: URL.createObjectURL(file)
				})
			);

			setImgFiles(maxFiles === 1 ? [mappedFiles[0]] : [...imgFiles, ...mappedFiles]);
		},
		[imgUrls, imgFiles]
	);

	const onDeleteImages = useCallback(
		(index: number) => {
			if (maxFiles === 1) {
				setImgFiles([]);
			} else {
				const updatedImagesPreview = imgFiles.filter((_, i) => i !== index);
				setImgFiles(updatedImagesPreview);
			}
		},
		[imgFiles, setImgFiles]
	);

	const onDeleteImagesUrl = useCallback(
		(index: number) => {
			debugLog('======> file ' + imgUrls.length);

			if (maxFiles === 1) {
				setImgUrls([]);
			} else {
				const updatedImagesPreview = imgUrls.filter((_, i) => i !== index);
				setImgUrls(updatedImagesPreview);
			}
		},
		[imgUrls, setImgUrls]
	);

	return (
		<Box maxH="100%" borderWidth="1px" borderRadius="lg" position={'relative'} overflow={'hidden'}>
			<SimpleGrid columns={{ sm: maxFiles }} gap={'10px'}>
				{imgFiles &&
					imgFiles.length > 0 &&
					imgFiles.map((item, index) => {
						return <DropImageFile index={index} file={item} onDeleteFile={onDeleteImages} />;
					})}
				{imgUrls &&
					imgUrls.length > 0 &&
					imgUrls.map((item, index) => {
						return <DropImageFileFromUrl index={index} url={item} onDeleteFile={onDeleteImagesUrl} />;
					})}
			</SimpleGrid>
			<Dropzone
				content={
					<Box maxW="100%">
						<Icon as={MdOutlineCloudUpload} w="20px" h="20px" color={textColor} />
						<Text mb="8px" fontSize="md" w="100%" maxW="100%" fontWeight="700" color={textColor} whiteSpace="pre-wrap">
							{title ? title : t('common.image')}
						</Text>
						<Text fontSize="sm" fontWeight="500" color="secondaryGray.500" white-space="pre-wrap !important" whiteSpace="pre-wrap">
							{t('components.only_accept_image_files')}
						</Text>
					</Box>
				}
				maxFiles={5}
				onDropAccepted={onDropImages}
				//onDropImage={onDropImages}
			/>
		</Box>
	);
}
