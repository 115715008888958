// Chakra imports
import { Avatar, Box, Button, Flex, Image, Link, Tag, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { useTranslation } from "react-i18next";
import { HashtagTrain } from "../../apis/livestreamingAPI";
// Assets

export default function LiveStreaming(props: {
	id: number;
	thumbnail: string;
	userProfileThumbnail: string;
	title: string;
	userName: string;
	userRealName: string;
	tags: HashtagTrain[];
	url: string;
	viewCount: number;
	handleClickForceClose: any;
}) {
	const {
		id,
		title,
		thumbnail,
		userProfileThumbnail,
		userName,
		userRealName,
		tags,
		url,
		viewCount,
		handleClickForceClose
	} = props;

	const { t } = useTranslation();
	const textColor = useColorModeValue('navy.700', 'white');
	const textColorBid = useColorModeValue('brand.500', 'white');
	const textBrand = useColorModeValue('brand.500', 'white');
	const bgBadge = useColorModeValue('secondaryGray.300', 'whiteAlpha.50');

	const handleClickDetail = () => {
		window.location.href = '/admin/contents/live/' + id;
	};

	return (
		<Card p="20px" id={'live' + id}>
			<Flex direction={{ base: 'column' }} justify="center" w="100%">
				<Flex flexDirection="row" justify="flex-start" h="100%" mb={5} w="100%" onClick={handleClickDetail}>
					<Avatar key={0} src={userProfileThumbnail} marginRight={5} />
					<Flex direction="column" w="80%">
						<Text
							color={textColor}
							fontSize={{
								base: 'xl',
								md: 'lg',
								lg: 'lg',
								xl: 'lg',
								'2xl': 'md',
								'3xl': 'lg'
							}}
							mb="5px"
							fontWeight="bold"
							me="14px"
							noOfLines={1}
						>
							{userName}
						</Text>
						<Text
							color="secondaryGray.600"
							fontSize={{
								base: 'sm'
							}}
							fontWeight="400"
							me="14px"
							noOfLines={1}
							w="100%"
							maxWidth="100%"
						>
							{userRealName}
						</Text>
					</Flex>
				</Flex>
				<Box  onClick={handleClickDetail}>
					<Image
						src={thumbnail}
						w={{ base: '100%', '3xl': '100%' }}
						h={{ base: 'xs'}}
						borderRadius="6px"
						objectFit='cover'
					/>
				</Box>
				<Flex flexDirection="column" justify="space-between" h="100%">
					<Flex
						justify="space-between"
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mb="auto"
						onClick={handleClickDetail}
					>
						<Flex direction="column">
							<Text
								color={textColor}
								fontSize={{
									base: 'xl',
									md: 'lg',
									lg: 'lg',
									xl: 'lg',
									'2xl': 'md',
									'3xl': 'lg'
								}}
								mb="5px"
								fontWeight="bold"
								me="14px"
							>
								{title}
							</Text>
							<Flex direction="row" mt="10px">
								{tags && tags.map((tag, key) => (
									<Tag
										key={key}
										bg={bgBadge}
										textAlign="center"
										mb={{ base: '20px', md: '0px' }}
										color={textBrand}
										me="10px"
										h="max-content"
									>
										{tag.hashTag}
									</Tag>
								))}
							</Flex>
						</Flex>
					</Flex>
					<Flex
						justify="space-between"
						align={{
							base: 'center',
							md: 'start',
							lg: 'center',
							xl: 'start',
							'2xl': 'center'
						}}
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mt="25px"
					>
						<Text fontWeight="700" fontSize="sm" color={textColorBid}>
							{t('components.view_count', { 'counts': viewCount })}
						</Text>
						<Link
							mt={{
								base: '0px',
								md: '10px',
								lg: '0px',
								xl: '10px',
								'2xl': '0px'
							}}
							zIndex={1}
						>
							<Button
								variant="brand"
								color="white"
								fontSize="sm"
								fontWeight="500"
								borderRadius="6px"
								px="24px"
								py="5px"
								onClick={() => {
									handleClickForceClose(id);
								}}
							>
								{t('content.live.force_stop')}
							</Button>
						</Link>
					</Flex>
				</Flex>
			</Flex>
		</Card>
	);
}
