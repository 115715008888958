// Chakra imports
import {
	Flex,
	Grid,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	SimpleGrid
} from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useState } from 'react';
import Card from 'components/card/Card';
import { useApi } from '../../../../providers/api-provider';
import { useTranslation } from 'react-i18next';
import { useThemeColors } from '../../../../providers/theme-provider';
import { ReportTrain } from '../../../../apis/reportAPI';
import TextField from '../../../../components/fields/TextField';
import { MemberTypeString, ReportContentTypeString, ReportStatusString } from '../../../../helper/types';
import { HSeparator } from '../../../../components/separator/Separator';
import SelectField from 'components/fields/SelectField';
import ButtonField from 'components/fields/ButtonField';
import {
	ReportContentsType,
	ReportStatusProcessingType,
	ReportStatusResultType,
	ReportStatusType
} from '../../../../types/enums';
import { showToast } from '../../../../helper/functions';
import ModalBasic from '../../../../components/modal/ModalBasic';
import ReportDetailInformation from './detailInformation';

// 금지어 목록
export default function ReportDetail(props: { reportDetail: ReportTrain; requestChangeParams: () => void }) {
	const { t } = useTranslation();
	const {} = useThemeColors();
	const { reportApi } = useApi();

	const { reportDetail, requestChangeParams } = props;

	const [item, setItem] = useState<ReportTrain>(null);
	const [isShowConfirm, setIsShowConfirm] = useState(false);

	const [isShowDetailInformation, setIsShowDetailInformation] = useState(false);

	useEffect(() => {
		if (reportDetail !== null) {
			setItem(reportDetail);
		}
	}, [reportDetail]);

	const requestReportStatus = () => {
		if (item?.status === ReportStatusType.PENDING) {
			item.status = ReportStatusType.VALID;
		}

		reportApi
			.postReportConfirm(String(reportDetail?.reportId), item?.status)
			.then(responseDetail)
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	const responseDetail = (response: any) => {
		reportDetail.status = item.status;
		requestChangeParams();
	};

	const requestReportStatusRevert = () => {
		reportApi.postReportRevert(String(reportDetail?.reportId)).then(responseDetail).catch(() => {
			showToast(t('error.try_again'));
		})
	}

	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '0px', md: '0px', xl: '0px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Flex direction={'column'}>
				<Grid
					display={{ base: 'block', md: 'grid' }}
					gridTemplateColumns={{ base: '1.5fr 1.5fr', lg: '1.5fr 1.5fr' }}
					gap={{ base: '20px', xl: '20px' }}
				>
					<Card>
						<SimpleGrid>
							<TextField label={t('common.id')} text={String(reportDetail?.reportId)} />
							<TextField
								label={t('reports.contents_type')}
								text={ReportContentTypeString(t, reportDetail?.contentsType)}
							/>
							<TextField label={t('reports.report_member_id')} text={String(reportDetail?.memberId)} />
							<TextField
								label={t('reports.report_member_type')}
								text={MemberTypeString(t, String(reportDetail?.memberType))}
							/>
							{/*<TextField*/}
							{/*	label={t('reports.report_member_type')}*/}
							{/*	text={MemberTypeString(t, String(reportDetail?.memberType))}*/}
							{/*/>*/}
							<TextField
								label={t('reports.report_status')}
								text={ReportStatusString(t, String(reportDetail?.status))}
							/>
						</SimpleGrid>
					</Card>
					<Card>
						<SimpleGrid>
							<ButtonField
								label={t('reports.contents')}
								buttonName={t('common.view_details')}
								onClick={() => {
									if (
										reportDetail.contentsType === ReportContentsType.LIVE_STREAMING_CHAT ||
										reportDetail.contentsType === ReportContentsType.LIVE_CHATTING ||
										reportDetail.contentsType === ReportContentsType.VOD_COMMENT
									) {
										showToast(t('error.prepare_function'));
										return;
									}

									setIsShowDetailInformation(true);
								}}
							/>
							<HSeparator />
							<SelectField
								label={t('reports.select_status')}
								mt={'20px'}
								disabled={reportDetail?.status === ReportStatusType.REVERTED || reportDetail?.status === ReportStatusType.INVALID}
								value={item?.status}
								options={Object.values(
									reportDetail?.status === ReportStatusType.PENDING
										? ReportStatusProcessingType
										: ReportStatusResultType
								)}
								displayOptionsString={ReportStatusString}
								onChange={e => {
									setItem(prevState => {
										return {
											...prevState,
											status: e.target.value as ReportStatusType
										};
									});
								}}
							/>
							<ButtonField
								buttonName={t('common.save')}
								disabled={reportDetail?.status === ReportStatusType.REVERTED || reportDetail?.status === ReportStatusType.INVALID}
								onClick={() => {
									setIsShowConfirm(true);
								}}
							/>
						</SimpleGrid>
					</Card>
				</Grid>
			</Flex>

			<ModalBasic
				isOpen={isShowConfirm}
				onClose={() => {
					setIsShowConfirm(false);
				}}
				title={t('reports.confirm_change_status')}
				desc={t('reports.confirm_change_status_desc')}
				secondaryButton={{
					title: t('common.ok'),
					onClick: () => {
						if (reportDetail?.status === ReportStatusType.PENDING || reportDetail?.status === ReportStatusType.REVERTED) {
							requestReportStatus();
						} else {
							requestReportStatusRevert();
						}

						setIsShowConfirm(false);
					}
				}}
			/>

			<Modal
				isOpen={isShowDetailInformation}
				onClose={() => {
					setIsShowDetailInformation(false);
				}}
				isCentered
				size={'xl'}
			>
				<ModalOverlay />

				<ModalContent>
					<ModalHeader>{t('reports.contents_detail')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody overflow="auto">
						<ReportDetailInformation reportDetail={reportDetail} requestChangeParams={() => {}} />
					</ModalBody>
				</ModalContent>
			</Modal>
		</Grid>
	);
}
