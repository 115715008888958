// Chakra imports
import {
	Button,
	Flex,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Select,
	Text,
	Tooltip,
	useBoolean,
	useColorModeValue
} from '@chakra-ui/react';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import Card from '../../../../components/card/Card';
import { useApi } from '../../../../providers/api-provider';
import {
	BlacklistTrain,
	MemberBlackListedRequest,
	MemberBlacklistResponse,
	MemberListSearchRequest
} from '../../../../apis/memberAPI';
import { DATA_FORMAT_FULL_DATE, dateFormat, showToast } from '../../../../helper/functions';
import {
	MemberSearchType,
	MemberSocialLoginType,
	MemberStatusType,
	MemberType,
	TableRowType
} from '../../../../types/enums';
import SearchTable from '../../../../components/table/SearchTable';
import SearchInput from '../../../../components/actions/Search';
import { MdOutlineSearch, MdRestore } from 'react-icons/md';
import MiniCalendar from '../../../../components/calendar/MiniCalendar';
import moment from 'moment/moment';
import UserDetail from '../detail';
import { MemberTypeString, TYPE_KEY_SORT } from '../../../../helper/types';
import { useTranslation } from 'react-i18next';
import { useThemeColors } from '../../../../providers/theme-provider';
import { createDynamicColumns, VisibleColumns } from '../../../../components/table/createDynamicColumns';
// Custom components
// Assets

export default function Blacklisted() {
	const { t } = useTranslation();
	const { memberApi } = useApi();
	const { textColor, buttonBg, activeButton, hoverButton } = useThemeColors();

	const [list, setList] = useState<BlacklistTrain[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);

	// 체크 박스 선택 값
	const [filterUserStatus, setFilterUserStatus] = useState<(string | number)[]>([]);
	const [filterUserType, setFilterUserType] = useState<(string | number)[]>([]);
	const [filterUserLoginType, setFilterUserLoginType] = useState<(string | number)[]>([]);
	const [filterUserIapYn, setFilterUserIapYn] = useState<(string | number)[]>([]);

	// 검색어
	const [searchString, setSearchString] = useState('');
	const [searchParams, setSearchParams] = useState<MemberListSearchRequest>(null);

	// 검색어 타입
	const [currentSearchType, setCurrentSearchType] = useState<MemberSearchType>(MemberSearchType.ALL);

	// 현재 정렬 필터
	const [currentSortFilter, setCurrentSortFilter] = useState(TYPE_KEY_SORT.LATEST);

	// 검색 필터 날짜
	const [today, setToday] = useState(new Date());

	const [isShowStartDate, setIsShowStartDate] = useBoolean(false);
	const [startDate, onStartDateChange] = useState<Date>(today);

	const [isShowEndDate, setIsShowEndDate] = useBoolean(false);
	const [endDate, onEndDateChange] = useState<Date>(today);

	const [selectMemberId, setSelectMemberId] = useState(-1);
	const [selectMemberType, setSelectMemberType] = useState(MemberType.IDOL);

	// 유저 상세 팝업 표시
	const [isShowDetail, setIsShowDetail] = useState(false);

	const requestUserList = async (offset: number, searchParams?: MemberListSearchRequest) => {
		memberApi
			.blacklisted({
				limit: limit,
				offset: offset
			})
			.then(responseMemberSearch)
			.catch(() => {});
	};

	const responseMemberSearch = (response: MemberBlacklistResponse) => {
		if (response.blackListTrain) {
			setList(response.blackListTrain);
		}

		if (response.count) {
			setTotalCount(response.count);
		}
	};

	const requestMemberBlacklisted = (id: number, type: MemberType, reason: string) => {
		let current = new Date();
		let endDate = new Date(current.setFullYear(current.getFullYear() + 90));

		let memberBlacklistRequest: MemberBlackListedRequest = {
			memberType: type,
			blackListStartDate: dateFormat(new Date(), DATA_FORMAT_FULL_DATE),
			blackListEndDate: dateFormat(endDate, DATA_FORMAT_FULL_DATE),
			registerReason: reason
		};

		memberApi.addBlacklisted(id, memberBlacklistRequest).then(() => {});
	};

	//  최초 데이터 불러오기 : 6개월치 회원 데이터
	useEffect(() => {
		let startDate = new Date();
		onStartDateChange(new Date(startDate.setMonth(startDate.getMonth() - 10)));
		onEndDateChange(new Date());

		setSearchParams(prevState => {
			return {
				...prevState,
				searchWord: '',
				registerDateStart: dateFormat(startDate, DATA_FORMAT_FULL_DATE),
				registerDateEnd: dateFormat(endDate, DATA_FORMAT_FULL_DATE)
			};
		});
		// setCurrentPageIndex(0);
	}, []);

	// 검색 타입이 변경된 경우
	useEffect(() => {
		if (currentSearchType) {
			// setCurrentPageIndex(0);
			setSearchParams(prevState => {
				return {
					...prevState,
					searchType: currentSearchType === MemberSearchType.ALL ? null : currentSearchType
				};
			});
		}
	}, [currentSearchType]);

	// 필터 유저 상태
	useEffect(() => {
		if (filterUserStatus) {
			// setCurrentPageIndex(0);
			setSearchParams(prevState => {
				return {
					...prevState,
					memberStatus: filterUserStatus[0] as MemberStatusType
				};
			});
		}
	}, [filterUserStatus]);

	// 필터 로그인 타입
	useEffect(() => {
		if (filterUserLoginType) {
			// setCurrentPageIndex(0);
			setSearchParams(prevState => {
				return {
					...prevState,
					loginType: filterUserLoginType[0] as MemberSocialLoginType
				};
			});
		}
	}, [filterUserLoginType]);

	// 필터 유저 타입
	useEffect(() => {
		if (filterUserType) {
			// setCurrentPageIndex(0);
			setSearchParams(prevState => {
				return {
					...prevState,
					memberType: filterUserType[0] as MemberType
				};
			});
		}
	}, [filterUserType]);

	// 가입 날짜 - 시작
	useEffect(() => {
		if (startDate) {
			// setCurrentPageIndex(0);
			setSearchParams(prevState => {
				return {
					...prevState,
					registerDateStart: dateFormat(startDate, DATA_FORMAT_FULL_DATE)
				};
			});
		}
	}, [startDate]);

	// 가입 날짜 - 종료
	useEffect(() => {
		if (endDate) {
			// setCurrentPageIndex(0);
			setSearchParams(prevState => {
				return {
					...prevState,
					registerDateEnd: dateFormat(endDate, DATA_FORMAT_FULL_DATE)
				};
			});
		}
	}, [endDate]);

	// 정렬 필터 변경
	const filterChange = (type: string) => {
		setCurrentSortFilter(type);
	};

	// 검색 엔터키 처리
	const handleSearchKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleClickSearch();
		}
	};

	// 검색 버튼 클릭
	const handleClickSearch = () => {
		if (searchString.trim().length < 2 && searchString.trim() !== '') {
			showToast(t('common.alert_search_word_length'), 'error');
			return;
		}

		setSearchParams(prevState => {
			return {
				...prevState,
				searchWord: searchString
			};
		});
	};

	// 검색어 저장
	const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchString(e.target.value);
	};

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<BlacklistTrain>[] = [
		{
			row: 'memberId',
			type: TableRowType.TEXT
		},
		{ row: 'activityName', type: TableRowType.TEXT },
		{ row: 'memberType', type: TableRowType.ENUM, enumToText: MemberTypeString },
		{ row: 'registerReason', type: TableRowType.TEXT },
		{ row: 'blackListStartDateTime', type: TableRowType.DATETIME },
		{ row: 'blackListEndDateTime', type: TableRowType.DATETIME }
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		setSelectMemberId(rowData.memberId);
		setSelectMemberType(rowData.memberType);
		setIsShowDetail(true);
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	return (
		<Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
			<Card px="0px">
				<Flex w="100%" direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'} mb="10px">
					<SearchInput
						onChangeSearch={onSearchInputChange}
						onEnterKeyPress={handleSearchKeyPress}
						leftElement={
							<Select
								fontSize="sm"
								id="edit_product"
								variant="unstyled"
								h={'44px'}
								w={{ base: '100px', md: '80px', xl: '120px' }}
								onChange={e => setCurrentSearchType(e.target.value as MemberSearchType)}
							>
								<option value={MemberSearchType.ALL}>{t('components.all')}</option>
								<option value={MemberSearchType.ACTIVITY_NAME}>
									{t('components.list_search_type_idol_real_name')}
								</option>
								<option value={MemberSearchType.PID}>{t('components.list_search_type_idol_pid')}</option>
								<option value={MemberSearchType.REGISTER_DATE}>{t('components.list_search_type_reg_date')}</option>
								<option value={MemberSearchType.MOBILE}>{t('components.list_search_type_mobile')}</option>
								<option value={MemberSearchType.EMAIL}>{t('components.list_search_type_email')}</option>
							</Select>
						}
						rightElement={
							<Button h="1.75rem" size="sm" onClick={handleClickSearch} borderRadius="20px">
								<Icon color={textColor} as={MdOutlineSearch} />
							</Button>
						}
					/>
					<Flex justifyContent={'flex-end'} mr={{ base: '0px', sm: '10px' }}>
						<Popover
							isOpen={isShowStartDate}
							onOpen={setIsShowStartDate.on}
							onClose={setIsShowStartDate.off}
							closeOnBlur={false}
							isLazy
							lazyBehavior="keepMounted"
						>
							<PopoverTrigger>
								<Button w="180px" colorScheme="purple">
									{dateFormat(startDate)}
								</Button>
							</PopoverTrigger>
							<PopoverContent bg={'transparent'} border={'0px'}>
								<MiniCalendar
									selectRange={false}
									mb="20px"
									onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
										if (endDate) {
											const timeStart = moment(value);
											const timeEnd = moment(endDate);
											const diff = timeEnd.diff(timeStart);
											if (diff < 0) {
												alert(t('user.list.filter_user_register_start_date_error'));
												return;
											}

											onStartDateChange(value);
											setIsShowStartDate.off();
										}
									}}
									value={startDate}
								/>
							</PopoverContent>
						</Popover>
						<Text px={'20px'} textAlign={'center'} mt={'10px'}>
							{'  ~  '}
						</Text>
						<Popover
							isOpen={isShowEndDate}
							onOpen={setIsShowEndDate.on}
							onClose={setIsShowEndDate.off}
							closeOnBlur={false}
							isLazy
							lazyBehavior="keepMounted"
						>
							<PopoverTrigger>
								<Button w="180px" colorScheme="purple">
									{dateFormat(endDate)}
								</Button>
							</PopoverTrigger>
							<PopoverContent bg={'transparent'} border={'0px'}>
								<MiniCalendar
									selectRange={false}
									mb="20px"
									onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
										if (startDate) {
											const timeStart = moment(startDate);
											const timeEnd = moment(value);
											const diff = timeEnd.diff(timeStart);
											if (diff < 0) {
												alert(t('user.list.filter_user_register_end_date_error'));
												return;
											}

											onEndDateChange(value);
											setIsShowEndDate.off();
										}
									}}
									value={endDate}
								/>
							</PopoverContent>
						</Popover>
						<Tooltip label={t('components.filter_clear')}>
							<Button
								mt={'3px'}
								me={{ base: '10px', md: '20px' }}
								ml={{ base: '10px', sm: '10px', md: '10px' }}
								bg={buttonBg}
								border="1px solid"
								color="secondaryGray.600"
								borderColor={useColorModeValue('secondaryGray.100', 'whiteAlpha.100')}
								borderRadius="16px"
								_placeholder={{ color: 'secondaryGray.600' }}
								_hover={hoverButton}
								_active={activeButton}
								_focus={activeButton}
								onClick={() => {
									setFilterUserStatus([]);
									setFilterUserLoginType([]);
									setFilterUserType([]);
									setFilterUserIapYn([]);
								}}
							>
								<Icon color={textColor} as={MdRestore} />
							</Button>
						</Tooltip>
					</Flex>
				</Flex>
				{/*<SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} gap="20px" mb="20px" height={'max-content'} px={'20px'}>*/}
				{/*	<Card flexDir={'row'} alignItems={'center'}>*/}
				{/*		<Text fontSize="sm" textColor={textColor} mr={'20px'}>*/}
				{/*			{t('user.list.filter_user_status')}*/}
				{/*		</Text>*/}
				{/*		<CheckboxGroup*/}
				{/*			colorScheme="purple"*/}
				{/*			value={filterUserStatus}*/}
				{/*			onChange={e => {*/}
				{/*				setFilterUserStatus(e);*/}
				{/*			}}*/}
				{/*		>*/}
				{/*			<Stack spacing={[1, 5]} direction={['column', 'row']}>*/}
				{/*				<Checkbox value={MemberStatusType.NORMAL}>{t('user.list.filter_user_status_normal')}</Checkbox>*/}
				{/*				<Checkbox value={MemberStatusType.BLACK_LISTED}>{t('user.list.filter_user_status_blacklist')}</Checkbox>*/}
				{/*				<Checkbox value={MemberStatusType.WITHDRAW_SUBMITTED}>*/}
				{/*					{t('user.list.filter_user_status_delete')}*/}
				{/*				</Checkbox>*/}
				{/*			</Stack>*/}
				{/*		</CheckboxGroup>*/}
				{/*	</Card>*/}
				{/*	<Card flexDir={'row'} alignItems={'center'}>*/}
				{/*		<Text fontSize="sm" textColor={textColor} mr={'20px'}>*/}
				{/*			{t('user.list.filter_user_type')}*/}
				{/*		</Text>*/}
				{/*		<CheckboxGroup*/}
				{/*			colorScheme="purple"*/}
				{/*			value={filterUserType}*/}
				{/*			onChange={e => {*/}
				{/*				setFilterUserType(e);*/}
				{/*			}}*/}
				{/*		>*/}
				{/*			<Stack spacing={[1, 5]} direction={['column', 'row']}>*/}
				{/*				<Checkbox value={MemberType.IDOL}>{t('user.list.filter_user_type_idol')}</Checkbox>*/}
				{/*				<Checkbox value={MemberType.PRODUCER}>{t('user.list.filter_user_type_producer')}</Checkbox>*/}
				{/*			</Stack>*/}
				{/*		</CheckboxGroup>*/}
				{/*	</Card>*/}
				{/*	<Card flexDir={'row'} alignItems={'center'}>*/}
				{/*		<Text fontSize="sm" textColor={textColor} mr={'20px'}>*/}
				{/*			{t('user.list.filter_user_login_type')}*/}
				{/*		</Text>*/}
				{/*		<CheckboxGroup*/}
				{/*			colorScheme="purple"*/}
				{/*			value={filterUserLoginType}*/}
				{/*			onChange={e => {*/}
				{/*				setFilterUserLoginType(e);*/}
				{/*			}}*/}
				{/*		>*/}
				{/*			<Stack spacing={[1, 5]} direction={['column', 'row']}>*/}
				{/*				<Checkbox value={MemberSocialLoginType.APPLE}>{t('user.list.filter_user_login_type_A')}</Checkbox>*/}
				{/*				<Checkbox value={MemberSocialLoginType.GOOGLE}>{t('user.list.filter_user_login_type_G')}</Checkbox>*/}
				{/*				<Checkbox value={MemberSocialLoginType.LINE}>{t('user.list.filter_user_login_type_L')}</Checkbox>*/}
				{/*				<Checkbox value={MemberSocialLoginType.TWITTER}>{t('user.list.filter_user_login_type_X')}</Checkbox>*/}
				{/*			</Stack>*/}
				{/*		</CheckboxGroup>*/}
				{/*	</Card>*/}
				{/*	<Card flexDir={'row'} alignItems={'center'}>*/}
				{/*		<Text fontSize="sm" textColor={textColor} mr={'20px'}>*/}
				{/*			{t('user.list.filter_user_iap_yn')}*/}
				{/*		</Text>*/}
				{/*		<CheckboxGroup*/}
				{/*			colorScheme="purple"*/}
				{/*			value={filterUserIapYn}*/}
				{/*			onChange={e => {*/}
				{/*				setFilterUserIapYn(e);*/}
				{/*			}}*/}
				{/*		>*/}
				{/*			<Stack spacing={[1, 5]} direction={['column', 'row']}>*/}
				{/*				<Checkbox value="Y">{t('user.list.filter_user_iap_y')}</Checkbox>*/}
				{/*				<Checkbox value="N">{t('user.list.filter_user_iap_n')}</Checkbox>*/}
				{/*			</Stack>*/}
				{/*		</CheckboxGroup>*/}
				{/*	</Card>*/}
				{/*</SimpleGrid>*/}

				<SearchTable request={requestUserList} data={list} columns={columns} totalCount={totalCount} />
			</Card>

			<Modal
				isOpen={isShowDetail}
				onClose={() => {
					setIsShowDetail(false);
				}}
				isCentered
				size={'xg'}
			>
				<ModalOverlay />

				<ModalContent>
					<ModalHeader></ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<UserDetail memberId={selectMemberId} type={selectMemberType} />
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	);
}
