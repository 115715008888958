import { Box, Button, Flex, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useApi } from '../../../providers/api-provider';
import { NoticeImages, NoticeInformation } from './components/NoticeRegisterComponents';
import { MemberType, NoticePlacementType, NoticeType } from '../../../types/enums';
import { DATA_FORMAT_SERVER_FULL_DATE, dateFormat, debugLog, showToast } from '../../../helper/functions';

/**
 * 사전 계약 아이돌 등록
 * @constructor
 */
export default function NoticeRegistration() {
	const { t } = useTranslation();
	const { noticeApi } = useApi();

	// 변수
	const [title, setTitle] = useState<string>('');
	const [mainText, setMainText] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [thumbnamilImgFile, setThumbnamilImgFile] = useState<File>(null);

	const [titleEn, setTitleEn] = useState<string>('');
	const [mainTextEn, setMainTextEn] = useState<string>('');
	const [descriptionEn, setDescriptionEn] = useState<string>('');
	const [thumbnamilImgFileEn, setThumbnamilImgFileEn] = useState<File>(null);

	const [titleKr, setTitleKr] = useState<string>('');
	const [mainTextKr, setMainTextKr] = useState<string>('');
	const [descriptionKr, setDescriptionKr] = useState<string>('');
	const [thumbnamilImgFileKr, setThumbnamilImgFileKr] = useState<File>(null);

	const [notieType, setNoticeType] = useState<string>(NoticeType.SYSTEM);
	const [targetMemberTypes, setTargetMemberTypes] = useState<MemberType[]>([MemberType.IDOL, MemberType.PRODUCER]);
	const [notiePlacementType, setNoticePlacementType] = useState<NoticePlacementType[]>([NoticePlacementType.HOME]);

	const [interalUrl, setInternalUrl] = useState<string>('');

	const [isActive, setIsActive] = useState<boolean>(true);

	const [today, setToday] = useState(new Date());
	const [startDate, onStartDateChange] = useState<Date>(today);
	const [endDate, onEndDateChange] = useState<Date>(today);

	/**
	 * 아이돌 등록
	 */
	const registerPreContractIdol = async () => {
		// // 입력값 확인
		if (title.length === 0 || !title) {
			showToast(t('error.input_title'));
			return;
		}

		if (description.length === 0 || !description) {
			showToast(t('error.input_description'));
			return;
		}

		// 요청 데이터
		const requestData = {
			title: {
				contentJa: title,
				contentKo: titleKr,
				contentEn: titleEn
			},
			noticeType: notieType,
			targetMemberTypes: targetMemberTypes,
			bannerPlacementTypes: notiePlacementType,
			mainText: {
				contentJa: mainText,
				contentKo: mainTextKr,
				contentEn: mainTextEn
			},
			description: {
				contentJa: description,
				contentKo: descriptionKr,
				contentEn: descriptionEn
			},
			internalUrl: '',
			isActive: isActive,
			startDateTime: dateFormat(startDate, DATA_FORMAT_SERVER_FULL_DATE),
			endDateTime: dateFormat(endDate, DATA_FORMAT_SERVER_FULL_DATE)
		};

		try {
			const formData = new FormData();
			formData.append('request', JSON.stringify(requestData));

			// 압축 파일 생성
			formData.append('thumbnailImgFileJa', thumbnamilImgFile);
			formData.append('thumbnailImgFileKo', thumbnamilImgFileKr);
			formData.append('thumbnailImgFileEn', thumbnamilImgFileEn);

			// 완료 후 목록 페이지로 이동
			noticeApi
				.postNoticeDetail(formData)
				.then(() => {
					// window.location.href = '/admin/idol/pre_contract/list';
					if (notieType === NoticeType.SYSTEM) {
						window.location.href = '/admin/notices/system_list';
					} else {
						window.location.href = '/admin/notices/ad_list';
					}
				})
				.catch(() => {
					showToast(t('error.try_again'));
				});
		} catch (error) {
			debugLog('Error during compression or upload:', error);
			showToast(t('error.try_again'));
		}
	};

	return (
		<Box
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '130px', md: '80px', xl: '80px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<SimpleGrid mb="20px" columns={{ sm: 1, lg: 2 }} spacing={{ base: '20px', xl: '20px' }}>
				{/* Column Left */}

				<Flex direction="column">
					<Tabs variant="enclosed">
						<TabList>
							<Tab>{t('common.japanese')}</Tab>
							<Tab>{t('common.english')}</Tab>
							<Tab>{t('common.korean')}</Tab>
						</TabList>
						<TabPanels>
							<TabPanel>
								<NoticeInformation
									title={title}
									setTitle={setTitle}
									mainText={mainText}
									setMainText={setMainText}
									description={description}
									setDescription={setDescription}
									thumbnailImg={thumbnamilImgFile}
									setThumbnailImg={setThumbnamilImgFile}
								/>
							</TabPanel>
							<TabPanel>
								<NoticeInformation
									title={titleEn}
									setTitle={setTitleEn}
									mainText={mainTextEn}
									setMainText={setMainTextEn}
									description={descriptionEn}
									setDescription={setDescriptionEn}
									thumbnailImg={thumbnamilImgFileEn}
									setThumbnailImg={setThumbnamilImgFileEn}
								/>
							</TabPanel>
							<TabPanel>
								<NoticeInformation
									title={titleKr}
									setTitle={setTitleKr}
									mainText={mainTextKr}
									setMainText={setMainTextKr}
									description={descriptionKr}
									setDescription={setDescriptionKr}
									thumbnailImg={thumbnamilImgFileKr}
									setThumbnailImg={setThumbnamilImgFileKr}
								/>
							</TabPanel>
						</TabPanels>
					</Tabs>
					{/* <Dropzone /> */}
				</Flex>
				{/* Column Right */}
				<Flex direction="column">
					<NoticeImages
						noticeType={notieType}
						setNoticeType={setNoticeType}
						noticePlacementType={notiePlacementType}
						setNoticePlacementType={setNoticePlacementType}
						interalUrl={interalUrl}
						setInternalUrl={setInternalUrl}
						targetMemberTypes={targetMemberTypes}
						setTargetMemberTypes={setTargetMemberTypes}
						startDate={startDate}
						onStartDateChange={onStartDateChange}
						endDate={endDate}
						onEndDateChange={onEndDateChange}
						isActive={isActive}
						setIsActive={setIsActive}
					/>
				</Flex>
			</SimpleGrid>
			<Button
				variant="brand"
				minW="183px"
				fontSize="sm"
				fontWeight="500"
				ms="auto"
				onClick={() => {
					registerPreContractIdol();
				}}
			>
				{t('common.save')}
			</Button>
		</Box>
	);
}
