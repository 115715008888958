import { BaseApi } from "./baseAPI";
import { PagingRequest } from "./commonAPI";
import { IdolApplySearchType, IdolApplyStatus } from "../types/enums";
import {
  URL_GET_IDOL_APPLY,
  URL_GET_IDOL_APPLY_DETAIL,
  URL_GET_IDOL_PRECONTRACT_DETAIL,
  URL_IDOL_PRECONTRACT,
  URL_POST_IDOL_PRECONTRACT,
  URL_PUT_IDOL_APPLY_REVIEW_APPROVE,
  URL_PUT_IDOL_APPLY_REVIEW_REJECT, URL_PUT_IDOL_APPLY_REVIEW_TEST_APPROVE, URL_TILE_STORAGES_FORCE_STORE
} from "./URLS";
import { debugLog } from "../helper/functions";

/**
 * Common Controller
 */
export class IdolAPI extends BaseApi {
  idolApplySearch = (request: IdolApplySearchRequest) => {
    return this.axios
      .get<IdolApplySearchResponse>(URL_GET_IDOL_APPLY, { params: request })
      .then(response => response)
      .catch(error => error.response);
  };

  postIdolPreContract = (request: FormData) => {
    return this.axios
      .post(URL_POST_IDOL_PRECONTRACT, request, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then(response => response)
      .catch(error => error.response);
  };

  // idolSearch = (request: IdolSearchRequest) => {
  // 	return this.axios
  // 		.get<IdolSearchResponse>(MEMBER_SEARCH_URL, { params: request })
  // 		.then(response => response)
  // 		.catch(error => error.response);
  // };

  // idolRegist = (memberId: number, request: IdolDetailRequest) => {
  // 	return this.axios
  // 		.get<MemberDetailResponse>(MEMBER_DETAIL_URL.replace('{memberId}', memberId.toString()), { params: request })
  // 		.then(response => response)
  // 		.catch(error => error.response);
  // };
  // idolDetail = (idolId: number, request: IdolDetailRequest) => {
  // 	return this.axios
  // 		.get<IdolApplyDetailResponse>(URL_GET_IDOL_APPLY_DETAIL.replace('{idolId}', idolId.toString()), { params: request })
  // 		.then(response => response)
  // 		.catch(error => error.response);
  // };
  // 사전계약아이돌 목록 조회
  idolPreContractSearch = (request: IdolPreContractSearchRequest) => {
    return this.axios
      .get<IdolPreContractSearchResponse>(URL_GET_IDOL_APPLY, { params: request })
      .then(response => response)
      .catch(error => error.response);
  };
  idolPreContractList = (request: PagingRequest) => {
    return this.axios
      .get<IdolPreContractListResponse>(URL_IDOL_PRECONTRACT, { params: request })
      .then(response => response)
      .catch(error => error.response);
  };
  // 사전계약아이돌 상세 조회
  idolPreContractDetail = (idolPreContractId: number) => {
    return this.axios
      .get<IdolPreContractDetailResponse>(
        URL_GET_IDOL_PRECONTRACT_DETAIL.replace("{idolPreContractId}", idolPreContractId.toString())
      )
      .then(response => response)
      .catch(error => error.response);
  };
  // 승인대상아이돌 목록 조회
  idolApplyList = (request: PagingRequest) => {
    return this.axios
      .get<IdolApplyListResponse>(URL_GET_IDOL_APPLY, { params: request })
      .then(response => response)
      .catch(error => error.response);
  };
  // 승인대상아이돌 상세 조회
  idolApplyDetail = (idolApplyId: number) => {
    return this.axios
      .get<IdolApplyDetailResponse>(URL_GET_IDOL_APPLY_DETAIL.replace("{idolApplyId}", idolApplyId.toString()))
      .then(response => response)
      .catch(error => error.response);
  };
  // 승인대상아이돌 상태 변경
  idolApplyStatusApprove = (idolApplyId: number, request: IdolApplyStatusApproveRequest) => {
    return this.axios
      .put<IdolApplyStatusApproveResponse>(
        URL_PUT_IDOL_APPLY_REVIEW_APPROVE.replace("{idolApplyId}", idolApplyId.toString()),
        request
      )
      .then(response => response)
      .catch(error => error.response);
  };

  idolApplyStatusTestApprove = (idolApplyId: number, request: IdolApplyStatusApproveRequest) => {
    return this.axios
      .put<IdolApplyStatusApproveResponse>(
        URL_PUT_IDOL_APPLY_REVIEW_TEST_APPROVE.replace("{idolApplyId}", idolApplyId.toString()),
        request
      )
      .then(response => response)
      .catch(error => error.response);
  };
  // 승인대상아이돌 상태 변경
  idolApplyStatusReject = (idolApplyId: number, request: IdolApplyStatusRejectRequest) => {
    try {
      return this.axios
        .put<IdolApplyStatusApproveResponse>(
          URL_PUT_IDOL_APPLY_REVIEW_REJECT.replace("{idolApplyId}", idolApplyId.toString()),
          request
        )
        .then(response => response)
        .catch(error => error.response);
    } catch (error) {
      debugLog(error);
      // if (error.response && error.response.status === 400) {
      //   // Handle 400 Bad Request
      //   console.error('Bad Request: ', error.response.data);
      //   return { error: 'Bad Request', details: error.response.data };
      // } else {
      //   // Handle other errors
      //   console.error('An error occurred: ', error);
      //   return { error: 'An error occurred', details: error.message };
      // }
    }
    // return this.axios
    // 	.put<IdolApplyStatusRejectResponse>(URL_PUT_IDOL_APPLY_REVIEW_REJECT.replace('{idolApplyId}', idolApplyId.toString()), request)
    // 	.then(response => response)
    // 	.catch(error => error.response);
  };
  // addApplylisted = (idolApplyId: number, request: IdolApplyListRequest) => {
  // 	return this.axios
  // 		.post<IdolApplyDetailResponse>(URL_GET_IDOL_APPLY_DETAIL.replace('{idolApplyId}', idolApplyId.toString()), request)
  // 		.then(response => response)
  // 		.catch(error => error.response);
  // };

  // deleteBlacklisted = (memberId: number, request: MemberBlackListedRequest) => {
  // 	return this.axios
  // 		.delete<MemberDetailResponse>(MEMBER_BLACKLISTED.replace('{memberId}', memberId.toString()), { params: request })
  // 		.then(response => response)
  // 		.catch(error => error.response);
  // };

  postTileStorageForceStores = (idolId: string, producerIds: string[]) => {
    return this.axios
      .post(URL_TILE_STORAGES_FORCE_STORE, {
        idolId: idolId,
        producerIds: producerIds
      })
      .then(response => response)
      .catch(error => error.response);
  };
}

/**
 * ====================================================
 * ====================================================
 * Request
 */
export type IdolApplySearchRequest = PagingRequest & {
  /**
   *  string
   *  (query)
   *  회원 검색 조건 종류
   */
  searchType?: IdolApplySearchType;

  /**
   * string
   *  (query)
   *  검색어
   */
  searchWord?: string;

  /**
   * string - yyyyMMdd
   *  (query)
   *  필터 - 가입일자(시작)
   */
  // registerDateStart?: string;

  /**
   * string - yyyyMMdd
   *  (query)
   *  필터 - 가입일자(종료)
   */
  // registerDateEnd?: string;

  /**
   * string
   *   (query)
   *   필터 - 계정상태
   */
  applyStatus?: IdolApplyStatus;

  /**
   * string
   *   (query)
   *   필터 - 연동 소셜
   */
  // loginType?: MemberSocialLoginType;
};
export type IdolPreContractSearchRequest = PagingRequest & {
  preContractStartDate: string;
  preContractEndDate: string;
};
export type IdolPreContractListRequest = PagingRequest & {
  preContractStartDate: string;
  preContractEndDate: string;
};
export type IdolPreContractDetailRequest = {
  preContractStartDate: string;
  preContractEndDate: string;
};
export type IdolApplyListRequest = PagingRequest & {
  applyStatus: string;
  applyStartDate: string;
  applyEndDate: string;
};
export type IdolApplyDetailRequest = {
  applyStatus: string;
  applyStartDate: string;
  applyEndDate: string;
};

// export type IdolApplySearchRequest = {
// 	applyStatus: IdolApplyStatus;
// };
// export type IdolApplyStatusRejectRequest = {
// 	// applyStatus: string;
// 	// reviewResult: string;
// };
export type IdolApplyStatusApproveRequest = {
  // applyStatus: string;
  reviewResult: string;
};
export type IdolApplyStatusRejectRequest = {
  // applyStatus: string;
  reviewResult: string;
};

// export type MemberBlackListedRequest = {
// 	memberType: MemberType;
// 	blackListStartDate: string;
// 	blackListEndDate: string;
// 	registerReason: string;
// };

/**
 * ====================================================
 * ====================================================
 * Response
 */
export type IdolPreContractSearchResponse = {
  idolPreContractTrain: IdolPreContractTrain[];
  count: number;
};
// 사전등록아이돌 목록 조회 Response
export type IdolPreContractListResponse = {
  idolPreContractTrain: IdolPreContractTrain[];
  count: number;
};
// 사전등록아이돌 상세 조회 Response
export type IdolPreContractDetailResponse = IdolPreContractDetail;
export type IdolApplySearchResponse = {
  idolApplyTrain: IdolApplyTrain[];
  count: number;
};
// 승인대상아이돌 목록 조회 Response
export type IdolApplyListResponse = {
  idolApplyTrain: IdolApplyTrain[];
  count: number;
};
// 승인대상아이돌 상세 조회 Response
export type IdolApplyDetailResponse = IdolApplyTrain;
export type IdolApplyStatusApproveResponse = IdolApplyTrain;
export type IdolApplyStatusRejectResponse = IdolApplyTrain;
// export type MemberDetailResponse = {
// 	// 회원 아이디
// 	memberId: number;

// 	// 프로필 사진 URL
// 	profileImgUrl: string;

// 	// 활동명
// 	activityName: string;

// 	// 회원 식별자
// 	pid: string;

// 	// 가입일자
// 	createDateTime: string;

// 	// 직업
// 	// Enum:
// 	//     [IDOL, PRODUCER]
// 	memberType: MemberType;

// 	// 전화번호 - 국가번호
// 	countryCode: string;

// 	// 전화번호
// 	mobile: string;

// 	// 이메일
// 	email: string;

// 	// 계정 상태
// 	// Enum:
// 	// [NORMAL, BLACK_LISTED, WITHDRAW_SUBMITTED]
// 	memberStatus: MemberStatusType;

// 	// 서비스 이용약관 동의 여부
// 	isTermsOfService: boolean;

// 	// 서비스 이용약관 동의일
// 	termsOfServiceDate: string;

// 	// 방송 수행 횟수
// 	liveStreamingCount: number;

// 	// 업로드된 재방송VOD 개수
// 	vodCount: number;

// 	// 아이돌 명함 담은 개수
// 	idolCardCount: number;

// 	// 현재 STIRKE 단계
// 	strikeStatus: string;

// 	// 최근 로그인 일자
// 	lastSignInDateTime: string;

// 	// 후원 개수
// 	donationCount: number;

// 	// 본인 인증 여부
// 	isVerified: boolean;

// 	// 이용 정지 여부
// 	blackListed: boolean;

// 	// 이용 정지 시작 일자
// 	blackListStartDateTime: string;

// 	// 이용 정지 만료 일자
// 	blackListEndDateTime: string;

// 	// 채팅/댓글 경고 종료 일자
// 	chatCommentWarningExpireDateTime: string;

// 	// 종합 경고 종료 일자
// 	synthesisWarningExpireDateTime: string;

// 	// 채팅 금지 경고 시작 일자
// 	lastChatCommentSanctionsStartDateTime: string;

// 	// 채팅 금지 경고 종료 일자
// 	lastChatCommentSanctionsExpireDateTime: string;

// 	// 마지막으로 받은 채팅 스트라이크 단계
// 	lastChatCommentStrikeLevel: number;

// 	// 마지막으로 받은 활동 정지 시작 일자
// 	lastActivitySanctionsStartDateTime: string;

// 	// 마지막으로 받은 활동 정지 종료 일자
// 	lastActivitySanctionsExpireDateTime: string;

// 	// 마지막으로 받은 종합 경고 스트라이크 단계
// 	lastSynthesisStrikeLevel: number;
// };

// 사전등록아이돌 목록 조회
export type IdolPreContractTrain = {
  idolPreContractId: number;
  name: string;
  countryCode: string;
  mobile: string;
  identityDocument: PreContractIdentityDocument;
  identityDocumentImgUrl01: string;
  identityDocumentImgUrl02: string;
  identityDocumentImgUrl03: string;
  identityDocumentImgUrl04: string;
  identityDocumentImgUrl05: string;
  memo: string;
  applyCode: string;
  applyCodeGenerateDateTime: string;
  applyCodeExpireDateTime: string;
};
// 사전등록아이돌 상세 정보
export type IdolPreContractDetail = {
  idolPreContractId: number;
  name: string;
  countryCode: string;
  mobile: string;
  identityDocument: PreContractIdentityDocument;
  identityDocumentImgUrl01: string;
  identityDocumentImgUrl02: string;
  identityDocumentImgUrl03: string;
  identityDocumentImgUrl04: string;
  identityDocumentImgUrl05: string;
  memo: string;
  applyCode: string;
  applyCodeGenerateDateTime: string;
  applyCodeExpireDateTime: string;
};
export type PreContractIdentityDocument = {
  password: string;
  idCardZipUrls: string[];
};
// /admin/idol/apply // 승인대상아이돌 목록 조회
export type IdolApplyTrain = {
  idolApplyId: number;
  loginType: string; //"APPLE",
  snsId: string;
  email: string;
  countryCode: string;
  mobile: string;
  pid: string;
  profileImgUrl: string;
  activityName: string;
  agreements: {
    termsOfService: IdolApplyAgreements;
    privacyPolicy: IdolApplyAgreements;
    idolTermsOfService: IdolApplyAgreements;
    dontSeeUnderAgeAgreement: IdolApplyAgreements;
  };
  identityDocument?: {
    password: string;
    idCardFrontZipUrl: string;
    idCardBackZipUrl: string;
  };
  applyStatus: string; //"UNDER_REVIEW",
  reviewResult: string;
  applyDateTime: string;
  reviewDateTime: string;
  bodyProfileImgUrls?: string[];
};

export type IdolApplyAgreements = {
  isAgree: boolean;
  agreeDate: string;
};

// /admin/idol/apply/{idolApplyId} 승인대상아이돌 상세조회
export type IdolApplyDetail = {
  idolApplyId: number;
  loginType: string; // "APPLE",
  snsId: string;
  email: string;
  countryCode: string;
  mobile: string;
  pid: string;
  profileImgUrl: string;
  activityName: string;
  agreements: {
    termsOfService: IdolApplyAgreements;
    privacyPolicy: IdolApplyAgreements;
    idolTermsOfService: IdolApplyAgreements;
    dontSeeUnderAgeAgreement: IdolApplyAgreements;
  };
  applyStatus: IdolApplyStatus; // "UNDER_REVIEW",
  reviewResult: string;
  applyDateTime: string;
  reviewDateTime: string;
};
// export type MemberTrain = {
// 	/**
// 	 * 회원 아이디
// 	 */
// 	memberId: number;

// 	/**
// 	 * 활동명
// 	 */
// 	activityName: string;

// 	/**
// 	 * 회원 식별자
// 	 */
// 	pid: string;

// 	/**
// 	 * 가입일자
// 	 */
// 	createDateTime: string;

// 	/**
// 	 * 직업
// 	 */

// 	memberType: MemberType;

// 	/**
// 	 * 전화번호 - 국가번호
// 	 */
// 	countryCode: string;

// 	/**
// 	 * 전화번호
// 	 */
// 	mobile: string;

// 	/**
// 	 * 이메일
// 	 */
// 	email: string;

// 	/**
// 	 * 연동 소셜
// 	 */
// 	loginType: MemberSocialLoginType;

// 	/**
// 	 * 계정 상태
// 	 *
// 	 *   Enum:
// 	 *     [ NORMAL, BLACK_LISTED, WITHDRAW_SUBMITTED ]
// 	 */
// 	memberStatus: MemberStatusType;

// 	/**
// 	 * 본인 인증 여부
// 	 */
// 	isVerified: boolean;

// 	/**
// 	 * 탈퇴 여부 (회원 탈퇴시에 $set)
// 	 */
// 	withdrawSubmitted: boolean;

// 	/**
// 	 * 블랙 리스트
// 	 */
// 	blackListed: boolean;

// 	/**
// 	 * 인 앱 결제 여부
// 	 */
// 	inAppPurchased: boolean;
// };

// // 회원 블랙 리스트 상세
// export type BlacklistTrain = {

// 	// 회원 종류
// 	memberType:	MemberType;

// 	//	회원 아이디
// 	memberId: number;

//   // 블랙 리스트
// 	blackListed:	boolean;

// 	// 블랙 리스트 추가 어드민
// 	registerAdminAccountId: number;

// 	// 블랙 리스트 추가 사유
// 	registerReason:	string;

//   // 활동명
// 	activityName:	string;

// 	// pid
// 	pid:	string;

// 	// 블랙 리스트 적용기간 시작
// 	blackListStartDateTime:	string;

//   // 블랙 리스트 적용기간 종료
// 	blackListEndDateTime:	string;

// }
