import { Navigate, Outlet } from 'react-router-dom';
import { useLogin } from '../providers/login-provider';

const PrivateRoute = () => {
	const { isLogin } = useLogin();

	return isLogin ? <Outlet /> : <Navigate to={process.env.REACT_APP_DEFAULT_URI} />;
};

export default PrivateRoute;
