// Chakra imports
import {
	Button,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Textarea
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

// Assets

/**
 * VOD 삭제 팝업
 * @param props
 * @constructor
 */
export default function ModalDeleteVod(props: {
	isOpen: boolean;
	requestDeleteVod: (text: string) => void;
	onClose: () => void;
}) {
	// Chakra Color Mode

	const { t } = useTranslation();
	const { isOpen, requestDeleteVod, onClose } = props;

	// 사유
	const [text, setText] = useState('');

	// 사유 입력 이벤트 처리
	const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setText(e.target.value);
	};

	const closePopup = () => {
		setText('');
		onClose && onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={closePopup} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{t('content.vod.modal_delete_vod_title')}</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<FormControl mt={4}>
						<FormLabel whiteSpace={'pre-line'}>{t('content.vod.modal_delete_vod_desc')}</FormLabel>
						<Textarea
							placeholder={t('content.vod.modal_delete_vod_placeholder')}
							onChange={onInputChange}
							value={text}
						/>
					</FormControl>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="brand"
						mr={3}
						onClick={() => {
							requestDeleteVod(text);
						}}
					>
						{t('common.save')}
					</Button>
					<Button onClick={closePopup}>{t('common.cancel')}</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
