import axios, { AxiosInstance } from "axios";
import { Tokens } from "../providers/login-provider";
import { debugLog } from "../helper/functions";

export type BaseApiOptions = {
  baseURL: string;
  tokens: Tokens;
  /**
   * 기존에 다른 리퀘스트가 리프레시를 요청했다면?
   * 이를 기다려야 한다.
   */
  getRefresh?: () => Promise<Tokens> | undefined;
  /**
   * 리프레시 프로미스를 보내서, 끝났을 시 그를 사용하여 새로운 토큰을 설정할 수 있도록 한다.
   */
  setRefresh?: (promise: Promise<Tokens>) => void;
};

export class BaseApi {
  axios: AxiosInstance;
  tokens: Tokens | null = null;

  get openPrefix() {
    return this.tokens?.accessToken == null && this.tokens?.refreshToken == null ? "open/" : "";
  }

  constructor(options: BaseApiOptions) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? "Asia/Seoul";

    const headers: Record<string, string> = {
      UserAgent: `admin 0.1 0.1 1 ko-KR ${timeZone}`
    };

    headers["Access-Control-Allow-Origin"] = "*";
    headers["Access-Control-Allow-Credentials"] = "true";
    headers["Access-Control-Allow-Methods"] = "GET,PUT,POST,DELETE";

    if (options.tokens?.accessToken) {
      headers["X-AUTH-TOKEN"] = options.tokens.accessToken;
    }

    this.tokens = options.tokens;

    this.axios = axios.create({
      baseURL: options.baseURL,
      headers: headers
    });

    this.axios.interceptors.request.use(request => {
      debugLog('API REQUEST ==============');
      debugLog(JSON.stringify(request, null, 2))
      debugLog('API REQUEST ==============')
      return request
    });


    this.axios.interceptors.response.use(
      response => {
        // Baund server specific ...
        if (response.data === "") {
          response.data = undefined;
        }

        debugLog('API RESPONSE ==============');
        debugLog(JSON.stringify(response.data, null, 2))
        debugLog('API RESPONSE ==============')
        return response.data;
      },

      async error => {
        debugLog('API ERROR ==============');
        debugLog(JSON.stringify(error, null, 2))
        debugLog('API ERROR ==============')

        if (axios.isAxiosError(error) && error.response?.status) {
          // 인증 관련 에러라면?
          if (error.response?.config.url?.includes("token/refresh")) {
            debugLog("리프레시 실패");
            // 토큰 지워준다.
            options.setRefresh?.(
              Promise.resolve({
                accessToken: null,
                refreshToken: null
              })
            );

            // 런타임 타입 에러 방지: 아무것도 리턴되지 않는 것은 막아야 한다.
            throw error;
          } else if (error.response.status === 401 && options.tokens?.accessToken != null) {
            debugLog("리프레시 할까?");
            // 탈퇴한 경우도 있을 수 있기에, 리프레시 토큰이 없더라도 무조건 1회 리프레시 시도한다.
            let refreshPromise: Promise<Tokens> | undefined = options.getRefresh?.();

            if (!refreshPromise) {
              debugLog("리프레시 시작");
              // 기존 프로세스가 없다면,
              // 리프레시 프로세스를 시작해 주고 부모에게 들고 있도록 넘겨준다.
              refreshPromise = (async () => {
                try {
                  const response = await this.axios.post("token/refresh", null, {
                    params: {
                      refreshToken: options.tokens.refreshToken
                    }
                  });

                  return {
                    accessToken: response.data.token,
                    refreshToken: response.data.refreshToken
                  };
                } catch (error) {
                  return {
                    accessToken: null,
                    refreshToken: null
                  };
                }
              })();

              options.setRefresh?.(refreshPromise);
            }

            const refreshed = await refreshPromise;

            if (refreshed.accessToken) {
              return axios.request({
                ...error.config,
                headers: {
                  ...error.config.headers,
                  "X-AUTH-TOKEN": refreshed.accessToken
                }
              });
            } else {
              // 아래로 흘러가겠지만, 명시를 위해
              throw error;
            }
          }
        }

        throw error;
      }
    );
  }
}

