// Chakra imports
import {
	Button,
	Flex,
	Grid,
	Popover,
	PopoverContent,
	PopoverTrigger,
	SimpleGrid,
	Stack,
	Text,
	useBoolean,
	useColorModeValue
} from '@chakra-ui/react';

// Custom components
// Assets
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import Card from 'components/card/Card';
import { useApi } from '../../../../../providers/api-provider';
import { ProjectDetailResponse, ProjectTrain } from '../../../../../apis/projectAPI';
import TextField from '../../../../../components/fields/TextField';
import InputField from '../../../../../components/fields/InputField';
import TextAreaField from 'components/fields/TextAreaField';
import {
	DATA_FORMAT_FULL_DATE,
	DATA_FORMAT_SERVER_FULL_DATE,
	dateFormat,
	debugLog,
	showToast
} from '../../../../../helper/functions';
import MiniCalendar from '../../../../../components/calendar/MiniCalendar';
import DropImageBox from '../../../../../components/dropzone/DropzoneImgBox';
import ButtonField from 'components/fields/ButtonField';
import SwitchField from 'components/fields/SwitchField';
import { HSeparator } from "../../../../../components/separator/Separator";
import ModalBasic from "../../../../../components/modal/ModalBasic";

// Live 콘텐츠 목록
export default function ProjectDetail(params: { projectId: string; requestChangeParams: () => void }) {
	const { t } = useTranslation();
	const { projectApi } = useApi();

	const { projectId, requestChangeParams } = params;

	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

	const [data, setData] = useState<ProjectTrain>(null);

	const [thumbnamilImgFile, setThumbnamilImgFile] = useState<File[]>([]);
	const [thumbnamilImgUrl, setThumbnamilImgUrl] = useState<string[]>([]);

	const [title, setTitle] = useState<string>('');
	const [description, setDescription] = useState<string>('');

	const [recruitStartDateTime, setRecruitStartDateTime] = useState<Date>(null);
	const [isShowRecruitStartDateTime, setIsShowRecruitStartDateTime] = useBoolean(false);

	const [recruitEndDateTime, setRecruitEndDateTime] = useState<Date>(null);
	const [isShowRecruitEndDateTime, setIsShowRecruitEndDateTime] = useBoolean(false);

	const [applyResultNoticeDateTime, setApplyResultNoticeDateTime] = useState<Date>(null);
	const [isShowApplyResultNoticeDateTime, setIsShowApplyResultNoticeDateTime] = useBoolean(false);

	const [operationStartDateTime, setOperationStartDateTime] = useState<Date>(null);
	const [isShowOperationStartDateTime, setIsShowOperationStartDateTime] = useBoolean(false);

	const [operationEndDateTime, setOperationEndDateTime] = useState<Date>(null);
	const [isShowOperationEndDateTime, setIsShowOperationEndDateTime] = useBoolean(false);

	const [isActive, setIsActive] = useState<boolean>(false);

	const [isShowConfirm, setIsShowConfirm] = useState(false);

	useEffect(() => {
		if (projectId) {
			requestProjectDetail(projectId);
		}
	}, [projectId]);

	const requestProjectDetail = async (projectId: string) => {
		projectApi
			.getProjectDetail(projectId)
			.then(responseProjectDetail)
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	const responseProjectDetail = (response: ProjectDetailResponse) => {
		if (response.detail) {
			let projectDetail = response.detail;
			setData(projectDetail);
			setThumbnamilImgUrl([projectDetail.visualImageUrl]);
			setTitle(projectDetail.name);
			setDescription(projectDetail.description);
			setRecruitStartDateTime(new Date(projectDetail.recruitStartDateTime));
			setRecruitEndDateTime(new Date(projectDetail.recruitEndDateTime));
			setApplyResultNoticeDateTime(new Date(projectDetail.applyResultNoticeDateTime));
			setOperationStartDateTime(new Date(projectDetail.operationStartDateTime));
			setOperationEndDateTime(new Date(projectDetail.operationEndDateTime));
			setIsActive(projectDetail.isActive);
		}
	};

	const requestUpdateProject = () => {
		if (!title || title.length === 0) {
			return;
		}

		if (!description || description.length === 0) {
			return;
		}

		let request = {
			projectId: projectId,
			name: title,
			description: description,
			recruitStartDateTime: dateFormat(recruitStartDateTime, DATA_FORMAT_SERVER_FULL_DATE, true),
			recruitEndDateTime: dateFormat(recruitEndDateTime, DATA_FORMAT_SERVER_FULL_DATE, true),
			applyResultNoticeDateTime: dateFormat(applyResultNoticeDateTime, DATA_FORMAT_SERVER_FULL_DATE, true),
			operationStartDateTime: dateFormat(operationStartDateTime, DATA_FORMAT_SERVER_FULL_DATE, true),
			operationEndDateTime: dateFormat(operationEndDateTime, DATA_FORMAT_SERVER_FULL_DATE, true),
			isActive: isActive
		};

		debugLog('=======> request ' + JSON.stringify(request));

		const form = new FormData();
		form.append('request', JSON.stringify(request));
		if (thumbnamilImgFile && thumbnamilImgFile.length > 0) {
			form.append('visualImageFile', thumbnamilImgFile[0]);
		}

		projectApi
			.putProjectDetail(form)
			.then(() => {
				requestChangeParams();
			})
			.catch(() => {});
	};

	if (!data) {
		return <Flex />;
	}

	// Chakra Color Mode
	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			gridTemplateColumns={{ base: '0.8fr 1fr 1fr', lg: '0.8fr 1fr 1fr' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Card>
				<DropImageBox
					title={t('project.thumbname')}
					imgUrls={thumbnamilImgUrl}
					setImgUrls={setThumbnamilImgUrl}
					imgFiles={thumbnamilImgFile}
					setImgFiles={setThumbnamilImgFile}
				/>
			</Card>
			<Card>
				<SimpleGrid>
					<TextField label={t('common.id')} text={String(data?.projectId)} />
					<InputField
						label={t('project.name')}
						text={title}
						onChange={e => {
							setTitle(e.target.value);
						}}
					/>
					<TextAreaField
						label={t('project.description')}
						text={description}
						onChange={e => {
							setDescription(e.target.value);
						}}
					/>
					<HSeparator />

					<SwitchField
						label={t('common.enable')}
						isChecked={isActive}
						onChange={e => {
							setIsActive(e.target.checked);
						}}
					/>

					<HSeparator />

					<ButtonField
						buttonName={t('common.save')}
						onClick={() => {
							// requestUpdateProject();
							setIsShowConfirm(true);
						}}
					/>
				</SimpleGrid>
			</Card>
			<Card>
				<SimpleGrid>
					<SimpleGrid columns={{ sm: 1 }} gap={'10px'} mb={'40px'}>
						<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
							{t('project.apply_start_date')}
						</Text>
						<Stack spacing={5} direction="row">
							<Popover
								isOpen={isShowRecruitStartDateTime}
								onOpen={setIsShowRecruitStartDateTime.on}
								onClose={setIsShowRecruitStartDateTime.off}
								closeOnBlur={false}
								isLazy
								lazyBehavior="keepMounted"
							>
								<PopoverTrigger>
									<Button w="220px" colorScheme="purple">
										{dateFormat(recruitStartDateTime, DATA_FORMAT_FULL_DATE)}
									</Button>
								</PopoverTrigger>
								<PopoverContent bg={'transparent'} border={'0px'}>
									<MiniCalendar
										selectRange={false}
										isShowTimeSelect={true}
										mb="20px"
										onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
											setRecruitStartDateTime(value);
										}}
										onClose={() => {
											setIsShowRecruitStartDateTime.off();
										}}
										value={recruitStartDateTime}
									/>
								</PopoverContent>
							</Popover>
						</Stack>
					</SimpleGrid>

					<SimpleGrid columns={{ sm: 1 }} gap={'10px'} mb={'40px'}>
						<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
							{t('project.apply_end_date')}
						</Text>
						<Stack spacing={5} direction="row">
							<Popover
								isOpen={isShowRecruitEndDateTime}
								onOpen={setIsShowRecruitEndDateTime.on}
								onClose={setIsShowRecruitEndDateTime.off}
								closeOnBlur={false}
								isLazy
								lazyBehavior="keepMounted"
							>
								<PopoverTrigger>
									<Button w="220px" colorScheme="purple">
										{dateFormat(recruitEndDateTime, DATA_FORMAT_FULL_DATE)}
									</Button>
								</PopoverTrigger>
								<PopoverContent bg={'transparent'} border={'0px'}>
									<MiniCalendar
										selectRange={false}
										isShowTimeSelect={true}
										mb="20px"
										onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
											setRecruitEndDateTime(value);
										}}
										onClose={() => {
											setIsShowRecruitEndDateTime.off();
										}}
										value={recruitEndDateTime}
									/>
								</PopoverContent>
							</Popover>
						</Stack>
					</SimpleGrid>

					<SimpleGrid columns={{ sm: 1 }} gap={'10px'} mb={'40px'}>
						<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
							{t('project.notice_applied_date')}
						</Text>
						<Stack spacing={5} direction="row">
							<Popover
								isOpen={isShowApplyResultNoticeDateTime}
								onOpen={setIsShowApplyResultNoticeDateTime.on}
								onClose={setIsShowApplyResultNoticeDateTime.off}
								closeOnBlur={false}
								isLazy
								lazyBehavior="keepMounted"
							>
								<PopoverTrigger>
									<Button w="220px" colorScheme="purple">
										{dateFormat(applyResultNoticeDateTime, DATA_FORMAT_FULL_DATE)}
									</Button>
								</PopoverTrigger>
								<PopoverContent bg={'transparent'} border={'0px'}>
									<MiniCalendar
										selectRange={false}
										isShowTimeSelect={true}
										mb="20px"
										onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
											setApplyResultNoticeDateTime(value);
										}}
										onClose={() => {
											setIsShowApplyResultNoticeDateTime.off();
										}}
										value={applyResultNoticeDateTime}
									/>
								</PopoverContent>
							</Popover>
						</Stack>
					</SimpleGrid>

					<SimpleGrid columns={{ sm: 1 }} gap={'10px'} mb={'40px'}>
						<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
							{t('project.operation_start_date')}
						</Text>
						<Stack spacing={5} direction="row">
							<Popover
								isOpen={isShowOperationStartDateTime}
								onOpen={setIsShowOperationStartDateTime.on}
								onClose={setIsShowOperationStartDateTime.off}
								closeOnBlur={false}
								isLazy
								lazyBehavior="keepMounted"
							>
								<PopoverTrigger>
									<Button w="220px" colorScheme="purple">
										{dateFormat(operationStartDateTime, DATA_FORMAT_FULL_DATE)}
									</Button>
								</PopoverTrigger>
								<PopoverContent bg={'transparent'} border={'0px'}>
									<MiniCalendar
										selectRange={false}
										isShowTimeSelect={true}
										mb="20px"
										onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
											setOperationStartDateTime(value);
										}}
										onClose={() => {
											setIsShowOperationStartDateTime.off();
										}}
										value={operationStartDateTime}
									/>
								</PopoverContent>
							</Popover>
						</Stack>
					</SimpleGrid>

					<SimpleGrid columns={{ sm: 1 }} gap={'10px'} mb={'40px'}>
						<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
							{t('project.operation_end_date')}
						</Text>
						<Stack spacing={5} direction="row">
							<Popover
								isOpen={isShowOperationEndDateTime}
								onOpen={setIsShowOperationEndDateTime.on}
								onClose={setIsShowOperationEndDateTime.off}
								closeOnBlur={false}
								isLazy
								lazyBehavior="keepMounted"
							>
								<PopoverTrigger>
									<Button w="220px" colorScheme="purple">
										{dateFormat(operationEndDateTime, DATA_FORMAT_FULL_DATE)}
									</Button>
								</PopoverTrigger>
								<PopoverContent bg={'transparent'} border={'0px'}>
									<MiniCalendar
										selectRange={false}
										isShowTimeSelect={true}
										mb="20px"
										onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
											setOperationEndDateTime(value);
										}}
										onClose={() => {
											setIsShowOperationEndDateTime.off();
										}}
										value={operationEndDateTime}
									/>
								</PopoverContent>
							</Popover>
						</Stack>
					</SimpleGrid>
				</SimpleGrid>
			</Card>

			<ModalBasic
				isOpen={isShowConfirm}
				onClose={() => {
					setIsShowConfirm(false);
				}}
				title={t('project.update_project')}
				desc={t('project.update_description')}
				secondaryButton={{
					title: t('common.save'),
					onClick: (value: any) => {
						requestUpdateProject();
					}
				}}
			/>
		</Grid>
	);
}
