import { BaseApi } from "./baseAPI";
import { PagingRequest } from "./commonAPI";
import {
	LiveStreamingBroadcastStatusType,
	LiveStreamingSearchType,
	MemberSocialLoginType,
	MemberStatusType,
	MemberType,
	PeroidSearchCondition,
	VideoContentType
} from "../types/enums";
import {
	URL_ADMIN_LIVE_FORCE_QUIT,
	URL_ADMIN_LIVE_STREAMING,
	URL_ADMIN_LIVE_STREAMING_DETAIL,
	URL_ADMIN_VOD,
	URL_ADMIN_VOD_DETAIL, URL_PUT_ADMIN_VOD_EXPOSE, URL_PUT_ADMIN_VOD_HIDE
} from "./URLS";

/**
 * Common Controller
 */
export class ContentsApi extends BaseApi {
	getLivestreamingList = (request: LiveStreamingRequest) => {
		return this.axios
			.get<LiveStreamingResponse>(URL_ADMIN_LIVE_STREAMING, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	getLiveStreamingDetail = (liveStreamingId: string) => {
		return this.axios
			.get<LiveStreamingDetailResponse>(URL_ADMIN_LIVE_STREAMING_DETAIL.replace('{liveStreamingId}', liveStreamingId) )
			.then(response => response)
			.catch(error => error.response);
	}

	postLivestreamingForceQuit = (liveStreamingId: string, forceQuitReason: string) => {
		return this.axios
			.put(URL_ADMIN_LIVE_FORCE_QUIT.replace('{liveStreamingId}', liveStreamingId), { forceQuitReason })
			.then(response => response)
			.catch(error => error.response);
	};

	getVodList = (request: VodListRequest) => {
		return this.axios
			.get<VodListResponse>(URL_ADMIN_VOD, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	getVodDetail = (vodId: string) => {
		return this.axios
			.get<VodDetailResponse>(URL_ADMIN_VOD_DETAIL.replace('{vodId}', vodId))
			.then(response => response)
			.catch(error => error.response);
	};

	putVodExpose = (vodId: string) => {
		return this.axios
			.put(URL_PUT_ADMIN_VOD_EXPOSE.replace('{vodId}', vodId))
			.then(response => response)
			.catch(error => error.response);
	};

	putVodHide = (vodId: string) => {
		return this.axios
			.put(URL_PUT_ADMIN_VOD_HIDE.replace('{vodId}', vodId))
			.then(response => response)
			.catch(error => error.response);
	};
}

/**
 * ====================================================
 * ====================================================
 * Request
 */
export type LiveStreamingRequest = PagingRequest & {
	/**
	 *  검색 조건
	 *  Available values : IDOL_STAGE_NAME, IDOL_NAME, LIVE_STREAMING_TITLE, LIVE_STREAMING_TAG
	 */
	searchCondition?: LiveStreamingSearchType;

	/**
	 *
	 * 방송 상태. 전체 검색시 null
	 * Available values : SYSTEM_PROBLEM_END, ADMIN_FORCE_END, ADMIN_FORCE_END_WAIT, EXPLODE_END, EXPLODE_END_WAIT, NORMAL_END, NORMAL_END_WAIT, LIVE, READY, CREATED
	 */
	broadcastStatus?: LiveStreamingBroadcastStatusType;

	/**
	 * string
	 *  검색어
	 */
	searchWord?: string;
};

export type MemberDetailRequest = {
	memberType: MemberType;
};

export type VodListRequest = PagingRequest & {
	periodSearchCondition?: PeroidSearchCondition;
	isDeleted?: boolean;
};

/**
 * ====================================================
 * ====================================================
 * Response
 */
export type LiveStreamingResponse = {
	liveStreamingTrain: LiveStreamingTrain[];
	count: number;
};

export type LiveStreamingTrain = {
	// 제목
	title: string;

	// 썸네일 이미지
	thumbnailImgUrl: string;

	// 라이브 스트리밍 아이디
	liveStreamingId: number;

	// 데이터 생성일
	createDateTime: string;

	// 방송 상태
	broadcastStatus: LiveStreamingBroadcastStatusType;

	// 시청자 수
	currentViewerCount: number;

	// 영상 종류
	videoContentType: VideoContentType;

	// 아이돌 아이디
	idolId: number;

	// 아이돌 실명
	idolRealName: string;

	// 아이돌 활동 명
	idolActivityName: string;

	// 아이돌 프로필 이미지
	idolProfileImgUrl: string;

	// 해시태그 ID 목록
	hashTagIdTrain: [number];

	// 해시 태그 목록
	hashTagTrain: [HashtagTrain];

	// 강제 종료 이유
	forceQuitReason: string;
};

export type HashtagTrain = {
	hashTagId: number;
	hashTag: string;
};

export type LiveStreamingDetailResponse = {
	liveStreaming: LiveStreamingDetail;
};

export type LiveStreamingDetail = LiveStreamingTrain & {
	streamUrl: string;
	playbackUrl: string;
	startDateTime: string;
	endDateTime: string;
	readyDatetime: string;
}


export type MemberTrain = {
	/**
	 * 회원 아이디
	 */
	memberId: number;

	/**
	 * 활동명
	 */
	activityName: string;

	/**
	 * 회원 식별자
	 */
	pid: string;

	/**
	 * 가입일자
	 */
	createDateTime: string;

	/**
	 * 직업
	 */

	memberType: MemberType;

	/**
	 * 전화번호 - 국가번호
	 */
	countryCode: string;

	/**
	 * 전화번호
	 */
	mobile: string;

	/**
	 * 이메일
	 */
	email: string;

	/**
	 * 연동 소셜
	 */
	loginType: MemberSocialLoginType;

	/**
	 * 계정 상태
	 *
	 *   Enum:
	 *     [ NORMAL, BLACK_LISTED, WITHDRAW_SUBMITTED ]
	 */
	memberStatus: MemberStatusType;

	/**
	 * 본인 인증 여부
	 */
	isVerified: boolean;

	/**
	 * 탈퇴 여부 (회원 탈퇴시에 $set)
	 */
	withdrawSubmitted: boolean;

	/**
	 * 블랙 리스트
	 */
	blackListed: boolean;

	/**
	 * 인 앱 결제 여부
	 */
	inAppPurchased: boolean;
};

/**
 * ============================================================
 *  VOD
 * ============================================================
 */

export type VodListResponse = {
	vodTrain: VodTrain[];
	count: number;
};

export type VodTrain = {
	/**
	 * 제목
	 */
	title: string;

	/**
	 * 썸네일 이미지
	 */
	thumbnailImgUrl: string;

	/**
	 * 라이브 스트리밍 아이디
	 */
	liveStreamingId: string;

	/**
	 * 데이터 생성일
	 */
	createDateTime: string;

	/**
	 * VOD 아이디
	 */
	vodId: number;

	/**
	 *
	 */
	isHidden: boolean;

	/**
	 *
	 */
	isDeleted: boolean;

	/**
	 *
	 */
	deleteReason: string;

	/**
	 * 아이돌 아이디
	 */
	idolId: number;

	/**
	 * 아이돌 실명
	 */
	idolRealName: string;

	/**
	 * 아이돌 활동 명
	 */
	idolActivityName: string;

	/**
	 * 아이돌 프로필 이미지
	 */
	idolProfileImgUrl: string;
};

export type VodDetailResponse = {
	vod: VodDetail;
};

export type VodDetail = {
	/**
	 * 제목
	 */
	title: string;

	/**
	 * 썸네일 이미지
	 */
	thumbnailImgUrl: string;

	/**
	 * 라이브 스트리밍 아이디
	 */
	liveStreamingId: number;

	/**
	 * 데이터 생성일
	 */
	createDateTime: string;

	/**
	 * VOD 아이디
	 */
	vodId: number;

	/**
	 *
	 */
	vodUrl: string;

	/**
	 *
	 */
	duration: number;

	/**
	 *
	 */
	isHidden: boolean;

	/**
	 *
	 */
	isDeleted: boolean;

	/**
	 *
	 */
	deleteReason: string;

	/**
	 *
	 */
	isPublic: boolean;

	/**
	 * 아이돌 아이디
	 */
	idolId: number;

	/**
	 * 아이돌 실명
	 */
	idolRealName: string;

	/**
	 * 아이돌 활동 명
	 */
	idolActivityName: string;

	/**
	 * 아이돌 프로필 이미지
	 */
	idolProfileImgUrl: string;
};
