import { AdminAccountDetail, AuthType, LoginType, Token, YN } from './common';
import { BaseApi } from './baseAPI';
import { URL_DASHBOARD } from './URLS';

/**
 * Common Controller
 */
export class CommonApi extends BaseApi {
	signin = (request: SigninRequest) => {
		return this.axios
			.post<SigninResponse>('/admin/account/signin', request)
			.then(response => response)
			.catch(error => error.response);
	};

	signup = (request: SignupRequest) => {
		const formData = new FormData();
		if (request.coverImg) {
			formData.append('coverImg', request.coverImg);
		}

		if (request.profileImg) {
			formData.append('profileImg', request.profileImg);
		}

		return this.axios
			.post<SignupResponse>('v1/signup', formData, {
				params: {
					countryCode: request.countryCode,
					email: request.email,
					loginType: request.loginType,
					mobile: request.mobile,
					name: request.name,
					password: request.password,
					pid: request.pid,
					receiveMarketingInfoYn: request.receiveMarketingInfoYn,
					receiveNightTimeInfoYn: request.receiveNightTimeInfoYn,
					snsToken: request.snsToken,
					device: request.device
				}
			})
			.then(response => response.data);
	};

	isUsingEmail = (request: IsUsingEmailRequest) => {
		return this.axios
			.get<IsUsingEmailResponse>('v1/check-email', {
				params: {
					email: request.email
				}
			})
			.then(response => response.data);
	};

	isUsingMobile = (request: IsUsingMobileRequest) => {
		return this.axios
			.get<IsUsingMobileResponse>('v1/check-mobile', {
				params: {
					countryCode: request.countryCode,
					mobile: request.mobile
				}
			})
			.then(response => response.data);
	};

	isUsingSns = (request: IsUsingSnsRequest) => {
		return this.axios
			.get<IsUsingSnsResponse>('v1/check-sns', {
				params: {
					email: request.email,
					loginType: request.loginType,
					snsToken: request.snsToken
				}
			})
			.then(response => response.data);
	};

	isUsingPId = (request: IsUsingPIdRequest) => {
		return this.axios
			.get<IsUsingPIdResponse>('v1/check-id', {
				params: {
					pid: request.pid
				}
			})
			.then(response => response.data);
	};

	requestEmailCode = (request: RequestEmailCodeRequest) => {
		return this.axios.post<RequestEmailCodeResponse>('v1/valid-email', request).then(response => response);
	};

	checkEmailCode = (request: CheckEmailCodeRequest) => {
		return this.axios
			.post<CheckEmailCodeResponse | undefined>('v1/valid-email-check', request)
			.then(response => response);
	};

	requestMobileCode = (request: RequestMobileCodeRequest) => {
		return this.axios.post<RequestMobileCodeResponse>('v1/valid-mobile', request).then(response => response);
	};

	checkMobileCode = (request: CheckMobileCodeRequest) => {
		return this.axios.post<CheckMobileCodeResponse>('v1/valid-mobile-check', request).then(response => response);
	};

	tempPassword = (request: TempPasswordRequest) => {
		return this.axios
			.post<TempPasswordResponse>('v1/temp-password', {
				email: request.email
			})
			.then(response => response);
	};

	getDashboard = () => {
		return this.axios.get<DashboardResponse>(URL_DASHBOARD).then(response => response).catch(error => error.response);;
	};
}

/**
 *
 *
 * 리퀘스트들
 *
 *
 */
export type PagingRequest = {
	/**
	 * 한번에 조회할 목록 수
	 */
	limit: number;

	/**
	 * 페이지 번호
	 */
	offset: number;
};

/**
 *
 *
 * 리퀘스트들
 *
 *
 */
export type SigninRequest = {
	/**
	 * 인증번호
	 */
	authNo?: string;
	/**
	 * 국가코드
	 */
	countryCode?: string;
	/**
	 * 이메일
	 */
	email?: string;
	/**
	 * 로그인 타입
	 */
	loginType?: LoginType;
	/**
	 * 휴대폰 번호
	 */
	mobile?: string;
	/**
	 * 비밀번호
	 * 영어 대소문자, 숫자, 특수문자 포함 8-20자리
	 */
	password?: string;
	/**
	 * SNS 인증 토큰
	 */
	snsToken?: string;
};

export type SignupRequest = {
	/**
	 * 국가코드
	 */
	countryCode?: string;
	/**
	 * 이메일
	 */
	email?: string;
	/**
	 * 로그인 타입
	 */
	loginType?: LoginType;
	/**
	 * 휴대폰 번호
	 */
	mobile?: string;
	/**
	 * 이름
	 */
	name?: string;
	/**
	 * 비밀번호
	 */
	password?: string;
	/**
	 * KIINRAP ID ex)test123
	 */
	pid?: string;
	/**
	 * 마케팅 정보 수신 동의
	 */
	receiveMarketingInfoYn?: YN;
	/**
	 * 야간 푸시알림 동의
	 */
	receiveNightTimeInfoYn?: YN;
	/**
	 * SNS 인증 토큰
	 */
	snsToken?: string;
	/**
	 * 프로필 배경 이미지
	 */
	coverImg?: File;
	/**
	 * 프로필 이미지
	 */
	profileImg?: File;
	// /**
	//  * 디바이스
	//  */
	device?: string;
};

export type IsUsingEmailRequest = {
	/**
	 * 이메일
	 */
	email?: string;
};

export type IsUsingMobileRequest = {
	/**
	 * 국가코드
	 */
	countryCode?: string;
	/**
	 * 휴대폰 번호
	 */
	mobile?: string;
};

export type IsUsingSnsRequest = {
	/**
	 * 이메일 주소
	 */
	email?: string;
	/**
	 * 로그인 타입
	 */
	loginType?: LoginType;
	/**
	 * SNS 인증토큰
	 */
	snsToken?: string;
};

export type IsUsingPIdRequest = {
	/**
	 * KIINRAP ID ex)test123
	 */
	pid?: string;
};

export type RequestEmailCodeRequest = {
	/**
	 * 인증 타입 R: 회원가입, L: 로그인, C: 업데이트
	 */
	authType?: AuthType;
	/**
	 * 이메일 주소
	 */
	email?: string;
	/**
	 * 강제 재전송 여부
	 */
	isForced?: boolean;
};

export type CheckEmailCodeRequest = {
	/**
	 * 이메일 인증번호
	 */
	authNo?: string;
	/**
	 * 인증 타입 R: 회원가입, L: 로그인, C: 업데이트
	 */
	authType?: AuthType;
	/**
	 * 이메일 주소
	 */
	email?: string;
	/**
	 * 강제 재전송 여부
	 */
	isForced?: boolean;
};

export type RequestMobileCodeRequest = {
	/**
	 * 인증 타입 R: 회원가입, L: 로그인, C: 업데이트
	 */
	authType?: AuthType;
	/**
	 * 국가 코드
	 */
	countryCode?: string;
	/**
	 * 강제 재전송 여부
	 */
	isForced?: boolean;
	/**
	 * 휴대번호
	 */
	mobile?: string;
};

export type CheckMobileCodeRequest = {
	/**
	 * 모바일 인증번호
	 */
	authNo?: string;
	/**
	 * 인증 타입 R: 회원가입, L: 로그인, C: 업데이트
	 */
	authType?: AuthType;
	/**
	 * 국가 코드
	 */
	countryCode?: string;
	/**
	 * 강제 재전송 여부
	 */
	isForced?: boolean;
	/**
	 * 휴대번호
	 */
	mobile?: string;
};

export type TempPasswordRequest = {
	email: string;
};

/**
 *
 *
 * 리스폰스들
 *
 *
 */
export type SigninResponse = {
	accountDetail: AdminAccountDetail;
	token: Token;
};

export type SignupResponse = {
	accountDetail: AdminAccountDetail;
	token: Token;
};

export type IsUsingEmailResponse = {
	isExist: boolean;
};

export type IsUsingMobileResponse = {
	isExist: boolean;
};

export type IsUsingSnsResponse = {
	isRegister: boolean;
	loginType: LoginType;
};

export type IsUsingPIdResponse = {
	isExist: boolean;
};

export type RequestEmailCodeResponse = {
	authNo: string;
};

export type CheckEmailCodeResponse = {
	authNo: string;
};

export type RequestMobileCodeResponse = {
	authNo: string;
};

export type CheckMobileCodeResponse = {
	authNo: string;
};

export type TempPasswordResponse = {
	member: AdminAccountDetail;
	token: Token;
};

export type DashboardResponse = {
	registerIdolCount: DashboardTrain;
	withdrawIdolCount: DashboardTrain;
	registerProducerCount: DashboardTrain;
	withdrawProducerCount: DashboardTrain;
	paymentAmount: DashboardStoreTrain;
};

export type DashboardTrain = {
	dailyRecord: DashboardValueTrain;
	weeklyRecord: DashboardValueTrain;
	monthlyRecord: DashboardValueTrain;
	totalRecord: DashboardValueTrain;
};

export type DashboardStoreTrain = {
	dailyRecord: DashboardValueTrain[];
	weeklyRecord: DashboardValueTrain[];
	monthlyRecord: DashboardValueTrain[];
	totalRecord: DashboardValueTrain[];
};

export type DashboardValueTrain = {
	value?: string;
	store?: string;
	payType?: string;
	searchStartDateTime?: string;
	searchEndDateTime?: string;
};
