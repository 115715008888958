// Chakra imports
import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useColorModeValue
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import * as React from "react";

// Assets

export type ButtonDef = {
	title: string;
	onClick: (value: any) => void;
};

export default function ModalBasic(props: {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	desc?: string;
	firstButton?: ButtonDef;
	secondaryButton?: ButtonDef;
}) {
	// Chakra Color Mode

	const { t } = useTranslation();
	const { isOpen, onClose, title, desc, firstButton, secondaryButton } = props;

	const textColor = useColorModeValue('navy.700', 'white');

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{title}</ModalHeader>
				<ModalCloseButton />
				{desc ? (
					<ModalBody pb={6}>
						<Text color={textColor} fontSize="md" fontWeight="500">
							{desc}
						</Text>
					</ModalBody>
				) : null}

				<ModalFooter>
					{secondaryButton ? (
						<Button colorScheme="brand" mr={3} onClick={secondaryButton.onClick}>
							{secondaryButton.title}
						</Button>
					) : null}

					<Button onClick={firstButton && firstButton.onClick ? firstButton.onClick : onClose}>
						{firstButton && firstButton.title ? firstButton.title : t('common.cancel')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
