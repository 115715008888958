// Chakra Imports
import {
	Avatar,
	Button,
	Flex,
	Icon,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text, Tooltip,
	useColorModeValue
} from "@chakra-ui/react";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import routes from "../../routes";
import { FaEthereum } from "react-icons/fa";
import { useLogin } from "../../providers/login-provider";
import { useNavigate } from "react-router-dom";
import { MdOutlineDangerous, MdOutlineLogout } from "react-icons/md";
import * as React from "react";
import { useTranslation } from "react-i18next";
// Custom Components
// Assets

export default function HeaderLinks(props: { secondary: boolean; [x: string]: any }) {
	const { secondary, theme, setTheme } = props;
	const { logout } = useLogin();
	const navigate = useNavigate();

	const {t} = useTranslation();

	const { loginInfo } = useLogin();

	// Chakra Color Mode
	const navbarIcon = useColorModeValue('gray.400', 'white');
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.700', 'brand.400');
	const ethColor = useColorModeValue('gray.700', 'white');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const ethBox = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue(
		'0px 1px 11px 0px rgba(28, 35, 50, 0.04)',
		'0px 1px 11px 0px rgba(28, 35, 50, 0.04)'
	);
	const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');

	const buttonBg = useColorModeValue('transparent', 'navy.800');
	const hoverButton = useColorModeValue({ bg: 'gray.100' }, { bg: 'whiteAlpha.100' });
	const activeButton = useColorModeValue({ bg: 'gray.200' }, { bg: 'whiteAlpha.200' });

	const requestLogout = () => {
		logout();
		navigate(process.env.REACT_APP_DEFAULT_URI);
	}

	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems="center"
			flexDirection="row"
			justifyContent="space-between"
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
		>
			{/*<Text color={ethColor} fontSize="sm" marginRight={'20px'} marginLeft={'20px'}>*/}
			{/*	{'Welcome,' + loginInfo.name}*/}
			{/*</Text>*/}
			{/*<SearchBar*/}
			{/*    mb={() => {*/}
			{/*        if (secondary) {*/}
			{/*            return {base: '10px', md: 'unset'};*/}
			{/*        }*/}
			{/*        return 'unset';*/}
			{/*    }}*/}
			{/*    me="10px"*/}
			{/*    borderRadius="40px"*/}
			{/*/>*/}
			{/*<Flex*/}
			{/*	bg={ethBg}*/}
			{/*	display={secondary ? 'flex' : 'none'}*/}
			{/*	borderRadius="40px"*/}
			{/*	ms="auto"*/}
			{/*	p="6px"*/}
			{/*	align="center"*/}
			{/*	me="6px"*/}
			{/*	border="1px solid"*/}
			{/*	borderColor={borderColor}*/}
			{/*>*/}
			{/*	<Flex align="center" justify="center" bg={ethBox} h="29px" w="29px" borderRadius="6px" me="7px">*/}
			{/*		<Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />*/}
			{/*	</Flex>*/}
			{/*	<Text w="max-content" color={ethColor} fontSize="sm" fontWeight="700" me="6px">*/}
			{/*		1,924*/}
			{/*		<Text as="span" display={{ base: 'none', md: 'unset' }}>*/}
			{/*			{' '}*/}
			{/*			ETH*/}
			{/*		</Text>*/}
			{/*	</Text>*/}
			{/*</Flex>*/}
			<SidebarResponsive routes={routes} />

			<Tooltip label={t('common.logout')}>
				<Button
					bg={buttonBg}
					size={'sm'}
					border="1px solid"
					color="secondaryGray.600"
					// borderColor={useColorModeValue('secondaryGray.100', 'whiteAlpha.100')}
					// borderRadius="16px"
					_placeholder={{ color: 'secondaryGray.600' }}
					_hover={hoverButton}
					_active={activeButton}
					_focus={activeButton}
					onClick={() => {
						logout();
						window.location.href = "/"
					}}
				>
					<Icon color={textColor} as={MdOutlineLogout} />
				</Button>
			</Tooltip>

			{/*<Menu>*/}
			{/*	<MenuButton p="0px">*/}
			{/*		<Icon mt="6px" as={MdNotificationsNone} color={navbarIcon} w="18px" h="18px" me="10px" />*/}
			{/*	</MenuButton>*/}
			{/*	<MenuList*/}
			{/*		boxShadow={shadow}*/}
			{/*		p="20px"*/}
			{/*		bg={menuBg}*/}
			{/*		border="none"*/}
			{/*		mt="22px"*/}
			{/*		me={{ base: '30px', md: 'unset' }}*/}
			{/*		minW={{ base: 'unset', md: '400px', xl: '450px' }}*/}
			{/*		maxW={{ base: '360px', md: 'unset' }}*/}
			{/*		borderRadius="16px"*/}
			{/*	>*/}
			{/*		<Flex w="100%" mb="20px">*/}
			{/*			<Text fontSize="md" fontWeight="600" color={textColor}>*/}
			{/*				Notifications*/}
			{/*			</Text>*/}
			{/*			<Text fontSize="sm" fontWeight="500" color={textColorBrand} ms="auto" cursor="pointer">*/}
			{/*				Mark all read*/}
			{/*			</Text>*/}
			{/*		</Flex>*/}
			{/*		<Flex flexDirection="column">*/}
			{/*			<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">*/}
			{/*				<ItemContent info="Horizon UI Dashboard PRO" />*/}
			{/*			</MenuItem>*/}
			{/*			<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">*/}
			{/*				<ItemContent info="Horizon Design System Free" />*/}
			{/*			</MenuItem>*/}
			{/*		</Flex>*/}
			{/*	</MenuList>*/}
			{/*</Menu>*/}

			{/*<Menu>*/}
			{/*	<MenuButton p="0px">*/}
			{/*		<Icon mt="6px" as={MdInfoOutline} color={navbarIcon} w="18px" h="18px" me="10px" />*/}
			{/*	</MenuButton>*/}
			{/*	<MenuList*/}
			{/*		boxShadow={shadow}*/}
			{/*		p="20px"*/}
			{/*		me={{ base: '30px', md: 'unset' }}*/}
			{/*		borderRadius="16px"*/}
			{/*		bg={menuBg}*/}
			{/*		border="none"*/}
			{/*		mt="22px"*/}
			{/*		minW={{ base: 'unset' }}*/}
			{/*		maxW={{ base: '360px', md: 'unset' }}*/}
			{/*	>*/}
			{/*		<Image src={navImage} borderRadius="6px" mb="28px" />*/}
			{/*		<Flex flexDirection="column">*/}
			{/*			<Link w="100%" href="https://horizon-ui.com/pro">*/}
			{/*				<Button w="100%" h="44px" mb="10px" variant="brand">*/}
			{/*					Buy Horizon UI PRO*/}
			{/*				</Button>*/}
			{/*			</Link>*/}
			{/*			<Link w="100%" href="https://horizon-ui.com/documentation/docs/introduction">*/}
			{/*				<Button w="100%" h="44px" mb="10px" border="1px solid" bg="transparent" borderColor={borderButton}>*/}
			{/*					See Documentation*/}
			{/*				</Button>*/}
			{/*			</Link>*/}
			{/*			<Link w="100%" href="https://github.com/horizon-ui/horizon-ui-chakra-ts">*/}
			{/*				<Button w="100%" h="44px" variant="no-hover" color={textColor} bg="transparent">*/}
			{/*					Try Horizon Free*/}
			{/*				</Button>*/}
			{/*			</Link>*/}
			{/*		</Flex>*/}
			{/*	</MenuList>*/}
			{/*</Menu>*/}
			{/*<Configurator mini={props.mini} setMini={props.setMini} theme={theme} setTheme={setTheme} />*/}

			{/*<Menu>*/}
			{/*	<MenuButton p="0px">*/}
			{/*		<Avatar*/}
			{/*			_hover={{ cursor: 'pointer' }}*/}
			{/*			color={textColor}*/}
			{/*			name="Adela"*/}
			{/*			bg="#11047A"*/}
			{/*			size="sm"*/}
			{/*			w="40px"*/}
			{/*			h="40px"*/}
			{/*		/>*/}
			{/*	</MenuButton>*/}
			{/*	<MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="16px" bg={menuBg} border="none">*/}
			{/*		<Flex w="100%" mb="0px">*/}
			{/*			<Text*/}
			{/*				ps="20px"*/}
			{/*				pt="16px"*/}
			{/*				pb="10px"*/}
			{/*				w="100%"*/}
			{/*				borderBottom="1px solid"*/}
			{/*				borderColor={borderColor}*/}
			{/*				fontSize="sm"*/}
			{/*				fontWeight="700"*/}
			{/*				color={textColor}*/}
			{/*			>*/}
			{/*				👋&nbsp; Hey, {loginInfo.name}*/}
			{/*			</Text>*/}
			{/*		</Flex>*/}
			{/*		<Flex flexDirection="column" p="10px">*/}
			{/*			/!*<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">*!/*/}
			{/*			/!*	<Text fontSize="sm">Profile Settings</Text>*!/*/}
			{/*			/!*</MenuItem>*!/*/}
			{/*			/!*<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">*!/*/}
			{/*			/!*	<Text fontSize="sm">Newsletter Settings</Text>*!/*/}
			{/*			/!*</MenuItem>*!/*/}
			{/*			<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} color="red.400" borderRadius="8px" px="14px" onClick={requestLogout}>*/}
			{/*				<Text fontSize="sm">Log out</Text>*/}
			{/*			</MenuItem>*/}
			{/*		</Flex>*/}
			{/*	</MenuList>*/}
			{/*</Menu>*/}
		</Flex>
	);
}
