// Chakra imports
import {
	Button,
	Flex,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Tooltip,
	useColorModeValue
} from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Card from '../../../../components/card/Card';

import { useTranslation } from 'react-i18next';
import { MdOutlineAdd, MdOutlineSearch } from 'react-icons/md';
import { createDynamicColumns, VisibleColumns } from '../../../../components/table/createDynamicColumns';
import { useThemeColors } from '../../../../providers/theme-provider';
import SearchInput from '../../../../components/actions/Search';
import Filter from '../../../../components/actions/Filter';
import { TYPE_KEY_SORT } from '../../../../helper/types';
import SearchTable from '../../../../components/table/SearchTable';
import { useApi } from '../../../../providers/api-provider';
import { IdolMemberListSearchResponse, IdolMemberTrain, MemberListSearchRequest } from '../../../../apis/memberAPI';
import { showToast } from '../../../../helper/functions';
import { TableRowType } from '../../../../types/enums';
import { TableBodyText } from '../../../../components/table/TableComponents';
import IdolPayoutRatioDetail from './detail';

// 금지어 목록
export default function IdolPayoutRatioList() {
	const { memberApi } = useApi();

	const { t } = useTranslation();
	const { textColor, buttonBg, hoverButton, activeButton } = useThemeColors();

	// 목록
	const [list, setList] = useState<IdolMemberTrain[]>([]);
	const [searchParams, setSearchParams] = useState<MemberListSearchRequest>(null);

	// 전체 갯수
	const [totalCount, setTotalCount] = useState<number>(0);
	// 한번에 불러올 목록
	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);

	// 상세 팝업
	const [selectDetail, setSelectDetail] = useState<IdolMemberTrain>(null);
	const [isShowModal, setIsShowModal] = useState<boolean>(false);

	useEffect(() => {
		requestList(offset);
	}, []);

	// 목록 요청
	const requestList = async (offset: number) => {
		setOffset(offset);
		memberApi
			.getIdolMemberSearch({
				limit: limit,
				offset: offset * limit,
				...searchParams
			})
			.then(responseList)
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	// 목록 처리
	const responseList = (response: IdolMemberListSearchResponse) => {
		if (response.idolTrain) {
			setList(response.idolTrain);
		}

		if (response.count) {
			setTotalCount(response.count);
		}
	};

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<IdolMemberTrain>[] = [
		{
			row: 'memberId',
			title: 'id',
			type: TableRowType.TEXT
		},
		{
			row: 'activityName',
			title: 'activityName',
			type: TableRowType.TEXT
		},
		{
			row: 'countryCode',
			title: 'countryCode',
			type: TableRowType.TEXT
		},
		{
			row: 'redeemRate',
			title: 'redeemRate',
			type: TableRowType.ACTION,
			extraComponent: (value: any, rowData: any) => {
				return <TableBodyText title={value ? value + '%' : '40%'} onClick={() => {
					handleRowClick(rowData);
				}} />;
			}
		}
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)
		// window.location.href = '';
		setSelectDetail(rowData);
		setIsShowModal(true);
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	// 정렬 필터 변경
	const filterChange = (type: string) => {};

	// 검색 엔터키 처리
	const handleSearchKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleClickSearch();
		}
	};

	const handleClickSearch = () => {};

	// 검색어 저장
	const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {};

	const handleClickAdd = () => {
		setIsShowModal(true);
		setSelectDetail(null);
	};

	// Chakra Color Mode
	return (
		<Flex direction="column" pt={{ sm: '75px', lg: '75px' }}>
			<Card px="0px">
				<Flex w="100%" direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'} mb="36px">
					<SearchInput
						onChangeSearch={onSearchInputChange}
						onEnterKeyPress={handleSearchKeyPress}
						rightElement={
							<Button h="1.75rem" size="sm" onClick={handleClickSearch} borderRadius="20px">
								<Icon color={textColor} as={MdOutlineSearch} />
							</Button>
						}
					/>
					<Flex justifyContent={'flex-end'} mr={{ base: '0px', sm: '10px' }}>
						<Filter
							onChange={filterChange}
							initialSortType={TYPE_KEY_SORT.LATEST}
							secondaryTitle={'components.filter_alphabet'}
						/>
					</Flex>
				</Flex>
				<SearchTable request={requestList} data={list} columns={columns} totalCount={totalCount} />
			</Card>

			<Modal
				isOpen={isShowModal}
				onClose={() => {
					setIsShowModal(false);
				}}
				isCentered
				size={'xl'}
			>
				<ModalOverlay />

				<ModalContent>
					<ModalHeader>{''}</ModalHeader>
					<ModalCloseButton />
					<ModalBody overflow="auto">
						<IdolPayoutRatioDetail
							detail={selectDetail}
							requestChangeParams={() => {
								requestList(offset);
								setIsShowModal(false);
							}}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	);
}
