// Chakra imports
import { Flex, FormLabel, Text } from '@chakra-ui/react';
import { useThemeColors } from '../../providers/theme-provider';
// Custom components

export default function Default(props: { label: string; extra?: string; [x: string]: any }) {
	const { label, extra, mb, ...rest } = props;

	const { textColorSecondary, textColorPrimary } = useThemeColors();

	return (
		<Flex direction="column" mb={mb ? mb : '10px'}>
			<FormLabel
				display="flex"
				ms="10px"
				fontSize="sm"
				color={textColorPrimary}
				fontWeight="bold"
				_hover={{ cursor: 'pointer' }}
			>
				{label}
				<Text fontSize="sm" fontWeight="400" pl={'10px'} ms="2px">
					{extra}
				</Text>
			</FormLabel>
		</Flex>
	);
}
