// Chakra imports
import { Flex, Grid, SimpleGrid } from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useState } from 'react';
import Card from 'components/card/Card';
import { useApi } from '../../../../providers/api-provider';
import { useTranslation } from 'react-i18next';
import { useThemeColors } from '../../../../providers/theme-provider';
import { ReportTrain } from '../../../../apis/reportAPI';
import TextField from '../../../../components/fields/TextField';
import { MemberStatusString, ReportStatusString } from '../../../../helper/types';
import { MemberType, ReportContentsType } from '../../../../types/enums';
import ImageField from 'components/fields/ImageField';
import { dateFormat } from '../../../../helper/functions';
import VideoField from 'components/fields/VideoField';

// 금지어 목록
export default function ReportDetailInformation(props: { reportDetail: ReportTrain; requestChangeParams: () => void }) {
	const { t } = useTranslation();
	const {} = useThemeColors();
	const { memberApi, contentsApi } = useApi();

	const { reportDetail, requestChangeParams } = props;
	const [item, setItem] = useState<ReportTrain>(null);

	const [contentsDetail, setContentsDetail] = useState(null);

	useEffect(() => {
		if (reportDetail !== null) {
			setItem(reportDetail);
			requestDetail();
		}
	}, [reportDetail]);

	/**
	 * PROFILE_IDOL = 'PROFILE_IDOL',
	 *  PROFILE_PRODUCER = 'PROFILE_PRODUCER',
	 *  LIVE_STREAMING = 'LIVE_STREAMING',
	 *  VOD = 'VOD',
	 *  VOD_COMMENT = 'VOD_COMMENT',
	 *  LIVE_STREAMING_CHAT = 'LIVE_STREAMING_CHAT', // 검색용
	 */
	const requestDetail = () => {
		if (reportDetail.contentsType === ReportContentsType.PROFILE_IDOL) {
			memberApi
				.memberDetail(reportDetail.contentsId, {
					memberType: MemberType.IDOL
				})
				.then(response => {
					setContentsDetail(response?.memberDetail);
				})
				.catch(() => {});
		} else if (reportDetail.contentsType === ReportContentsType.PROFILE_PRODUCER) {
			memberApi
				.memberDetail(reportDetail.contentsId, {
					memberType: MemberType.PRODUCER
				})
				.then(response => {
					setContentsDetail(response?.memberDetail);
				})
				.catch(() => {});
		} else if (reportDetail.contentsType === ReportContentsType.VOD) {
			contentsApi
				.getVodDetail(String(reportDetail.contentsId))
				.then(response => {
					setContentsDetail(response?.vod);
				})
				.catch(() => {});
		} else if (reportDetail.contentsType === ReportContentsType.LIVE_STREAMING) {
			contentsApi
				.getLiveStreamingDetail(String(reportDetail.contentsId))
				.then(response => {
					setContentsDetail(response?.liveStreaming);
				})
				.catch(() => {});
		}
	};

	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '0px', md: '0px', xl: '0px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Flex direction={'column'}>
				<Grid
					display={{ base: 'block', md: 'grid' }}
					gridTemplateColumns={{ base: '1.5fr 1.5fr', lg: '1.5fr 1.5fr' }}
					gap={{ base: '20px', xl: '20px' }}
				>
					<Card>
						{reportDetail.contentsType === ReportContentsType.PROFILE_IDOL ||
						reportDetail.contentsType === ReportContentsType.PROFILE_PRODUCER ? (
							<SimpleGrid>
								<TextField label={t('common.id')} text={String(contentsDetail?.memberId)} />
								<TextField label={t('user.activity_name')} text={contentsDetail?.activityName} />
								<TextField label={t('user.member_status')} text={MemberStatusString(t, contentsDetail?.memberStatus)} />
								<TextField
									label={t('user.number_livestreaming_count')}
									text={String(contentsDetail?.liveStreamingCount)}
								/>
								<TextField label={t('user.vod_count')} text={contentsDetail?.vodCount} />

								<TextField
									label={t('reports.report_status')}
									text={ReportStatusString(t, String(reportDetail?.status))}
								/>
							</SimpleGrid>
						) : null}

						{reportDetail.contentsType === ReportContentsType.VOD ? (
							<SimpleGrid>
								<TextField label={t('common.id')} text={String(contentsDetail?.vodId)} />
								<TextField label={t('common.title')} text={contentsDetail?.title} />
								<TextField label={t('common.created_date')} text={dateFormat(contentsDetail?.createDateTime)} />
								<TextField label={t('user.donation_count')} text={String(contentsDetail?.duration)} />
								<TextField label={t('user.activity_name')} text={contentsDetail?.idolActivityName} />
								<ImageField label={t('common.profile_img')} url={contentsDetail?.idolProfileImgUrl} />
							</SimpleGrid>
						) : null}

						{reportDetail.contentsType === ReportContentsType.LIVE_STREAMING ? (
							<SimpleGrid>
								<TextField label={t('common.id')} text={String(contentsDetail?.liveStreamingId)} />
								<TextField label={t('common.title')} text={contentsDetail?.title} />
								<TextField label={t('common.created_date')} text={dateFormat(contentsDetail?.createDateTime)} />
								<TextField label={t('content.live.viewer_count')} text={String(contentsDetail?.currentViewerCount)} />
								<TextField label={t('user.activity_name')} text={contentsDetail?.idolActivityName} />
								<ImageField label={t('common.profile_img')} url={contentsDetail?.idolProfileImgUrl} />
							</SimpleGrid>
						) : null}
					</Card>
					<Card>
						<SimpleGrid>
							{reportDetail.contentsType === ReportContentsType.PROFILE_IDOL ||
							reportDetail.contentsType === ReportContentsType.PROFILE_PRODUCER ? (
								<SimpleGrid>
									<ImageField label={t('common.profile_img')} url={contentsDetail?.profileImgUrl} />
								</SimpleGrid>
							) : null}
							{reportDetail.contentsType === ReportContentsType.VOD ? (
								<SimpleGrid>
									<ImageField label={t('content.vod.detail_thumbnail_img')} url={contentsDetail?.thumbnailImgUrl} />
									<VideoField label={t('menu.contents.vod')} vodUrl={contentsDetail?.vodUrl} />
								</SimpleGrid>
							) : null}

							{reportDetail.contentsType === ReportContentsType.LIVE_STREAMING ? (
								<SimpleGrid>
									<ImageField label={t('content.live.detail_thumbnail_img')} url={contentsDetail?.thumbnailImgUrl} />
									<VideoField label={t('menu.contents.vod')} vodUrl={contentsDetail?.playbackUrl} />
								</SimpleGrid>
							) : null}
						</SimpleGrid>
					</Card>
				</Grid>
			</Flex>
		</Grid>
	);
}
