// Chakra imports
import { Button, Flex, Icon, Tooltip, useColorModeValue } from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Card from '../../../../components/card/Card';

import { useTranslation } from 'react-i18next';
import { MdOutlineAdd, MdOutlineSearch } from 'react-icons/md';
import { createDynamicColumns, VisibleColumns } from '../../../../components/table/createDynamicColumns';
import { useApi } from '../../../../providers/api-provider';
import { useThemeColors } from '../../../../providers/theme-provider';
import { NoticeType, SortCondition, TableRowType } from '../../../../types/enums';
import { MemberTypeString, TYPE_KEY_SORT } from '../../../../helper/types';
import SearchInput from '../../../../components/actions/Search';
import Filter from '../../../../components/actions/Filter';
import SearchTable from '../../../../components/table/SearchTable';
import { NoticeListRequest, NoticeListResponse, NoticeTrain } from '../../../../apis/noticeAPI';
import { TableBodyText } from '../../../../components/table/TableComponents';
import ModalBasic from '../../../../components/modal/ModalBasic';
import { DATA_FORMAT_SERVER_FULL_DATE, dateFormat, showToast } from '../../../../helper/functions';
import i18n from 'i18next';

// 금지어 목록
export default function Notices() {
	const { noticeApi } = useApi();

	const { t } = useTranslation();
	const { textColor, buttonBg, hoverButton, activeButton } = useThemeColors();

	// 목록
	const [list, setList] = useState<NoticeTrain[]>([]);

	// 전체 갯수
	const [totalCount, setTotalCount] = useState<number>(0);
	// 한번에 불러올 목록
	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);

	// 검색 조건
	const [searchParams, setSearchParams] = useState<Partial<NoticeListRequest>>({
		noticeType: NoticeType.SYSTEM
	});

	// 숨김 처리
	const [isShowModal, setIsShowModal] = useState(false);
	const [selectNotice, setSelectNotice] = useState<NoticeTrain>(null);

	useEffect(() => {
		requestNoticeList(offset);
	}, []);

	// 목록 요청
	const requestNoticeList = async (offset: number) => {
		setOffset(offset);
		noticeApi
			.getNotcieList({
				limit: limit,
				offset: offset * limit,
				...searchParams
			})
			.then(responseNoticeList)
			.catch(() => {});
	};

	// 목록 처리
	const responseNoticeList = (response: NoticeListResponse) => {
		if (response.noticeTrain) {
			setList(response.noticeTrain);
		}

		if (response.count) {
			setTotalCount(response.count);
		}
	};

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<NoticeTrain>[] = [
		{
			row: 'noticeId',
			title: 'id',
			type: TableRowType.TEXT
		},
		{
			row: 'noticeId',
			type: TableRowType.ACTION,
			title: 'noticeMemberType',
			extraComponent: (value: any) => {
				let item = list.filter(x => x.noticeId === value);

				let type = '';
				item[0].targetMemberTypes.map((text, i) => {
					if (i !== item[0].targetMemberTypes.length - 1) {
						type += MemberTypeString(t, text) + ', ';
					} else {
						type += MemberTypeString(t, text);
					}
				});
				return <TableBodyText title={type} />;
			}
		},
		{ row: 'startDateTime', type: TableRowType.DATETIME },
		{ row: 'endDateTime', type: TableRowType.DATETIME },
		{ row: 'createDateTime', type: TableRowType.DATETIME },
		{
			row: 'noticeId',
			type: TableRowType.ACTION,
			title: 'title',
			extraComponent: (value: any) => {
				let item = list.filter(x => x.noticeId === value);

				const currentLanguage = i18n.language;
				let displayText = '';
				if (currentLanguage.includes('KR')) {
					displayText = item[0].title.contentKo;
				} else if (currentLanguage.includes('EN')) {
					displayText = item[0].title.contentEn;
				} else {
					displayText = item[0].title.contentJa;
				}
				return <TableBodyText title={displayText} />;
			}
		},
		{
			row: 'isActive',
			type: TableRowType.BOOLEAN,
			action: rowData => {
				setSelectNotice(rowData);
				setIsShowModal(true);
			}
		}
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)
		window.location.href = '/admin/notice/' + rowData.noticeId;
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	// 정렬 필터 변경
	const filterChange = (type: string) => {
		setSearchParams(prevState => {
			return {
				...prevState,
				sortCondition: type === TYPE_KEY_SORT.LATEST ? SortCondition.NEWEST_DEST : SortCondition.WORD_ASC
			};
		});
	};

	// 검색 엔터키 처리
	const handleSearchKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleClickSearch();
		}
	};

	const handleClickSearch = () => {};

	// 검색어 저장
	const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// setSearchString(e.target.value);
	};

	const handleClickAdd = () => {
		// setIsShowAddModal(true);
		window.location.href = '/admin/notice/register';
	};

	const handleSwitchHide = () => {
		if (selectNotice) {
			const requestData: NoticeTrain = selectNotice;
			if (selectNotice.isActive) {
				requestData.isActive = false;
				requestData.noticeType = NoticeType.SYSTEM;

				const startDt = new Date(requestData.startDateTime);
				requestData.startDateTime = dateFormat(startDt, DATA_FORMAT_SERVER_FULL_DATE);

				const endDt = new Date(requestData.endDateTime);
				requestData.endDateTime = dateFormat(endDt, DATA_FORMAT_SERVER_FULL_DATE);

				const formData = new FormData();
				formData.append('request', JSON.stringify(requestData));
				formData.append('thumbnailImgFileJa', null);
				formData.append('thumbnailImgFileKo', null);
				formData.append('thumbnailImgFileEn', null);

				noticeApi
					.putNoticeDetail(String(selectNotice.noticeId), formData)
					.then(() => {
						requestNoticeList(offset);
					})
					.catch(() => {
						showToast(t('error.try_again'));
					});
			} else {
				requestData.isActive = true;
				requestData.noticeType = NoticeType.SYSTEM;

				const startDt = new Date(requestData.startDateTime);
				requestData.startDateTime = dateFormat(startDt, DATA_FORMAT_SERVER_FULL_DATE);

				const endDt = new Date(requestData.endDateTime);
				requestData.endDateTime = dateFormat(endDt, DATA_FORMAT_SERVER_FULL_DATE);

				const formData = new FormData();

				formData.append('request', JSON.stringify(requestData));
				formData.append('thumbnailImgFileJa', null);
				formData.append('thumbnailImgFileKo', null);
				formData.append('thumbnailImgFileEn', null);

				noticeApi
					.putNoticeDetail(String(selectNotice.noticeId), formData)
					.then(() => {
						requestNoticeList(offset);
					})
					.catch(() => {
						showToast(t('error.try_again'));
					});
			}
		}

		setIsShowModal(false);
	};

	// Chakra Color Mode
	return (
		<Flex direction="column" pt={{ sm: '75px', lg: '75px' }}>
			<Card px="0px">
				<Flex w="100%" direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'} mb="36px">
					<SearchInput
						onChangeSearch={onSearchInputChange}
						onEnterKeyPress={handleSearchKeyPress}
						rightElement={
							<Button h="1.75rem" size="sm" onClick={handleClickSearch} borderRadius="20px">
								<Icon color={textColor} as={MdOutlineSearch} />
							</Button>
						}
					/>
					<Flex justifyContent={'flex-end'} mr={{ base: '0px', sm: '10px' }}>
						<Filter
							onChange={filterChange}
							initialSortType={TYPE_KEY_SORT.LATEST}
							secondaryTitle={'components.filter_alphabet'}
						/>
						<Tooltip label={t('notice.register')}>
							<Button
								me={{ base: '10px', md: '20px' }}
								ml={{ base: '10px', sm: '10px', md: '10px' }}
								bg={buttonBg}
								border="1px solid"
								color="secondaryGray.600"
								borderColor={useColorModeValue('secondaryGray.100', 'whiteAlpha.100')}
								borderRadius="16px"
								_placeholder={{ color: 'secondaryGray.600' }}
								_hover={hoverButton}
								_active={activeButton}
								_focus={activeButton}
								onClick={handleClickAdd}
							>
								<Icon color={textColor} as={MdOutlineAdd} />
							</Button>
						</Tooltip>
					</Flex>
				</Flex>
				<SearchTable request={requestNoticeList} data={list} columns={columns} totalCount={totalCount} />
			</Card>

			<ModalBasic
				isOpen={isShowModal}
				onClose={() => {
					setIsShowModal(false);
				}}
				title={selectNotice && selectNotice.isActive === true ? t('notice.disable_notice') : t('notice.enble_notice')}
				desc={
					selectNotice && selectNotice.isActive === true
						? t('notice.disable_notice_desc')
						: t('notice.enable_notice_desc')
				}
				secondaryButton={{
					title: selectNotice && selectNotice.isActive === true ? t('common.disable') : t('common.enable'),
					onClick: (value: any) => {
						handleSwitchHide();
					}
				}}
			/>
		</Flex>
	);
}
