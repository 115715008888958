// Chakra imports
import { Flex, Grid, SimpleGrid } from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import TextField from 'components/fields/TextField';
import { useThemeColors } from '../../../../providers/theme-provider';
import { useApi } from '../../../../providers/api-provider';
import Card from '../../../../components/card/Card';
import { dateFormat, phoneFomatter, showToast } from "../../../../helper/functions";
import { ProjectApplicantDetailResponse, ProjectApplicantDetailTrain } from '../../../../apis/projectAPI';
import ImageField from 'components/fields/ImageField';
import { HSeparator } from '../../../../components/separator/Separator';
import { ProjectApplicantResultStatus, ProjectAppliedStatus } from '../../../../types/enums';
import ButtonField from '../../../../components/fields/ButtonField';
import SelectField from 'components/fields/SelectField';
import ModalBasic from '../../../../components/modal/ModalBasic';
import { ProjectApplicantResultStatusString } from '../../../../helper/types';

// 금지어 목록
export default function ProjectApplicantDetail(props: { detailId: string; requestChangeParams: () => void }) {
	const { t } = useTranslation();
	const {} = useThemeColors();
	const { projectApi } = useApi();

	const { detailId, requestChangeParams } = props;

	const [item, setItem] = useState<ProjectApplicantDetailTrain>();
	const [applicantResult, setApplicantResult] = useState<ProjectApplicantResultStatus>(
		ProjectApplicantResultStatus.QUALIFIED
	);
	const [isShowConfirm, setIsShowConfirm] = useState(false);

	useEffect(() => {
		if (detailId !== '-1') {
			requestDetail();
		}
	}, [detailId]);

	const requestDetail = () => {
		projectApi
			.getProjectApplicantDetail(detailId)
			.then(responseDetail)
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	const responseDetail = (response: ProjectApplicantDetailResponse) => {
		if (response.application) {
			setItem(response.application);
		}
	};

	const requestApplicantResult = () => {
		if (applicantResult === ProjectApplicantResultStatus.UNQUALIFIED) {
			projectApi
				.putProjectApplicantUnQualified([String(item?.projectApplicationId)])
				.then(responseApplicantDetail)
				.catch(() => {
					showToast(t('error.try_again'));
				});
		} else {
			projectApi
				.putProjectApplicantQualified([String(item?.projectApplicationId)])
				.then(responseApplicantDetail)
				.catch(() => {
					showToast(t('error.try_again'));
				});
		}
	};

	const responseApplicantDetail = () => {
		requestChangeParams();
	};

	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '0px', md: '0px', xl: '0px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Flex direction={'column'}>
				<Grid
					display={{ base: 'block', md: 'grid' }}
					gridTemplateColumns={{ base: '1fr 1fr 1fr', lg: '1fr 1fr 1fr' }}
					gap={{ base: '20px', xl: '20px' }}
				>
					<Card>
						<SimpleGrid>
							<TextField label={t('common.id')} text={String(item?.projectApplicationId)} />
							<TextField label={t('project.league_type')} text={String(item?.leagueType)} />
							<TextField label={t('user.activity_name')} text={String(item?.activityName)} />
							<TextField label={t('user.birth_date')} text={dateFormat(item?.birthDate)} />
							<TextField label={t('user.birth_prefecture')} text={String(item?.birthPrefecture)} />
							<TextField label={t('user.height')} text={String(item?.height)} />
							<TextField label={t('user.blood_type')} text={String(item?.bloodType)} />
							<TextField label={t('user.email')} text={String(item?.email)} />
							<TextField label={t('user.mobile')} text={phoneFomatter(String(item?.mobile), true) }  />
							<TextField label={t('common.status')} text={String(item?.status)} />
						</SimpleGrid>
					</Card>
					<Card>
						<SimpleGrid>
							<ImageField label={t('common.profile_img')} url={item?.profileImgUrl} />
							<HSeparator mb={'20px'} />
							<TextField label={t('project.detail_photos')} />
							<SimpleGrid columns={5}>
								{ item?.photoUrls && item?.photoUrls.length > 0 && item?.photoUrls.map(url => {
									return <ImageField url={url} />;
								})}
							</SimpleGrid>
						</SimpleGrid>
					</Card>
					<Card>
						<SimpleGrid>
							<SelectField
								label={t('project.select_applied_status')}
								value={applicantResult}
								disabled={item?.status !== ProjectAppliedStatus.APPLIED}
								options={Object.values(ProjectApplicantResultStatus)}
								onChange={e => {
									setApplicantResult(e.target.value as ProjectApplicantResultStatus);
								}}
								displayOptionsString={ProjectApplicantResultStatusString}
							/>
							<ButtonField
								buttonName={t('common.save')}
								disabled={item?.status !== ProjectAppliedStatus.APPLIED}
								onClick={() => {
									setIsShowConfirm(true);
								}}
							/>
						</SimpleGrid>
					</Card>
				</Grid>
			</Flex>

			<ModalBasic
				isOpen={isShowConfirm}
				onClose={() => {
					setIsShowConfirm(false);
				}}
				title={t('project.confirm_change_status', {
					status: ProjectApplicantResultStatusString(t, applicantResult)
				})}
				desc={t('project.confirm_change_status_desc', {
					status: ProjectApplicantResultStatusString(t, applicantResult)
				})}
				secondaryButton={{
					title: t('common.ok'),
					onClick: () => {
						requestApplicantResult();
						setIsShowConfirm(false);
					}
				}}
			/>
		</Grid>
	);
}
