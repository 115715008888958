// Chakra imports
import { Flex, Grid, SimpleGrid } from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import InputField from 'components/fields/InputField';
import { useApi } from '../../../providers/api-provider';
import { useThemeColors } from '../../../providers/theme-provider';
import Card from '../../../components/card/Card';
import { AccountDetailResponse, AccountInformationRequest, AccountRequest } from '../../../apis/accountAPI';
import { AdminAuth } from '../../../providers/auth-provider';
import { TYPE_ROUTE_AUTH } from '../../../helper/types';
import SwitchField from 'components/fields/SwitchField';
import TitleField from 'components/fields/TitleField';
import { HSeparator } from '../../../components/separator/Separator';
import DropImageBox from '../../../components/dropzone/DropzoneImgBox';
import { debugLog, showToast } from "../../../helper/functions";
import ButtonField from 'components/fields/ButtonField';

// 금지어 목록
export default function AccountDetail(props: { detailId: string; requestChangeParams: () => void }) {
	const { accountApi } = useApi();

	const { t } = useTranslation();
	const {} = useThemeColors();
	const { detailId, requestChangeParams } = props;

	const [name, setName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [profileImg, setProfileImg] = useState<File[]>([]);
	const [profileImgUrl, setProfileImgUrl] = useState<string[]>([]);
	const [countryCode, setCountryCode] = useState<string>('');
	const [mobile, setMobile] = useState<string>('');
	const [authTrain, setAuthTrain] = useState<AdminAuth[]>([]);
	const [password, setPassword] = useState<string>('');

	const [isActive, setIsActive] = useState<boolean>(false);

	useEffect(() => {
		if (detailId !== '-1') {
			requestAccountDetail(detailId);
		}
	}, [detailId]);

	const requestAccountDetail = (id: string) => {
		accountApi
			.getAccountDetail(id)
			.then(responseAccountDetail)
			.catch(() => {});
	};

	const responseAccountDetail = (response: AccountDetailResponse) => {
		if (response?.accountDetail) {
			let detail = response?.accountDetail;
			setName(detail.name);
			setEmail(detail.email);
			setMobile(detail.mobile);
			setProfileImgUrl([detail.profileImgUrl]);
			setIsActive(detail.isActive);
			setCountryCode(detail.countryCode);

			setAuthTrain(detail.authTrain);
		}
	};

	// 회원 등록
	const requestPostAccount = () => {
		if (!email && email.length === 0) {
			showToast(t('error.input_email'));
			return;
		}

		if (!password && password.length === 0) {
			showToast(t('error.input_password'));
			return;
		}

		let request: AccountRequest = {
			email: email,
			password: password,
			countryCode: countryCode,
			mobile: mobile,
			name: name,
			authTrain: authTrain,
			isActive: isActive
		};

		debugLog('=====> request ' + JSON.stringify(request));

		const formData = new FormData();
		formData.append('request', JSON.stringify(request));

		if (profileImg && profileImg.length > 0) {
			formData.append('profileImage', profileImg[0]);
		}

		accountApi
			.postAccount(formData)
			.then(() => {
				requestChangeParams();
			})
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	const requestPutAccount = () => {
		let request: AccountInformationRequest = {
			countryCode: countryCode,
			mobile: mobile,
			name: name,
			authTrain: authTrain
		};

		const formData = new FormData();
		formData.append('request', JSON.stringify(request));

		debugLog('=======> request ' + JSON.stringify(request));

		if (profileImgUrl && profileImgUrl.length === 0) {
			formData.append('profileImage', null);
		} else if (profileImg && profileImg.length > 0) {
			formData.append('profileImage', profileImg[0]);
		}


		accountApi
			.putAccountInfomation(String(detailId), formData)
			.then(() => {
				requestPutActivation();
			})
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	const requestPutActivation = () => {
		accountApi
			.putAccountActivation(String(detailId), isActive)
			.then(() => {
				requestChangeParams();
			})
			.catch(() => {
				showToast(t('error.try_again'));
			});
	}

	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '0px', md: '0px', xl: '0px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Flex direction={'column'}>
				<Grid
					display={{ base: 'block', md: 'grid' }}
					gridTemplateColumns={{ base: '1.5fr 1.5fr', lg: '1.5fr 1.5fr' }}
					gap={{ base: '20px', xl: '20px' }}
				>
					<Card>
						<SimpleGrid>
							<InputField
								label={t('common.name')}
								disabled={false}
								value={name}
								onChange={e => {
									setName(e.target.value);
								}}
							/>
							<InputField
								label={t('common.email')}
								disabled={detailId !== '-1'}
								value={email}
								onChange={e => {
									setEmail(e.target.value);
								}}
							/>
							<InputField
								label={t('common.country_code')}
								disabled={false}
								value={countryCode}
								type={'number'}
								onChange={e => {
									setCountryCode(e.target.value);
								}}
							/>
							<InputField
								label={t('common.mobile')}
								disabled={false}
								value={mobile}
								type={'number'}
								onChange={e => {
									setMobile(e.target.value);
								}}
							/>
							{detailId === '-1' ? (
								<InputField
									label={t('common.password')}
									disabled={detailId !== '-1'}
									value={password}
									onChange={e => {
										setPassword(e.target.value);
									}}
								/>
							) : null}
							<DropImageBox
								imgFiles={profileImg}
								setImgFiles={setProfileImg}
								imgUrls={profileImgUrl}
								setImgUrls={setProfileImgUrl}
								maxFiles={1}
								title={t('event.detail_img')}
							/>
						</SimpleGrid>
					</Card>
					<Card>
						<TitleField label={t('account.admin_auth')} />
						<SimpleGrid>
							{Object.keys(TYPE_ROUTE_AUTH).map((authType: string) =>
								authType !== TYPE_ROUTE_AUTH.NONE ? (
									<SwitchField
										key={authType}
										label={authType}
										isChecked={authTrain.filter(x => x.typeRouteAuth === authType).length > 0}
										onChange={e => {
											if (e.target.checked) {
												setAuthTrain(prev => [...prev, { typeRouteAuth: authType, typeRoutePermission: 'MANAGER' }]);
											} else {
												setAuthTrain(prev => prev.filter(item => item.typeRouteAuth !== authType));
											}
										}}
									/>
								) : null
							)}
							<HSeparator />
							<SwitchField
								label={t('common.enable')}
								isChecked={isActive}
								onChange={e => {
									setIsActive(e.target.checked);
								}}
							/>
							<HSeparator />
							<ButtonField
								buttonName={t('common.save')}
								onClick={() => {
									if (detailId === '-1') {
										requestPostAccount();
									} else {
										requestPutAccount();
									}
								}}
							/>
						</SimpleGrid>
					</Card>
				</Grid>
			</Flex>
		</Grid>
	);
}
