// Chakra imports
import {
	Button,
	Flex,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Tooltip,
	useColorModeValue
} from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Card from 'components/card/Card';

import { useTranslation } from 'react-i18next';
import { MdOutlineAdd, MdOutlineSearch } from 'react-icons/md';
import { createDynamicColumns, VisibleColumns } from 'components/table/createDynamicColumns';
import { useApi } from '../../../../providers/api-provider';
import { useThemeColors } from '../../../../providers/theme-provider';
import { ProjectAppliedResponse, ProjectAppliedTrain } from '../../../../apis/projectAPI';
import SearchInput from 'components/actions/Search';
import Filter from 'components/actions/Filter';
import { TYPE_KEY_SORT } from '../../../../helper/types';
import SearchTable from 'components/table/SearchTable';
import { TableRowType } from '../../../../types/enums';
import ProjectApplicantDetail from './detail';
import ProjectAddIdol from './add';

// 금지어 목록
export default function ProjectAppliedList() {
	const { projectApi } = useApi();

	const { t } = useTranslation();
	const { textColor, buttonBg, hoverButton, activeButton } = useThemeColors();

	// 목록
	const [list, setList] = useState<ProjectAppliedTrain[]>([]);

	// 전체 갯수
	const [totalCount, setTotalCount] = useState<number>(0);
	// 한번에 불러올 목록
	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);

	const [selectApplicationId, setSelectApplicationId] = useState<string>('');
	const [isShowModal, setIsShowModal] = useState<boolean>(false);

	const [isShowAddModal, setIsShowAddModal] = useState<boolean>(false);

	useEffect(() => {
		requestProjectAppliedList(offset);
	}, []);

	// 목록 요청
	const requestProjectAppliedList = async (offset: number) => {
		setOffset(offset);
		projectApi
			.getProjectAppliedList({
				limit: limit,
				offset: offset * limit
			})
			.then(responseProjectAppliedList)
			.catch(() => {});
	};

	// 목록 처리
	const responseProjectAppliedList = (response: ProjectAppliedResponse) => {
		if (response.applications) {
			setList(response.applications);
		}

		if (response.totalElements) {
			setTotalCount(response.totalElements);
		}
	};

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<ProjectAppliedTrain>[] = [
		{
			row: 'projectApplicationId',
			title: 'id',
			type: TableRowType.TEXT
		},
		{
			row: 'projectName',
			type: TableRowType.TEXT
		},
		{
			row: 'activityName',
			type: TableRowType.TEXT
		},
		{
			row: 'status',
			type: TableRowType.TEXT
		},
		{
			row: 'profileImgUrl',
			type: TableRowType.IMAGE
		}
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)
		// window.location.href = '/admin/event/' + rowData.eventId;
		setSelectApplicationId(String(rowData.projectApplicationId));
		setIsShowModal(true);
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	// 정렬 필터 변경
	const filterChange = (type: string) => {};

	// 검색 엔터키 처리
	const handleSearchKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleClickSearch();
		}
	};

	const handleClickSearch = () => {};

	// 검색어 저장
	const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// setSearchString(e.target.value);
	};

	const handleClickAdd = () => {
		setIsShowAddModal(true);
		//window.location.href = '/admin/notice/register';
	};

	const handleSwitchHide = () => {
		// setIsShowModal(false);
	};

	// Chakra Color Mode
	return (
		<Flex direction="column" pt={{ sm: '75px', lg: '75px' }}>
			<Card px="0px">
				<Flex w="100%" direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'} mb="36px">
					<SearchInput
						onChangeSearch={onSearchInputChange}
						onEnterKeyPress={handleSearchKeyPress}
						rightElement={
							<Button h="1.75rem" size="sm" onClick={handleClickSearch} borderRadius="20px">
								<Icon color={textColor} as={MdOutlineSearch} />
							</Button>
						}
					/>
					<Flex justifyContent={'flex-end'} mr={{ base: '0px', sm: '10px' }}>
						<Filter
							onChange={filterChange}
							initialSortType={TYPE_KEY_SORT.LATEST}
							secondaryTitle={'components.filter_alphabet'}
						/>
					</Flex>
					<Tooltip label={t('project.add_idol')}>
						<Button
							me={{ base: '10px', md: '20px' }}
							ml={{ base: '10px', sm: '10px', md: '10px' }}
							bg={buttonBg}
							border="1px solid"
							color="secondaryGray.600"
							borderColor={useColorModeValue('secondaryGray.100', 'whiteAlpha.100')}
							borderRadius="16px"
							_placeholder={{ color: 'secondaryGray.600' }}
							_hover={hoverButton}
							_active={activeButton}
							_focus={activeButton}
							onClick={handleClickAdd}
						>
							<Icon color={textColor} as={MdOutlineAdd} />
						</Button>
					</Tooltip>
				</Flex>
				<SearchTable request={requestProjectAppliedList} data={list} columns={columns} totalCount={totalCount} />
			</Card>

			<Modal
				isOpen={isShowModal}
				onClose={() => {
					setIsShowModal(false);
				}}
				isCentered
				size={'4xl'}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('project.applicant')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody overflow="auto">
						<ProjectApplicantDetail
							detailId={selectApplicationId}
							requestChangeParams={() => {
								setIsShowModal(false);
								requestProjectAppliedList(offset);
							}}
						/>
					</ModalBody>
					<ModalFooter />
				</ModalContent>
			</Modal>

			<Modal
				isOpen={isShowAddModal}
				onClose={() => {
					setIsShowAddModal(false);
				}}
				isCentered
				size={'md'}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('project.add_idol')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody overflow="auto">
						<ProjectAddIdol
							requestChangeParams={() => {
								setIsShowAddModal(false);
								requestProjectAppliedList(offset);
							}}
						/>
					</ModalBody>
					<ModalFooter />
				</ModalContent>
			</Modal>
		</Flex>
	);
}
