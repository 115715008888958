import { Flex, Input, InputGroup, InputLeftAddon, InputRightAddon } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default function SearchInput(props: {
	onEnterKeyPress?: any;
	onChangeSearch: any;
	leftElement?: any;
	rightElement?: any;
}) {
	const { onChangeSearch, onEnterKeyPress, leftElement, rightElement } = props;

	const { t } = useTranslation();

	return (
		<Flex
			align={{ sm: 'flex-start', lg: 'flex-start' }}
			justify={{ sm: 'flex-start', lg: 'flex-start' }}
			px="20px"
			w={{ base: '100%', md: '50%', lg: '50%', xl: '30%' }}
			mb={{ base: '0px', sm: '10px' }}
		>
			<InputGroup size="md">
				{leftElement ? <InputLeftAddon children={leftElement} borderLeftRadius="16px" /> : null}

				<Input
					w={'180px'}
					pr="4.5rem"
					borderRadius="16px"
					placeholder={t('components.placeholder_search')}
					onKeyPress={onEnterKeyPress}
					onChange={onChangeSearch}
				/>

				{rightElement ? <InputRightAddon children={rightElement} borderRightRadius="16px" /> : null}
			</InputGroup>
		</Flex>
	);
}
