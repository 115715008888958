// Chakra imports
import { Flex, Grid } from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { MemberType, TableRowType } from "../../../../types/enums";
import { useApi } from '../../../../providers/api-provider';
import { useThemeColors } from '../../../../providers/theme-provider';
import { createDynamicColumns, VisibleColumns } from '../../../../components/table/createDynamicColumns';
import { MemberListSearchRequest, MemberListSearchResponse, MemberTrain } from '../../../../apis/memberAPI';
import SearchTable from '../../../../components/table/SearchTable';
import { MemberStatusString, MemberTypeString } from "../../../../helper/types";

// 금지어 목록

export default function SearchMember(props: { requestChangeParams: (selectId: string, selectMemberType: MemberType) => void }) {
	const { memberApi } = useApi();

	const { requestChangeParams } = props;
	const { t } = useTranslation();
	const {} = useThemeColors();

	// 목록
	const [list, setList] = useState<MemberTrain[]>([]);

	// 전체 갯수
	const [totalCount, setTotalCount] = useState<number>(0);
	// 한번에 불러올 목록
	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);

	const requestUserList = async (offset: number, search?: MemberListSearchRequest) => {
		setOffset(offset);
		memberApi
			.getMemberListSearch({
				limit: limit,
				offset: limit * offset,
				...search
			})
			.then(responseMemberSearch)
			.catch(() => {});
	};

	const responseMemberSearch = (response: MemberListSearchResponse) => {
		if (response.memberTrain) {
			setList(response.memberTrain);
		}

		if (response.count) {
			setTotalCount(response.count);
		}
	};

	const visibleColumns: VisibleColumns<MemberTrain>[] = [
		{
			row: 'memberId',
			type: TableRowType.TEXT
		},
		{ row: 'activityName', type: TableRowType.TEXT },
		{ row: 'email', type: TableRowType.TEXT },
		{ row: 'memberType', type: TableRowType.ENUM, enumToText: MemberTypeString },
		{ row: 'memberStatus', type: TableRowType.ENUM, enumToText: MemberStatusString }
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		requestChangeParams(String(rowData.memberId), rowData.memberType);
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '0px', md: '0px', xl: '0px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Flex direction={'column'}>
				<SearchTable request={requestUserList} data={list} columns={columns} totalCount={totalCount} />
			</Flex>
		</Grid>
	);
}
