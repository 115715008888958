// Chakra imports
import { Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import Card from '../../../../components/card/Card';
import { useApi } from '../../../../providers/api-provider';
import {
    IdolApplyListRequest,
    IdolApplyListResponse,
    IdolApplySearchRequest,
    IdolApplySearchResponse
} from "../../../../apis/idolAPI";  // 변경된 부분
import { DATA_FORMAT_FULL_DATE, dateFormat } from '../../../../helper/functions';
import { IdolApplyStatus } from '../../../../types/enums';
import SearchTableApplyList from "../components/SearchTableApplyList";
// Custom components
// Assets

export default function Applylisted() {
    const { idolApi } = useApi(); // 변경된 부분
    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [limit, setLimit] = useState(10);

    const requestIdolApplySearch = async (offset: number, searchParams?: IdolApplySearchRequest) => { // 변경된 부분
        idolApi // 변경된 부분
            .idolApplyList({
                limit: limit,
                offset: offset,
				...searchParams
            })
            .then(responseIdolApplySearch)
            .catch(() => {});
    };

    const requestIdolApplyList = async (offset: number, searchParams?: IdolApplyListRequest) => { // 변경된 부분
        idolApi // 변경된 부분
            .idolApplyList({
                limit: limit,
                offset: offset
            })
            .then(responseIdolApplyList)
            .catch(() => {});
    };

    const responseIdolApplySearch = (response: IdolApplySearchResponse) => {  // 변경된 부분
        if (response.idolApplyTrain) {
            setList(response.idolApplyTrain);
        }

        if (response.count) {
            setTotalCount(response.count);
        }
    };

    const responseIdolApplyList = (response: IdolApplyListResponse) => {  // 변경된 부분
        if (response.idolApplyTrain) {
            setList(response.idolApplyTrain);
        }

        if (response.count) {
            setTotalCount(response.count);
        }
    };

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Card px="0px">
                <SearchTableApplyList
                    tableData={list}
                    totalCount={totalCount}
                    requestIdolApplySearch={requestIdolApplySearch} // 변경된 부분
                />
            </Card>
        </Flex>
    );
}
