import {
	Button,
	Checkbox,
	Flex,
	FormControl,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Select,
	SimpleGrid,
	Stack,
	Text,
	useBoolean
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextAreaField';
import { useThemeColors } from '../../../../providers/theme-provider';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useEffect, useState } from 'react';

import Quill from 'quill';
import { dateFormat } from '../../../../helper/functions';
import MiniCalendar from '../../../../components/calendar/MiniCalendar';
import moment from 'moment/moment';
import {
	EventRewardPayType,
	EventRewardType,
	MemberType,
	NoticePlacementType,
	NoticeType
} from '../../../../types/enums';
import DynamicInputField from 'components/fields/DynamicInputField';
import DropImageBox from "../../../../components/dropzone/DropzoneImgBox";

const BlockEmbed = Quill.import('blots/block/embed');

class VideoBlot extends BlockEmbed {
	static create(value: string) {
		const node = super.create(value);
		node.setAttribute('src', value);
		node.setAttribute('frameborder', '0');
		node.setAttribute('allowfullscreen', 'true');
		node.setAttribute('width', '100%'); // Full width
		node.setAttribute('height', '400px'); // Adjust height as needed
		return node;
	}

	static value(node: HTMLIFrameElement) {
		return node.getAttribute('src');
	}
}

VideoBlot.blotName = 'video';
VideoBlot.tagName = 'iframe';
VideoBlot.className = 'ql-video';
Quill.register(VideoBlot);

// Custom size format using px
const Size = Quill.import('formats/size');
Size.whitelist = ['12px', '14px', '16px', '18px', '24px', '32px', '48px']; // Define your custom sizes in px
Quill.register(Size, true);

const Parchment = Quill.import('parchment');
const SizeStyle = new Parchment.Attributor.Style('size', 'font-size', {
	scope: Parchment.Scope.INLINE,
	whitelist: ['12px', '14px', '16px', '18px', '20px', '24px', '32px', '48px'] // Define px sizes here
});
Quill.register(SizeStyle, true);

// Custom Toolbar component
const CustomToolbar = () => (
	<div id="toolbar">
		{/* Font size dropdown with px values */}
		<select className="ql-size" defaultValue="16px">
			<option value="12px">12px</option>
			<option value="14px">14px</option>
			<option value="16px">16px</option>
			<option value="18px">18px</option>
			<option value="20px">20px</option>
			<option value="24px">24px</option>
			<option value="32px">32px</option>
			<option value="48px">48px</option>
		</select>

		{/* Bold, Italic, Underline, Strikethrough */}
		<button className="ql-bold" />
		<button className="ql-italic" />
		<button className="ql-underline" />
		<button className="ql-strike" />

		{/* Blockquote */}
		<button className="ql-blockquote" />

		{/* List buttons */}
		<button className="ql-list" value="ordered" />
		<button className="ql-list" value="bullet" />
		<button className="ql-list" value="check" />

		{/* Indent buttons */}
		<button className="ql-indent" value="-1" />
		<button className="ql-indent" value="+1" />

		{/* Subscript/Superscript */}
		<button className="ql-script" value="sub" />
		<button className="ql-script" value="super" />

		{/* Color dropdown */}
		<select className="ql-color">
			<option value="black" />
			<option value="blue" />
			<option value="red" />
			<option value="green" />
			<option value="purple" />
			<option value="white" />
			<option value="yellow" />
			<option value="gray" />
		</select>

		{/* Right-to-left text direction */}
		<button className="ql-direction" value="rtl" />

		{/* Link, Image, Video */}
		<button className="ql-link" />
		<button className="ql-image" />
		<button className="ql-video" />

		{/* Clean formatting */}
		<button className="ql-clean" />
	</div>
);

const modules = {
	toolbar: '#toolbar'
};

const formats = [
	'header',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'check',
	'indent',
	'script',
	'color',
	'direction',
	'link',
	'image',
	'video'
];

export function EventInformation(props: {
	title: string;
	setTitle: React.Dispatch<React.SetStateAction<string>>;
	mainText: string;
	setMainText: React.Dispatch<React.SetStateAction<string>>;
	eventButtonName?: string;
	setEventButtonName?: React.Dispatch<React.SetStateAction<string>>;
	cautions?: string[];
	cautionsOrg?: string[];
	setCautions?: React.Dispatch<React.SetStateAction<string[]>>;
	thumbnailImg: File[];
	setThumbnailImg: React.Dispatch<React.SetStateAction<File[]>>;
	thumbnailImgUrl?: string[];
	setThumbnailImgUrl?: React.Dispatch<React.SetStateAction<string[]>>;
	shareImg?: File[];
	setShareImg?: React.Dispatch<React.SetStateAction<File[]>>;
	shareImgUrl?: string[];
	setShareImgUrl?: React.Dispatch<React.SetStateAction<string[]>>;
	detailedImgUrls?: string[];
	setDetailedImgUrls?: React.Dispatch<React.SetStateAction<string[]>>;
	detailedImgs?: File[];
	setDetailedImgs?: React.Dispatch<React.SetStateAction<File[]>>;
}) {
	const { t } = useTranslation();
	const { textColor, textColorPrimary } = useThemeColors();
	const {
		title,
		setTitle,
		mainText,
		setMainText,
		thumbnailImg,
		setThumbnailImg,
		thumbnailImgUrl,
		setThumbnailImgUrl,
		eventButtonName,
		setEventButtonName,
		cautions,
		cautionsOrg,
		setCautions,
		shareImg,
		setShareImg,
		shareImgUrl,
		setShareImgUrl,
		detailedImgs,
		setDetailedImgs,
		detailedImgUrls,
		setDetailedImgUrls
	} = props;

	const [inputFields, setInputFields] = useState([{ id: Date.now(), value: '' }]);

	useEffect(() => {
		if (cautions && cautions.length > 0) {
			cautions.map(caution => {
				const newField = { id: Date.now(), value: caution };
				setInputFields([...inputFields, newField]);
			});
		}
	}, [cautions]);

	return (
		<FormControl>
			<Card>
				<SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: '10px', xl: '10px' }}>
					<InputField
						id="name"
						label={t('common.title')}
						placeholder={t('common.title')}
						value={title}
						onChange={e => {
							setTitle(e.target.value);
						}}
					/>
					<InputField
						id="memo"
						label={t('common.banner_title')}
						h="100px"
						placeholder={t('common.banner_title')}
						value={mainText}
						onChange={e => {
							setMainText(e.target.value);
						}}
					/>

					<InputField
						id="button"
						label={t('event.button_name')}
						h="100px"
						placeholder={t('event.button_name')}
						value={eventButtonName}
						onChange={e => {
							setEventButtonName(e.target.value);
						}}
					/>

					<DynamicInputField
						label={'event.cautions'}
						placeholder={'notice.input_cautions_placeholder'}
						originalValues={cautionsOrg}
						values={cautions}
						setValues={setCautions}
					/>

					<DropImageBox
						imgFiles={detailedImgs}
						setImgFiles={setDetailedImgs}
						imgUrls={detailedImgUrls}
						setImgUrls={setDetailedImgUrls}
						maxFiles={5}
						title={t('event.detail_img')}
					/>

					<DropImageBox
						imgFiles={thumbnailImg}
						setImgFiles={setThumbnailImg}
						imgUrls={thumbnailImgUrl}
						setImgUrls={setThumbnailImgUrl}
						maxFiles={1}
						title={t('notice.thumbnail_img')}
					/>

					<DropImageBox
						imgFiles={shareImg}
						setImgFiles={setShareImg}
						imgUrls={shareImgUrl}
						setImgUrls={setShareImgUrl}
						maxFiles={1}
						title={t('notice.share_img')}
					/>
				</SimpleGrid>
			</Card>
		</FormControl>
	);
}

export function EventImages(props: {
	noticePlacementType: NoticePlacementType[];
	setNoticePlacementType: React.Dispatch<React.SetStateAction<NoticePlacementType[]>>;
	targetMemberTypes: MemberType[];
	setTargetMemberTypes: React.Dispatch<React.SetStateAction<MemberType[]>>;
	detailMovieUrls: string[];
	detailMovieUrlsOrg?: string[];
	setDetailMovieUrls: React.Dispatch<React.SetStateAction<string[]>>;
	startDate: Date;
	onStartDateChange: React.Dispatch<React.SetStateAction<Date>>;
	endDate: Date;
	onEndDateChange: React.Dispatch<React.SetStateAction<Date>>;
	isActive: boolean;
	setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { t } = useTranslation();
	const { textColor, brand, textColorPrimary, textColorSecondary } = useThemeColors();
	const {
		noticePlacementType,
		setNoticePlacementType,
		targetMemberTypes,
		setTargetMemberTypes,
		detailMovieUrls,
		detailMovieUrlsOrg,
		setDetailMovieUrls,
		startDate,
		onStartDateChange,
		endDate,
		onEndDateChange,
		isActive,
		setIsActive
	} = props;

	// 검색 필터 날짜
	const [isShowStartDate, setIsShowStartDate] = useBoolean(false);
	const [isShowEndDate, setIsShowEndDate] = useBoolean(false);

	return (
		<FormControl>
			<Card>
				<Flex direction="column" mb="40px" ms="10px">
					<DynamicInputField
						originalValues={detailMovieUrlsOrg}
						values={detailMovieUrls}
						setValues={setDetailMovieUrls}
						label={'event.detail_movie_url'}
						placeholder={'event.detail_movie_url'}
					/>
					<SimpleGrid columns={{ sm: 1 }} gap={'10px'}>
						<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
							{t('notice.target_member')}
						</Text>
						<Stack spacing={5} direction="row">
							<Checkbox
								isChecked={targetMemberTypes?.includes(MemberType.IDOL) || false}
								onChange={() => {
									if (targetMemberTypes?.includes(MemberType.IDOL)) {
										setTargetMemberTypes(targetMemberTypes?.filter(value => value !== MemberType.IDOL));
									} else {
										setTargetMemberTypes([...targetMemberTypes, MemberType.IDOL]);
									}
								}}
							>
								{t('common.idol')}
							</Checkbox>
							<Checkbox
								isChecked={targetMemberTypes?.includes(MemberType.PRODUCER)}
								onChange={() => {
									if (targetMemberTypes?.includes(MemberType.PRODUCER)) {
										setTargetMemberTypes(targetMemberTypes?.filter(value => value !== MemberType.PRODUCER));
									} else {
										setTargetMemberTypes([...targetMemberTypes, MemberType.PRODUCER]);
									}
								}}
							>
								{t('common.producer')}
							</Checkbox>
						</Stack>
					</SimpleGrid>
					<SimpleGrid columns={{ sm: 1 }} gap={'10px'} mt={'20px'}>
						<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
							{t('notice.target_placement_type')}
						</Text>
						<Stack spacing={5} direction="row">
							<Checkbox
								isChecked={noticePlacementType?.includes(NoticePlacementType.HOME)}
								onChange={() => {
									if (noticePlacementType?.includes(NoticePlacementType.HOME)) {
										setNoticePlacementType(noticePlacementType?.filter(value => value !== NoticePlacementType.HOME));
									} else {
										setNoticePlacementType([...noticePlacementType, NoticePlacementType.HOME]);
									}
								}}
							>
								HOME
							</Checkbox>
							<Checkbox
								isChecked={noticePlacementType?.includes(NoticePlacementType.LOOK_AROUND)}
								onChange={() => {
									if (noticePlacementType?.includes(NoticePlacementType.LOOK_AROUND)) {
										setNoticePlacementType(
											noticePlacementType?.filter(value => value !== NoticePlacementType.LOOK_AROUND)
										);
									} else {
										setNoticePlacementType([...noticePlacementType, NoticePlacementType.LOOK_AROUND]);
									}
								}}
							>
								LOOK_AROUND
							</Checkbox>
						</Stack>
					</SimpleGrid>
					{/*<SimpleGrid columns={{ sm: 1 }} gap={'10px'} mt={'20px'}>*/}
					{/*	<Text fontSize="md" color={textColorPrimary} fontWeight="bold">*/}
					{/*		{t('notice.notice_type')}*/}
					{/*	</Text>*/}
					{/*	<Select*/}
					{/*		placeholder={t('notice.notice_type')}*/}
					{/*		value={noticeType}*/}
					{/*		onChange={e => {*/}
					{/*			setNoticeType(e.target.value);*/}
					{/*		}}*/}
					{/*	>*/}
					{/*		<option value={NoticeType.SYSTEM}>{t('notice.type_system')}</option>*/}
					{/*		<option value={NoticeType.ADVERTISEMENT}>{t('notice.type_ad')}</option>*/}
					{/*	</Select>*/}
					{/*</SimpleGrid>*/}
					<SimpleGrid columns={{ sm: 1 }} gap={'10px'} mt={'20px'}>
						<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
							{t('common.enable')}
						</Text>
						<Checkbox
							isChecked={isActive}
							onChange={e => {
								setIsActive(e.target.checked);
							}}
						>
							{t('common.enable')}
						</Checkbox>
					</SimpleGrid>
				</Flex>
				<SimpleGrid columns={{ sm: 1 }} gap={'10px'} mt={'20px'}>
					<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
						{t('notice.notice_date')}
					</Text>
					<Stack spacing={5} direction="row">
						<Popover
							isOpen={isShowStartDate}
							onOpen={setIsShowStartDate.on}
							onClose={setIsShowStartDate.off}
							closeOnBlur={false}
							isLazy
							lazyBehavior="keepMounted"
						>
							<PopoverTrigger>
								<Button w="180px" colorScheme="purple">
									{dateFormat(startDate)}
								</Button>
							</PopoverTrigger>
							<PopoverContent bg={'transparent'} border={'0px'}>
								<MiniCalendar
									selectRange={false}
									mb="20px"
									onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
										if (endDate) {
											const timeStart = moment(endDate);
											const timeEnd = moment(value);
											const diff = timeEnd.diff(timeStart);
											if (diff > 0) {
												alert(t('user.list.filter_user_register_start_date_error'));
												return;
											}

											onStartDateChange(value);
											setIsShowStartDate.off();
										}
									}}
									value={startDate}
								/>
							</PopoverContent>
						</Popover>
						{' ~ '}
						<Popover
							isOpen={isShowEndDate}
							onOpen={setIsShowEndDate.on}
							onClose={setIsShowEndDate.off}
							closeOnBlur={false}
							isLazy
							lazyBehavior="keepMounted"
						>
							<PopoverTrigger>
								<Button w="180px" colorScheme="purple">
									{dateFormat(endDate)}
								</Button>
							</PopoverTrigger>
							<PopoverContent bg={'transparent'} border={'0px'}>
								<MiniCalendar
									selectRange={false}
									mb="20px"
									onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
										if (startDate) {
											const timeStart = moment(startDate);
											const timeEnd = moment(value);
											const diff = timeEnd.diff(timeStart);
											if (diff < 0) {
												alert(t('user.list.filter_user_register_end_date_error'));
												return;
											}

											onEndDateChange(value);
											setIsShowEndDate.off();
										}
									}}
									value={endDate}
								/>
							</PopoverContent>
						</Popover>
					</Stack>
				</SimpleGrid>
			</Card>
		</FormControl>
	);
}

/**
 *  "eventRewardType": "POINT",
 *   "eventRewardItemId": 0,
 *   "eventRewardItemExpireDt": "2024-10-09T02:20:29.836Z",
 *   "eventRewardPayType": "SEQUENCE",
 *   "eventRewardPayMaxSequence": 0,
 *   "eventRewardTotal": 0,
 *   "eventRewardUnit": 0,
 * @param props
 * @constructor
 */
export function EventRewards(props: {
	eventRewardType: EventRewardType;
	setEventRewardType: React.Dispatch<React.SetStateAction<EventRewardType>>;
	eventRewardItemId: number;
	setEventRewardItemId: React.Dispatch<React.SetStateAction<number>>;
	eventRewardItemExpireDt: Date;
	onEventRewardItemExpireDt: React.Dispatch<React.SetStateAction<Date>>;
	eventRewardPayType: EventRewardPayType;
	setEventRewardPayType: React.Dispatch<React.SetStateAction<EventRewardPayType>>;
	eventRewardPayMaxSequence: number;
	setEventRewardPayMaxSequence: React.Dispatch<React.SetStateAction<number>>;
	eventRewardTotal: number;
	setEventRewardTotal: React.Dispatch<React.SetStateAction<number>>;
	eventRewardUnit: number;
	setEventRewardUnit: React.Dispatch<React.SetStateAction<number>>;
	eventRewardBalance?: number;
}) {
	const { t } = useTranslation();
	const { textColor, brand, textColorPrimary, textColorSecondary } = useThemeColors();
	const {
		eventRewardType,
		setEventRewardType,
		eventRewardItemId,
		setEventRewardItemId,
		eventRewardItemExpireDt,
		onEventRewardItemExpireDt,
		eventRewardPayType,
		setEventRewardPayType,
		eventRewardPayMaxSequence,
		setEventRewardPayMaxSequence,
		eventRewardTotal,
		setEventRewardTotal,
		eventRewardUnit,
		setEventRewardUnit,
		eventRewardBalance
	} = props;

	// 검색 필터 날짜
	const [isExpiredDate, setIsExpiredDate] = useBoolean(false);

	return (
		<FormControl mt={'40px'}>
			<Card>
				<Flex direction="column" mb="40px" ms="10px">
					<SimpleGrid columns={{ sm: 1 }} gap={'10px'} mb={'10px'}>
						<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
							{t('event.reward_type')}
						</Text>
						<Select
							placeholder={t('notice.notice_type')}
							value={eventRewardType}
							onChange={e => {
								setEventRewardType(e.target.value as EventRewardType);
							}}
						>
							<option value={EventRewardType.NONE}>{t('common.none')}</option>
							<option value={EventRewardType.POINT}>{t('common.hypy_point')}</option>
							<option value={EventRewardType.ITEM}>{t('common.paid_item')}</option>
						</Select>
					</SimpleGrid>

					{eventRewardType === EventRewardType.ITEM ? (
						<InputField
							id="button"
							label={t('event.reward_item_id')}
							h="100px"
							placeholder={t('event.reward_item_id')}
							value={eventRewardItemId}
							type={'number'}
							onChange={e => {
								setEventRewardItemId(parseInt(e.target.value));
							}}
						/>
					) : null}

					{eventRewardType === EventRewardType.ITEM ? (
						<SimpleGrid columns={{ sm: 1 }} gap={'10px'} mb={'40px'}>
							<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
								{t('event.reward_item_expired_date')}
							</Text>
							<Stack spacing={5} direction="row">
								<Popover
									isOpen={isExpiredDate}
									onOpen={setIsExpiredDate.on}
									onClose={setIsExpiredDate.off}
									closeOnBlur={false}
									isLazy
									lazyBehavior="keepMounted"
								>
									<PopoverTrigger>
										<Button w="180px" colorScheme="purple">
											{dateFormat(eventRewardItemExpireDt)}
										</Button>
									</PopoverTrigger>
									<PopoverContent bg={'transparent'} border={'0px'}>
										<MiniCalendar
											selectRange={false}
											mb="20px"
											onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
												onEventRewardItemExpireDt(value);
												setIsExpiredDate.off();
											}}
											value={eventRewardItemExpireDt}
										/>
									</PopoverContent>
								</Popover>
							</Stack>
						</SimpleGrid>
					) : null}

					{eventRewardType !== EventRewardType.NONE ? (
						<SimpleGrid columns={{ sm: 1 }} gap={'10px'}>
							<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
								{t('event.reward_pay_type')}
							</Text>
							<Select
								placeholder={t('event.reward_pay_type')}
								value={eventRewardPayType}
								onChange={e => {
									setEventRewardPayType(e.target.value as EventRewardPayType);
								}}
							>
								<option value={EventRewardPayType.SEQUENCE}>{t('event.reward_pay_sequence')}</option>
								<option value={EventRewardPayType.TERM}>{t('event.reward_pay_term')}</option>
							</Select>
						</SimpleGrid>
					) : null}

					{eventRewardType !== EventRewardType.NONE && eventRewardPayType === EventRewardPayType.SEQUENCE ? (
						<InputField
							id="button"
							label={t('event.reward_pay_max_sequence')}
							h="100px"
							placeholder={t('event.reward_pay_max_sequence')}
							value={eventRewardPayMaxSequence}
							type={'number'}
							onChange={e => {
								setEventRewardPayMaxSequence(parseInt(e.target.value));
							}}
						/>
					) : null}

					{eventRewardType !== EventRewardType.NONE ? (
						<InputField
							id="button"
							label={t('event.reward_pay_total')}
							h="100px"
							placeholder={t('event.reward_pay_total')}
							value={eventRewardTotal}
							type={'number'}
							onChange={e => {
								setEventRewardTotal(parseInt(e.target.value));
							}}
						/>
					) : null}

					{eventRewardType !== EventRewardType.NONE ? (
						<InputField
							id="button"
							label={t('event.reward_pay_unit')}
							h="100px"
							placeholder={t('event.reward_pay_unit')}
							value={eventRewardUnit}
							type={'number'}
							onChange={e => {
								setEventRewardUnit(parseInt(e.target.value));
							}}
						/>
					) : null}

					{eventRewardBalance ? (
						<Stack>
							<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
								{t('event.reward_balance')}
							</Text>
							<Text fontSize="md" color={textColorPrimary} fontWeight="bold">
								{eventRewardBalance.toLocaleString()}
							</Text>
						</Stack>
					) : null}
				</Flex>
			</Card>
		</FormControl>
	);
}
