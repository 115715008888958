import { createContext, useContext, useEffect, useState } from 'react';
import { getCookie, setCookie } from '../helper/cookies';
import { useTranslation } from 'react-i18next';
import { KEY_USER_AUTHORITIES } from '../helper/keys';

export interface AdminAuth {
	typeRouteAuth: string;
	typeRoutePermission: string;
}

export type AuthContextValue = {
	currentUserPermissions: AdminAuth[];
	savePermissions: (newRoles: AdminAuth[]) => void;
	availableAdminMenus: string[];
};

export const AuthContext = createContext<AuthContextValue>(undefined!);

export const AuthProvider: React.FC<{
	initialPermissions: AdminAuth[];
	children?: any;
}> = ({ initialPermissions, children }) => {
	const { t } = useTranslation();
	const [userPermissions, setUserPermissions] = useState<AdminAuth[]>(null);
	const [availableMenus, setAvailableMenus] = useState<string[]>([]);

	useEffect(() => {
		// 초기 권한 값이 있다면 처리
		if (initialPermissions) {
			setUserPermissions(initialPermissions);
			initialPermissions.map((item, index) => {
				setAvailableMenus(menus => [...menus, item.typeRouteAuth]);
			});
		}
	}, [initialPermissions]);

	const savePermissions = (permissions: AdminAuth[]) => {
		setUserPermissions(permissions);
		permissions.map((item, index) => {
			setAvailableMenus(menus => [...menus, item.typeRouteAuth]);
		});

		// 권한 쿠키에 저장
		setCookie(KEY_USER_AUTHORITIES, JSON.stringify(permissions));
	};

	return (
		<AuthContext.Provider
			value={{
				currentUserPermissions: userPermissions,
				savePermissions: savePermissions,
				availableAdminMenus: availableMenus
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const context = useContext(AuthContext);

	if (context == null) {
		throw new Error("Cannot use 'useAuth' outside of AuthContext.");
	}

	return context;
};


export function checkApiAuthority(permissions: AdminAuth[], targetPermisison: string) {
	let isAvailable = false;

	permissions.map((item, index) => {
		if (item.typeRouteAuth === targetPermisison) {
			isAvailable = true;
		}
	});

	return isAvailable;
}

// 로그인 권한 정보 불러오기
export function getUserPermissions(): AdminAuth[] | null {
	if (typeof window !== 'undefined') {
		try {
			return JSON.parse(JSON.stringify(getCookie(KEY_USER_AUTHORITIES))) as AdminAuth[];
		} catch (e) {
			return null;
		}
	} else {
		return null;
	}
}
